import React, { useEffect } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { resetPayment } from "../../../../store/actions";
import "./ThankYouModal.scss";
import LogoIco from "../../../../assets/elf-logo-clr.png";
import { useNavigate } from "react-router-dom";

const ThankYouModal = (props) => {
    const { onHide, show } = props;
    const dispatch = useDispatch();
    let navigate = useNavigate();

    useEffect(() => {
        return () => dispatch(resetPayment());
    }, []);

    return (
        <Modal show={show} className="ThankYouModal" onHide={() => {
            onHide();
            navigate("/");
        }}>
            <Modal.Header closeButton className="pb-0">
                <Modal.Title>
                    <div className="TitleContent">
                        <h5>Educate Lanka</h5>
                        <small>Gift Card Donation Confirmation</small>
                        <img src={LogoIco} width="50" alt="logo" />
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-4">
                <Row>
                    <Col style={{margin:"0 1rem"}}>
                        <h6 style={{ textAlign: "center" }}>Thank you for purchasing an Educate Lanka Gift Card with your donation and giving the gift of an education to a deserving student.
                        </h6>
                        <h6 style={{ textAlign: "center" }}>The Gift Card has been emailed to the recipient and a receipt is shared with you as well.
                        </h6>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default ThankYouModal;
