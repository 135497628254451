/*eslint-disable*/
import React from "react";
import "./Page404.scss";
import LogoIcon from "../../../assets/elf-logo-clr.png";

function index() {
  return (
    <div className="Page404">
      <img src={LogoIcon} />
      <br />
      <h1>404 Not found</h1>
    </div>
  );
}

export default index;
