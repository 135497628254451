import React from 'react'
import './BlueImpactArea.scss'

function BlueImpactArea() {
    return (
        <div className="BlueImpactArea">
            <div className="row">
                <div className="col-lg-3">
                    <div className="handrend">100%</div>
                </div>
                <div className="col-lg-9">
                    <div className="row">
                        <div className="col-lg-3">
                            <h2>IMPACT</h2>
                            <p>We maximize impact of our student sponsorships and donations by transferring 100% of them to scholars and direct program costs.</p>
                        </div>
                        <div className="col-lg-3">
                            <h2>TRANSPARENCY</h2>
                            <p>By making end-to-end communication and reporting the heart of our efforts, we assure complete transparency to everyone.</p>
                        </div>
                        <div className="col-lg-3">
                            <h2>LOCAL PARTICIPATION</h2>
                            <p>Our own networks of local liaisons and alumni allow us to secure critical input and contribution from local communities.</p>
                        </div>
                        <div className="col-lg-3">
                            <h2>ACCOUNTABILITY</h2>
                            <p>Our unique approach that merges a global platform with our own local networks empowers us to be responsible for our operations and accountable for our impact.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="text-center">These four principles guide our approach to the issue and define our commitment to the mission.</div>
                </div>
            </div>
        </div>
    )
}

export default BlueImpactArea
