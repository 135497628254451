import React, { useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
function TemplateTwelve({ data }) {
  return (
    <section className="gMap" id={data?.title?.trim().split(" ").join("").toLowerCase()}>
      <iframe
        src={data.iframe}
        width="100%"
        height="450"
        allowfullscreen=""
        loading="lazy"
      ></iframe>
    </section>
  );
}

export default TemplateTwelve;
