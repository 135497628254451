/*eslint-disable*/
import React, { useState, useEffect } from "react";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import "./BirthdayHeader.scss";
import { Button, ButtonBlue } from "../../Reusable/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { daysUntilBirthday } from "../../../utils/func";
import * as actionCreators from "../../../store/actions";
import { useNavigate } from "react-router-dom";

const months = [
  { label: "January", value: "1" },
  { label: "February", value: "2" },
  { label: "March", value: "3" },
  { label: "April", value: "4" },
  { label: "May", value: "5" },
  { label: "June", value: "6" },
  { label: "July", value: "7" },
  { label: "August", value: "8" },
  { label: "September", value: "9" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];

const days = [
  { label: "01", value: "1" },
  { label: "02", value: "2" },
  { label: "03", value: "3" },
  { label: "04", value: "4" },
  { label: "05", value: "5" },
  { label: "06", value: "6" },
  { label: "07", value: "7" },
  { label: "08", value: "8" },
  { label: "09", value: "9" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
  { label: "18", value: "18" },
  { label: "19", value: "19" },
  { label: "20", value: "20" },
  { label: "21", value: "21" },
  { label: "22", value: "22" },
  { label: "23", value: "23" },
  { label: "24", value: "24" },
  { label: "25", value: "25" },
  { label: "26", value: "26" },
  { label: "27", value: "27" },
  { label: "28", value: "28" },
  { label: "29", value: "29" },
  { label: "30", value: "30" },
  { label: "31", value: "31" },
];

const years = [];

for (let year = 2024; year >= 1904; year--) {
  const yearString = year.toString();
  years.push({ label: yearString, value: yearString });
}

function BirthdayHeader({
  handleBirthdaySubmit,
  section,
  setHideModal,
  data,
  setData,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { me } = useSelector((state) => state.auth);
  const campaign = useSelector((state) => state.campaign);
  const [validator, setValidator] = useState(
    new SimpleReactValidator({
      autoForceUpdate: this,
    })
  );
  const [validationLoad, setValidationLoad] = useState(false);

  const [currentBirthdayCampaignList, setCurrentBirthdayCampaignList] =
    useState([]);

  useEffect(() => {
    if (me && me.data && me.data.birthday) {
      const bd = moment(me.data.birthday).format("YYYY-MM-DD");
      const check = moment(bd, "YYYY/MM/DD");
      setData({
        month: check.format("M"),
        year: check.format("YYYY"),
        date: check.format("D"),
      });
      dispatch(actionCreators.getCampaignList(me.data.id));
    }
  }, [me]);

  // Function to check if there are any unexpired campaigns
  const hasUnexpiredCampaign = (campaigns) => {
    const now = new Date();
    return campaigns.some((campaign) => {
      const endDate = new Date(campaign.endDate);
      return endDate > now;
    });
  };

  // Function to get the latest unexpired campaign
  const getLatestUnexpiredCampaign = (campaigns) => {
    const now = new Date();

    // Filter out expired campaigns
    const unexpiredCampaigns = campaigns.filter((campaign) => {
      const endDate = new Date(campaign.endDate);
      return endDate > now;
    });

    // Find the latest unexpired campaign
    const latestCampaign = unexpiredCampaigns.reduce((latest, campaign) => {
      const campaignEndDate = new Date(campaign.endDate);
      if (!latest || campaignEndDate > new Date(latest.endDate)) {
        return campaign;
      }
      return latest;
    }, null);

    return latestCampaign;
  };

  useEffect(() => {
    if (campaign?.campaignList?.length > 0) {
      const filterBirthDayCampaignOnly = campaign?.campaignList?.filter(
        (c) => c?.type === "Birthday"
      );
      setCurrentBirthdayCampaignList(filterBirthDayCampaignOnly);
    }
  }, [campaign?.campaignList]);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    if (validator.allValid()) {
      handleBirthdaySubmit(
        moment.utc(`${data.month}-${data.date}-${data.year}`)._d
      );
      setValidationLoad(!validationLoad);
    } else {
      setValidationLoad(!validationLoad);
      validator.showMessages();
    }
  };

  return (
    <div
      id={section?.data?.title?.trim().split(" ").join("").toLowerCase()}
      className="BirthdayHeader"
      style={{ backgroundImage: `url(${section?.data?.image})` }}
    >
      <div className="FundBox" id="birthday">
        <div dangerouslySetInnerHTML={{ __html: section?.data?.text1 }}></div>

        <div className="BirthDayBox">
          {hasUnexpiredCampaign(currentBirthdayCampaignList) ? (
            <>
              <div className="text-center mb-3">
                <p>
                  Thank you for donating your birthday to Educate Lanka. <br />
                  We hope you will join us again next year.
                </p>
              </div>
              <div className="PledgeShare">
                <ButtonBlue
                  title="View my campaign"
                  full
                  onClick={() =>
                    navigate(
                      `/campaign/${
                        getLatestUnexpiredCampaign(currentBirthdayCampaignList)
                          ?.id
                      }`
                    )
                  }
                />
                <div
                  dangerouslySetInnerHTML={{ __html: section?.data?.text2 }}
                ></div>
              </div>
            </>
          ) : daysUntilBirthday(me?.data?.birthday) <= 30 ? (
            <>
              <div className="text-center mb-3">
                <p>
                  Thank you for pledging your birthday for education.
                  <br /> It looks like your birthday is coming up soon.
                </p>
              </div>
              <div className="PledgeShare">
                <Button
                  title="Create my campaign now"
                  full
                  onClick={() => navigate("/create-campaign")}
                />
                <div
                  dangerouslySetInnerHTML={{ __html: section?.data?.text2 }}
                ></div>
              </div>
            </>
          ) : !me?.data?.pledge ? (
            <>
              <div className="inputContainer">
                <div className="formInp">
                  {validator.message(
                    "month",
                    data.month,
                    "required|integer|between:1,12,num"
                  )}
                  <select
                    id="month"
                    name="month"
                    placeholder="MM"
                    value={data.month}
                    onChange={handleChange}
                  >
                    <option value="">MM</option>
                    {months.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="formInp">
                  {validator.message(
                    "date",
                    data.date,
                    "required|integer|between:1,31,num"
                  )}
                  <select
                    id="date"
                    name="date"
                    placeholder="DD"
                    value={data.date}
                    onChange={handleChange}
                  >
                    <option value="">DD</option>
                    {days.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="formInp">
                  {validator.message(
                    "year",
                    data.year,
                    `required|integer|between:1930,${moment().year()},num`
                  )}
                  <select
                    id="year"
                    name="year"
                    placeholder="YYYY"
                    value={data.year}
                    onChange={handleChange}
                  >
                    <option value="">YYYY</option>
                    {years.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="text-center mb-3">
                <small>{section?.data?.subText}</small>
              </div>
              <div className="PledgeShare">
                {me?.data?.pledge ? (
                  <Button
                    title="Create my campaign now"
                    full
                    onClick={() => setHideModal(true)}
                  />
                ) : (
                  <Button
                    title={
                      me?.data?.pledge
                        ? "Thank you for your pledge"
                        : section?.data?.buttonText
                    }
                    full
                    onClick={handleSubmit}
                  />
                )}
                <div
                  dangerouslySetInnerHTML={{ __html: section?.data?.text2 }}
                ></div>
              </div>
            </>
          ) : (
            <>
              <div className="text-center mb-3">
                <p>
                  Thank you for pledging your birthday for education.
                  <br /> We will remind you closer to your birthday to create
                  your birthday campaign fundraiser.
                  <br /> You can also choose to create your campaign now.
                </p>
              </div>
              <div className="PledgeShare">
                <Button
                  title="Create my campaign now"
                  full
                  onClick={() => navigate("/create-campaign")}
                />
                <div
                  dangerouslySetInnerHTML={{ __html: section?.data?.text2 }}
                ></div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default BirthdayHeader;
