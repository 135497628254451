/*eslint-disable*/
import React from "react";

import NotFound from "../../components/Reusable/NotFound";
import { useSelector } from "react-redux";
import PageSections from "../../components/Reusable/PageSections/PageSections";
import { useLocation } from "react-router";

function BlankPage() {
  const location=useLocation()
  const { list } = useSelector((state) => state.pages);
  const page = list?.find((p) => p.link === location.pathname);

  if (page) {
    return <PageSections pageSections={page?.pageSections} />;
  } else {
    return <NotFound />;
  }
}

export default BlankPage;
