import { mapKeys } from "lodash";
import React from "react";
import "./SearchArea.scss";
import { Form } from "react-bootstrap";

function Summary({
  selectedOption,
  count,
  handleClear,
  setSelectedOption,
  setFilter,
}) {
  const { Location, AcademicLevel, Age, Gender, Amount, keyword } =
    selectedOption;
  let clearBtn = null;

  let keys = <span></span>;

  if (
    Location.length ||
    AcademicLevel.length ||
    Age.length ||
    Gender.length ||
    Amount.length ||
    keyword
  ) {
    clearBtn = (
      <strong className="clearBtn" onClick={handleClear}>
        <i className="fa fa-close" /> Clear
      </strong>
    );
    keys = (
      <div>
        {Location.length ? (
          <span>
            <strong>Location</strong> {Location.map((e) => e.label).join(", ")}
          </span>
        ) : (
          ""
        )}
        {AcademicLevel.length ? (
          <span>
            <strong>Academic Level</strong>{" "}
            {AcademicLevel.map((e) => e.label).join(", ")}
          </span>
        ) : (
          ""
        )}
        {Age.length ? (
          <span>
            <strong>Age</strong> {Age.map((e) => e.label).join(", ")}
          </span>
        ) : (
          ""
        )}
        {Gender.length ? (
          <span>
            <strong>Gender</strong> {Gender.map((e) => e.label).join(", ")}
          </span>
        ) : (
          ""
        )}
        {Amount.length ? (
          <span>
            <strong>Amount</strong> {Amount.map((e) => e.label).join(", ")}
          </span>
        ) : (
          ""
        )}
        {keyword ? (
          <span>
            <strong>keyword</strong> {keyword}
          </span>
        ) : (
          ""
        )}
      </div>
    );
  }

  return (
    <div className="filtersSummery">
      <div>
        {clearBtn}
        {keys}
        {!(
          Location.length ||
          AcademicLevel.length ||
          Age.length ||
          Gender.length ||
          Amount.length ||
          keyword
        ) && (
          <span>
            <strong>Result</strong> : {count}
          </span>
        )}
      </div>
      {(Location.length ||
        AcademicLevel.length ||
        Age.length ||
        Gender.length ||
        Amount.length ||
        keyword) && (
        <div>
          <span>
            <strong>Result</strong> : {count}
          </span>
        </div>
      )}
      <div>
        <Form className="text-center">
          <Form.Check // prettier-ignore
            type="switch"
            id="custom-switch"
            label="Display Sponsored Students"
            onChange={() => {
              setSelectedOption({
                ...selectedOption,
                IsSponsored: !selectedOption?.IsSponsored,
              });
              setFilter(true);
            }}
            checked={selectedOption?.IsSponsored}
            style={{
              cursor: "pointer",
              color: "#00af6b",
              fontSize: "1rem",
            }}
          />
        </Form>
      </div>
    </div>
  );
}

export default Summary;
