import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { FormControl, FormGroup, FormLabel, Button } from "react-bootstrap";
import { useEffect } from "react";

function TemplateSix({ section, editMode, handleSectionDataChange }) {
  const [data, setData] = useState({
    title: "",
    image: "",
    text1: "",
    phones: [],
    emails: [],
  });
  useEffect(() => {
    handleSectionDataChange({
      ...section,
      index: section.index,
      data: data,
    });
  }, [data]);

  //===EDIT MODE SET DATA
  useEffect(() => {
    if (editMode && section.data) {
      setData(section.data);
    }
  }, [editMode]);
  //===END EDIT MODE SET DATA

  const handleChange = (e) => {
    setData((dt) => ({ ...dt, [e.target.name]: e.target.value }));
  };

  const handleChangePhone = (e) => {
    const copyPhones = [...data.phones];
    const phoneIndex = e.target.name.split("_")[1];
    copyPhones[phoneIndex].phone = e.target.value;
    setData((dt) => ({ ...dt, phones: copyPhones }));
  };
  const handleChangeEmail = (e) => {
    const copyEmails = [...data.emails];
    const emailIndex = e.target.name.split("_")[1];
    copyEmails[emailIndex].email = e.target.value;
    setData((dt) => ({ ...dt, emails: copyEmails }));
  };
  const addSection = (type) => {
    if (type === "phone") {
      setData((dt) => ({ ...dt, phones: [...dt.phones, { phone: "" }] }));
    } else {
      setData((dt) => ({ ...dt, emails: [...dt.emails, { email: "" }] }));
    }
  };
  const handleChangeTextOne = (e) => {
    setData((dt) => ({ ...dt, text1: e }));
  };
  return (
    <div>
      <FormGroup>
        <FormLabel>Title</FormLabel>
        <FormControl name="title" value={data?.title} onChange={handleChange} />
      </FormGroup> 
      <FormGroup>
        <FormLabel>Background Image</FormLabel>
        <FormControl name="image" value={data?.image} onChange={handleChange} />
      </FormGroup>

      <FormGroup>
        <FormLabel>Content</FormLabel>
        <Editor
          name="text1"
          value={data?.text1}
          apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
          init={{
            plugins: [
              "lists link image paste help wordcount code",
              "searchreplace",
              "insertdatetime media",
            ],
            image_title: true,
            image_dimensions: true,
            image_advtab: true
          }}
          onEditorChange={handleChangeTextOne}
        />
      </FormGroup>
      
      {data?.phones?.map((v, i) => (
        <FormGroup key={i}>
          <FormLabel>Phone {i + 1}</FormLabel>
          <FormControl
            name={`phone_${i}`}
            value={data?.phones[i].phone}
            onChange={handleChangePhone}
          />
        </FormGroup>
      ))}

      <div className="mt-3"><Button onClick={() => addSection("phone")}>ADD PHONE NUMBER</Button></div>
      {data?.emails?.map((v, i) => (
        <FormGroup key={i}>
          <FormLabel>Email {i + 1}</FormLabel>
          <FormControl
            name={`email_${i}`}
            value={data?.emails[i].email}
            onChange={handleChangeEmail}
          />
        </FormGroup>
      ))}
      <div className="mt-3"><Button onClick={() => addSection("email")}>ADD EMAIL ADDRESS</Button></div>
    </div>
  );
}

export default TemplateSix;
