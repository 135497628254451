import React from "react";
import { Modal } from "react-bootstrap";
import LogoIco from "../../../../assets/elf-logo-clr.png";

import "./SharedModal.scss";

const SharedModal = (props) => {
  return (
    <Modal {...props} className="SharedModal">
      <Modal.Header closeButton className="pb-0">
        <Modal.Title>
          <div className="TitleContent">
            <h5>Educate Lanka</h5>
            {props.title ? <h2>{props.title}</h2> : undefined}
            {props?.subTitle ? <small>{props?.subTitle}</small> : undefined}
            <img src={LogoIco} width="50" alt="logo" />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-4">
        {props.children}
      </Modal.Body>
    </Modal>
  );
};

export default SharedModal;
