import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import AddForm from "../../../../components/admin/CMS/Sections/AddForm";
import {
  createSection,
  getSection,
  updateSection,
} from "../../../../store/actions";

function Add() {
  const dispatch = useDispatch();
  const { loading, data: section } = useSelector((state) => state.section);
  const [editMode, setEditMode] = useState(false);
  //======EDIT MODE===========
  const params = useParams();
  useEffect(() => {
    if (params.id) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  }, [params]);

  useEffect(() => {
    if (editMode) {
      dispatch(getSection(params.id));
    }
}, [editMode]);

  const handleSubmit = async (obj) => {
    const defaultValue=JSON.stringify({ test: "test" })
    if (editMode) {
      section.name = obj.name;
      section.defaultValue = defaultValue;
      dispatch(updateSection(section));
    } else {
      obj.defaultValue = defaultValue;
      await dispatch(createSection(obj));
    }
  };
  return (
    <div>
      <div className="HeaderTextCMS">New Section</div>
      <AddForm
        data={section}
        editMode={editMode}
        handleFormSubmit={handleSubmit}
      />
    </div>
  );
}

export default Add;
