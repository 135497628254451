import * as actions from "../actions/actionsTypes";

const initialState = {
  loading: false,
  regErr: null,
  regData: null,
  logErr: null,
  logData: null,
  token: null,
  impersonateToken: null,
  meLoading: false,
  me: null,
  meError: null,
  users: null,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.REMOVE_USER_UPDATE_DATA:
      return {
        ...state,
        regData: null,
        logErr: null,
      };
    case actions.INIT_USER_REGISTER:
      return {
        ...state,
        loading: true,
      };
    case actions.SUCCESS_USER_REGISTER:
      return {
        ...state,
        regErr: null,
        loading: false,
        regData: action.data,
      };
    case actions.FAIL_USER_REGISTER:
      return {
        ...state,
        regData: null,
        loading: false,
        regErr: action.err,
      };



    case actions.INIT_USER_EDIT:
      return {
        ...state,
        loading: true,
      };
    case actions.SUCCESS_USER_EDIT:
      return {
        ...state,
        regErr: null,
        loading: false,
        regData: action.data,
      };
    case actions.FAIL_USER_EDIT:
      return {
        ...state,
        regData: null,
        loading: false,
        regErr: action.err,
      };

    case actions.INIT_USER_LOGIN:
      return {
        ...state,
        loading: true,
      };
    case actions.SUCCESS_USER_LOGIN:
      return {
        ...state,
        logErr: null,
        loading: false,
        logData: action.data,
        token: action.data.token,
      };
    case actions.FAIL_USER_LOGIN:
      return {
        ...state,
        logData: null,
        loading: false,
        logErr: "action.err",
        auth: null,
      };

    case actions.INIT_IMPERSONATE:
      return {
        ...state,
        loading: true,
      };
    case actions.SUCCESS_IMPERSONATE:
      return {
        ...state,
        logErr: null,
        loading: false,
        logData: action.data,
        impersonateToken: action.data.token,
      };
    case actions.FAIL_IMPERSONATE:
      return {
        ...state,
        logData: null,
        loading: false,
        logErr: "action.err",
        auth: null,
      };


    case actions.INIT_ME:
      return {
        ...state,
        meLoading: true,
      };
    case actions.SUCCESS_ME:
      return {
        ...state,
        meLoading: false,
        me: action.data,
        meError: null,
      };
    case actions.FAIL_ME:
      return {
        ...state,
        meLoading: false,
        me: null,
        meError: action.err,
      };

    case actions.AUTH_CHECK:
      return {
        ...state,
        token: action.token,
        impersonateToken: action.impersonateToken,
        meLoading: false,
        me: null,
        meError: null,
      };
    case actions.INIT_GET_ALL_USERS:
      return {
        ...state,
        loading: true,
      };
    case actions.SUCCESS_GET_ALL_USERS:
      return {
        ...state,
        loading: false,
        users: action.data,
      };
    case actions.FAIL_GET_ALL_USERS:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export default reducer;
