import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { FormControl, FormGroup, FormLabel, Button,Row,Col } from "react-bootstrap";
import { useEffect } from "react";

function TemplateSix({ section, editMode, handleSectionDataChange }) {
  const [data, setData] = useState({
    title: "",
    videos: [],
  });
  useEffect(() => {
    handleSectionDataChange({
      ...section,
      index: section.index,
      data: data,
    });
  }, [data]);

  //===EDIT MODE SET DATA
  useEffect(() => {
    if (editMode && section.data) {
      setData(section.data);
    }
  }, [editMode]);
  //===END EDIT MODE SET DATA

  const handleChange = (e) => {
    setData((dt) => ({ ...dt, [e.target.name]: e.target.value }));
  };

  const handleChangeUrl = (e) => {
    const copyVideo = [...data.videos];
    const videoIndex = e.target.name.split("_")[1];
    copyVideo[videoIndex][e.target.name.split("_")[0]] = e.target.value;
    setData((dt) => ({ ...dt, videos: copyVideo }));
  };
  const addSection = (e) => {
    setData((dt) => ({
      ...dt,
      videos: [...dt.videos, { code: "", title: "" }],
    }));
  };

  const handleSectionDelete = (index) => {
    if (window.confirm("Are you sure to delete this?")) {
      const copyData = {...data};
      copyData.videos.splice(index,1)
      setData(copyData);
    }
  };
  return (
    <div>
      <FormGroup>
        <FormLabel>Title</FormLabel>
        <FormControl name="title" value={data?.title} onChange={handleChange} />
      </FormGroup>
      
 
      <Row className="RowColors">
      {data?.videos?.map((v, i) => (
          <Col md={4}>
            <FormGroup key={i}>
              <FormLabel>Video Code {i + 1}</FormLabel>
              <FormControl
                name={`code_${i}`}
                value={data?.videos[i].code}
                onChange={handleChangeUrl}
              />
            </FormGroup>
            <FormGroup key={i}>
              <FormLabel>Video Title {i + 1}</FormLabel>
              <FormControl
                name={`title_${i}`}
                value={data?.videos[i].title}
                onChange={handleChangeUrl}
              />
            </FormGroup>
            <Button
              onClick={() => handleSectionDelete(i)}
              className="mb-4"
              variant="danger"
            >
              <i className="fa fa-trash" />
            </Button>
          </Col>
      ))}
      </Row>

      <Button onClick={addSection}>ADD YOUTUBE VIDEO URL</Button>
    </div>
  );
}

export default TemplateSix;
