import moment from "moment";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateMessage } from "../../../store/actions";
import { isValidHttpUrl } from "../../../utils/func";
import MessagesList from "./MessagesList";

function List(props) {
  const { chats } = props;
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [selectedChat, setSelectedChat] = useState(0);

  let cList = null;

  const handleClick = (index, ch) => {
    setSelectedChat(index);
    if (!ch.readAt) {
      const data = {
        sponsorshipId: ch.sponsorshipId,
        time: moment(),
        admin: true,
      };
      dispatch(updateMessage(data, token));
    }
  };

  if (chats?.length > 0) {
    cList = chats?.map((c, index) => {
      const spImage = isValidHttpUrl(c.user?.image)
        ? c.user?.image
        : process.env.REACT_APP_SERVER + c.user?.image;
      return (
        <div
          key={c.id}
          className={`sponsorshipChat ${
            index === selectedChat ? "active" : ""
          }`}
          onClick={() => handleClick(index, c)}
        >
          <div
            className="img"
            style={
              c.beneficiary?.image && {
                backgroundImage: `url('${c.beneficiary?.image}')`,
              }
            }
          ></div>
          <div
            className="img"
            style={spImage && { backgroundImage: `url('${spImage}')` }}
          ></div>
          <div className="detailBox">
            <div>
              <span>Sponsor</span> {c?.user?.firstName}
            </div>
            <div>
              <span>Student</span> {c?.beneficiary?.firstName}
            </div>
            <div className="date">{moment(c?.createdAt).fromNow()}</div>
            {!c.readAt && <div className="new"></div>}
          </div>
        </div>
      );
    });
  }
  return (
    <div className="messageContainer">
      {cList} <MessagesList list={chats[selectedChat]?.list} />
    </div>
  );
}

export default List;
