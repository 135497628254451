import * as actions from "./actionsTypes";
import axios from "../../axios";
import { toast } from "react-toastify";

const initSection = () => ({
  type: actions.INIT_SECTION,
});
const errorSection = (error) => ({
  type: actions.FAIL_SECTION,
  error,
});
const addSection = (data) => ({
  type: actions.SUCCESS_CREATE_SECTION,
  data,
});
const listingSection = (list) => ({
  type: actions.SUCCESS_LIST_SECTION,
  list,
});
const getSingleSection = (data) => ({
  type: actions.SUCCESS_SECTION,
  data,
});
const deletedSection = (sectionId) => ({
  type: actions.SUCCESS_DELETE_SECTION,
  sectionId,
});
export const listSection = (payload) => async (dispatch) => {
  try {
    dispatch(initSection());
    const section = await axios.get("sections?page=1&perPage=100");
    dispatch(listingSection(section?.data));
    return section;
  } catch (error) {
    dispatch(errorSection(error));
  }
};
export const getSection = (id) => async (dispatch) => {
  try {
    dispatch(initSection());
    const section = await axios.get(`sections/${id}`);
    dispatch(getSingleSection(section?.data?.data));
    return section;
  } catch (error) {
    dispatch(errorSection(error));
  }
};
export const createSection = (payload) => async (dispatch) => {
  try {
    dispatch(initSection());
    const section = await axios.post("sections", payload);
    dispatch(addSection(section?.data?.data));
    toast.success("Section added successfully.", { autoClose: 3000 });
  } catch (error) {
    dispatch(errorSection(error));
    toast.error("Section added fail.", { autoClose: 3000 });
  }
};
export const updateSection = (payload) => async (dispatch) => {
  try {
    dispatch(initSection());
    const section = await axios.patch(`sections/${payload.id}`, payload);
    dispatch(addSection(section?.data?.data));
    toast.success("Section updated successfully.", { autoClose: 3000 });
    return section;
  } catch (error) {
    dispatch(errorSection(error));
    toast.error("Section updated fail.", { autoClose: 3000 });
  }
};
export const deleteSection = (id) => async (dispatch) => {
  try {
    dispatch(initSection());
    const section = await axios.delete(`sections/${id}`);
    dispatch(deletedSection(id));
    toast.success("Section delete successfully.", { autoClose: 3000 });
    return section;
  } catch (error) {
    dispatch(errorSection(error));
    toast.error("Section delete fail.", { autoClose: 3000 });
  }
};
