import * as actions from "./actionsTypes";
import axios from "../../axios";

import { getStudentNews, resetStudentNews } from "./news";

const initStudents = () => ({
  type: actions.INIT_STUDENTS,
});

const successStudents = (data, fresh) => ({
  type: actions.SUCCESS_STUDENTS,
  data: data,
  fresh: fresh,
});

const failStudents = (err) => ({
  type: actions.FAIL_STUDENTS,
  err,
});
// const successStudentLimit = (data) => ({
//   type: actions.SUCCESS_STUDENTS_LIMIT,
//   data: data,
// });

const initOneStudent = () => ({
  type: actions.INIT_ONE_STUDENT,
});

const successOneStudent = (data) => ({
  type: actions.SUCCESS_ONE_STUDENT,
  data: data,
});

const failOneStudent = (err) => ({
  type: actions.FAIL_ONE_STUDENT,
  err,
});

export const resetOneStudent = () => ({
  type: actions.RESET_ONE_STUDENTS,
});

export const getAllStudents = (page, filters) => {
  console.log(filters);
  return (dispatch) => {
    const params = {
      page: page,
      AcademicLevel: filters?.AcademicLevel?.map(e=>e.value),
      Age: filters?.Age?.map(e=>e.value),
      Gender: filters?.Gender?.map(e=>e.value),
      Location: filters?.Location?.map(e=>e.value),
      Amount: filters?.Amount?.map(e=>e.value),
      keyword: filters?.keyword,
      order: filters?.order,
      pageSize: filters?.pageSize,
      attributes: filters?.attributes,
      allTypes: filters?.allTypes,
      selected: filters?.selected,
      IsSponsored : filters?.IsSponsored ?? false
    };

    dispatch(initStudents());
    axios
      .get(`beneficiaries`, {
        params: params,
      })
      .then((res) => {
        return dispatch(successStudents(res.data));
      })
      .catch((err) => {
        console.log(err.error);
        return dispatch(failStudents(err.response));
      });
  };
};

export const getOneStudent = (id) => {
  return (dispatch) => {
    dispatch(initOneStudent());
    dispatch(resetStudentNews());
    axios
      .get(`beneficiaries/${id}`)
      .then((res) => {
        dispatch(getStudentNews(res.data.data.detail.Id));
        return dispatch(successOneStudent(res.data));
      })
      .catch((err) => {
        
        return dispatch(failOneStudent(err));
      });
  };
};
export const getOneStudentFromRefId = (refId) => {
  return (dispatch) => {
    dispatch(initOneStudent());
    dispatch(resetStudentNews());
    axios
      .get(`beneficiaries/ref/${refId}`)
      .then((res) => {
        dispatch(getStudentNews(res.data.data.detail.Id));
        return dispatch(successOneStudent(res.data));
      })
      .catch((err) => {
        
        return dispatch(failOneStudent(err));
      });
  };
};
