import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Pagination } from "react-bootstrap";
import ReactPaginate from "react-paginate";

import SearchArea from "../../components/StudentSearch/SearchArea/SearchArea";
import StudentTag from "../../components/StudentSearch/StudentTag/StudentTag";

// import Loading from "../../components/Reusable/Loading/Loading";
import "./StudentSearch.scss";

import * as actionCreators from "../../store/actions/index";
import { useNavigate, useParams } from "react-router";
import { CommonLoading } from "../../utils/CommonLoading";

function StudentSearch(props) {
  const [fillFilters, setFillFilters] = useState(null);
  const { getStudents } = props;
  const params = useParams();
  const history = useNavigate();
  const [pageNumber, setPageNumber] = useState(params?.pageNumber || 1);
  let navigate = useNavigate();
  useEffect(() => {
    getStudents(pageNumber);
  }, [getStudents]);

  useEffect(() => {
    getStudents(pageNumber + 1, fillFilters);
  }, [pageNumber, fillFilters]);

  const handleCurrentPagination = async (page) => {
    setPageNumber(page.selected ?? 1);
    scrollToTop();
    //await getStudents(page.selected, fillFilters);
  };
  const filterChange = async (filters) => {
    setPageNumber(0);
    setFillFilters({ ...fillFilters, ...filters });
  };

  let students;

  let pageCount;

  if (props.data) {
    pageCount = props.data.pages;
    if (props.data && Array.isArray(props.data.rows)) {
      students =
        props.data.rows &&
        props.data.rows.length > 0 &&
        props.data.rows.map((student) => {
          return (
            <Col md={4} key={student.id}>
              <StudentTag studentData={student} />
            </Col>
          );
        });

      if (props.data.rows && props.data.rows.length === 0) {
        students = <h1 className="notFound">Students not found.</h1>;
      }
    }
  }

  // Step 1: Create a ref using useRef
  const scrollableDivRef = useRef(null);

  // Step 2: Implement the scrollToTop function
  const scrollToTop = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current?.scrollIntoView({ behavior: "smooth" })
    }
  };

  return (
    <>
      <SearchArea
        scrollableDivRef={scrollableDivRef}
        filterChange={filterChange}
        count={props.data?.count}
        loading={props.loading}
      />
      <div className="StudentSearch">
        <Row className="justify-content-center">{students}</Row>
        {props.data && props.data.count > 0 && (
          <ReactPaginate
            pageCount={props.data.pages}
            onPageChange={handleCurrentPagination}
            containerClassName="pagination"
            nextLabel={<i className="fa fa-arrow-right" aria-hidden="true"></i>}
            previousLabel={
              <i className="fa fa-arrow-left" aria-hidden="true"></i>
            }
            pageRangeDisplayed={10}
            marginPagesDisplayed={1}
            initialPage={0}
            forcePage={pageNumber}
          />
        )}
      </div>
      <div id="ourcommittment" class="BlueImpactArea">
        <div class="row">
          <div class="col-lg-3">
            <div class="handrend">100%</div>
          </div>
          <div class="col-lg-9">
            <div class="row">
              <div class="col-lg-3">
                <h2>IMPACT</h2>
                <div>
                  <p>
                    We ensure the full impact of student sponsorships and
                    program donations by directing 100% of funds to our scholars
                    through micro-scholarships and program servicing.
                  </p>
                </div>
              </div>
              <div class="col-lg-3">
                <h2>TRANSPARENCY</h2>
                <div>
                  <p>
                    We prioritize open communication and reporting at the core
                    of our program and operations, ensuring complete
                    transparency for all stakeholders.
                  </p>
                </div>
              </div>
              <div class="col-lg-3">
                <h2>LOCAL PARTICIPATION</h2>
                <div>
                  <p>
                    Our impact is shaped by critical input and contributions
                    from local communities - our own networks of local liaisons,
                    alumni, and educators.
                  </p>
                </div>
              </div>
              <div class="col-lg-3">
                <h2>ACCOUNTABILITY</h2>
                <div>
                  <p>
                    Our unique approach that combines global resources with our
                    local networks empowers us to directly shape our operations
                    and be accountable for our impact.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div>
              <p>&nbsp;</p>
              <p>
                <span style={{ fontSize: "14pt" }}>
                  These four principles guide our approach to the issue we are
                  striving to solve while reinforcing our commitment to the
                  mission.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      {props.loading && <CommonLoading />}
    </>
  );
}
const mapStateToProps = (state) => ({
  loading: state.students.loading,
  data: state.students.data,
  err: state.students.err,
  acData: state.account.data,
});
const mapDispatchToProps = (dispatch) => ({
  getStudents: (page, filters) =>
    dispatch(actionCreators.getAllStudents(page, filters)),

  getAccount: (acIds, stId) => dispatch(actionCreators.getAccount(acIds, stId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(StudentSearch);
