import React, { useState, useEffect } from "react";
import { InputGroup, Button, Form, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";

function CreateSponsorship(props) {
  const [options, setOptions] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const { data: students } = useSelector((state) => state.students);

  let sponsor = null;
  if (selectedStudent?.users.length > 0) {
    sponsor = selectedStudent?.users.find((sp) => {
      return (
        sp?.sponsorship?.status === "active" ||
        sp?.sponsorship?.status === "pending"
      );
    });
  }
  useEffect(() => {
    if (students?.rows?.length > 0) {
      const op = students.rows.map((st) => {
        return {
          value: st.id,
          label: `${st.firstName} ${st.lastName}`,
        };
      });

      setOptions(op);
    }
  }, [students]);
  const handleChange = (e) => {
    if (e.value) {
      const selStudent = students?.rows?.find((st) => st.id === e.value);
      setSelectedStudent(selStudent);
    }
  };
  const formSubmit = (e) => {
    e.preventDefault();
    if (!sponsor && selectedStudent) {
      props.handleCreateSponsorship(selectedStudent, "student");
    }
  };

  return (
    <div className="OfflinePay">
      <div className="formIn">
        <h4>Create a new sponsorship</h4>

        <Form onSubmit={formSubmit}>
          <Row>
            <Col>
              <InputGroup className="ml-3 select">
                <InputGroup.Prepend>
                  <InputGroup.Text>Student</InputGroup.Text>
                </InputGroup.Prepend>
                <Select options={options} onChange={handleChange} />
              </InputGroup>
            </Col>
            <Col>
              <Button
                variant="success"
                className="ml-3 mr-3"
                type="submit"
                disabled={sponsor || !selectedStudent ? true : false}
              >
                Create Sponsorship
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      {sponsor && (
        <div className="note">
          This student has a <strong>{sponsor.sponsorship.status} </strong>{" "}
          sponsor{" "}
          <strong>
            {sponsor.firstName} {sponsor.lastName}
          </strong>
        </div>
      )}
    </div>
  );
}

export default CreateSponsorship;
