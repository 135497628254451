import React from "react";
import { Container } from "react-bootstrap";

function BasicTemplate({ data }) {
  return (
    <Container
      id={data?.title
        .trim()
        .replace(/&/g, "")
        .split(" ")
        .join("")
        .toLowerCase()}
      dangerouslySetInnerHTML={{ __html: data?.text1 }}
    ></Container>
  );
}

export default BasicTemplate;
