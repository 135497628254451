import * as actions from '../actions/actionsTypes'

const initialState = {
    loading: false,
    error: null,
    data: null
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.INIT_CAMPAIGN_PAYMENT:
            return {
                ...state,
                loading: true
            }
        case actions.SUCCESS_CAMPAIGN_PAYMENT:
            return {
                ...state,
                loading: false,
                data: action.data,
                error: null

            }
        case actions.FAIL_CAMPAIGN_PAYMENT:
            return {
                ...state,
                loading: false,
                error: action.error,
                data: null
            }

        default: return state
    }
}
export default reducer;