import {
    INIT_HOME,
    SUCCESS_HOME,
    FAIL_HOME,
    INIT_INVITE,
    SUCCESS_INVITE,
    FAIL_INVITE
} from '../actions/actionsTypes'

const initialState = {
    loading:false,
    err:null,
    data:null
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case INIT_HOME:
            return {
            ...state,
            loading: true
        }
        case SUCCESS_HOME:
            return {
            ...state,
            loading:false,
            data:action.data,
            err:null
            
        }
        case FAIL_HOME:
            return {
            ...state,
            loading:false,
            err:action.err,
            data:null
        }
        case INIT_INVITE:
            return {
            ...state,
            inviteLoading: true
        }
        case SUCCESS_INVITE:
            return {
            ...state,
            inviteLoading:false,
            
        }
        case FAIL_INVITE:
            return {
            ...state,
            inviteLoading:false,
        }
        
        default: return state
    }
}
export default reducer;