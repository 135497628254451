import * as actions from '../actions/actionsTypes'

const initialState = {
    loading: false,
    error: null,
    data: null,
    success: false,
    campaignList: null
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.INIT_CREATE_CAMPAIGN:
            return {
                ...state,
                loading: true
            }
        case actions.SUCCESS_CREATE_CAMPAIGN:
            return {
                ...state,
                loading: false,
                data: action.data,
                success: true,
                error: null
            }
        case actions.FAIL_CREATE_CAMPAIGN:
            return {
                ...state,
                loading: false,
                error: action.error,
                data: null
            }
        case actions.INIT_UPDATE_CAMPAIGN:
            return {
                ...state,
                loading: true
            }
        case actions.SUCCESS_UPDATE_CAMPAIGN:
            return {
                ...state,
                loading: false,
                success: true,
                data: action.data,
                error: null

            }
        case actions.FAIL_UPDATE_CAMPAIGN:
            return {
                ...state,
                loading: false,
                error: action.error,
                data: null
            }
        case actions.INIT_CAMPAIGN:
            return {
                ...state,
                loading: true
            }
        case actions.SUCCESS_CAMPAIGN:
            return {
                ...state,
                loading: false,
                data: action.data,
                error: null

            }
        case actions.FAIL_CAMPAIGN:
            return {
                ...state,
                loading: false,
                error: action.error,
                data: null
            }
        case actions.INIT_CAMPAIGN_LIST:
            return {
                ...state,
                loading: true
            }
        case actions.SUCCESS_CAMPAIGN_LIST:
            return {
                ...state,
                loading: false,
                campaignList: action.data,
                error: null

            }
        case actions.FAIL_CAMPAIGN_LIST:
            return {
                ...state,
                loading: false,
                error: action.error,
                campaignList: null
            }

        case actions.RESET_CAMPAIGN:
            return initialState

        default: return state
    }
}
export default reducer;