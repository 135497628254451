import React from "react";
import "./Campaign.scss";
import { Button } from "../Reusable/Button/Button";
import userImage from "../../assets/user.png";
import moment from "moment";

const PaymentBox = (props) => {
  const data = props.campaign.data;

  return (
    <>
      <div className="PaymentBox">
        <div className="Price">
          $ <strong>{data?.total?.toLocaleString()}</strong> <small>Raised</small>
        </div>

        <div className="iconLine mt-4">
          <i className="fa fa-user" aria-hidden="true"></i>
          <strong>{Math.floor(data.total/10)}</strong>
          months of education will be funded
        </div>
        <div className="iconLine mt-2 mb-4">
          <i className="fa fa-clock-o" aria-hidden="true"></i>
          <strong>{moment(data.endDate).diff(moment(), "days")}</strong>
          days left to donate
        </div>
        {data.goal > 0 && (
          <div className="targetLine">
            <div className="percentage">
                <span>{Math.floor((data.total / data.goal) * 100)}%</span>
                <span>${data.goal.toLocaleString()}</span>
            </div>
            <div className="line">
              <span
                style={{ width: (data.total / data.goal) * 100 + "%" }}
              ></span>
            </div>
          </div>
        )}
        <Button title="Donate" color="pink" id="pay-donate-btn" onClick={props.handleModal} />
      </div>
      <div className="avatars-wraper">
        
        {data?.payments? 
          <div className="mt-4">
            <span>
              <strong>DONATED BY</strong>
            </span>
            <hr className="mt-3"></hr>
          </div>
        :' '}

        <div className="avatars mt-2">
          {data?.payments?.map((payment, i) => (
            <span
              key={i}
              style={{
                backgroundImage: `url('${
                  payment?.anonymous
                    ? userImage
                    : payment?.user?.image
                    // ? process.env.REACT_APP_SERVER + payment?.user?.image
                    ? payment?.user?.image
                    : userImage
                }')`,
              }}
            >
              {payment?.anonymous ? (
                <small>Anonymous</small>
              ) : (
                <small>
                  {payment?.user?.firstName} {payment?.user?.lastName}
                </small>
              )}
            </span>
          ))}
        </div>
      </div>
    </>
  );
};

export default PaymentBox;
