import React from 'react'

function AdminFooter() {
    return (
        <div>

        </div>
    )
}

export default AdminFooter
