import moment from "moment/moment";
import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getElfDonations } from "../../../store/actions";
import { AnchorButton, LinkButtonLargeText } from "../Button/Button";

function TemplateOne({ data }) {
 const activeGoal=data?.goals?.find(d=>d.active)
  const dispatch = useDispatch();
  const {donations}=useSelector(state=>state.pages)
  useEffect(() => {
    dispatch(getElfDonations({
      to:moment().format("YYYY-MM-DD"),
      from:activeGoal?.startDate
    }));
  }, [dispatch, getElfDonations,activeGoal]);
  let raisedValue = donations?.amount;
  let targetValue = +activeGoal?.goal;

  const count = (raisedValue / targetValue) * 100;
  return (
    <div
      id={data?.title?.trim().split(" ").join("").toLowerCase()}
      className="ThirdSection"
      style={{ backgroundImage: `url(${data?.backgroundImage})` }}
    >
      <div className="wrapper">
        <div dangerouslySetInnerHTML={{ __html: data?.text1 }}></div>

        <div className="donate-line">
          <Link to="#">{activeGoal?.goalLabel}</Link>
          <div className="line">
            <span style={{ width: `${count}%` }}></span>
          </div>
          <div className="target-tag">
            <div className="raised">
              <span>${raisedValue ? raisedValue.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") : "0"}</span> Raised
            </div>
            <div className="target">${targetValue.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}</div>
          </div>
        </div>

        <LinkButtonLargeText title={data.btnText} to={data.btnLink} />
        <div dangerouslySetInnerHTML={{ __html: data?.text2 }}></div>
      </div>
    </div>
  );
}

export default TemplateOne;
