import React from "react";
import userImg from "../../../assets/user.png";

function Profile(props) {
  const { sponsor } = props;
  return (
    <div className="Profile">
      <div className="Images">
        <div
          className="Image"
          style={{
            backgroundImage: `url(${
              sponsor?.image ? sponsor?.image : userImg
            })`,
          }}
        ></div>
      </div>

      <div className="DetailsBox">
        <ul>
          <li>
            <strong>Name</strong>
            {sponsor?.firstName} {sponsor?.lastName}
          </li>
          <li>
            <strong>Email</strong>
            {sponsor?.email}
          </li>
          <li>
            <strong>City</strong>
            {sponsor?.city}
          </li>
          <li>
            <strong>Country</strong>
            {sponsor?.country}
          </li>
          <li>
            <strong>Website</strong>
            {sponsor?.website}
          </li>
          <li>
            <strong>Social Media</strong>

            <div className="SocialLinks">
              {sponsor?.linkedin && (
                <a href={sponsor?.linkedin}>
                  <i className="fa fa-linkedin"></i>
                </a>
              )}
              {sponsor?.twitter && (
                <a href={sponsor?.twitter}>
                  <i className="fa fa-twitter-square"></i>
                </a>
              )}
              {sponsor?.facebook && (
                <a href={sponsor?.facebook}>
                  <i className="fa fa-facebook-square"></i>
                </a>
              )}
            </div>
          </li>
          <li>
            <strong>Created At</strong>
            {sponsor?.createdAt}
          </li>
          <li>
            <strong>Updated At</strong>
            {sponsor?.updatedAt}
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Profile;
