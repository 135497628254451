import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import todo from "./reducers/todo";
import auth from "./reducers/auth";
import home from "./reducers/home";
import forgetPassword from "./reducers/forgetPassword";
import students from "./reducers/students";
import account from "./reducers/account";
import sponsor from "./reducers/sponsor";
import payment from "./reducers/payment";
import giftcard from "./reducers/giftcard";
import campaignPayment from "./reducers/campaignpayment";
import campaign from "./reducers/campaign";
import chat from "./reducers/chat";
import news from "./reducers/news";
import modals from "./reducers/modals";
import contact from "./reducers/contact";
import sponsorships from "./reducers/sponsorships";
import dataSync from "./reducers/dataSync";
import sectionContent from "./reducers/sectionContent";
import section from "./reducers/section";
import pages from "./reducers/pages";
import media from "./reducers/media";
import distributionEvent from "./reducers/distributionEvent";
import logs from "./reducers/logs";

const rootReducers = combineReducers({
  todo,
  auth,
  home,
  forgetPassword,
  students,
  account,
  sponsor,
  payment,
  giftcard,
  campaignPayment,
  campaign,
  chat,
  news,
  modals,
  contact,
  sponsorships,
  dataSync,
  sectionContent,
  section,
  pages,
  media,
  distributionEvent,
  logs
});

const store = createStore(
  rootReducers,
  composeWithDevTools(applyMiddleware(thunk))
);
export default store;
