import React from "react";
import { Button, Col, Modal, ModalBody, Row } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import CompareUsers from "./CompareUsers";
import Filters from "./Filters";

const StudentFilter = ({ show,handleClose,addStudent }) => {
  return (
    <Modal show={show} size={"xl"}  animation={false}>
      <ModalHeader closeButton onHide={handleClose}>
      <Modal.Title>Manage students</Modal.Title>

      </ModalHeader>
      <ModalBody>
        <Filters />
        <hr />
        <CompareUsers handleClose={handleClose} addStudent={addStudent}/>
      </ModalBody>
    </Modal>
  );
};

export default StudentFilter;
