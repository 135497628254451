import moment from "moment";
import React, { useState } from "react";
import { FormControl, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { sentMessage } from "../../../store/actions";
import {isValidHttpUrl} from '../../../utils/func'

function MessagesList(props) {
  const { list } = props;
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);

  const [data, setData] = useState({
    chatfiles: "",
    message: "",
    sponsorshipId: null,
    admin: true,
  });

  const handleChange = (e) => {
    if (e.target.type === "file") {
      setData({ ...data, [e.target.name]: e.target.files[0] });
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (data.message || data.chatfiles) {
      data.sponsorshipId = list[0].sponsorshipId;
      dispatch(sentMessage(data, token));
      setData((prev) => ({ ...prev, message: "", chatfiles: "" }));
    } else {
      alert("Empty message can't send");
    }
  };

  const listItems = list?.map((l) => {
    let image;
    if (l.file) {
      image = isValidHttpUrl(l.file)
        ? l.file
        : `${process.env.REACT_APP_SERVER}${l.file}`;
    }

    return (
      <li key={l.id}>
        <div className={`bubble ${l.from === "student" ? "right" : ""}`}>
          {l.message}
          {l.file && (
            <div>
              <img
                src={image}
                alt=""
                width="300"
              />
            </div>
          )}
          <div className="date">{moment(l.createdAt).fromNow()}</div>
        </div>
      </li>
    );
  });
  return (
    <div className="messageBox">
      <ul>{listItems}</ul>

      <form className="inputBox" onSubmit={handleSubmit}>
        <Row>
          <Col md={3}>
            <FormControl type="file" name="chatfiles" onChange={handleChange} />
          </Col>
          <Col md={8}>
            <FormControl
              name="message"
              value={data.message}
              onChange={handleChange}
            />
          </Col>
          <Col md={1}>
            <Button type="submit" variant="success" block>
              <i className="fa fa-send"></i>
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
}

export default MessagesList;
