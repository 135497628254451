import * as actions from "../actions/actionsTypes";

const initialState = {
  loading: false,
  data: null,
  list: null,
  error: null,
  donations:null
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.INIT_PAGES:
      return {
        ...state,
        loading: true,
      };
    case actions.FAIL_PAGES:
      return {
        ...state,
        loading: false,
        list: null,
        data: null,
        error: action.error,
      };
    case actions.SUCCESS_CREATE_PAGES:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.data,
      };
    case actions.SUCCESS_LIST_PAGES:
      return {
        ...state,
        loading: false,
        error: null,
        list: action.list?.data,
      };
    case actions.SUCCESS_PAGES:
      return {
        ...state,
        loading: false,
        error: null,
        data: action?.data,
      };
    case actions.SUCCESS_DELETE_PAGES:
      return {
        ...state,
        loading: false,
        error: null,
        list: state.list.filter((p) => p.id !== action.pageId),
      };
    case actions.SUCCESS_GET_ELF_DONATIONS:
      return {
        ...state,
        donations:action.data
      }
      default:
      return state;
  }
};
export default reducer;
