import React from "react";
import { Button } from "react-bootstrap";

function UpDownButtons({ index, length, handleOrder,handleSectionDelete }) {
  return (
    <div className="UpDownButtons">
      <Button
      variant="dark"
        disabled={index === 0}
        onClick={() => handleOrder({ type: "UP", index: index })}
      >
        <i className="fa fa-arrow-up" />
      </Button>

      <Button
      variant="dark"
        disabled={length - 1 === index}
        onClick={() => handleOrder({ type: "DOWN", index: index })}
      >
        <i className="fa fa-arrow-down" />
      </Button>
      <Button onClick={()=>handleSectionDelete(index)} className="mt-5" variant="danger"><i className="fa fa-trash" /></Button>
    </div>
  );
}

export default UpDownButtons;
