import moment from "moment";
import React, { useState } from "react";
import { Tab, Nav, Col, Row } from "react-bootstrap";
import Image from "../../../assets/user.png";
import "./BottomProfileDetail.scss";
import Profile from "./Profile/Profile";
import ProfileTab from "./ProfileTab/ProfileTab";

const BottomProfileDetail = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [key, setKey] = useState(props.sponsor?.sponsorships[0]?.id);
  let content = [];

  if (props.sponsor.sponsorships.length > 0) {
    const sortedSpList = props.sponsor.sponsorships;

    const sponsorships = sortedSpList.map((sp) => {
      const filterSps = sortedSpList.filter(
        (s) => s.beneficiaryId === sp.beneficiaryId
      );

      if (
        filterSps.length > 1 &&
        filterSps[0].id !== sp.id &&
        sp.status === "canceled"
      ) {
        sp.status = "renewed";
      }
      return sp;
    });

    const groupedSponsorships = Object.fromEntries(
      sponsorships.map((x) => [x]).map((item, index) =>  [index, item])
    );

    if (sponsorships.length > 0) {
      content = (
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey={Object.entries(groupedSponsorships)[0][0]}
        >
          <Row>
            <Col lg={3}>
              <Nav
                variant="pills"
                className="flex-column"
                onSelect={(k) => setKey(k)}
              >
                {Object.entries(groupedSponsorships).map(([key, el]) => {
                  const topSponsorship = el[0];
                  const student = topSponsorship.beneficiary;
                  let classTag = "";
                  if (topSponsorship.status === "active") {
                    classTag = "active";
                  } else if (topSponsorship.status === "pending") {
                    classTag = "pending";
                  } else if (topSponsorship.status === "canceled") {
                    classTag = "canceled";
                  } else if (topSponsorship.status === "renewed") {
                    classTag = "renewed";
                  }
                  // if (sp.type === "student") {
                  return (
                    <Nav.Item
                      key={key}
                      className={!student?.status && "disabled"}
                    >
                      <Nav.Link eventKey={key}>
                        <img
                          src={student?.image ? student?.image : Image}
                          alt={student?.firstName}
                        />
                        {student?.status}
                        <div>
                          <span>
                            {student?.firstName}{" "}
                            <span
                              className={student?.status ? "active" : "inactive"}
                            ></span>
                          </span>
                          <span className={classTag}>
                            {" "}
                            {topSponsorship.status === "canceled" ||
                            topSponsorship.status === "renewed"
                              ? moment(topSponsorship.dueDate).format("YYYY")
                              : ""}{" "}
                            {topSponsorship.status}
                          </span>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  );
                  // }
                })}
              </Nav>
            </Col>
            <Col lg={9}>
              <Tab.Content>
                {Object.entries(groupedSponsorships).map(([key, el]) => {
                  const topSponsorship = el[0];
                  const student = topSponsorship.beneficiary;
                  // if (topSponsorship.type === "student") {
                  return (
                    <Tab.Pane
                      key={key}
                      eventKey={key}
                    >
                      <Profile
                          src={student?.image ? student.image : Image}
                          alt={student?.firstName + " " + student?.lastName}
                          sponsorship={topSponsorship}
                        />

                        <ProfileTab
                          topSponsorship={topSponsorship}
                          sponsorshipsList={el}
                        />
                    </Tab.Pane>
                  );
                  // }
                })}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      );
    }
  }

  return <div className="BottomProfileDetail">{content}</div>;
};

export default BottomProfileDetail;
