import React, { useState } from "react";

import {
  FormControl,
  FormGroup,
  FormLabel,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { useEffect } from "react";

function TemplateEight({ section, editMode, handleSectionDataChange }) {
  const [data, setData] = useState({
    title: "",
    tags: [],
  });
  useEffect(() => {
    handleSectionDataChange({
      ...section,
      index: section.index,
      data: data,
    });
  }, [data]);

  //===EDIT MODE SET DATA
  useEffect(() => {
    if (editMode && section.data) {
      setData(section.data);
    }
  }, [editMode]);
  //===END EDIT MODE SET DATA

  const handleChange = (e) => {
    setData((dt) => ({ ...dt, [e.target.name]: e.target.value }));
  };

  const handleChangeTags = (e) => {
    const copyTags = [...data.tags];
    const tagIndex = e.target.name.split("_")[1];
    copyTags[tagIndex][e.target.name.split("_")[0]] = e.target.value;
    setData((dt) => ({ ...dt, tags: copyTags }));
  };
  const addSection = (e) => {
    setData((dt) => ({
      ...dt,
      tags: [
        ...dt.tags,
        { image: "", title: "", text: "", linkText: "", url: "" },
      ],
    }));
  };
  const handleSectionDelete = (index) => {
    if (window.confirm("Are you sure to delete this?")) {
      const copyData = { ...data };
      copyData.tags.splice(index, 1);
      setData(copyData);
    }
  };
  return (
    <div>
      <FormGroup>
        <FormLabel>Title</FormLabel>
        <FormControl name="title" value={data?.title} onChange={handleChange} />
      </FormGroup>

      <div className="RowColors">
        {data?.tags?.map((v, i) => (
          <Row key={i}>
            <Col sm={4}>
              <FormGroup>
                <FormLabel>Image {i + 1}</FormLabel>
                <FormControl
                  name={`image_${i}`}
                  value={data?.tags[i].image}
                  onChange={handleChangeTags}
                />
              </FormGroup>
            </Col>
            <Col sm={4}>
              <FormGroup>
                <FormLabel>Title {i + 1}</FormLabel>
                <FormControl
                  name={`title_${i}`}
                  value={data?.tags[i].title}
                  onChange={handleChangeTags}
                />
              </FormGroup>
            </Col>
            <Col sm={4}>
              <FormGroup>
                <FormLabel>Text {i + 1}</FormLabel>
                <FormControl
                  name={`text_${i}`}
                  value={data?.tags[i].text}
                  onChange={handleChangeTags}
                />
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <FormLabel>Link Text {i + 1}</FormLabel>
                <FormControl
                  name={`linkText_${i}`}
                  value={data?.tags[i].linkText}
                  onChange={handleChangeTags}
                />
              </FormGroup>
            </Col>
            <Col sm={5}>
              <FormGroup>
                <FormLabel>URL {i + 1}</FormLabel>
                <FormControl
                  name={`url_${i}`}
                  value={data?.tags[i].url}
                  onChange={handleChangeTags}
                />
              </FormGroup>
            </Col>{" "}
            <Col sm={1} className="pt-2">
              <Button
                onClick={() => handleSectionDelete(i)}
                className="mt-4"
                variant="danger"
              >
                <i className="fa fa-trash" />
              </Button>
            </Col>
          </Row>
        ))}
      </div>
      <Button onClick={addSection}>ADD SECTION</Button>
    </div>
  );
}

export default TemplateEight;
