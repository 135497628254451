import React from "react";
import "./ContactHeader.scss";
import Background from "../../../assets/bg-poolfund-children.png";

function ContactHeader({ section }) {
  return (
    <div
      className="ContactHeader"
      style={{ backgroundImage: `url(${Background})` }}
    >
      <div className="FundBox">
        <div dangerouslySetInnerHTML={{ __html: section?.data?.text1 }}></div>
        {section?.data?.phones.map((p, i) => (
          <a key={i} href={`mailto:${p.phone}`}>
            {p.phone}
          </a>
        ))}
        {section?.data?.emails.map((p, i) => (
          <a key={i} href={`tel:${p.email}`}>
            {p.email}
          </a>
        ))}
      </div>
    </div>
  );
}

export default ContactHeader;
