import axios from "../../axios";
import * as actions from "./actionsTypes";

const initGiftcard = () => ({
  type: actions.INIT_GIFTCARD
});

const successGiftcard = data => ({
  type: actions.SUCCESS_GIFTCARD,
  data
});

const failGiftcard = err => ({
  type: actions.FAIL_GIFTCARD,
  err
});

export const getGiftcard = (token, id) => {
  return dispatch => {
    dispatch(initGiftcard());
    axios
      .get(`giftcard/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {
        dispatch(successGiftcard(res.data));
      })
      .catch(err => {
        dispatch(failGiftcard(err));
      });
  };
};

export const resetGiftcard = () => ({
  type: actions.RESET_GIFTCARD
});
