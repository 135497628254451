import React from "react";
import { Tabs, Tab } from 'react-bootstrap'
function TemplateFourteen({ data }) {
  return (
    <section className="SixthSection" id={data?.title?.trim().split(" ").join("").toLowerCase()}>
    <Tabs>
      {data?.tags?.map((tab,i)=><Tab key={i} eventKey={tab?.name?.split(" ").join("_")} title={tab?.name}>
        <div className="tab-content p-5" >
          <div dangerouslySetInnerHTML={{__html:tab.text}} className="wrapper"></div>
        </div>
      </Tab>)}

    </Tabs>
  </section>
  );
}

export default TemplateFourteen;
