import React from "react";
import Header from "./Header/AdminHeader";
import Footer from "./Footer/AdminFooter";
import Sidebar from "./Sidebar/Sidebar";
import "../../Admin.scss";

function AdminLayout(props) {
  return (
    <>
      <Header />
      <Sidebar />
      <div className="adminContainer">{props.children}</div>
      <Footer />
    </>
  );
}

export default AdminLayout;
