import BasicTemplate from "./BasicTemplate";
import HomePageHeader from "./HomePageHeader";
import PoolFundHeader from "./PoolFundHeader";
import TemplateOne from "./TemplateOne";
import TemplateTwo from "./TemplateTwo";
import TemplateThree from "./TemplateThree";
import TemplateFore from "./TemplateFore";
import TemplateFive from "./TemplateFive";
import TemplateSix from "./TemplateSix";
import TemplateSeven from "./TemplateSeven";
import TemplateEight from "./TemplateEight";
import TemplateNine from "./TemplateNine";
import TemplateTen from "./TemplateTen";
import TemplateEleven from "./TemplateEleven";
import TemplateTwelve from "./TemplateTwelve";
import TemplateThirteen from "./TemplateThirteen";
import TemplateFourteen from "./TemplateFourteen";
import TemplateFifteen from "./TemplateFifteen";
import TemplateSixteen from "./TemplateSixteen";
import TemplateSeventeen from "./TemplateSeventeen";
import TemplateEighteen from "./TemplateEighteen";
import ContactHeader from "./ContactHeader";
import PledgeYourBirthdayHeader from "./PledgeYourBirthdayHeader";
import GiftCardPageHeader from "./GiftCardPageHeader";
import TemplateHeader from "./TemplateHeader";
import MetaData from "./MetaData";

export default {
  MetaData,
  BasicTemplate,
  HomePageHeader,
  PoolFundHeader,
  TemplateOne,
  TemplateTwo,
  TemplateThree,
  TemplateFore,
  TemplateFive,
  TemplateSix,
  TemplateSeven,
  TemplateEight,
  TemplateNine,
  TemplateTen,
  TemplateEleven,
  TemplateTwelve,
  TemplateThirteen,
  TemplateFourteen,
  TemplateFifteen,
  TemplateSixteen,
  ContactHeader,
  PledgeYourBirthdayHeader,
  GiftCardPageHeader,
  TemplateHeader,
  TemplateSeventeen,
  TemplateEighteen
};
