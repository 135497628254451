import React, { useState } from "react";

import {
  FormControl,
  FormGroup,
  FormLabel,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { useEffect } from "react";

function TemplateTwelve({ section, editMode, handleSectionDataChange }) {
  const [data, setData] = useState({
    title: "",
    iframe: "",
  });
  useEffect(() => {
    handleSectionDataChange({
      ...section,
      index: section.index,
      data: data,
    });
  }, [data]);

  //===EDIT MODE SET DATA
  useEffect(() => {
    if (editMode && section.data) {
      setData(section.data);
    }
  }, [editMode]);
  //===END EDIT MODE SET DATA

  const handleChange = (e) => {
    setData((dt) => ({ ...dt, [e.target.name]: e.target.value }));
  };

  return (
    <div>
      <FormGroup>
        <FormLabel>Title</FormLabel>
        <FormControl name="title" value={data?.title} onChange={handleChange} />
      </FormGroup>{" "}
      <FormGroup>
        <FormLabel>IFrame</FormLabel>
        <FormControl
          name="iframe"
          value={data?.iframe}
          onChange={handleChange}
        />
      </FormGroup>
    </div>
  );
}

export default TemplateTwelve;
