import React, { useEffect } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { resetPayment } from "../../../../store/actions";
import "./MiddlePopup.scss";
import LogoIco from "../../../../assets/elf-logo-clr.png";
import CopyToClipboardButton from "../../CopyToClipboardButton/CopyToClipboardButton";
import addThousandSeparator from "../../../../utils/addThousandSeparator";

const MiddlePopup = (props) => {
    const { onHide, show, modalData, donationType } = props;
    const dispatch = useDispatch();
    // let navigate = useNavigate();
    const initialAmount = modalData?.data?.amount || modalData?.donationAmount || '';
    const formattedValue = addThousandSeparator(initialAmount);

    useEffect(() => {
        return () => dispatch(resetPayment());
    }, []);


    const modalContent = () => {
        if (donationType === "elf") 
        {   
            return(
                <>
                    <h4>
                        Thank you for donating to Educate Lanka Fund!
                    </h4>
                    <p className="mb-4">
                        Your donation of ${ formattedValue } will help Educate Lanka fund its scholarships and quality learning opportunities for its Scholars.
                    </p>
                    <small>
                        A copy of your donation receipt has been emailed to you for your reference and tax purposes. Thank you again and we look forward to sharing our updates with you.
                    </small>
                </>
            );
        } 
        else if (donationType === "sponsorship")
        {
            return(
                <>
                    <h4>
                        Thank you for investing in {modalData?.data?.beneficiary?.firstName}'s education!
                    </h4>
                    <p className="mb-4">
                        Your sponsorship payment of $
                        {/* { (modalData?.data?.amount - modalData?.data?.tip).toFixed(2).toLocaleString()}  */}
                        { (parseFloat(modalData?.data?.amount * modalData?.data?.currentPlanPeriod ) + 
                        parseFloat(modalData?.data?.tip))
                        .toFixed(2).toLocaleString() } {" "}
                        has been processed and a copy of your payment receipt will be emailed for your reference and tax purposes. 
                    </p>
                    <small>
                        We will remind you on your next payment prior to the due date. If you're subscribed for recurrcing payments, 
                        the next payment will be automatically processed on the due date. Thank you again and we look forward to sharing 
                        your student's feedback and updates with you soon.
                    </small>
                </>
            );

        }
        else if (donationType === "campaign") 
        {
            return(
                <>
                    <h4>
                        Thank you for donating to the { modalData?.campaignName } fundraising campaign!
                    </h4>
                    <p className="mb-4">
                        Your donation of ${ formattedValue } is being processed and a receipt will be sent to your email.
                    </p>
                    <small>
                        Please check back on the page for updates about the fundraiser and take a moment to share it with your friends so they could join too.
                    </small>
                </>
            );
        }

        return null;

    }

    return (
        <Modal show={show} className="MiddlePopup" size="lg" centered onHide={() => {
            onHide();
            // navigate("/");
        }}>
            <Modal.Header closeButton className="pb-0 px-4">
                <Modal.Title>
                    <div className="TitleContent">
                        <h5>Educate Lanka</h5>
                        {/* {elf? <small>To support micro-scholarships and learning opportunities</small> : <small>A gift for someone to pay for a student's education</small>} */}
                        <img src={LogoIco} width="50" alt="logo" />
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="py-5 px-5">
                <Row>
                    <Col>
                    <div className="modalContentWraper pb-5">
                        <div className="modalIconWraper">
                            <i className="fa fa-check-circle-o" aria-hidden="true"></i>
                        </div>
                        <div className="modalTextWraper mt-4">
                            { modalContent() }
                        </div>
                        <div className="socialButtonsWraper mt-5">
                            <a href={`https://twitter.com/intent/tweet?url=${window.location.href}`} target={"_blank"}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>
                                Tweet
                            </a>
                            <a href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} target={"_blank"}>
                                <i className="fa fa-facebook-square mr-3" aria-hidden="true"></i>
                                Share
                            </a>
                            <CopyToClipboardButton text={window.location.href} />
                        </div>
                    </div>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default MiddlePopup;
