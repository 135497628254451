import React from "react";
import { Row, Col } from "react-bootstrap";
import "./Profile.scss";
// import _ from "lodash";
// import moduleName from '../../../Reusable'
import { LinkLineButtonBlue } from "../../../Reusable/Button/Button";
import ProgressStudents from "../../../Reusable/ProgressStudent/ProgressStudents";
import moment from "moment";
import { Link } from "react-router-dom";

const Profile = (props) => {
  const sponsorship = props?.sponsorship;
  const payments = sponsorship?.payments;
  const totalPayments = sponsorship?.payments.reduce(
    (a, b) => a + (b.amount - b.tip),
    0
  );
  const student = sponsorship?.beneficiary;

  const latestPayment = payments.find(
    (p) => p.id === +sponsorship.latest_payment_id
  );

  const periodLength = sponsorship?.payments
    ?.map((payment) => payment.currentPlanPeriod)
    .reduce((acc, period) => acc + period, 0);

  const sortedPayments = payments?.sort(
    (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
  );

  const payment = payments
    ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
    .slice(-1)[0];

  const getPaymentPeriodMonth = (paymentArray, id) => {
    let periodLength = 0;
    let found = false;

    paymentArray?.forEach((payment) => {
      if (!found) {
        periodLength += payment.currentPlanPeriod;
        if (payment.id === id) {
          found = true;
        }
      }
    });

    return periodLength;
  };

  const formatPlanPeriod = (months) => {
    if (months === 1) {
      return `${months} Month`;
    } else if (months < 12) {
      return `${months} Months`;
    } else {
      const years = Math.floor(months / 12);
      const remainingMonths = months % 12;
      const yearText = years === 1 ? "1 Year" : `${years} Years`;
      const monthText =
        remainingMonths === 1 ? "1 Month" : `${remainingMonths} Months`;

      return remainingMonths > 0 ? `${yearText} and ${monthText}` : yearText;
    }
  };

  return (
    <div className="Profile">
      <Row>
        <Col md={3}>
          <img src={props.src} alt={props.alt} className="ProfImg" />
          {sponsorship?.type !== "elf" && student?.detail && (
            <div className="BtnCenter">
              <LinkLineButtonBlue
                title="View Profile"
                to={`/student/${student?.id}`}
              />
            </div>
          )}
        </Col>
        <Col md={7}>
          <h3>
            {student?.firstName} <span> {student?.lastName}</span>
          </h3>
          {sponsorship?.type !== "elf" && (
            <ProgressStudents orange sponsorPeriod student={student} />
          )}
          <ul className="List">
            <li>
              <strong>Current Sponsorship Status</strong>
              {sponsorship.status === "active" && (
                <span className="green">
                  <i className="fa fa-check"></i> Active
                </span>
              )}
              {sponsorship.status === "renewed" && (
                <span className="yellow">
                  <i className="fa fa-ban"></i> Renewed
                </span>
              )}
              {sponsorship.status === "canceled" && (
                <span className="red">
                  <i className="fa fa-ban"></i> Canceled
                </span>
              )}
              {sponsorship.status === "pending" && (
                <span className="orange">
                  <i className="fa fa-hourglass-start"></i> Pending Payment
                </span>
              )}
            </li>
            <li>
              <strong>Current Payment Type</strong>
              {sponsorship.status === "pending" ? (
                <span>N/A</span>
              ) : (
                sponsorship?.recurring
                  ? `Recurring ($${sponsorship?.amount *
                  sponsorship?.currentPlanPeriod
                  } every 
                  ${sponsorship?.currentPlanPeriod > 1
                    ? sponsorship?.currentPlanPeriod + " months"
                    : sponsorship?.currentPlanPeriod + " month"
                  })`
                  : "Non-Recurring"
              )}
            </li>
            <li>
              <strong>Last Payment & Period Applied</strong>
              {sponsorship.status === "pending" ? (
                <span>N/A</span>
              ) : (
                <span>
                  {" "}
                  $
                  {sponsorship?.amount *
                    sponsorship?.currentPlanPeriod}{" "}
                  for{' '}
                  {payment?.currentPlanPeriod === 1
                    ? moment(sponsorship.startDate)
                      .add(
                        getPaymentPeriodMonth(sortedPayments, payment.id) - 1,
                        "months"
                      )
                      .format("MMM YYYY")
                    : `${moment(sponsorship.startDate)
                      .add(
                        getPaymentPeriodMonth(sortedPayments, payment?.id) -
                        payment?.currentPlanPeriod,
                        "months"
                      )
                      .format("MMM YYYY")} - ${moment(sponsorship.startDate)
                        .add(
                          getPaymentPeriodMonth(sortedPayments, payment?.id) -
                          1,
                          "months"
                        )
                        .format("MMM YYYY")}`}
                </span>
              )}
            </li>
            <li>
              <strong>Last Payment Date</strong>
              {sponsorship.status === "pending" ? (
                <span>N/A</span>
              ) : (
                moment(latestPayment?.createdAt).local().format("MMMM D, YYYY"))}
            </li>

            <li>
              <strong>Next Payment Due On </strong>{" "}
              <span className="orange">
                {/* {moment(latestPayment?.createdAt).add(sponsorship?.currentPlanPeriod, 'months').format("MMMM D, YYYY")} */}
                {moment(sponsorship.dueDate).local().format("MMMM D, YYYY")}
              </span>
              {sponsorship.status === "active" ||
                sponsorship.status === "pending"
                ? sponsorship?.type !== "elf" && (
                  <Link
                    className="quickPayBtn"
                    to={`/sponsorship/${sponsorship.id}`}
                  >
                    {sponsorship?.recurring ? "View Plan" : "Quick Pay"}
                  </Link>
                )
                : ""}
            </li>
            <li>
              <strong>Total Sponsorship Paid by Sponsor</strong>
              {sponsorship.status === "pending" ? (
                <span>N/A</span>
              ) : (
                <div>${parseInt(totalPayments)?.toLocaleString()}</div>
              )}
            </li>
            <li>
              <strong>Length of Sponsorship Paid For</strong>
              {sponsorship.status === "pending" ? (
                <span>N/A</span>
              ) : (
                <div>
                  {formatPlanPeriod(periodLength)}
                </div>
              )}
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export default Profile;
