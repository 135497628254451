import React, { useState, useEffect } from "react";
import MultiSelect from "../../Reusable/MultiSelect/MultiSelect";
import "./SearchArea.scss";
import SriLankaMap from "../../Reusable/SriLankaMap/SriLankaMap";
import Summary from "./Sumery";

const AcademicLevel = [
  { value: null, label: "Select", name: "AcademicLevel" },
  {
    value: "Up to Grade 11 (O/L)",
    label: "Up to Grade 11 (O/L)",
    name: "AcademicLevel",
  },
  {
    value: "In Grade 12 & 13 (A/L)",
    label: "In Grade 12 & 13 (A/L)",
    name: "AcademicLevel",
  },
  {
    value: "In Higher Education",
    label: "In Higher Education",
    name: "AcademicLevel",
  },
];

const Age = [
  { value: null, label: "Select", name: "Age" },
  { value: "1-13", label: "Below 13", name: "Age" },
  { value: "14-16", label: "14 to 16", name: "Age" },
  { value: "17-19", label: "17 to 19", name: "Age" },
  { value: "20-100", label: "20 & above", name: "Age" },
];

const Gender = [
  { value: null, label: "Select", name: "Gender" },
  { value: "Male", label: "Male", name: "Gender" },
  { value: "Female", label: "Female", name: "Gender" },
];
const Amount = [
  { value: null, label: "Select", name: "Amount" },
  { value: "10", label: "$10", name: "Amount" },
  { value: "15", label: "$15", name: "Amount" },
  { value: "20", label: "$20", name: "Amount" },
  { value: "25", label: "$25", name: "Amount" },
];
const Location = [
  { value: null, label: "Select", name: "Location" },
  { value: "Ampara", label: "Ampara", name: "Location" },
  { value: "Anuradhapura", label: "Anuradhapura", name: "Location" },
  { value: "Badulla", label: "Badulla", name: "Location" },
  { value: "Batticaloa", label: "Batticaloa", name: "Location" },
  { value: "Colombo", label: "Colombo", name: "Location" },
  { value: "Galle", label: "Galle", name: "Location" },
  { value: "Gampaha", label: "Gampaha", name: "Location" },
  { value: "Hambantota", label: "Hambantota", name: "Location" },
  { value: "Jaffna", label: "Jaffna", name: "Location" },
  { value: "Kandy", label: "Kandy", name: "Location" },
  { value: "Kalutara", label: "Kalutara", name: "Location" },
  { value: "Kegalle", label: "Kegalle", name: "Location" },
  { value: "Kilinochchi", label: "Kilinochchi", name: "Location" },
  { value: "Kurunegala", label: "Kurunegala", name: "Location" },
  { value: "Mannar", label: "Mannar", name: "Location" },
  { value: "Matale", label: "Matale", name: "Location" },
  { value: "Matara", label: "Matara", name: "Location" },
  { value: "Moneragala", label: "Moneragala", name: "Location" },
  { value: "Mullaitivu", label: "Mullaitivu", name: "Location" },
  { value: "Nuwara Eliya", label: "Nuwara Eliya", name: "Location" },
  { value: "Polonnaruwa", label: "Polonnaruwa", name: "Location" },
  { value: "Puttalam", label: "Puttalam", name: "Location" },
  { value: "Ratnapura", label: "Ratnapura", name: "Location" },
  { value: "Trincomalee", label: "Trincomalee", name: "Location" },
  { value: "Vavuniya", label: "Vavuniya", name: "Location" },
];

function SearchArea(props) {
  const [selectedOption, setSelectedOption] = useState({
    AcademicLevel: [],
    Age: [],
    Gender: [],
    Location: [],
    IsSponsored: false,
    Amount: [],
    keyword: "",
  });
  const [searchValue, setSearchValues] = useState('');
  const [filter, setFilter] = useState(false);

  const handleChange = (e) => {
    const itemIndex = selectedOption[e.name].findIndex(
      (sl) => sl.value === e.value
    );
    let arr = [];
    if (e.value) {
      if (itemIndex >= 0) {
        arr = selectedOption[e.name].filter((itm) => itm.value !== e.value);
      } else {
        arr = selectedOption[e.name].concat(e);
      }
    } else {
      arr = [];
    }
    setFilter(true);
    setSelectedOption({
      ...selectedOption,
      [e.name]: arr,
    });
  };
  const handleInputChange = (e) => {
    if(e.target.value === '') {
      setFilter(true);
      setSelectedOption({ ...selectedOption, keyword: e.target.value });
    }
    setSearchValues(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleClick(); // Trigger the search logic
    }
  };

  const handleClick = () => {
    setFilter(true);
    setSelectedOption({ ...selectedOption, keyword: searchValue });
  };

  useEffect(() => {
    if (filter) {
      setFilter(false);
      props.filterChange(selectedOption);
    }
  }, [filter]);

  const handleClear = () => {
    const reset = {
      AcademicLevel: [],
      Age: [],
      Gender: [],
      Location: [],
      IsSponsored: false,
      Amount: [],
      keyword: "",
    };
    setSelectedOption(reset);
    setSearchValues('');
    props.filterChange(reset);
  };
  return (
    <>
      <div className="SearchArea" ref={props.scrollableDivRef}>
        <div className="wrapper">
          <h1>Choose a student to sponsor from the following profiles</h1>
          <div className="SearchableBoxes">
            <div
              className={
                selectedOption.Location && selectedOption.Location.value
                  ? selectedOption.Location.value !== "All" && "Loacated"
                  : ""
              }
            >
              <SriLankaMap
                className="fullMap"
                district={
                  selectedOption.Location &&
                  selectedOption.Location.map((e) => e.value)
                }
              />
            </div>

            <div className="inputContainer sm">
              <label>Location</label>
              <MultiSelect
                onSelect={handleChange}
                options={Location}
                selected={selectedOption.Location}
              />
            </div>

            <div className="inputContainer sm">
              <label>Academic Level</label>
              <MultiSelect
                onSelect={handleChange}
                options={AcademicLevel}
                selected={selectedOption.AcademicLevel}
              />
            </div>
            <div className="inputContainer sm">
              <label>Age</label>
              <MultiSelect
                onSelect={handleChange}
                options={Age}
                selected={selectedOption.Age}
              />
            </div>
            <div className="inputContainer sm">
              <label>Gender</label>
              <MultiSelect
                onSelect={handleChange}
                options={Gender}
                selected={selectedOption.Gender}
              />
            </div>
            <div className="inputContainer sm">
              <label>Monthly Amount</label>
              <MultiSelect
                onSelect={handleChange}
                options={Amount}
                selected={selectedOption.Amount}
              />
            </div>

            {/* <div className="inputContainer sm">
              <label>Keyword</label>
              <input
                type="text"
                name="keyword"
                placeholder="search"
                value={selectedOption.keyword}
                onChange={handleInputChange}
              />
            </div> */}
            <div className="inputContainer sm">
              <label>Keyword</label>
              <div className="searchWrapper">
                <input
                  type="text"
                  name="keyword"
                  placeholder="search"
                  value={searchValue}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                />
                <button type="button" onClick={handleClick}>
                  <i className="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>

          <Summary
            handleClear={handleClear}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            count={props.count}
            setFilter={setFilter}
          />
        </div>
      </div>
    </>
  );
}

export default SearchArea;
