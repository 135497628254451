import moment from "moment";
import React from "react";

function PaymentTag(props) {
  const { payment } = props;

  let paymentMethod = null;
  let payDate = null;

  if (payment.method === "pp") {
    paymentMethod = (
      <div className="PaymentMethod">
        <i className="fa fa-cc-paypal"></i> Paypal
      </div>
    );
    payDate = (
      <div className="payDate">{moment(payment.payDate).format("MMMM D, YYYY")}</div>
    );
  }
  if (payment.method === "cc") {
    paymentMethod = (
      <div className="PaymentMethod">
        <i className="fa fa-credit-card "></i> Credit Card
      </div>
    );
    payDate = (
      <div className="payDate">{moment(payment.payDate).format("MMMM D, YYYY")}</div>
    );
  }

  if (payment.method === "gc") {
    paymentMethod = (
      <div className="PaymentMethod">
        <i className="fa fa-gift"></i> Gift Card
      </div>
    );
    payDate = (
      <div className="payDate">{moment(payment.payDate).format("MMMM D, YYYY")}</div>
    );
  }

  if (payment.method === "ml") {
    paymentMethod = (
      <div className="PaymentMethod">
        <i className="fa fa-money"></i> Offline
        {payment?.detail?.type === "WriteOff" ? " - Write Off" : ""}
        {payment?.detail?.type === "Credit" ? " - Credit" : ""}
      </div>
    );
    payDate = (
      <div className="payDate">
        {moment(payment?.detail?.paymentReceivedData).format("MMMM D, YYYY")}
      </div>
    );
  }

  return (
    <tr>
      <td>{paymentMethod}</td>
      <td>
        <div className="Amount">{payment.amount}</div>
      </td>
      <td>
        <div className="Amount">{payment.tip}</div>
      </td>
      <td>
        <div className="Amount">{payment.currentPlanPeriod}</div>
      </td>
      <td>
        <div className="Amount">{payment.recurring ? "Yes" : "No"}</div>
      </td>
      <td>
        <div className="createdAt">{moment(payment.dueDate).format("MMMM D, YYYY")}</div>
      </td>
      <td>{payDate}</td> <td>{payment?.detail?.note}</td>
    </tr>
  );
}

export default PaymentTag;
