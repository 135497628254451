import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import Image from "../../assets/1.jpg";
import { AnchorButton } from "../Reusable/Button/Button";

const CampaignList = ({ campaign }) => {
  const campaignList = campaign?.campaignList;
  const filteredList = campaignList?.filter((e) => e.id !== campaign.data.id);

  return (
    <>
      <Container className="mt-5 mb-5">
        {filteredList && filteredList.length > 0 && (
          <div className="CampaignList">
            <h1>See {campaign.data?.sponsor?.firstName}'s other campaigns</h1>
            <Row className="justify-content-center">
              {filteredList.map((camp) => {
                return (
                  <Col md={4} key={camp.id}>
                    <Link
                      to={`/campaign/${camp.id}`}
                      className="imageTag"
                      style={{
                        backgroundImage: `url(${
                          process.env.REACT_APP_SERVER + camp.uploadImage
                        })`,
                      }}
                    >
                      <div>{camp.name}</div>
                    </Link>
                  </Col>
                );
              })}
            </Row>
          </div>
        )}
      </Container>
      <section className="lightBlueBackground">
        <Row>
          <Col>
            <div className="support mt-5 mb-5">
              <h2 className="mb-3">
                Create your own campaign to celebrate{" "}
                <strong>your special day.</strong>
              </h2>
              <AnchorButton
                title=" Create a New Campaign "
                to="/create-campaign"
              />
            </div>
          </Col>
        </Row>
      </section>
      <Container>
        <div className="whyEducation mt-3">
          <h1>Why Education?</h1>
          <Row>
            <Col md={6}>
              <img src={Image} alt="" width="100%" />
            </Col>
            <Col>
              <h3>Difficulties</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Voluptate sed esse aut suscipit numquam, distinctio et dolorem
                laborum quos, obcaecati quis? Consequuntur odit iste similique
                officia nostrum deserunt maiores eligendi?
              </p>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={6}>
              <h3>School Education</h3>
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsa
                placeat perferendis minima laborum quasi ab at, tempora beatae
                soluta nihil explicabo dignissimos! Doloribus neque quasi fugit
                voluptas provident quam officia.
              </p>
            </Col>
          </Row>
        </div>
        <div className="support mt-5 mb-5">
          <h2>
            You can still help someone's education.{" "}
            <strong>Support a student</strong>
          </h2>
          <AnchorButton  color="pink" title="Donate " to="/student-list" />
        </div>
      </Container>
    </>
  );
};

export default CampaignList;
