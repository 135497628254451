/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import "./ChatList.scss";
import { ButtonBlue } from "../../../../Reusable/Button/Button";
import ChatBuble from "./ChatBuble/ChatBuble";
import * as actionCreators from "../../../../../store/actions";
import Loading from "../../../../Reusable/Loading/Loading";

const ChatList = (props) => {
  const [data, setData] = useState({
    chatfiles: "",
    message: "",
    sponsorshipId: props.sponsorship.id,
  });
  const [validationLoad, setValidationLoad] = useState(false);
  const [validator, setValidator] = useState(
    new SimpleReactValidator({
      autoForceUpdate: this,
    })
  );
  useEffect(() => {
    if (props.chat.data) {
      setData({
        chatfiles: "",
        message: "",
        sponsorshipId: props.sponsorship.id,
      });
    }
  }, [props.chat.data]);
  useEffect(() => {
    if (props.loaded) {
      props.getMessage(props.sponsorship.id, props.auth.token);
    }
  }, [props.loaded]);
  const handleChange = (e) => {
    if (e.target.type === "file") {
      setData({ ...data, [e.target.name]: e.target.files[0] });
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      props.sentMessage(data, props.auth.token);
      setValidationLoad(!validationLoad);
    } else {
      setValidationLoad(!validationLoad);
      validator.showMessages();
    }
  };
  return (
    <div className="ChatList">
      {props.chat.data.length > 0 &&
        props.chat.data.map((chat) => {
          return (
            <ChatBuble
              key={chat.id}
              msg={chat.message}
              data={chat}
              picture={
                chat.from === "sponsor" ? props.auth.me.data.image : null
              }
              student={chat.from === "sponsor"}
            />
          );
        })}
      {/* <ChatBuble
                msg="Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus numquam quidem placeat commodi, ad quas qui itaque! Magni atque, nihil amet exercitationem temporibus blanditiis quam totam qui perferendis porro ipsam." />
             */}
      {props.chat.loading && <Loading button />}
      <div className="chatGroup">
        <form onSubmit={handleSubmit}>
          <div className="inputContainer">
            {validator.message("message", data.message, "required")}
            <textarea
              name="message"
              value={data.message}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="file">
            <span>
              <i className="fa fa-paperclip"></i> Add files
            </span>
            <input type="file" name="chatfiles" onChange={handleChange} />
          </div>
          <ButtonBlue title="SEND" />
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  chat: state.chat,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  sentMessage: (data, token) =>
    dispatch(actionCreators.sentMessage(data, token)),
  getMessage: (benId, token) =>
    dispatch(actionCreators.getMessages(benId, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatList);
