/*eslint-disable*/
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  resetPasswordIsValidToken,
  resetPassword,
  forgetPasswordReset,
} from "../../store/actions";
import { useQuery } from "../../utils/func";

import "./ExistingUserSignUp.scss";
import { Formik } from "formik";
import countries from "../../store/data/countries";
import * as Yup from "yup";
import { Col, Form, Row } from "react-bootstrap";
import { existingRegisterUser } from "../../store/actions/auth";
import axios from "../../axios";

const existingAccountLoginSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: Yup.string()
    .required("New password is required")
    .min(6, "Password must be at least 6 characters"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
  salutation: Yup.string().required("Salutation is required"),
  anonymous: Yup.boolean(),
  country: Yup.string().required("Country is required"),
});

function ExistingUserSignUp() {
  const history = useNavigate();
  const params = useQuery();
  const id = params.get("id");
  const urlToken = params.get("token");
  const email = params.get("email");
  const firstName = params.get("firstName");
  const lastName = params.get("lastName");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    if (id && urlToken) {
      axios
        .get(`auth/existing-account-is-valid-token/${id}/${urlToken}`)
        .then((res) => {
          console.log("Valid Token");
        })
        .catch((err) => {
          navigate("/");
        });
    }
  }, [id, urlToken]);
  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token]);

  return (
    <div className="PasswordReset">
      <h2>Existing Account Registration</h2>
      <small>We are honored to welcome you to our journey</small>
      <div className="form">
        <Formik
          enableReinitialize
          initialValues={{
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: "",
            confirmPassword: "",
            anonymous: false,
            salutation: "Mr.",
            country: "",
          }}
          validationSchema={existingAccountLoginSchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            console.log("values", values);
            setSubmitting(true);
            dispatch(existingRegisterUser(values));
            setSubmitting(false);
          }}
        >
          {({
            isSubmitting,
            handleChange,
            handleBlur,
            setFieldTouched,
            setFieldValue,
            handleSubmit,
            resetForm,
            values,
            errors,
            touched,
            isValid,
            dirty,
            ...formik
          }) => (
            <Form>
              <div className="inputContainer icon">
                {errors.salutation && touched.salutation && (
                  <div className="srv-validation-message">
                    {errors.salutation}
                  </div>
                )}
                <select
                  id="salutation"
                  name="salutation"
                  placeholder="Salutation"
                  value={values.salutation}
                  defaultValue={values.salutation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="Dr.">Dr.</option>
                  <option value="Mr." selected>
                    Mr.
                  </option>
                  <option value="Mrs.">Mrs.</option>
                  <option value="Ms.">Ms.</option>
                  <option value="Mstr.">Mstr.</option>
                  <option value="Other">Other</option>
                </select>
                <i className="fa fa-lock"></i>
              </div>
              <Row>
                <Col sm={6}>
                  <div className="inputContainer icon">
                    {errors.firstName && touched.firstName && (
                      <div className="srv-validation-message">
                        {errors.firstName}
                      </div>
                    )}
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      id="firstName"
                      name="firstName"
                      value={values.firstName}
                      placeholder="First name"
                    />
                    <i className="fa fa-user"></i>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="inputContainer icon">
                    {errors.lastName && touched.lastName && (
                      <div className="srv-validation-message">
                        {errors.lastName}
                      </div>
                    )}
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      name="lastName"
                      id="lastName"
                      value={values.lastName}
                      placeholder="Last name"
                    />
                    <i className="fa fa-user"></i>
                  </div>
                </Col>
              </Row>
              <div className="inputContainer icon">
                {errors.email && touched.email && (
                  <div className="srv-validation-message">{errors.email}</div>
                )}
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  id="email"
                  name="email"
                  value={values.email}
                  placeholder="Email"
                  disabled
                />
                <i className="fa fa-envelope"></i>
              </div>
              <div className="inputContainer icon">
                {errors.password && touched.password && (
                  <div className="srv-validation-message">
                    {errors.password}
                  </div>
                )}
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="password"
                  name="password"
                  id="password"
                  value={values.password}
                  placeholder="Password"
                />
                <i className="fa fa-lock"></i>
              </div>
              <div className="inputContainer icon">
                {errors.confirmPassword && touched.confirmPassword && (
                  <div className="srv-validation-message">
                    {errors.confirmPassword}
                  </div>
                )}
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  value={values.confirmPassword}
                  placeholder="Confirm password"
                />
                <i className="fa fa-lock"></i>
              </div>

              <Row>
                <Col sm={12}>
                  <div className="inputContainer icon">
                    {errors.country && touched.country && (
                      <div className="srv-validation-message">
                        {errors.country}
                      </div>
                    )}
                    <select
                      id="country"
                      name="country"
                      value={values.country}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="" disabled>
                        Select Country
                      </option>
                      {countries.map((country, index) => (
                        <option key={index} value={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                    <i className="fa fa-lock"></i>
                  </div>
                </Col>
              </Row>
              <Form className="text-center mb-4">
                <Form.Check // prettier-ignore
                  name="anonymous"
                  type="switch"
                  id="anonymous"
                  label="Anonymize my name for public view"
                  onChange={handleChange}
                  checked={values.anonymous}
                  className="custom-switch"
                  style={{
                    cursor: "pointer",
                  }}
                />
              </Form>
              <div className="text-center">
                <button
                  type="button"
                  className="blueButton"
                  disabled={isSubmitting || !dirty}
                  onClick={() => handleSubmit()}
                >
                  {" "}
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ExistingUserSignUp;
