import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import Header from "./Header/Header";
import Footer from "./Footer/Footer";

import SignInModal from "../Reusable/Modal/SignInModal/SignInModal";
import ForgotPassword from "../Reusable/Modal/ForgotPassword/ForgotPassword";
import SignUpModal from "../Reusable/Modal/SignUpModal/SignUpModal";
import Loading from "../Reusable/Loading/Loading";
import {
  listPages,
  signupModalHandle,
  loginModalHandle,
  forgetPasswordModalHandle,
} from "../../store/actions";
import { useLocation } from "react-router-dom";

function Layout(props) {
  const { pathname } = useLocation();
  const modals = useSelector((state) => state.modals);
  const {
    loading: loadingAuth,
    regData,
    logData,
    logErr,
  } = useSelector((state) => state.auth);
  const { loading: loadingForgetPassword, data: forgetData } = useSelector(
    (state) => state.forgetPassword
  );
  const { loading } = useSelector((state) => state.pages);
  // const loading = loadingAuth || loadingForgetPassword || loadingPages;
  const dispatch = useDispatch();

  useEffect(() => {
    const isAdminRoute = pathname.split("/")[1];
    if (isAdminRoute !== "admin") {
      dispatch(listPages("?include=sections&perPage=100"));
    }
  }, [dispatch, pathname]);

  useEffect(() => {
    if (regData) {
      dispatch(signupModalHandle(false));
    }
  }, [dispatch, regData]);
  useEffect(() => {
    if (logData) {
      dispatch(loginModalHandle(false));
    }
  }, [dispatch, logData]);

  return (
    <div className="App">
      {loading ? (
        <Loading />
      ) : (
        <>
          <Header />
          <div>{props.children}</div>
          <Footer />
        </>
      )}
      <SignInModal
        show={modals.isLoginModalVisable}
        handleClose={() => dispatch(loginModalHandle(false))}
        handlePasswordModal={() => dispatch(forgetPasswordModalHandle(true))}
        handleSighnUpModal={() => dispatch(signupModalHandle(true))}
        loginError={logErr}
      />
      <SignUpModal
        show={modals.isSignupModalVisable}
        handleSignInModal={() => dispatch(loginModalHandle(true))}
        handleCloseSignUp={() => dispatch(signupModalHandle(false))}
      />

      <ForgotPassword
        forgetData={forgetData}
        show={modals.isForgetPasswordModal}
        handleCloseForget={() => dispatch(forgetPasswordModalHandle(false))}
      />
    </div>
  );
}

export default Layout;
