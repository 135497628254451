import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
function TemplateSix({ data }) {
  const [videoId, setVideoId] = useState("iXMV6qz0RNQ");

  const handleVideoChange = (e) => {
    setVideoId(e);
  };
  return (
    <section id={data?.title?.trim().split(" ").join("").toLowerCase()}>
      <div className="inContent">
        <Row>
          <Col md={8}>
            <div className="videoWrapper">
              <iframe
                src={`https://www.youtube.com/embed/${videoId}`}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </Col>
          <Col md={4} className="innerAbsolute">
            <ul className="youtubeThumbnailList">
              {data?.videos?.map((v, i) => (
                <li
                  key={i}
                  onClick={() => handleVideoChange(v.code)}
                  className={v.id === videoId && "active"}
                >
                  <img
                    src={`https://img.youtube.com/vi/${v.code}/0.jpg`}
                    alt={v.title}
                  />
                  <div>
                    <h6>{v.title}</h6>
                  </div>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default TemplateSix;
