import React from "react";

function TemplateTwo({ data }) {
  return (
    <div
    id={data?.title?.trim().split(" ").join("").toLowerCase()}
      className="DirectStudent"
      style={{ backgroundImage: `url(${data.imageLink})` }}
    >
      <div className="ShortDiscription">
        <h3>{data.title}</h3>
        <div dangerouslySetInnerHTML={{ __html: data.text1 }}></div>
      </div>
    </div>
  );
}

export default TemplateTwo;
