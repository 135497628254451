import axios from "../../axios";
import {
  INIT_LOG_LIST,
  SUCCESS_LOG_LIST,
  FAIL_LOG_LIST,
  INIT_LOG_ITEM,
  SUCCESS_LOG_ITEM,
  FAIL_LOG_ITEM,
} from "./actionsTypes";

/**
 * Get list of logs and store it in log
 * @param {Object} payload
 * @argument {string} payload.page default value 1 and op
 * @argument {string} payload.modelName default page is number 1
 * @argument {string} payload.perPage default page size is number 10
 * @returns void
 */

export const getLogList = (payload) => async (dispatch) => {
  try {
    console.log(payload);
    dispatch({ type: INIT_LOG_LIST });
    const data = await axios.get("/user-impersonation-audit-trail", {
      params: {
        page: payload?.page ? payload.page : 1,
        modelName: payload?.modelName ? payload.modelName : null,
        perPage: payload?.perPage ? payload.perPage : 10,
      },
    });
    console.log("getLogList");
    dispatch({ type: SUCCESS_LOG_LIST, data: data?.data });
  } catch (error) {
    dispatch({ type: FAIL_LOG_LIST, error });
  }
};
/**
 * This will get one log item details and store it in log store
 * @argument {id} payload
 * @returns {Object} data
 */
export const getLog = (payload) => async (dispatch) => {
  try {
    dispatch({ type: INIT_LOG_ITEM });
    const data = await axios.get(
      `/user-impersonation-audit-trail/${payload.id}`
    );
    dispatch({ type: SUCCESS_LOG_ITEM, data: data?.data });
    return "success";
  } catch (error) {
    dispatch({ type: FAIL_LOG_ITEM, error });
  }
};
