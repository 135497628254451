import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { NavHashLink } from "react-router-hash-link";
import "./Home.scss";

import VideoBackground from "../../components/Home/VideoBackground/VideoBackground";
import TopSlider from "../../components/Home/TopSlider/TopSlider";
import Loading from "../../components/Reusable/Loading/Loading";
import PageSections from "../../components/Reusable/PageSections/PageSections";
import NotFound from "../../components/Reusable/NotFound";
import { useLocation } from "react-router-dom";

function Home() {
  const { list, loading } = useSelector((state) => state.pages);
  const { hash } = useLocation();

  let homePage = list?.find((p) => p.group === "/");
  let page = {
    ...homePage,
    pageSections: homePage?.pageSections?.filter(
      (s) => s.section.key !== "meta-data"
    ),
  };

  const homePageSection = page?.pageSections?.find(
    (s) => s.section.key === "home-page-header"
  );

  const withoutHomeSection = page?.pageSections?.filter((e) => {
    if (e.section.key === "home-page-header") {
      return;
    }
    return e.data.title;
  });
  const homePageSectionTitleArray = withoutHomeSection?.map((e) => {
    return e.data.title;
  });

  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
        }, 100);
      }
    }
  }, [hash]);

  if (page) {
    return (
      <div>
        {homePageSection ? (
          <section className="MainSection">
            <VideoBackground
              videoUrl={homePageSection?.data?.backgroundVideoLink}
              backImage={homePageSection?.data?.backgroundVideoBannerImage}
            />
            <TopSlider
              textBottom={homePageSection?.data?.text2}
              linkUrl={homePageSection?.data?.link}
              linkText={homePageSection?.data?.linkText}
              slides={homePageSection?.data?.slides}
              btnTitle={homePageSection?.data?.buttonText}
              btnLink={homePageSection?.data?.btnLink}
            />
          </section>
        ) : (
          <NotFound
            title={loading ? "LOADING..." : "Home page header section missing"}
          />
        )}

        <div id="sticky" className={`StikyTab sticky`}>
          {homePageSection?.data?.subMenus?.map((t, i) => (
            <li key={i}>
              <NavHashLink
                smooth
                className={
                  hash ===
                  `#${t.scrollId
                    .trim()
                    .replace(/&/g, "")
                    .split(" ")
                    .join("")
                    .toLowerCase()}`
                    ? "on"
                    : ""
                }
                to={`#${t.scrollId
                  .trim()
                  .replace(/&/g, "")
                  .split(" ")
                  .join("")
                  .toLowerCase()}`}
              >
                {t.title}
              </NavHashLink>
            </li>
          ))}
        </div>
        <PageSections pageSections={page.pageSections} />
      </div>
    );
  } else {
    if (loading) {
      return <Loading />;
    } else {
      return <NotFound title={"Content missing!"} />;
    }
  }
}

export default Home;
