import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import userImg from "../../../assets/user.png";
import { isValidHttpUrl } from "../../../utils/func";
import { Button, FormControl, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { impersonateUser } from "../../../store/actions";

function UserTag(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user } = props;
  const [show, setShow] = useState(false)
  const [password, setPassword] = useState("")
  let type = "Sponsor";
  if (user.type === 1) {
    type = "Super Admin";
  } else if (user.type === 2) {
    type = "Manager";
  } else if (user.type === 3) {
    type = "Sponsor";
  } else if (user.type === 4) {
    type = "Coordinator";
  }

  let userImage = null;
  if (user?.image) {
    userImage = isValidHttpUrl(user?.image)
      ? user?.image
      : `${process.env.REACT_APP_SERVER}${user?.image}`;
  }
  const handleUserImpersonate = async (e) => {
    e.preventDefault()
    try {
      const payload = {
        userId: user.id,
        password: password
      }
      const msg = await dispatch(impersonateUser(payload))
      if (msg === 'success') {
        navigate('/sponsor/me')
      }
    } catch (error) {
      console.log(error);
    }
  }
  const handleUserView = () => {
    navigate(`/admin/users/${user.id}`)
  }

  return (
    <div className="UserTag">
      <div className="tagInfo">
        <div
          className="Image"
          style={{ backgroundImage: `url(${userImage ? userImage : userImg})` }}
        ></div>
        <div className="name">{user.firstName} {user.lastName}</div>
        <div className="role">{type}</div>
      </div>
      <div className="tagButton">
        <Button variant="info" onClick={() => setShow(true)}>Impersonate</Button>
        <Button onClick={handleUserView}>View</Button>
      </div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        className="CampaignPayment"
      >
        <form onSubmit={handleUserImpersonate} >
        <Modal.Header closeButton>
          Password (Your password)
        </Modal.Header>
        <Modal.Body>
        <FormControl placeholder="Your password" name="password" onChange={(e)=>setPassword(e.target.value)} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setShow(false)}>Close</Button>
          <Button type="submit">Impersonate</Button>
        </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

export default UserTag;
