import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const headers = { "Content-Type": "application/json" };

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER}api/v1/`,
  headers: headers,
  timeout: 20000,
});

// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    const impersonateToken = localStorage.getItem("impersonateToken");
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
    if(impersonateToken){
      config.headers['X-Impersonate'] = impersonateToken;
    }
    return config;
  },
  function (error) {
    if (process.env.NODE_ENV === "development") {
      console.error(error);
    }
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      if (process.env.NODE_ENV === "development") {
        console.error("Error", error.response);
      }
      if (error.response.status !== 422) {
        toast.error(
          `${error.response.data?.message ||
          error.response.data?.error?.decline_code ||
          error.response.data?.error?.code
          }`,
          {
            autoClose: 8000,
          }
        );
      }
      if (error?.response?.data?.errors) {
        for (let data in error?.response?.data?.errors) {
          toast.error(error?.response?.data?.errors[data], { autoClose: 3000 });
        }
      }
    } else if (error.request) {
      if (process.env.NODE_ENV === "development") {
        console.log("Error", error.request);
      }
      if (error.request?.data) {
        toast.error(`${error.request?.data}`, { autoClose: 8000 });
      }
    } else {
      if (process.env.NODE_ENV === "development") {
        console.log("Error", error.message);
      }
      toast.error(error.message, { autoClose: 8000 });
    }
    return Promise.reject(error);
  }
);

export default instance;
