import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getAllStudents } from "../../../store/actions";
import StudentTag from "../../StudentSearch/StudentTag/StudentTag";
import { LinkLineButton } from "../Button/Button";

function TemplateSixteen({ data }) {
  const dispatch = useDispatch();
  const students = useSelector((state) => state.students.data);
  useEffect(() => {
    const { student_1, student_2, student_3, student_4 } = data;
    const studentsIds = [student_1, student_2, student_3, student_4];
    dispatch(getAllStudents(1, { selected: studentsIds }));
  }, [data, dispatch]);

  return (
    <section id={data?.title?.trim().split(" ").join("").toLowerCase()} className="inContent">
      {data.text1 ? (
        <div dangerouslySetInnerHTML={{ __html: data.text1 }}></div>
      ) : undefined}
      <Row className="justify-content-center">
        {students?.rows?.map((student) => (
          <Col sm={3} key={student.id}>
            <StudentTag studentData={student} />
          </Col>
        ))}
      </Row>
      <div className="meetAll">
        {data.buttonLink ? (
          <LinkLineButton title={data.buttonText} to={data.buttonLink} />
        ) : undefined}
        {data.text2 ? (
          <div dangerouslySetInnerHTML={{ __html: data.text2 }}></div>
        ) : undefined}
      </div>
    </section>
  );
}

export default TemplateSixteen;
