import React from "react";
import { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAllStudents } from "../../../store/actions";
import StudentTag from "../../StudentSearch/StudentTag/StudentTag";
import { LinkLineButton } from "../Button/Button";

function TemplateSeventeen({ data }) {
    const dispatch = useDispatch();
    const students = useSelector((state) => state.students.data);
    useEffect(() => {
        const { student_1, student_2, student_3, student_4 } = data;
        const studentsIds = [student_1, student_2, student_3, student_4];
        dispatch(getAllStudents(1, { selected: studentsIds }));
    }, [data, dispatch]);

    return (
        <section
            id={data?.title?.trim().split(" ").join("").toLowerCase()}
            className="inContent"
        >
            {data.text1 ? (
                <div dangerouslySetInnerHTML={{ __html: data.text1 }}></div>
            ) : undefined}

            <div class="socialData center-items">
                {data.fb&&<a
                    rel="noopener noreferrer"
                    target="_blank"
                    class="fb"
                    href={`https://www.facebook.com/sharer/sharer.php?u=${data.fb}`}
                >
                    <i class="fa fa-facebook" aria-hidden="true"></i>
                </a>}
                {data.twitter&&<a
                    rel="noopener noreferrer"
                    target="_blank"
                    class="tw"
                    href={`https://twitter.com/intent/tweet?url=http:${data.twitter}`}
                >
                    <i class="fa fa-twitter" aria-hidden="true"></i>
                </a>}
                {data.linkedin&&<a
                    rel="noopener noreferrer"
                    target="_blank"
                    class="linkedin"
                    href={`https://www.linkedin.com/cws/share?url=${data.linkedin}`}
                >
                    <i class="fa fa-linkedin" aria-hidden="true"></i>
                </a>}
                {data.email&&<a
                    rel="noopener noreferrer"
                    href={`mailto:?subject=Educate Lanka&amp;body=${data.email}`}
                    target="_blank"
                    class="mail"
                >
                    <i class="fa fa-envelope" aria-hidden="true"></i>
                </a>}
            </div>
            {data.text2 ? (
                <div dangerouslySetInnerHTML={{ __html: data.text2 }}></div>
            ) : undefined}
        </section>
    );
}

export default TemplateSeventeen;
