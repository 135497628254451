export const INIT_USER_REGISTER = "INIT_USER_REGISTER",
  SUCCESS_USER_REGISTER = "SUCCESS_USER_REGISTER",
  FAIL_USER_REGISTER = "FAIL_USER_REGISTER",
  INIT_USER_EDIT = "INIT_USER_EDIT",
  SUCCESS_USER_EDIT = "SUCCESS_USER_EDIT",
  FAIL_USER_EDIT = "FAIL_USER_REGISTER",
  INIT_USER_LOGIN = "INIT_USER_LOGIN",
  SUCCESS_USER_LOGIN = "SUCCESS_USER_LOGIN",
  FAIL_USER_LOGIN = "FAIL_USER_LOGIN",
  AUTH_CHECK = "AUTH_CHECK",
  INIT_HOME = "INIT_HOME",
  SUCCESS_HOME = "SUCCESS_HOME",
  FAIL_HOME = "FAIL_HOME",
  INIT_FORGET_PASSWORD = "INIT_FORGET_PASSWORD",
  SUCCESS_FORGET_PASSWORD = "SUCCESS_FORGET_PASSWORD",
  FAIL_FORGET_PASSWORD = "FAIL_FORGET_PASSWORD",
  SUCCESS_RESET_PASSWORD = "SUCCESS_RESET_PASSWORD",
  INIT_STUDENTS = "INIT_STUDENTS",
  SUCCESS_STUDENTS = "SUCCESS_STUDENTS",
  FAIL_STUDENTS = "FAIL_STUDENTS",
  SUCCESS_STUDENTS_LIMIT = "SUCCESS_STUDENTS_LIMIT",
  SUCCESS_STUDENTS_FILTER = "SUCCESS_STUDENTS_FILTER",
  INIT_ONE_STUDENT = "INIT_ONE_STUDENT",
  SUCCESS_ONE_STUDENT = "SUCCESS_ONE_STUDENT",
  FAIL_ONE_STUDENT = "FAIL_ONE_STUDENT",
  RESET_ONE_STUDENTS = "RESET_ONE_STUDENTS",
  INIT_ACCOUNT = "INIT_ACCOUNT",
  SUCCESS_ACCOUNT = "SUCCESS_ACCOUNT",
  FAIL_ACCOUNT = "FAIL_ACCOUNT",
  RESET_ACCOUNT = "RESET_ACCOUNT",
  INIT_ONE_SPONSOR = "INIT_ONE_SPONSOR",
  SUCCESS_ONE_SPONSOR = "SUCCESS_ONE_SPONSOR",
  FAIL_ONE_SPONSOR = "FAIL_ONE_SPONSOR",
  RESET_ONE_SPONSOR = "RESET_ONE_SPONSOR",
  INIT_SPONSORSHIPS = "INIT_SPONSORSHIPS",
  SUCCESS_SPONSORSHIPS = "SUCCESS_SPONSORSHIPS",
  FAIL_SPONSORSHIPS = "FAIL_SPONSORSHIPS",
  INIT_ME = "INIT_ME",
  SUCCESS_ME = "SUCCESS_ME",
  FAIL_ME = "FAIL_ME",
  INIT_SPONSORSHIP = "INIT_SPONSORSHIP",
  SUCCESS_SPONSORSHIP = "SUCCESS_SPONSORSHIP",
  FAIL_SPONSORSHIP = "FAIL_SPONSORSHIP",
  INIT_PAYMENT = "INIT_PAYMENT",
  SUCCESS_PAYMENT = "SUCCESS_PAYMENT",
  FAIL_PAYMENT = "FAIL_PAYMENT",
  RESET_PAYMENT = "RESET_PAYMENT",
  INIT_GIFTCARD = "INIT_GIFTCARD",
  SUCCESS_GIFTCARD = "SUCCESS_GIFTCARD",
  FAIL_GIFTCARD = "FAIL_GIFTCARD",
  INIT_CAMPAIGN_PAYMENT = "INIT_CAMPAIGN_PAYMENT",
  SUCCESS_CAMPAIGN_PAYMENT = "SUCCESS_CAMPAIGN_PAYMENT",
  FAIL_CAMPAIGN_PAYMENT = "FAIL_CAMPAIGN_PAYMENT",
  INIT_CREATE_CAMPAIGN = "INIT_CREATE_CAMPAIGN",
  SUCCESS_CREATE_CAMPAIGN = "SUCCESS_CREATE_CAMPAIGN",
  FAIL_CREATE_CAMPAIGN = "FAIL_CREATE_CAMPAIGN",
  INIT_CAMPAIGN = "INIT_CAMPAIGN",
  SUCCESS_CAMPAIGN = "SUCCESS_CAMPAIGN",
  FAIL_CAMPAIGN = "FAIL_CAMPAIGN",
  INIT_CAMPAIGN_LIST = "INIT_CAMPAIGN_LIST",
  SUCCESS_CAMPAIGN_LIST = "SUCCESS_CAMPAIGN_LIST",
  FAIL_CAMPAIGN_LIST = "FAIL_CAMPAIGN_LIST",
  INIT_UPDATE_CAMPAIGN = "INIT_UPDATE_CAMPAIGN",
  FAIL_UPDATE_CAMPAIGN = "FAIL_UPDATE_CAMPAIGN",
  SUCCESS_UPDATE_CAMPAIGN = "SUCCESS_UPDATE_CAMPAIGN",
  RESET_GIFTCARD = "RESET_GIFTCARD",
  RESET_CAMPAIGN = "RESET_CAMPAIGN",
  INIT_CHAT_SENT = "INIT_CHAT_SENT",
  FAIL_CHAT_SENT = "FAIL_CHAT_SENT",
  SUCCESS_CHAT_SENT = "SUCCESS_CHAT_SENT",
  INIT_CHAT_GET = "INIT_CHAT_GET",
  SUCCESS_CHAT_GET = "SUCCESS_CHAT_GET",
  FAIL_CHAT_GET = "FAIL_CHAT_GET",
  INIT_GET_ALL_SPONSORSHIPS = "INIT_GET_ALL_SPONSORSHIPS",
  SUCCESS_GET_ALL_SPONSORSHIPS = "SUCCESS_GET_ALL_SPONSORSHIPS",
  FAIL_GET_ALL_SPONSORSHIPS = "FAIL_GET_ALL_SPONSORSHIPS",
  INIT_PAGE = "INIT_PAGE",
  SUCCESS_PAGE = "SUCCESS_PAGE",
  FAIL_PAGE = "FAIL_PAGE",
  INIT_CREATE_PAGE = "INIT_CREATE_PAGE",
  SUCCESS_CREATE_PAGE = "SUCCESS_CREATE_PAGE",
  FAIL_CREATE_PAGE = "FAIL_CREATE_PAGE",
  INIT_UPDATE_PAGE = "INIT_UPDATE_PAGE",
  SUCCESS_UPDATE_PAGE = "SUCCESS_UPDATE_PAGE",
  FAIL_UPDATE_PAGE = "FAIL_UPDATE_PAGE",
  INIT_STUDENT_NEWS = "INIT_STUDENT_NEWS",
  SUCCESS_STUDENT_NEWS = "SUCCESS_STUDENT_NEWS",
  FAIL_STUDENT_NEWS = "FAIL_STUDENT_NEWS",
  RESET_NEWS = "RESET_NEWS",
  INIT_CREATE_SPONSORSHIP = "INIT_CREATE_SPONSORSHIP",
  SUCCESS_CREATE_SPONSORSHIP = "SUCCESS_CREATE_SPONSORSHIP",
  FAIL_CREATE_SPONSORSHIP = "FAIL_CREATE_SPONSORSHIP",
  INIT_CANCEL_SPONSORSHIP = "INIT_CANCEL_SPONSORSHIP",
  SUCCESS_CANCEL_SPONSORSHIPS = "SUCCESS_CANCEL_SPONSORSHIPS",
  FAIL_CANCEL_SPONSORSHIPS = "FAIL_CANCEL_SPONSORSHIPS",
  LOGIN_MODAL_VISIBILITY = "LOGIN_MODAL_VISIBILITY",
  SIGNUP_MODAL_VISIBILITY = "SIGNUP_MODAL_VISIBILITY",
  RESET_SPONSORSHIP = "RESET_SPONSORSHIP",
  FORGET_PASSWORD_MODAL_VISIBILITY = "FORGET_PASSWORD_MODAL_VISIBILITY",
  RESET_FORGET_PASSWORD = "RESET_FORGET_PASSWORD",
  INIT_CONTACT_US = "INIT_CONTACT_US",
  SUCCESS_CONTACT_US = "SUCCESS_CONTACT_US",
  FAIL_CONTACT_US = "FAIL_CONTACT_US",
  REMOVE_USER_UPDATE_DATA = "REMOVE_USER_UPDATE_DATA",
  RESET_CONTACT_US = "RESET_CONTACT_US",
  SUCCESS_CANCEL_SUBSCRIPTION = "SUCCESS_CANCEL_SUBSCRIPTION",
  FAIL_CANCEL_SUBSCRIPTION = "FAIL_CANCEL_SUBSCRIPTION";

export const INIT_GET_SPONSORSHIP = "INIT_GET_SPONSORSHIP",
  SUCCESS_GET_SPONSORSHIP = "SUCCESS_GET_SPONSORSHIP",
  FAIL_GET_SPONSORSHIP = "FAIL_GET_SPONSORSHIP";

export const INIT_GET_ALL_USERS = "INIT_GET_ALL_USERS",
  SUCCESS_GET_ALL_USERS = "SUCCESS_GET_ALL_USERS",
  FAIL_GET_ALL_USERS = "FAIL_GET_ALL_USERS";

export const INIT_SYNC_STUDENTS = "INIT_SYNC_STUDENTS",
  SUCCESS_SYNC_STUDENTS = "SUCCESS_SYNC_STUDENTS",
  FAIL_SYNC_STUDENTS = "FAIL_SYNC_STUDENTS";

export const INIT_LEGACY_PAYMENT = "INIT_LEGACY_PAYMENT",
  SUCCESS_LEGACY_PAYMENT = "SUCCESS_LEGACY_PAYMENT",
  FAIL_LEGACY_PAYMENT = "FAIL_LEGACY_PAYMENT";

export const INIT_UPDATE_STATUS_SPONSORSHIP = "INIT_UPDATE_STATUS_SPONSORSHIP",
  SUCCESS_CUPDATE_STATUSSPONSORSHIPS = "SUCCESS_CUPDATE_STATUSSPONSORSHIPS",
  FAIL_CUPDATE_STATUSSPONSORSHIPS = "FAIL_CUPDATE_STATUSSPONSORSHIPS";

export const INIT_CHAT_UPDATE = "INIT_CHAT_UPDATE",
  SUCCESS_CHAT_UPDATE = "SUCCESS_CHAT_UPDATE",
  FAIL_CHAT_UPDATE = "FAIL_CHAT_UPDATE";

export const INIT_GET_ELF_DONATIONS = "INIT_GET_ELF_DONATIONS",
  SUCCESS_GET_ELF_DONATIONS = "SUCCESS_GET_ELF_DONATIONS",
  FAIL_GET_ELF_DONATIONS = "FAIL_GET_ELF_DONATIONS";

export const SECTION_CONTENT_SET_LIST = "SECTION_CONTENT_SET_LIST",
  SECTION_CONTENT_SET_SEARCH_QUERY = "SECTION_CONTENT_SET_SEARCH_QUERY",
  SECTION_CONTENT_SET_PAGINATION = "SECTION_CONTENT_SET_PAGINATION",
  SECTION_CONTENT_SET_ITEM = "SECTION_CONTENT_SET_ITEM",
  SECTION_CONTENT_SET_REQUEST_IN_PROGRESS =
    "SECTION_CONTENT_SET_REQUEST_IN_PROGRESS";

export const INIT_SECTION = "INIT_SECTION",
  FAIL_SECTION = "FAIL_SECTION",
  SUCCESS_SECTION = "SUCCESS_SECTION",
  SUCCESS_CREATE_SECTION = "SUCCESS_CREATE_SECTION",
  SUCCESS_DELETE_SECTION = "SUCCESS_DELETE_SECTION",
  SUCCESS_LIST_SECTION = "SUCCESS_LIST_SECTION";

export const INIT_PAGES = "INIT_PAGES",
  FAIL_PAGES = "FAIL_PAGES",
  SUCCESS_CREATE_PAGES = "SUCCESS_CREATE_PAGES",
  SUCCESS_PAGES = "SUCCESS_PAGES",
  SUCCESS_DELETE_PAGES = "SUCCESS_DELETE_PAGES",
  SUCCESS_LIST_PAGES = "SUCCESS_LIST_PAGES";

export const INIT_MEDIA = "INIT_MEDIA",
  FAIL_MEDIA = "FAIL_MEDIA",
  SUCCESS_CREATE_MEDIA = "SUCCESS_CREATE_MEDIA",
  UPLOAD_PROGRESS_MEDIA = "UPLOAD_PROGRESS_MEDIA",
  SUCCESS_DELETE_MEDIA = "SUCCESS_DELETE_MEDIA",
  SUCCESS_LIST_MEDIA = "SUCCESS_LIST_MEDIA";

export const INIT_INVITE = "INIT_INVITE",
  SUCCESS_INVITE = "SUCCESS_INVITE",
  FAIL_INVITE = "FAIL_INVITE";

export const INIT_GET_METADATA = "INIT_GET_METADATA",
  SUCCESS_GET_METADATA = "SUCCESS_GET_METADATA",
  FAIL_GET_METADATA = "FAIL_GET_METADATA";

export const INIT_GET_STUDENT = "INIT_GET_STUDENT",
  SUCCESS_GET_STUDENT = "SUCCESS_GET_STUDENT",
  FAIL_GET_STUDENT = "FAIL_GET_STUDENT";

export const INIT_GET_EVENT_LIST = "INIT_GET_EVENT_LIST",
  SUCCESS_GET_EVENT_LIST = "SUCCESS_GET_EVENT_LIST",
  FAIL_GET_EVENT_LIST = "FAIL_GET_EVENT_LIST";

export const INIT_CREATE_EVENT = "INIT_CREATE_EVENT",
  SUCCESS_CREATE_EVENT = "SUCCESS_CREATE_EVENT",
  FAIL_CREATE_EVENT = "FAIL_CREATE_EVENT";

export const INIT_UPDATE_EVENT = "INIT_UPDATE_EVENT",
  SUCCESS_UPDATE_EVENT = "SUCCESS_UPDATE_EVENT",
  FAIL_UPDATE_EVENT = "FAIL_UPDATE_EVENT";

export const INIT_GET_EVENT = "INIT_GET_EVENT",
  SUCCESS_GET_EVENT = "SUCCESS_GET_EVENT",
  FAIL_GET_EVENT = "FAIL_GET_EVENT";

export const ADD_STUDENT_TO_LIST = "ADD_STUDENT_TO_LIST",
  DELETE_STUDENT_FROM_LIST = "DELETE_STUDENT_FROM_LIST",
  CLEAR_STUDENT_LIST = "CLEAR_STUDENT_LIST";

export const HANDLE_EVENT_DATA_CHANGE = "HANDLE_EVENT_DATA_CHANGE";

export const SUCCESS_DELETE_EVENT = "SUCCESS_DELETE_EVENT",
  INIT_DELETE_EVENT = "INIT_DELETE_EVENT",
  FAIL_DELETE_EVENT = "FAIL_DELETE_EVENT";

export const INIT_DOWNLOAD_EXCEL = "INIT_DOWNLOAD_EXCEL",
  SUCCESS_DOWNLOAD_EXCEL = "SUCCESS_DOWNLOAD_EXCEL",
  FAIL_DOWNLOAD_EXCEL = "FAIL_DOWNLOAD_EXCEL";

export const INIT_EVENT_SYNC = "INIT_EVENT_SYNC",
  SUCCESS_EVENT_SYNC = "SUCCESS_EVENT_SYNC",
  FAIL_EVENT_SYNC = "FAIL_EVENT_SYNC";

export const INIT_IMPERSONATE = "INIT_IMPERSONATE",
  SUCCESS_IMPERSONATE = "SUCCESS_IMPERSONATE",
  FAIL_IMPERSONATE = "FAIL_IMPERSONATE";

export const INIT_LOG_LIST = "INIT_LOG_LIST",
  SUCCESS_LOG_LIST = "SUCCESS_LOG_LIST",
  FAIL_LOG_LIST = "FAIL_LOG_LIST";

export const INIT_LOG_ITEM = "INIT_LOG_ITEM",
  SUCCESS_LOG_ITEM = "SUCCESS_LOG_ITEM",
  FAIL_LOG_ITEM = "FAIL_LOG_ITEM";
