import React, { useState } from "react";
import {
  FormGroup,
  FormLabel,
  Form,
  Button,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";
import RightSideBarToggler from "../../Layout/RightSideBarToggler/RightSideBarToggler";

const selectDefaultValue = {
  value: "",
  label: `Select`,
  name: ``,
};
const status = [
  {
    value: "pending",
    label: `Pending`,
    name: `status`,
  },
  {
    value: "active",
    label: `Active`,
    name: `status`,
  },
  {
    value: "canceled",
    label: `Canceled`,
    name: `status`,
  },
];
const due = [
  {
    value: "today",
    label: `Today due`,
    name: `due`,
  },
  {
    value: "past",
    label: `Past due`,
    name: `due`,
  },
];
function Filters({ handleFilter }) {
  const [selectedStudent, setSelectedStudent] = useState(selectDefaultValue);
  const [selectedSponsor, setSelectedSponsor] = useState(selectDefaultValue);
  const [selectedStatus, setSelectedStatus] = useState(selectDefaultValue);
  const [selectedDue, setSelectedDue] = useState(selectDefaultValue);

  const { data: students } = useSelector((state) => state.students);
  const { users } = useSelector((state) => state.auth);

  const formSubmit = (e) => {
    e.preventDefault()
    handleFilter({
      userId: selectedSponsor.value,
      beneficiaryId: selectedStudent.value,
      status: selectedStatus.value,
      due: selectedDue.value,
    });
  };

  return (
    <RightSideBarToggler>
      <h4>Filter sponsorships</h4>
      <hr />
      <Form onSubmit={formSubmit}>
        <FormGroup>
          <FormLabel>Sponsorship status</FormLabel>
          <div className={selectedStatus.value ? "autoGrid" : ""}>
            <Select
              options={status}
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e)}
            />
            {selectedStatus.value && (
              <Button
                block
                variant="danger"
                onClick={() => setSelectedStatus(selectDefaultValue)}
              >
                <i className="fa fa-trash-o" aria-hidden="true"></i>
              </Button>
            )}
          </div>
        </FormGroup>

        <FormGroup>
          <FormLabel>Select due</FormLabel>
          <div className={selectedDue.value ? "autoGrid" : ""}>
            <Select
              options={due}
              value={selectedDue}
              onChange={(e) => setSelectedDue(e)}
            />
            {selectedDue.value && (
              <Button
                block
                variant="danger"
                onClick={() => setSelectedDue(selectDefaultValue)}
              >
                <i className="fa fa-trash-o" aria-hidden="true"></i>
              </Button>
            )}
          </div>
        </FormGroup>

        <FormGroup>
          <FormLabel>Student name</FormLabel>
          <div className={selectedStudent.value ? "autoGrid" : ""}>
            <Select
              options={students?.rows?.map((st) => {
                return {
                  value: st.id,
                  label: `${st.firstName} ${st.lastName}`,
                  name: `students`,
                };
              })}
              value={selectedStudent}
              onChange={(e) => setSelectedStudent(e)}
            />
            {selectedStudent.value && (
              <Button
                block
                variant="danger"
                onClick={() => setSelectedStudent(selectDefaultValue)}
              >
                <i className="fa fa-trash-o" aria-hidden="true"></i>
              </Button>
            )}
          </div>
        </FormGroup>

        <FormGroup>
          <FormLabel>Sponsor name</FormLabel>
          <div className={selectedSponsor.value ? "autoGrid" : ""}>
            <Select
              options={users?.rows.map((st) => {
                return {
                  value: st.id,
                  label: `${st.firstName} ${st.lastName}`,
                  name: `students`,
                };
              })}
              value={selectedSponsor}
              onChange={(e) => setSelectedSponsor(e)}
            />
            {selectedSponsor.value && (
              <Button block variant="danger"  onClick={() => setSelectedSponsor(selectDefaultValue)}>
                <i className="fa fa-trash-o" aria-hidden="true"></i>
              </Button>
            )}
          </div>
        </FormGroup>
        <Button
          // variant="success"
          className="mt-2"
          type="submit"
          block
        >
          Filter
        </Button>
      </Form>
    </RightSideBarToggler>
  );
}

export default Filters;
