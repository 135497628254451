import {
  LOGIN_MODAL_VISIBILITY,
  SIGNUP_MODAL_VISIBILITY,
  FORGET_PASSWORD_MODAL_VISIBILITY,
} from "./actionsTypes";

export const signupModalHandle = (value) => ({
  type: SIGNUP_MODAL_VISIBILITY,
  value,
});
export const loginModalHandle = (value) => ({
  type: LOGIN_MODAL_VISIBILITY,
  value,
});
export const forgetPasswordModalHandle = (value) => ({
  type: FORGET_PASSWORD_MODAL_VISIBILITY,
  value,
});
