/*eslint-disable*/
import React from "react";
import { Row, Col } from "react-bootstrap";
import StudentTag from "../../StudentSearch/StudentTag/StudentTag";
import "./MeetMore.scss";
import { LinkLineButton } from "../../Reusable/Button/Button";
import Loading from "../../Reusable/Loading/Loading";

function MeetMore(props) {
  let students;
  if (props.loading) {
    students = (
      <div className="centerItem">
        <Loading section />
      </div>
    );
  } else {
    if (props.data) {
      students = props.data.rows.map((student, i) => {
        if (i < 3) {
          return (
            <Col md={4} key={student.id}>
              <StudentTag
                studentData={student}
                getAccount={props.getAccount}
                acData={props.acData}
              />
            </Col>
          );
        }
        return;
      });
    }
  }
  return (
    <div className="MeetMore">
      <h2 className="text-center mt-5 mb-3 light-font">Meet more students</h2>
      <Row className="mb-5 mt-5">{students}</Row>

      <LinkLineButton to="/student-list" title="Meet all students" />
    </div>
  );
}

export default MeetMore;
