import axios from "../../axios";
import { FAIL_SYNC_STUDENTS, INIT_SYNC_STUDENTS, SUCCESS_SYNC_STUDENTS } from "./actionsTypes";

const initStudentSync = () => ({
  type: INIT_SYNC_STUDENTS,
});
const successStudentSync = (data) => ({
  type: SUCCESS_SYNC_STUDENTS,
  data,
});
const failStudentSync = (error) => ({
  type: FAIL_SYNC_STUDENTS,
  error,
});

export const syncStudents = (token) => async (dispatch) => {
  try {
    dispatch(initStudentSync());
    const students = await axios.post("salesforce/students/migrate", {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: 180000,
    });
    dispatch(successStudentSync(students?.data));
  } catch (error) {
    dispatch(failStudentSync(error));
  }
};
