import React from "react";
import { useEffect } from "react";
import { Col, FormControl, Row, ProgressBar, Button, FormGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { listMedia, createMedia, deleteMedia } from "../../../../store/actions";

function MediaLibrary() {
  const dispatch = useDispatch();
  const { list, progress } = useSelector((state) => state.media);

  useEffect(() => {
    dispatch(listMedia());
  }, [dispatch]);

  const handleUploadMedia = (e) => {
    dispatch(createMedia(e.target.files[0]));
  };
  const handleDelete = (id) => {
    if(window.confirm("Do you want to delete this image?")){
      dispatch(deleteMedia(id));
    }
  };
  return (
    <div>
      {progress && (
        <ProgressBar
          variant="info"
          now={parseInt(progress)}
          label={`${parseInt(progress)}%`}
        />
      )}
      <div className="HeaderTextCMS">
        CMS/Sections
        <div className="Filters">
          <FormControl
            className="fileUpload"
            type="file"
            onChange={handleUploadMedia}
          />
        </div>
      </div>

      <Row>
        {list?.map((m) => {
          const type = m.mimeType.split("/");
          if (type[0] === "image") {
            return (
              <Col md={3} key={m.id}>
                <div
                  className="MediaContent"
                  style={{ backgroundImage: `url('${m.url}')` }}
                >
                  <Button
                    className="deleteButton"
                    variant="danger"
                    onClick={() => handleDelete(m.id)}
                  >
                    <i className="fa fa-trash"></i>
                  </Button>
                </div>
                <FormGroup>
                  <FormControl value={m.url} />
                </FormGroup>
              </Col>
            );
          } else if (type[0] === "video") {
            return (
              <Col md={3}>
                <div className="MediaContent">
                  <Button
                    className="deleteButton"
                    variant="danger"
                    onClick={() => handleDelete(m.id)}
                  >
                    <i className="fa fa-trash"></i>
                  </Button>
                  <video width="100%" height="240" controls>
                    <source src={m.url} type={m.mimeType} />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <FormGroup>
                  <FormControl value={m.url} />
                </FormGroup>
              </Col>
            );
          }
        })}
      </Row>
    </div>
  );
}

export default MediaLibrary;
