import * as actions from "./actionsTypes";
import axios from "../../axios";

const initOneSponsor = () => ({
  type: actions.INIT_ONE_SPONSOR,
});

const successOneSponsor = (data) => ({
  type: actions.SUCCESS_ONE_SPONSOR,
  data: data,
});

const failOneSponsor = (err) => ({
  type: actions.FAIL_ONE_SPONSOR,
  err,
});

export const getAuthSponsor = (token) => {
  return (dispatch) => {
    dispatch(initOneSponsor());
    axios
      .post(
        `auth/detail`,
        {},
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        dispatch(successOneSponsor(res.data.data));
      })
      .catch((err) => {
        return dispatch(failOneSponsor(err));
      });
  };
};

export const getAdminAuthSponsor = (userId, token) => {
  return (dispatch) => {
    dispatch(initOneSponsor());
    axios
      .post(
        `auth/detail`,
        { userId },
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        dispatch(successOneSponsor(res.data.data));
      })
      .catch((err) => {
        return dispatch(failOneSponsor(err));
      });
  };
};

export const getOneSponsor = (id) => {
  return (dispatch) => {
    dispatch(initOneSponsor());
    axios
      .get(`user/${id}`)
      .then((res) => {
        dispatch(successOneSponsor(res.data.data));
      })
      .catch((err) => {
        return dispatch(failOneSponsor(err));
      });
  };
};

const initCancelSponsorship = () => ({
  type: actions.INIT_CANCEL_SPONSORSHIP,
});
const successCancelSponsorship = (data) => ({
  type: actions.SUCCESS_CANCEL_SPONSORSHIPS,
  data,
});
const successCancelSubscription = (data) => ({
  type: actions.SUCCESS_CANCEL_SUBSCRIPTION,
  data,
});
const failCancelSubscription = (err) => ({
  type: actions.FAIL_CANCEL_SUBSCRIPTION,
  err,
});
const failCancelSponsorship = (err) => ({
  type: actions.FAIL_CANCEL_SPONSORSHIPS,
  err,
});

export const cancelSponsorship = (data, token) => {
  return (dispatch) => {
    dispatch(initCancelSponsorship());

   return axios
      .put(`sponsorships/cancel/${data.sponsorshipId}`, data, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        dispatch(successCancelSponsorship(res.data));
        return res.data;
      })
      .catch((err) => {
        dispatch(failCancelSponsorship(err));
        throw err;
      });
  };
};

export const cancelSubscription = (id, token) => {
  return (dispatch) => {
    dispatch(initCancelSponsorship());

   return axios
      .put(`sponsorships/cancel/subscription/${id}`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        dispatch(successCancelSubscription(res.data));
        return res.data;
      })
      .catch((err) => {
        dispatch(failCancelSubscription(err));
        throw err;
      });
  };
};
