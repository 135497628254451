import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import {
  FormControl,
  FormGroup,
  FormLabel,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { useEffect } from "react";

function HomePageHeader({ section, editMode, handleSectionDataChange }) {
  const [data, setData] = useState({
    title: "",
    backgroundVideoLink: "",
    backgroundVideoBannerImage: "",
    buttonText: "",
    btnLink: "",
    text2: "",
    linkText: "",
    link: "",
    slides: [],
    subMenus: [],
  });

  useEffect(() => {
    handleSectionDataChange({
      ...section,
      index: section.index,
      data: data,
    });
  }, [data]);

  //===EDIT MODE SET DATA
  useEffect(() => {
    if (editMode && section.data) {
      setData(prev=>({...prev,...section.data}));
    }
  }, [editMode]);
  //===END EDIT MODE SET DATA

  const handleChange = (e) => {
    setData((dt) => ({ ...dt, [e.target.name]: e.target.value }));
  };
  const handleChangeSlides = (e) => {
    const copySlides = [...data.slides];
    const tagIndex = e.target.name.split("_")[1];
    copySlides[tagIndex][e.target.name.split("_")[0]] = e.target.value;
    setData((dt) => ({ ...dt, slides: copySlides }));
  };
  const addSection = (e) => {
    setData((dt) => ({
      ...dt,
      slides: [...dt.slides, { title: "", text: "" }],
    }));
  };

  const handleChangeSubMenu = (e) => {
    const copySubMenu = [...data.subMenus];
    const tagIndex = e.target.name.split("_")[1];
    copySubMenu[tagIndex][e.target.name.split("_")[0]] = e.target.value;
    setData((dt) => ({ ...dt, subMenus: copySubMenu }));
  };
  const addSabMenuSection = () => {
    setData((dt) => ({
      ...dt,
      subMenus: [...dt.subMenus, { title: "", scrollId: "" }],
    }));
  };
  return (
    <div>
      <FormGroup>
        <FormLabel>Title</FormLabel>
        <FormControl name="title" value={data?.title} onChange={handleChange} />
      </FormGroup>
      <FormGroup>
        <FormLabel>Background Video Link</FormLabel>
        <FormControl
          name="backgroundVideoLink"
          value={data?.backgroundVideoLink}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Background Video Banner Image</FormLabel>
        <FormControl
          name="backgroundVideoBannerImage"
          value={data?.backgroundVideoBannerImage}
          onChange={handleChange}
        />
      </FormGroup>

      <FormGroup>
        <FormLabel>Button Text</FormLabel>
        <FormControl
          name="buttonText"
          value={data?.buttonText}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Button Link</FormLabel>
        <FormControl
          name="btnLink"
          value={data?.btnLink}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Text2</FormLabel>
        <FormControl name="text2" value={data?.text2} onChange={handleChange} />
      </FormGroup>
      <Row>
        <Col md={6}>
          <FormGroup>
            <FormLabel>Link Text</FormLabel>
            <FormControl
              name="linkText"
              value={data?.linkText}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <FormLabel>Link</FormLabel>
            <FormControl
              name="link"
              value={data?.link}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
      {data?.slides?.map((v, i) => (
        <Row key={i}>
          <Col sm={4}>
            <FormGroup>
              <FormLabel>Title {i + 1}</FormLabel>
              <FormControl
                name={`title_${i}`}
                value={data?.slides[i].title}
                onChange={handleChangeSlides}
              />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <FormLabel>Text {i + 1}</FormLabel>
              <FormControl
                name={`text_${i}`}
                value={data?.slides[i].text}
                onChange={handleChangeSlides}
              />
            </FormGroup>
          </Col>
        </Row>
      ))}
      <Button onClick={addSection}>ADD SECTION</Button>
      <hr />
      {data?.subMenus?.map((v, i) => (
        <Row key={i}>
          <Col sm={4}>
            <FormGroup>
              <FormLabel>Title {i + 1}</FormLabel>
              <FormControl
                name={`title_${i}`}
                value={data?.subMenus[i].title}
                onChange={handleChangeSubMenu}
              />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <FormLabel>Scroll ID {i + 1} (section name)</FormLabel>
              <FormControl
                name={`scrollId_${i}`}
                value={data?.subMenus[i].scrollId}
                onChange={handleChangeSubMenu}
              />
            </FormGroup>
          </Col>
        </Row>
      ))}
      <Button onClick={addSabMenuSection}>ADD SUB MENU</Button>
    </div>
  );
}

export default HomePageHeader;
