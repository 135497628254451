import React from "react";
import "./Loading.scss";

const Loading = props => {
  let load = (
    <div className="loadingMode">
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  );

  if (props.section) {
    load = (
      <div className="LoadingSection">
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
  if (props.page) {
    load = (
      <div className="LoadingPage">
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
  if (props.button) {
    load = (
      <div className="LoadingButton">
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
  if (props.cycle) {
    load = (
      <div className="LoadingCycle">
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
  return load;
};

export default Loading;
