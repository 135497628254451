import * as actions from "./actionsTypes";
import axios from "../../axios";

const initChatSent = () => ({
  type: actions.INIT_CHAT_SENT,
});
const successChatSent = (data) => ({
  type: actions.SUCCESS_CHAT_SENT,
  data,
});
const failChatSent = (error) => ({
  type: actions.FAIL_CHAT_SENT,
  error,
});
const initChatUpdate = () => ({
  type: actions.INIT_CHAT_UPDATE,
});
const successChatUpdate = (data) => ({
  type: actions.SUCCESS_CHAT_UPDATE,
  data,
});
const failChatUpdate = (error) => ({
  type: actions.FAIL_CHAT_UPDATE,
  error,
});
const initChatGet = () => ({
  type: actions.INIT_CHAT_GET,
});
const successChatGet = (data) => ({
  type: actions.SUCCESS_CHAT_GET,
  data,
});
const failChatGet = (error) => ({
  type: actions.FAIL_CHAT_GET,
  error,
});

export const sentMessage = (data, token) => {
  const formData = new FormData();
  formData.append("chatfiles", data.chatfiles);
  formData.append("message", data.message);
  formData.append("sponsorshipId", data.sponsorshipId);
  const admin = data.admin ? true : false;
  return (dispatch) => {
    dispatch(initChatSent());
    axios
      .post("/chat", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        dispatch(successChatSent({ ...res.data, admin: admin }));
        return res.data
      })
      .catch((err) => {
        dispatch(failChatSent(err.data));
      });
  };
};

export const updateMessage = (data, token) => {
  return (dispatch) => {
    dispatch(initChatUpdate());
    const { admin } = data;
    axios
      .put(`/chat/${data.sponsorshipId}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(successChatUpdate({ ...res.data[0], admin: admin }));
      })
      .catch((err) => {
        dispatch(failChatUpdate(err.data));
      });
  };
};
export const getMessages = (spsId, token) => {
  return (dispatch) => {
    dispatch(initChatGet());
    axios
      .get(`/chat/${spsId}`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        dispatch(successChatGet(res.data));
      })
      .catch((err) => {
        dispatch(failChatGet(err.data));
      });
  };
};export const getMessageFilterFromSponsorship = (sId) => {
  return (dispatch) => {
    dispatch(initChatGet());
    axios
      .get(`/chat/filter-by-sponsorship/${sId}`)
      .then((res) => {
        dispatch(successChatGet(res.data));
      })
      .catch((err) => {
        dispatch(failChatGet(err.data));
      });
  };
};
