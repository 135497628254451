/*eslint-disable*/
import * as actions from "./actionsTypes";
import axios from "../../axios";
import { toast } from "react-toastify";

const initCampaignPayment = () => ({
  type: actions.INIT_CAMPAIGN_PAYMENT,
});

const failCampaignPayment = (error) => ({
  type: actions.FAIL_CAMPAIGN_PAYMENT,
  error,
});

const successCampaignPayment = (data) => ({
  type: actions.SUCCESS_CAMPAIGN_PAYMENT,
  data,
});

export const createCampaignPaymentWithStripe = (data, token) => {
  return (dispatch) => {
    dispatch(initCampaignPayment());
    axios
      .post("/campaignpayment/stripe", data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        // toast.success("Thank you for contribute for this campaign", {
        //   autoClose: 15000,
        // });
        dispatch(successCampaignPayment(res.data.data));
      })
      .catch((err) => {
        dispatch(failCampaignPayment(err.data));
      });
  };
};
export const createCampaignPaymentWithPaypal = (data, token) => {
  return (dispatch) => {
    dispatch(initCampaignPayment());
    axios
      .post("/campaignpayment/paypal", data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.data.data) {
          const links = res.data.data.links;
          links.map((link) => {
            if (link.method === "REDIRECT") {
              window.location.href = link.href;
            }
          });
        }
      })
      .catch((err) => {
        dispatch(failCampaignPayment(err.data));
      });
  };
};

export const exicuteCampaignPaymentWithPaypal = (data, token) => {
  return (dispatch) => {
    dispatch(initCampaignPayment());
    axios
      .post("/campaignpayment/paypal/execute", data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast.success("Thank you for contribute for this campaign", {
          autoClose: 15000,
        });
        dispatch(successCampaignPayment(res.data.data));
      })
      .catch((err) => {
        dispatch(failCampaignPayment(err.data));
      });
  };
};
