/**
 * Created by asankah on 11/9/15.
 */
import React, { Component } from "react";
import { useForm } from "react-hook-form";
import { inviteUser, loginModalHandle } from "../../../../store/actions";
import Loading from "../../../Reusable/Loading/Loading";
import { useDispatch, useSelector } from "react-redux";

import "./InviteFriends.scss";

function InviteFriends() {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
    reset,
  } = useForm();
  const { token } = useSelector((state) => state.auth);
  const { inviteLoading } = useSelector((state) => state.home);

  const dispatch = useDispatch();

  const onSubmit = () => {
    if (token) {
      dispatch(inviteUser(getValues()));
      reset();
    } else {
      dispatch(loginModalHandle(true));
    }
  };

  return (
    <div className="elf-engage-invite-email">
      <h4>invite your friends </h4>
      <form className="form-inline" onSubmit={handleSubmit(onSubmit)}>
        <div className="relative">
          {errors.email && (
            <p className="srv-validation-message">{errors.email.message}</p>
          )}

          <input
            {...register("email", {
              required: {
                value: true,
                message: "Enter your friend's email here",
              },
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Enter valid email format",
              },
            })}
            className="elf-textBox"
            placeholder="Enter your friend's email here"
          />
        </div>

        <button
          disabled={!isValid || inviteLoading}
          className="btn btn-primary btn-lg pl-5 pr-5"
        >
          Invite
        </button>

        {inviteLoading && <Loading type="button" />}
      </form>
    </div>
  );
}

export default InviteFriends;
