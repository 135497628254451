import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  filterStudents,
  getMetadata,
  clearStudentFilter,
} from "../../../store/actions";
import {
  Button,
  Col,
  FormGroup,
  FormLabel,
  Row,
  Spinner,
} from "react-bootstrap";

function Filters() {
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.distributionEvent);
  const students = data?.students;
  const sponsors = data?.sponsors;
  const liaisonOfficers = data?.liaisonOfficers;
  const grades = data?.grades;
  const accounts = data?.accounts;

  const [filters, setFilters] = useState({
    liaisonOfficers: "",
    sponsor: "",
    student: "",
    grade: "",
    salesforceContactId: "",
    from: "",
    to: "",
    accounts: "",
  });

  useEffect(() => {
    dispatch(getMetadata());
  }, []);
  const handleFilter = () => {
    const obj = {
      liaison_officer: filters.liaisonOfficers?.value,
      sponsor_id: filters.sponsor?.value,
      salesforceContactId: filters.salesforceContactId?.value,
      grade: filters.grade?.value,
      student_name: filters.student?.label,
      from: filters.from,
      to: filters.to,
      account_id: filters.accounts?.value,
    };
    dispatch(filterStudents(obj));
  };
  const handleClearFilter = () => {
    dispatch(clearStudentFilter());
  };

  return (
    <Row>
      <Col md={3}>
        <FormGroup>
          <FormLabel>Accounts</FormLabel>
          {/* <FormControl /> */}
          <Select
            size="sm"
            isClearable
            options={accounts?.map((e) => ({
              label: e.name,
              value: e.id,
            }))}
            value={filters.accounts}
            onChange={(e) => setFilters({ ...filters, accounts: e })}
          />
        </FormGroup>
      </Col>{" "}
      <Col md={3}>
        <FormGroup>
          <FormLabel>Liaison Officers</FormLabel>
          {/* <FormControl /> */}
          <Select
            isClearable
            options={liaisonOfficers?.map((e) => ({
              label: e.name,
              value: e.id,
            }))}
            value={filters.liaisonOfficers}
            onChange={(e) => setFilters({ ...filters, liaisonOfficers: e })}
          />
        </FormGroup>
      </Col>
      <Col md={3}>
        <FormGroup>
          <FormLabel>Sponsor</FormLabel>
          <Select
            isClearable
            options={sponsors?.map((e) => ({
              label: e.name,
              value: e.user_id,
            }))}
            value={filters.sponsor}
            onChange={(e) => setFilters({ ...filters, sponsor: e })}
          />
        </FormGroup>
      </Col>
      <Col md={3}>
        <FormGroup>
          <FormLabel>Student Name</FormLabel>
          <Select
            isClearable
            options={students?.map((e) => ({ label: e.name, value: e.id }))}
            value={filters.student}
            onChange={(e) => setFilters({ ...filters, student: e })}
          />
        </FormGroup>
      </Col>
      <Col md={3}>
        <FormGroup>
          <FormLabel>Student Grade</FormLabel>
          <Select
            isClearable
            options={grades
              ?.filter((e) => e !== "None")
              ?.map((e) => ({ label: e, value: e }))}
            value={filters.grade}
            onChange={(e) => setFilters({ ...filters, grade: e })}
          />
        </FormGroup>
      </Col>
      <Col md={3}>
        <FormGroup>
          <FormLabel>Distribution (From)</FormLabel>
          <DatePicker
            className="form-control"
            maxDate={new Date()}
            selected={filters.from}
            onChange={(e) => setFilters({ ...filters, from: e })}
            placeholderText="From"
          />
        </FormGroup>
      </Col>
      <Col md={3}>
        <FormGroup>
          <FormLabel>Distribution (To)</FormLabel>
          <DatePicker
            className="form-control"
            maxDate={new Date()}
            selected={filters.to}
            onChange={(e) => setFilters({ ...filters, to: e })}
            placeholderText="To"
          />
        </FormGroup>
      </Col>
      <Col md={3} className="pt-4 ">
        <Button
          block
          disabled={loading}
          onClick={handleFilter}
          className="text-nowrap mt-2"
          variant="info"
        >
          {loading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span> Loading...</span>
            </>
          ) : (
            <span>Search</span>
          )}
        </Button>
      </Col>
      <Col md={2} className="pt-4 ">
        <Button
          block
          disabled={loading}
          onClick={handleClearFilter}
          className="text-nowrap mt-2"
          variant="danger"
        >
          Clear
        </Button>
      </Col>
    </Row>
  );
}

export default Filters;
