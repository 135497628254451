import { Row, Col } from "react-bootstrap";
import Student from "./Student/Student";
import { LinkLineButton } from "../../Reusable/Button/Button";
import "./SponsoredStudents.scss";

function SponsoredStudents(props) {
  const { sponsor } = props;

  let content = null;

  if (sponsor.sponsorships.length === 0) {
    content = <h1 className="notFound">Sponsorships not found.</h1>;
  } else {
    content = (
      <Row className="justify-content-center">
        {sponsor?.sponsorships
          ?.filter((sp) => sp.beneficiary.type !== "elf")
          ?.map((spShip) => {
            return (
              <Col key={spShip.id} md={4}>
                <Student sponsorship={spShip} />
              </Col>
            );
          })}
      </Row>
    );
  }

  return (
    <div className="SponsoredStudents mt-5 mb-5">
      <h1>All students sponsored by {sponsor.firstName}</h1>
      {content}
      <Row>
        <Col md={12}>
          <LinkLineButton to="/student-list" title="All students " />
          <p>
            <small>
              There are more than <span>120 students </span> currently waiting
              to be sponsored.
            </small>
          </p>
        </Col>
      </Row>
    </div>
  );
}

export default SponsoredStudents;
