import * as actions from "./actionsTypes";
import axios from "../../axios";

const initCreateCampaign = () => ({
  type: actions.INIT_CREATE_CAMPAIGN,
});

const failCreateCampaign = (error) => ({
  type: actions.FAIL_CREATE_CAMPAIGN,
  error,
});

const successCreateCampaign = (data) => ({
  type: actions.SUCCESS_CREATE_CAMPAIGN,
  data,
});

const initGetCampaign = () => ({
  type: actions.INIT_CAMPAIGN,
});

const failGetCampaign = (error) => ({
  type: actions.FAIL_CAMPAIGN,
  error,
});

const successGetCampaign = (data) => ({
  type: actions.SUCCESS_CAMPAIGN,
  data,
});
const initGetCampaignList = () => ({
  type: actions.INIT_CAMPAIGN_LIST,
});

const failGetCampaignList = (error) => ({
  type: actions.FAIL_CAMPAIGN_LIST,
  error,
});

const successGetCampaignList = (data) => ({
  type: actions.SUCCESS_CAMPAIGN_LIST,
  data,
});

const initUpdateCampaign = () => ({
  type: actions.INIT_UPDATE_CAMPAIGN,
});

const failUpdateCampaign = (error) => ({
  type: actions.FAIL_UPDATE_CAMPAIGN,
  error,
});

const successUpdateCampaign = (data) => ({
  type: actions.SUCCESS_UPDATE_CAMPAIGN,
  data,
});

export const createCampaign = (data, token) => {
  const formData = new FormData();

  formData.append("goalStatus", data.goalStatus);
  formData.append("endDate", data.endDate);
  formData.append("goal", data.goal);
  formData.append("type", data.type);
  formData.append("name", data.name);
  formData.append("selectImage", data.selectImage);
  formData.append("description", data.description);
  formData.append("campaignImage", data.campaignImage);

  return (dispatch) => {
    dispatch(initCreateCampaign());
    axios
      .post("campaign", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        dispatch(successCreateCampaign(res.data.data));
      })
      .catch((err) => {
        dispatch(failCreateCampaign(err.data.error));
      });
  };
};
export const updateCampaign = (data, id, token) => {
  const formData = new FormData();
  formData.append("goalStatus", data.goalStatus);
  formData.append("endDate", data.endDate);
  formData.append("goal", data.goal);
  formData.append("type", data.type);
  formData.append("name", data.name);
  formData.append("selectImage", data.selectImage);
  formData.append("description", data.description);
  formData.append("campaignImage", data.campaignImage);

  return (dispatch) => {
    dispatch(initUpdateCampaign());
    axios
      .put(`campaign/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        dispatch(successUpdateCampaign(res.data.data));
      })
      .catch((err) => {
        dispatch(failUpdateCampaign(err.data.error));
      });
  };
};

export const updateCampaignStatusMessage = (message, id, token) => {
  return (dispatch) => {
    dispatch(initUpdateCampaign());
    axios
      .put(
        `campaign/statusMessage/${id}`,
        { message },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        dispatch(successUpdateCampaign(res.data.data));
      })
      .catch((err) => {
        dispatch(failUpdateCampaign(err.data.error));
      });
  };
};
export const getCampaign = (id) => {
  return (dispatch) => {
    dispatch(initGetCampaign());
    axios
      .get(`campaign/${id}`)
      .then((res) => {
        dispatch(successGetCampaign(res.data.data));
      })
      .catch((err) => {
        dispatch(failGetCampaign(err.data));
      });
  };
};
export const getCampaignList = (uId) => {
  return (dispatch) => {
    dispatch(initGetCampaignList());
    axios
      .get(`campaign/all/${uId}`)
      .then((res) => {
        dispatch(successGetCampaignList(res.data.data));
      })
      .catch((err) => {
        dispatch(failGetCampaignList(err.data));
      });
  };
};

export const resetCapaign = () => ({
  type: actions.RESET_CAMPAIGN,
});
