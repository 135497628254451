import React from "react";
import "./VideoBackground.scss";

function VideoBackground({ backImage, videoUrl }) {
  const mimeArr = videoUrl?.split(".");
  return (
    <div className="Video" style={{ backgroundImage: `url(${backImage})` }}>
      <video
        className=" mobileHide"
        preload="auto"
        width="100%"
        height="auto"
        muted
        loop
        autoPlay
        poster=""
      >
        <source
          src={videoUrl}
          type={`video/${mimeArr && mimeArr[mimeArr?.length - 1]}`}
        />
      </video>
    </div>
  );
}

export default VideoBackground;
