/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Modal, Alert } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import LogoIco from "../../../../assets/elf-logo-clr.png";
import { Button as ButtonSubmit } from "../../Button/Button";
import { connect, useDispatch } from "react-redux";
import { forgetPasswordReset, forgotPassword } from "../../../../store/actions";

import "./ForgotPassword.scss";
import SharedModal from "../SharedModal/SharedModal";

function ForgotPassword(props) {
  const dispatch = useDispatch();

  const [validator, setValidator] = useState(
    new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        email: "That is not an email.",
      },
    })
  );

  const [formData, setFormData] = useState({ email: "" });

  const onChangeHandle = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (!validator.allValid()) {
      validator.showMessageFor(e.target.name);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      props.onForgotPassword(formData);
    } else {
      validator.showMessages();
    }
  };
  const handleHideModal = () => {
    dispatch(forgetPasswordReset());
    props.handleCloseForget();
  };
  return (
    <SharedModal title="Enter your email for instructions" show={props.show} onHide={handleHideModal}>
      {props.forgetData ? (
        <Alert variant="success">
          We have sent reset password link you your email.
        </Alert>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="inputContainer icon">
            {validator.message("email", formData.email, "required|email")}
            <input
              type="text"
              name="email"
              placeholder="Enter your email"
              onChange={onChangeHandle}
            />
            <i className="fa fa-envelope"></i>
          </div>
          <ButtonSubmit
            title="Send link"
            full
            disabled={validator.allValid() ? false : true}
          />
          <br />
          <br />
        </form>
      )}
    </SharedModal>


  );
}

const mapStateToProps = (state) => ({
  Loading: state.forgetPassword.loading,
  forgetData: state.forgetPassword.data,
  forgetErr: state.forgetPassword.err,
});
const mapDispatchToProp = (dispatch) => ({
  onForgotPassword: (mail) => dispatch(forgotPassword(mail)),
});

export default connect(mapStateToProps, mapDispatchToProp)(ForgotPassword);
