import React from "react";
import "./SriLankaMap.scss";

function SriLankaMap(props) {
  let district = [];
  if (props.district) {
    district = props.district;
    if (!Array.isArray(props.district)) {
      district = [props.district];
    }
  }
  return (
    <svg
      className={`elf-student-profile-map ${props.className} `}
      viewBox="0 0 20 40"
      data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0"
    >
      <g className="map" data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0">
        <g className="regions" data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0">
          <path
            className={`map-region ${district.find(
              dis => dis === "Mullaitivu"
            ) && "active"}`}
            d="M6.82 8.1c-.3.17-.86.1-1.18.08-.2-.03-.25-.6-.2-.76.15-.5.07-.67-.06-1.2-.04-.14-.06-.28-.06-.43.08-.06.15-.12.2-.2.1-.16.16-.42.3-.55.22-.2 1.23.32 1.34 0 .17-.48.67-.05.94-.33.26-.26.03-.4.4-.26.15.06.26.05.27-.12.02-.25.27-.38.3-.62.07.05.48.3.42.4-.04.05-.37-.23-.4-.14 0 .1 1.1.88 1.1.87.1-.1-.75-.53-.7-.7-.03.1 1.26.87 1.16 1-.13.14-.24-.14-.33-.2-.1-.07-.48-.1-.33.15.06.1.67.58.65.4 0-.05-.1-.27-.08-.3.14-.13.25.2.3.3 0 .03.4 1.1.4 1.1-.05.02-.3-.6-.46-.22-.1.25.16.1.25.07.1-.03.2.18.08.22 0 .02.1.12.14.1v-.13c.24 0 .44.67.53.8.1.13.25.55.03.24-.05-.06-.36-.58-.44-.36-.1.2-.2.1.03.27.08.06.23.04.26.06.02.03-.1.07-.1.07.14-.14-.06.36-.07.3-.34.2-.8.05-1.1.3-.04-.2.1-.5-.07-.68-.1-.12-.73-.17-.73-.16-.03-.3.1-.33-.2-.46-.1-.04-.07-.25-.18-.25-.23-.02-.46.1-.68.16-.32.08-.53-.16-.84-.16-.13 0-.26-.04-.4-.02-.2.03-.18.2 0 .25.6.2-.37 1-.5 1.08 0 0 .03 0 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M6=182 8=11c-=13=117-=186=11-1=118=108-=12-=103-=125-=16-=12-=176=115-=15=107-=167-=106-1=12-=104-=114-=106-=128-=106-=143=108-=106=115-=112=12-=12=11-=116=116-=142=13-=155=122-=12 1=123=132 1=134 0 =117-=148=167-=105=194-=133=126-=126=103-=14=14-=126=115=106=126=105=127-=112=102-=125=127-=138=13-=162=107=105=148=13=142=14-=104=105-=137-=123-=14-=114 0 =11 1=11=188 1=11=187=11-=11-=175-=153-=17-=17-=103=11 1=126=187 1=116 1-=113=114-=124-=114-=133-=12-=11-=107-=148-=11-=133=115=106=11=167=158=165=14 0-=105-=11-=127-=108-=13=114-=113=125=12=13=13 0 =103=14 1=11=14 1=11-=105=102-=13-=16-=146-=122-=11=125=116=11=125=107=11-=103=12=118=108=122 0 =102=11=112=114=11v-=113c=124 0 =144=167=153=18=11=113=125=155=103=124-=105-=106-=136-=158-=144-=136-=11=12-=12=11=103=127=108=106=123=104=126=106=102=103-=11=107-=11=107=114-=114-=106=136-=107=13-=134=12-=18=105-1=11=13-=104-=12=11-=15-=107-=168-=11-=112-=173-=117-=173-=116-=103-=13=11-=133-=12-=146-=11-=104-=107-=125-=118-=125-=123-=102-=146=11-=168=116-=132=108-=153-=116-=184-=116-=113 0-=126-=104-=14-=102-=12=103-=118=12 0 =125=16=12-=137 1-=15 1=108 0 0 =103 0 0 0z"
          ></path>
          <path
            className={`map-region ${district.find(dis => dis === "Vavuniya") &&
              "active"}`}
            d="M5.8 10.6c-.06-.17-.25-.28-.34-.43-.1-.17 0-.37.04-.54.15-.53.4-.3.8-.38.26-.06.9 0 .85-.38-.03-.25-.17-.55-.33-.76.2-.1.58-.34.64-.6.04-.13.04-.35-.1-.44-.06-.03-.3-.13-.13-.22.18-.1.54-.1.73-.03.22.08.25.23.5.18.22-.04.4-.12.6-.17.16-.03.6.32.6.47-.04.17-.07.37.16.24.17-.1.26.06.42.1.3.05.15.52.2.73-.2.14-.54.47-.75.5-.24 0-.6-.06-.8.1-.12.1.4.42.44.54.06.17-.62.8-.73.92-.33.35-.47.36-.9.16-.42-.2-.78.96-1.2 1.15-.4.2-.27-.38-.47-.5-.35-.24-.14-.3-.24-.6-.07-.18 0 0 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M5=18 10=16c-=106-=117-=125-=128-=134-=143-=11-=117 0-=137=104-=154=115-=153=14-=13=18-=138=126-=106=19 0 =185-=138-=103-=125-=117-=155-=133-=176=12-=11=158-=134=164-=16=104-=113=104-=135-=11-=144-=106-=103-=13-=113-=113-=122=118-=11=154-=11=173-=103=122=108=125=123=15=118=122-=104=14-=112=16-=117=116-=103=16=132=16=147-=104=117-=107=137=116=124=117-=11=126=106=142=11=13=105=115=152=12=173-=12=114-=154=147-=175=15-=124 0-=16-=106-=18=11-=112=11=14=142=144=154=106=117-=162=18-=173=192-=133=135-=147=136-=19=116-=142-=12-=178=196-1=12 1=115-=14=12-=127-=138-=147-=15-=135-=124-=114-=13-=124-=16-=107-=118 0 0 0 0z"
          ></path>
          <path
            className={`map-region ${district.find(
              dis => dis === "Kilinochchi"
            ) && "active"}`}
            d="M5.32 5.8c-.17.08-.35.13-.54.13 0-.1-.1-.54-.07-.6.15-.16.05-.2-.1-.35-.22-.18-.23 0-.32-.3-.08-.26-.06-.36.22-.48.26-.1 1-.4.77-.82-.13-.26-.63-.8-.95-.8.25-.16.6.23.82.4 0 0 1.12.32 1 .46-.06.06-.14-.08-.2 0-.06.1.2.14.18.17-.03.05-.18-.02-.22 0-.04.05.28.2.3.2.18.02.36-.1.53-.1.32 0 .4-.5.43-.5.03.03.04.06.03.1 0 .02.34.1.34.1.2.03.4-.1.56.13.17.22.17.24.47.3.1 0 .37.04.45-.02.06-.04-.1-.07-.1-.1.3-.13-.17.83-.33.8-.2-.06-.3-.3-.4 0-.08.28-.25.3-.53.28-.18-.02-.45 0-.48.2-.06.28-.7.1-.9.1-.33-.03-.43-.14-.6.22-.1.18-.25.4-.38.5-.17.08 0-.02 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M5=132 5=18c-=117=108-=135=113-=154=113 0-=11-=11-=154-=107-=16=115-=116=105-=12-=11-=135-=122-=118-=123 0-=132-=13-=108-=126-=106-=136=122-=148=126-=11 1-=14=177-=182-=113-=126-=163-=18-=195-=18=125-=116=16=123=182=14 0 0 1=112=132 1 =146-=106=106-=114-=108-=12 0-=106=11=12=114=118=117-=103=105-=118-=102-=122 0-=104=105=128=12=13=12=118=102=136-=11=153-=11=132 0 =14-=15=143-=15=103=103=104=106=103=11 0 =102=134=11=134=11=12=103=14-=11=156=113=117=122=117=124=147=13=11 0 =137=104=145-=102=106-=104-=11-=107-=11-=11=13-=113-=117=183-=133=18-=12-=106-=13-=13-=14 0-=108=128-=125=13-=153=128-=118-=102-=145 0-=148=12-=106=128-=17=11-=19=11-=133-=103-=143-=114-=16=122-=11=118-=125=14-=138=15-=117=108 0-=102 0 0z"
          ></path>
          <path
            className="map-region"
            d="M3.9 3.6c.04.02-.03.16-.07.13-.08-.07-.06-.27.07-.14.03.02 0-.02 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M3=19 3=16c=104=102-=103=116-=107=113-=108-=107-=106-=127=107-=114=103=102 0-=102 0 0z"
          ></path>
          <path
            className="map-region"
            d="M3.8 5.36c0 .05 0 .1-.05.1-.04-.02-.1-.17 0-.16 0 .02.03.04.05.06 0 .02 0 0 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M3=18 5=136c0 =105 0 =11-=105=11-=104-=102-=11-=117 0-=116 0 =102=103=104=105=106 0 =102 0 0 0 0z"
          ></path>
          <path
            className="map-region"
            d="M3.66 5.27c.04.1-.15.1-.2.03-.05-.13.14-.16.2-.03.05.12 0 0 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M3=166 5=127c=104=11-=115=11-=12=103-=105-=113=114-=116=12-=103=105=112 0 0 0 0z"
          ></path>
          <path
            className={`map-region ${district.find(dis => dis === "Jaffna") &&
            "active"}`}
            d="M7.5 2.72c.02.06.1.53-.06.52-.08 0-.08-.14-.2-.1l-.2.03c.02.02.02.04 0 .07.04-.07-.57-.53-.64-.58-.23-.17-.4-.15-.3-.42.06-.12.1-.25.15-.37.1.1 1.1.93 1.25.85z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M7=15 2=172c=102=106=11=153-=106=152-=108 0-=108-=114-=12-=11l-=12=103c=102=102=102=104 0 =107=104-=107-=157-=153-=164-=158-=123-=117-=14-=115-=13-=142=106-=112=11-=125=115-=137=11=11 1=11=193 1=125=185z"
          ></path>
          <path
            className="map-region"
            d="M19.4 21.34c-.05 0-.1.02-.15.04 0-.03 0-.22.03-.22.07 0 .13.2.12.18-.05 0 0-.02 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M19=14 21=134c-=105 0-=11=102-=115=104 0-=103 0-=122=103-=122=107 0 =113=12=112=118-=105 0 0-=102 0 0z"
          ></path>
          <path
            className={`map-region ${district.find(
              dis => dis === "Batticaloa"
            ) && "active"}`}
            d="M19.38 21.13c.1.14-.2-.15-.15-.14-.1-.03.03.15-.04.16-.3.05-.14-.34-.13-.4.04-.22-.1-.62-.2-.8-.1-.2-.3-.3-.32-.52 0-.17-.18-.43-.33-.5.07.03-.05.53-.15.17-.06-.2-.3-.68-.62-.57-.27.1-.22.42 0 .5-.03 0 .54-.23.47-.02l-.16.1c0 .03.44.25.5.3.02.02 0 .7.14.62-.06.03-.06-.53.13-.35.04.04.4.6.4.58-.02.03-.26.12-.15.2.07.04.2-.2.18.07-.03.22-.17-.04-.24-.03-.28.03.3.22.3.22.1.13-.3.2-.32.17.04.08.2.04.22.14-.34.17-.7.97-.93.25-.06-.15-.07-.6-.23-.65-.23-.08-.62.12-.88.08-.34-.05-.74-.22-.86-.56-.05-.14.18-.6.07-.67-.1-.07-.35-.03-.48-.08-.18-.07-.35-.17-.5-.3-.1-.1-.14-.22-.26-.3-.18-.12-.4-.03-.6-.1.13-.2-.1-.45-.02-.66.1-.28 0-.57.16-.84.2-.3 1.3.03.74-.62-.36-.44-.14-1.17-.18-1.7 0-.28 0-.52.32-.54.16-.02.33 0 .5.02.05 0-.04-.17.03-.17.14 0 .3 1.1.32 1.23.04.54-.43-.65-.35-.64-.14.02-.2.37-.16.38-.06-.03-.1-.12-.18-.06.2-.14.73.88.73.88-.02 0-.04-.55.02-.6-.02 0 .22.76.24.8.1.15.2.26.34.37.1.08.25.04.3.1.1.1-.23.1-.14.27.04.07.18.08.08.16-.15.13-.3.2-.18.43 0 .02.36.55.2.52-.06-.02-.5.06-.3.16.08.05.1-.12.18-.07.16.08.17-.1.16-.22-.03-.24-.33-.47-.08-.7.04-.02.2-.17.25-.14.02 0 .16.24.2.18-.28.06-.1.63.06.75.17.1.17 0 .3.17.08.1.67.67.65.75.04-.1-.16-.2-.13-.28-.06.18.5.73.6.87.32.48.28 1.15.5 1.63.13.16 0 0 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M19=138 21=113c=11=114-=12-=115-=115-=114-=11-=103=103=115-=104=116-=13=105-=114-=134-=113-=14=104-=122-=11-=162-=12-=18-=11-=12-=13-=13-=132-=152 0-=117-=118-=143-=133-=15=107=103-=105=153-=115=117-=106-=12-=13-=168-=162-=157-=127=11-=122=142 0 =15-=103 0 =154-=123=147-=102l-=116=11c0 =103=144=125=15=13=102=102 0 =17=114=162-=106=103-=106-=153=113-=135=104=104=14=16=14=158-=102=103-=126=112-=115=12=107=104=12-=12=118=107-=103=122-=117-=104-=124-=103-=128=103=13=122=13=122=11=113-=13=12-=132=117=104=108=12=104=122=114-=134=117-=17=197-=193=125-=106-=115-=107-=16-=123-=165-=123-=108-=162=112-=188=108-=134-=105-=174-=122-=186-=156-=105-=114=118-=16=107-=167-=11-=107-=135-=103-=148-=108-=118-=107-=135-=117-=15-=13-=11-=11-=114-=122-=126-=13-=118-=112-=14-=103-=16-=11=113-=12-=11-=145-=102-=166=11-=128 0-=157=116-=184=12-=13 1=13=103=174-=162-=136-=144-=114-1=117-=118-1=17 0-=128 0-=152=132-=154=116-=102=133 0 =15=102=105 0-=104-=117=103-=117=114 0 =13 1=11=132 1=123=104=154-=143-=165-=135-=164-=114=102-=12=137-=116=138-=106-=103-=11-=112-=118-=106=12-=114=173=188=173=188-=102 0-=104-=155=102-=16-=102 0 =122=176=124=18=11=115=12=126=134=137=11=108=125=104=13=11=11=11-=123=11-=114=127=104=107=118=108=108=116-=115=113-=13=12-=118=143 0 =102=136=155=12=152-=106-=102-=15=106-=13=116=108=105=11-=112=118-=107=116=108=117-=11=116-=122-=103-=124-=133-=147-=108-=17=104-=102=12-=117=125-=114=102 0 =116=124=12=118-=128=106-=11=163=106=175=117=11=117 0 =13=117=108=11=167=167=165=175=104-=11-=116-=12-=113-=128-=106=118=15=173=16=187=132=148=128 1=115=15 1=163=113=116 0 0 0 0z"
          ></path>
          <path
            className={`map-region ${district.find(dis => dis === "Ampara") &&
              "active"}`}
            d="M12.1 19c.08-.2.83-.1.98-.07.18.05.2.3.4.4.14.06.47.18.62.07.24-.18-.03-.46.14-.62.12-.1.38-.03.52 0 .27.1.4.38.64.52.2.1.44.1.64.18.22.1 0 .3-.02.46 0 .28.15.5.38.63s.5.2.74.2c.18-.02.47-.17.64-.1.33.16.08.76.37.9.24.14.6-.44.77-.53 0 .08-.18.2-.12.3.13.15.17-.1.23-.15-.02 0 .1.54.13.55.07.03.03-.9.38-.07.26.63.26 1.12.25 1.8-.02.26-.43.27-.36.67.08.5.23-.38.28-.45-.06.08.02.4.03.5 0 .1 0 .2-.02.27-.04.13-.23.05-.23.1 0-.1.2.5.07.43.12.08.07-.08.1-.1.07-.03 0 .26.12.2.06 0-.18-.48-.08-.52.1-.03.3 1.03.02 1-.03 0-.06-.17-.14-.18-.3-.02.27.3.2.2.04.06-.3.64-.23.68 0 0-.1-.52-.22-.34.03-.04.22.43.23.46.1.44-.12.1-.23.15-.1.06.18.2.2.2.1.08-.1.2-.15.24-.1.08.08.23 0 .34-.02.02-.25-.04-.17.07.03.06.2-.06.2.04-.02.06-.25.23-.2.28.04-.15-.15-.1-.1 0 .02 0 .14.2.1.22-.04.04-.12-.07-.17-.06.03 0-.02.7-.13.77-.06.04.06-.04.07.02.02.06-.23.26-.27.27.08-.03.03.34-.16.28-.04 0 .08.08.04.1 0 0-.12.03-.1.05.08.1.1.12-.03.22-.24-.27-.74-.37-.83-.75-.13-.57.1-1.34.15-1.9.05-.63-.08-1.25-.14-1.85-.06-.5-.3-.45-.68-.65-.53-.28-.12-.7-.06-1.07.07-.36-.28-.7-.4-1.02-.1-.27-.26-.65-.2-.93.03-.24-.07-.27-.35-.17-.1.04-.22.1-.25.2-.06.23-.02.1-.2.18-.08.03 0 .37-.08.46-.1.13-.33.14-.38.23-.06.1.05 1.03-.38.53-.1-.1-.05-.27-.18-.4-.17-.14-.4-.1-.46-.37-.13-.52.07-.98 0-1.48-.05-.56-.35-.3-.55-.03-.1.12-.16.18-.33.16-.14 0-.5.13-.6.06-.22-.18-.13-.94-.53-.87.03-.25.15-.4.08-.66 0-.1.03-.4 0-.3.04-.14-.02.03 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M12=11 19c=108-=12=183-=11=198-=107=118=105=12=13=14=14=114=106=147=118=162=107=124-=118-=103-=146=114-=162=112-=11=138-=103=152 0 =127=11=14=138=164=152=12=11=144=11=164=118=122=11 0 =13-=102=146 0 =128=115=15=138=163s=15=12=174=12c=118-=102=147-=117=164-=11=133=116=108=176=137=19=124=114=16-=144=177-=153 0 =108-=118=12-=112=13=113=115=117-=11=123-=115-=102 0 =11=154=113=155=107=103=103-=19=138-=107=126=163=126 1=112=125 1=18-=102=126-=143=127-=136=167=108=15=123-=138=128-=145-=106=108=102=14=103=15 0 =11 0 =12-=102=127-=104=113-=123=105-=123=11 0-=11=12=15=107=143=112=108=107-=108=11-=11=107-=103 0 =126=112=12=106 0-=118-=148-=108-=152=11-=103=13 1=103=102 1-=103 0-=106-=117-=114-=118-=13-=102=127=13=12=12=104=106-=13=164-=123=168 0 0-=11-=152-=122-=134=103-=104=122=143=123=146=11=144-=112=11-=123=115-=11=106=118=12=12=12=11=108-=11=12-=115=124-=11=108=108=123 0 =134-=102=102-=125-=104-=117=107=103=106=12-=106=12=104-=102=106-=125=123-=12=128=104-=115-=115-=11-=11 0 =102 0 =114=12=11=122-=104=104-=112-=107-=117-=106=103 0-=102=17-=113=177-=106=104=106-=104=107=102=102=106-=123=126-=127=127=108-=103=103=134-=116=128-=104 0 =108=108=104=11 0 0-=112=103-=11=105=108=11=11=112-=103=122-=124-=127-=174-=137-=183-=175-=113-=157=11-1=134=115-1=19=105-=163-=108-1=125-=114-1=185-=106-=15-=13-=145-=168-=165-=153-=128-=112-=17-=106-1=107=107-=136-=128-=17-=14-1=102-=11-=127-=126-=165-=12-=193=103-=124-=107-=127-=135-=117-=11=104-=122=11-=125=12-=106=123-=102=11-=12=118-=108=103 0 =137-=108=146-=11=113-=133=114-=138=123-=106=11=105 1=103-=138=153-=11-=11-=105-=127-=118-=14-=117-=114-=14-=11-=146-=137-=113-=152=107-=198 0-1=148-=105-=156-=135-=13-=155-=103-=11=112-=116=118-=133=116-=114 0-=15=113-=16=106-=122-=118-=113-=194-=153-=187=103-=125=115-=14=108-=166 0-=11=103-=14 0-=13=104-=114-=102=103 0 0z"
          ></path>
          <path
            className="map-region"
            d="M2.02 12.82c0 .04.03.18 0 .2-.02.02-.23-.96-.14-.94.1.02.12.65.14.74 0 0 0-.02 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M2=102 12=182c0 =104=103=118 0 =12-=102=102-=123-=196-=114-=194=11=102=112=165=114=174 0 0 0-=102 0 0z"
          ></path>
          <path
            className="map-region"
            d="M2.15 13.66c0 .04 0 .08-.02.12-.05 0-.1-.23-.02-.2l.05.08z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M2=115 13=166c0 =104 0 =108-=102=112-=105 0-=11-=123-=102-=12l=105=108z"
          ></path>
          <path
            className="map-region"
            d="M2.08 13.98c.02.08-.2-.04-.06-.12.07-.04.06.1.06.12 0 .06 0 0 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M2=108 13=198c=102=108-=12-=104-=106-=112=107-=104=106=11=106=112 0 =106 0 0 0 0z"
          ></path>
          <path
            className="map-region"
            d="M2.13 17.5c0 .05 0 .1-.03.13-.04 0 0-.15.03-.13.03 0 0 0 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M2=113 17=15c0 =105 0 =11-=103=113-=104 0 0-=115=103-=113=103 0 0 0 0 0z"
          ></path>
          <path
            className="map-region"
            d="M2.1 17.79c-.04.05-.08.1-.14.13-.06.07 0 .24 0 .33-.08.27.1.5.1.78-.02.1-.1.92-.07.93l-.13-1.35c-.13-.65-.3-1.28-.46-1.92-.08-.34-.13-.67-.22-1-.05-.2 0-.3.06-.5.05-.17-.16-.4-.02-.56.13-.13.2-.16.26-.44 0-.05.27-.57.3-.54.12.13-.23.47-.16.57.03.05.1-.1.13-.04.02.05-.4.3-.3.36.1.06.25-.32.36-.3.04 0-.02.36-.04.4 0 0-.12 0-.17.07-.13.14.02.3-.05.48-.12.36.06.65 0 .93-.03.14-.03.3.02.44.1.25.36.12.52.2.08.05.06.6 0 .7"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M2=11 17=179c-=104=105-=108=11-=114=113-=106=107 0 =124 0 =133-=108=127=11=15=11=178-=102=11-=11=192-=107=193l-=113-1=135c-=113-=165-=13-1=128-=146-1=192-=108-=134-=113-=167-=122-1-=105-=12 0-=13=106-=15=105-=117-=116-=14-=102-=156=113-=113=12-=116=126-=144 0-=105=127-=157=13-=154=112=113-=123=147-=116=157=103=105=11-=11=113-=104=102=105-=14=13-=13=136=11=106=125-=132=136-=13=104 0-=102=136-=104=14 0 0-=112 0-=117=107-=113=114=102=13-=105=148-=112=136=106=165 0 =193-=103=114-=103=13=102=144=11=125=136=112=152=12=108=105=106=16 0 =17"
          ></path>
          <path
            className={`map-region ${district.find(dis => dis === "Puttalam") &&
              "active"}`}
            d="M3.3 22.9c.1.13-.86.18-.95.2.1-.3-.1-1.2-.23-1.6-.05-.14-.4-1.28-.22-1.42-.06.05.22.9.33.8.04-.04-.15-.3-.17-.34-.12-.23 0-.24.04-.44.06-.28.02-.7.02-.97 0-.1-.08-.32-.03-.4.08-.2.17 0 .1-.32-.08-.45.07-.9.03-1.32-.02-.26.2-.52.05-.75-.1-.15-.33-.3-.26-.45.08-.16.34-.34.3-.56 0-.07-.3-.7-.3-.7 0-.03.06-.06.07-.1 0-.1-.04.08.02 0 .14-.13.24-.6.23-.78 0-.2.12-.36.1-.57-.02-.26.05-.34.14-.56.07-.17-.03-.37.05-.56.08-.18.28-.26.46-.33.23.22 1.04.08.98.5-.04.26-.35.67-.53.87-.1.12-.3.08-.18.3.1.18.17.32.2.53 0 .17.03.35.12.5.14.25.28.04.44.13.4.2.6.34.6.86.02.4.36.48.36.93 0 .12-.14.4-.08.5.12.2.02.23-.17.2-.23-.04-.65.62-.8.76-.23.2-.43.54-.37.87.08.43-.56.27-.27.66.18.23-.15.1-.17.1-.1 0-.13.36-.16.42-.32.5-.13 1.12 0 1.65.1.42-.06 1.04.23 1.4.13.17 0 0 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M3=13 22=19c=11=113-=186=118-=195=12=11-=13-=11-1=12-=123-1=16-=105-=114-=14-1=128-=122-1=142-=106=105=122=19=133=18=104-=104-=115-=13-=117-=134-=112-=123 0-=124=104-=144=106-=128=102-=17=102-=197 0-=11-=108-=132-=103-=14=108-=12=117 0 =11-=132-=108-=145=107-=19=103-1=132-=102-=126=12-=152=105-=175-=11-=115-=133-=13-=126-=145=108-=116=134-=134=13-=156 0-=107-=13-=17-=13-=17 0-=103=106-=106=107-=11 0-=11-=104=108=102 0 =114-=113=124-=16=123-=178 0-=12=112-=136=11-=157-=102-=126=105-=134=114-=156=107-=117-=103-=137=105-=156=108-=118=128-=126=146-=133=123=122 1=104=108=198=15-=104=126-=135=167-=153=187-=11=112-=13=108-=118=13=11=118=117=132=12=153 0 =117=103=135=112=15=114=125=128=104=144=113=14=12=16=134=16=186=102=14=136=148=136=193 0 =112-=114=14-=108=15=112=12=102=123-=117=12-=123-=104-=165=162-=18=176-=123=12-=143=154-=137=187=108=143-=156=127-=127=166=118=123-=115=11-=117=11-=11 0-=113=136-=116=142-=132=15-=113 1=112 0 1=165=11=142-=106 1=104=123 1=14=113=117 0 0 0 0z"
          ></path>
          <path
            className={`map-region ${district.find(
              dis => dis === "Kurunegala"
            ) && "active"}`}
            d="M8.55 21.52c0 .15-.25.25-.33.35-.08.1-.23-.06-.32-.08-.2-.06-.1.57-.54.4-.15-.32-.6-.53-.64-.05-.05.4-.5.52-.86.64-.2.07-.28.4-.5.25-.12-.08-.15-.18-.28-.26-.13-.07-.05-.26-.22-.28-.2 0-.36.16-.5.26-.27.18-.3 0-.56-.03-.18-.03-.32.15-.5.15-.26-.32-.1-.9-.22-1.28-.15-.42-.23-.92-.15-1.36.03-.2.2-.88.4-.77.15.07.05-.2 0-.25-.16-.13.1-.2.2-.27.2-.17.07-.66.2-.75.24-.15.55-.66.7-.9l.3-.28c.1-.07.33.1.35-.08.02-.18-.16-.17 0-.36.1-.14-.02-.43 0-.6 0-.15-.2-.2-.28-.3-.12-.15-.13-.66-.14-.86.36.35 1 .4 1.46.6.35.16.6.55.97.63.43.1.25.42.33.77.03.13.2.08.3.16.13.14.18.4.2.6 0 .14-.07.3-.03.44.02.08.07.13.16.14-.04.1-.04.17 0 .26.16.3.36.56.5.88.17.45-.18 1.02.04 1.4.22.4-.05.5-.05.8 0 .12 0 0 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M8=155 21=152c0 =115-=125=125-=133=135-=108=11-=123-=106-=132-=108-=12-=106-=11=157-=154=14-=115-=132-=16-=153-=164-=105-=105=14-=15=152-=186=164-=12=107-=128=14-=15=125-=112-=108-=115-=118-=128-=126-=113-=107-=105-=126-=122-=128-=12 0-=136=116-=15=126-=127=118-=13 0-=156-=103-=118-=103-=132=115-=15=115-=126-=132-=11-=19-=122-1=128-=115-=142-=123-=192-=115-1=136=103-=12=12-=188=14-=177=115=107=105-=12 0-=125-=116-=113=11-=12=12-=127=12-=117=107-=166=12-=175=124-=115=155-=166=17-=19l=13-=128c=11-=107=133=11=135-=108=102-=118-=116-=117 0-=136=11-=114-=102-=143 0-=16 0-=115-=12-=12-=128-=13-=112-=115-=113-=166-=114-=186=136=135 1 =14 1=146=16=135=116=16=155=197=163=143=11=125=142=133=177=103=113=12=108=13=116=113=114=118=14=12=16 0 =114-=107=13-=103=144=102=108=107=113=116=114-=104=11-=104=117 0 =126=116=13=136=156=15=188=117=145-=118 1=102=104 1=14=122=14-=105=15-=105=18 0 =112 0 0 0 0z"
          ></path>
          <path
            className={`map-region ${district.find(dis => dis === "Colombo") &&
              "active"}`}
            d="M5.33 27.03c-.34-.05-.82-.07-1.1.14-.43.33-1.02.16-1.4.5-.14-.37-.75-1.94-.08-2.1-.17.03.35.26.33.26.22.05.56-.03.75.03.2.06.28.38.57.3.2-.03.2-.3.34-.4.4-.33.8.05.72.48-.03.18.05.82-.13.8-.17-.03 0 0 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M5=133 27=103c-=134-=105-=182-=107-1=11=114-=143=133-1=102=116-1=14=15-=114-=137-=175-1=194-=108-2=11-=117=103=135=126=133=126=122=105=156-=103=175=103=12=106=128=138=157=13=12-=103=12-=13=134-=14=14-=133=18=105=172=148-=103=118=105=182-=113=18-=117-=103 0 0 0 0z"
          ></path>
          <path
            className={`map-region ${district.find(dis => dis === "Gampaha") &&
              "active"}`}
            d="M5.5 23.12c.1.04-.84 1.15-.16 1.1.23 0 .03.32 0 .43-.12.3-.08.7-.02 1.02-.34 0-.5-.08-.7.26-.2.4-.5.2-.74-.05-.1-.1-.5.06-.68-.04-.1-.06-.18.02-.26-.04-.06 0-.1-.03-.17-.06 0-.05 0-.1.02-.16-.04-.05-.15.07-.18.08.04-.1-.3-2.06-.44-2.08.02 0 .37 1.06.4 1 0-.03-.06-.23-.05-.24.05-.04.03.1.08.1.12-.05-.04-.47-.06-.54-.05-.18-.3-.3-.23-.43.12-.22-.06-.36.23-.38.22-.03.48-.02.7-.1.1-.05.22-.14.37-.2.07-.04.2-.12.27-.05.2.22.42.08.6-.06.56-.4.57.2 1.02.42 0 0-.03 0 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M5=15 23=112c=11=104-=184 1=115-=116 1=11=123 0 =103=132 0 =143-=112=13-=108=17-=102 1=102-=134 0-=15-=108-=17=126-=12=14-=15=12-=174-=105-=11-=11-=15=106-=168-=104-=11-=106-=118=102-=126-=104-=106 0-=11-=103-=117-=106 0-=105 0-=11=102-=116-=104-=105-=115=107-=118=108=104-=11-=13-2=106-=144-2=108=102 0 =137 1=106=14 1 0-=103-=106-=123-=105-=124=105-=104=103=11=108=11=112-=105-=104-=147-=106-=154-=105-=118-=13-=13-=123-=143=112-=122-=106-=136=123-=138=122-=103=148-=102=17-=11=11-=105=122-=114=137-=12=107-=104=12-=112=127-=105=12=122=142=108=16-=106=156-=14=157=12 1=102=142 0 0-=103 0 0 0z"
          ></path>
          <path
            className={`map-region ${district.find(dis => dis === "Kegalle") &&
              "active"}`}
            d="M5.32 25.66c-.05-.24-.1-.67-.03-.9.03-.1.27-.54.06-.52-.6.04.1-1.03.22-1.22.13-.2.63-.44.86-.46.44-.04.13-.55.37-.66.4-.2.56.32.7.56.1.13.15.17.27.24.23.14.03.1-.04.22.1-.18.57.72.62.78.28.33-.17.15-.3.38-.07.14-.3.1-.35.27-.05.23.06.5.03.74-.03.2-.25.26-.17.5.07.25-.12.52.2.6.3.08.64.67.1.64-.5-.04-.67-.36-1.15-.02-.4.28-.34-.17-.62-.43-.15-.16-.46-.8-.68-.5l-.08-.23c-.03-.12 0 0 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M5=132 25=166c-=105-=124-=11-=167-=103-=19=103-=11=127-=154=106-=152-=16=104=11-1=103=122-1=122=113-=12=163-=144=186-=146=144-=104=113-=155=137-=166=14-=12=156=132=17=156=11=113=115=117=127=124=123=114=103=11-=104=122=11-=118=157=172=162=178=128=133-=117=115-=13=138-=107=114-=13=11-=135=127-=105=123=106=15=103=174-=103=12-=125=126-=117=15=107=125-=112=152=12=16=13=108=164=167=11=164-=15-=104-=167-=136-1=115-=102-=14=128-=134-=117-=162-=143-=115-=116-=146-=18-=168-=15l-=108-=123c-=103-=112 0 0 0 0z"
          ></path>
          <path
            className={`map-region ${district.find(dis => dis === "Kandy") &&
              "active"}`}
            d="M11.9 23.6c-.04 0-.1 0-.14-.02-.18-.06-.2.02-.35.07-.15.06-.27-.1-.37.2-.08.2-.3.32-.37.53-.1.3.36.37.27.67-.06.18-.32.22-.5.2-.27-.05-.37-.52-.5-.73-.16-.23-.43-.2-.64-.04-.2.14-.52.2-.75.2-.18 0 .03.33.1.36.23.1.3.4.08.53-.6.4-.66-.65-.98-.75.05-.26-.2-.46.12-.6.14-.07.2-.24.33-.25.07 0 .2 0 .24-.07.13-.16-.26-.44-.33-.57 0-.02-.33-.53-.4-.44.05-.08.15-.05.2-.1-.1.1-.5-.55-.54-.6.48.2.3-.5.55-.4.2.05.23.17.4 0 .2-.16.2-.33.46-.22.23.1.45.4.68.45.25.06.43-.3.4-.5-.04-.1-.06-.34.07-.4.2-.05.3.3.42.4.26.16.67-.4 1.02-.32.06.05.13.08.2.1.13-.04.12-.2.26-.22.38-.04.3.18.28.47-.02.4.13.96.3 1.33.2.5-.05.6-.47.73 0 0 .04 0 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M11=19 23=16c-=104 0-=11 0-=114-=102-=118-=106-=12=102-=135=107-=115=106-=127-=11-=137=12-=108=12-=13=132-=137=153-=11=13=136=137=127=167-=106=118-=132=122-=15=12-=127-=105-=137-=152-=15-=173-=116-=123-=143-=12-=164-=104-=12=114-=152=12-=175=12-=118 0 =103=133=11=136=123=11=13=14=108=153-=16=14-=166-=165-=198-=175=105-=126-=12-=146=112-=16=114-=107=12-=124=133-=125=107 0 =12 0 =124-=107=113-=116-=126-=144-=133-=157 0-=102-=133-=153-=14-=144=105-=108=115-=105=12-=11-=11=11-=15-=155-=154-=16=148=12=13-=15=155-=14=12=105=123=117=14 0 =12-=116=12-=133=146-=122=123=11=145=14=168=145=125=106=143-=13=14-=15-=104-=11-=106-=134=107-=14=12-=105=13=13=142=14=126=116=167-=14 1=102-=132=106=105=113=108=12=11=113-=104=112-=12=126-=122=138-=104=13=118=128=147-=102=14=113=196=13 1=133=12=15-=105=16-=147=173 0 0 =104 0 0 0z"
          ></path>
          <path
            className={`map-region ${district.find(
              dis => dis === "Nuwara Eliya"
            ) && "active"}`}
            d="M12.03 23.96c.02.18-.22.12-.03.3.12.12-.18.72-.2.9-.07.28-.33.2-.45.38-.07.1.04.2 0 .32-.03.14-.17.14-.14.3.06.25.06.26-.17.34-.1.04.06.16-.12.24-.17.08 0 .13.04.24.05.1-.3.17-.28.3-.53.07-1.12.23-1.64.1-.35-.08-.92.1-.97-.35-.03-.2.15-.28.08-.48-.1-.27-.36-.26-.52-.45-.08-.1-.1-.6-.04-.74.06-.13.2-.1.2-.28 0-.1-.03-.18 0-.26.28.1.28.66.66.78.45.14.53-.34.24-.58-.38-.32-.03-.4.3-.4.22 0 .33-.22.54-.25.26-.04.34.06.5.25.22.27.18.74.68.62.63-.16-.2-.68.1-1.04.13-.15.2-.38.34-.52.06-.06.35-.08.44-.1.3-.1.45.13.47.38.02.1 0-.03 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M12=103 23=196c=102=118-=122=112-=103=13=112=112-=118=172-=12=19-=107=128-=133=12-=145=138-=107=11=104=12 0 =132-=103=114-=117=114-=114=13=106=125=106=126-=117=134-=11=104=106=116-=112=124-=117=108 0 =113=104=124=105=11-=13=117-=128=13-=153=107-1=112=123-1=164=11-=135-=108-=192=11-=197-=135-=103-=12=115-=128=108-=148-=11-=127-=136-=126-=152-=145-=108-=11-=11-=16-=104-=174=106-=113=12-=11=12-=128 0-=11-=103-=118 0-=126=128=11=128=166=166=178=145=114=153-=134=124-=158-=138-=132-=103-=14=13-=14=122 0 =133-=122=154-=125=126-=104=134=106=15=125=122=127=118=174=168=162=163-=116-=12-=168=11-1=104=113-=115=12-=138=134-=152=106-=106=135-=108=144-=11=13-=11=145=113=147=138=102=11 0-=103 0 0z"
          ></path>
          <path
            className={`map-region ${district.find(dis => dis === "Badulla") &&
              "active"}`}
            d="M12.08 21.1c-.03-.15-.16-1.2.03-1.15.22.04.28.4.34.56.03.12.1.42.28.37.3-.1.66-.02.8-.33.12-.22.36-.45.5-.08.14.4-.17.77-.07 1.17.04.16.06.62.22.7.15.08.2.02.36.17.1.1 0 .23.08.3-.28.1-.32.04-.4.4-.03.1-.32.53-.42.55-.2.04-.15-.3-.38-.05-.2.22.08.34.18.5.18.32.1.36.44.25.2-.07.35.23.4.37.07.24-.1.42-.1.65.02.06 0 .1-.06.12-.1.06 0 .1 0 .2.02.04-.3-.1-.36.06-.1.3-.27.36-.45.6-.15.2-.07.3-.3.45-.23.15-.37.36-.34.64.04.2.45.05.34.26-.12.2-.17.26-.23.5-.06.26.22.3.2.47-.02.2-.42.16-.5.22-.07.06 0 .25-.04.34-.1.2-.17-.47-.17-.47-.02-.1-.2-.36-.26-.45-.08-.14-.33-.48-.5-.4.02.13-1 .53-.82-.13.06-.22.23-.3.27-.54.02-.2-.36-.08-.45-.07 0-.17.2-.17.28-.28.1-.1-.18-.13 0-.3.07-.07 0-.16.06-.2.05-.05.14-.04.2-.08.02 0 .05-.4.1-.5.1-.18-.07-.35.14-.46.26-.13.36-.2.42-.5s.23-.55.06-.8c-.04-.07.12-.15.1-.22 0-.13-.1-.23-.1-.36.67-.2.48-.4.34-.98-.1-.5-.06-1.06-.18-1.52-.1-.4 0 0 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M12=108 21=11c-=103-=115-=116-1=12=103-1=115=122=104=128=14=134=156=103=112=11=142=128=137=13-=11=166-=102=18-=133=112-=122=136-=145=15-=108=114=14-=117=177-=107 1=117=104=116=106=162=122=17=115=108=12=102=136=117=11=11 0 =123=108=13-=128=11-=132=104-=14=14-=103=11-=132=153-=142=155-=12=104-=115-=13-=138-=105-=12=122=108=134=118=15=118=132=11=136=144=125=12-=107=135=123=14=137=107=124-=11=142-=11=165=102=106 0 =11-=106=112-=11=106 0 =11 0 =12=102=104-=13-=11-=136=106-=11=13-=127=136-=145=16-=115=12-=107=13-=13=145-=123=115-=137=136-=134=164=104=12=145=105=134=126-=112=12-=117=126-=123=15-=106=126=122=13=12=147-=102=12-=142=116-=15=122-=107=106 0 =125-=104=134-=11=12-=117-=147-=117-=147-=102-=11-=12-=136-=126-=145-=108-=114-=133-=148-=15-=14=102=113-1 =153-=182-=113=106-=122=123-=13=127-=154=102-=12-=136-=108-=145-=107 0-=117=12-=117=128-=128=11-=11-=118-=113 0-=13=107-=107 0-=116=106-=12=105-=105=114-=104=12-=108=102 0 =105-=14=11-=15=11-=118-=107-=135=114-=146=126-=113=136-=12=142-=15s=123-=155=106-=18c-=104-=107=112-=115=11-=122 0-=113-=11-=123-=11-=136=167-=12=148-=14=134-=198-=11-=15-=106-1=106-=118-1=152-=11-=14 0 0 0 0z"
          ></path>
          <path
            className={`map-region ${district.find(
              dis => dis === "Moneragala"
            ) && "active"}`}
            d="M17.8 25.84c.07.45 0 1.05-.06 1.5-.04.28-.03.55-.04.83 0 .27-.14.57-.03.85-.52-.06-1.1.6-1.1 1.06 0 .37-.08.3-.4.25-.22-.03-.7.3-.94.37-.3.07-.45.32-.7.4-.17.07-.38-.22-.48-.32-.16-.15-.34-.2-.5-.33-.42-.33-.63.2-1 .15-.3-.04-.05.22-.07.34-.04.2-.35.32-.5.4-.27.12-.28.15-.47-.06-.2-.24-.5-.4-.55-.73-.05-.37-.17-.7 0-1.04.15-.27.2-.62.37-.87.1-.16.36-.38.32-.6.2-.1.56.32.53.5-.02.15.24.25.2.52-.06.25.32.46.22.1-.06-.2.2-.16.3-.28.05-.06.24 0 .2-.12-.08-.16-.25-.17-.2-.38.03-.15 0-.34.1-.46.05-.04.18-.1.17-.17-.02-.22-.45.07-.33-.4.1-.33.32-.32.5-.55.1-.1.04-.25.15-.37.1-.12.28-.17.34-.35.1-.35.13-.2.4-.25-.05 0 .1-.42.1-.44.07-.2.2-.47.03-.63-.3-.3-.17-.25-.57-.18-.05 0-.4-.52-.45-.6-.1-.18.2-.5.3-.3.14.25.33-.12.44-.26.1-.14.15-.28.17-.44 0-.17.24-.12.35-.16.63.6.23-.4.45-.52.1-.05.25.08.3-.06.08-.24.15-.26.13-.52 0-.1.2-.02.2-.2.02-.15.33-.24.46-.3.33-.12.15.44.24.66.15.4.27.8.4 1.2.05.2.25.2.1.38-.2.2-.27.45-.2.7.16.47.85.26.92.7.05.32.16.78.2 1 .02.24-.02-.06 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M17=18 25=184c=107=145 0 1=105-=106 1=15-=104=128-=103=155-=104=183 0 =127-=114=157-=103=185-=152-=106-1=11=16-1=11 1=106 0 =137-=108=13-=14=125-=122-=103-=17=13-=194=137-=13=107-=145=132-=17=14-=117=107-=138-=122-=148-=132-=116-=115-=134-=12-=15-=133-=142-=133-=163=12-1 =115-=13-=104-=105=122-=107=134-=104=12-=135=132-=15=14-=127=112-=128=115-=147-=106-=12-=124-=15-=14-=155-=173-=105-=137-=117-=17 0-1=104=115-=127=12-=162=137-=187=11-=116=136-=138=132-=16=12-=11=156=132=153=15-=102=115=124=125=12=152-=106=125=132=146=122=11-=106-=12=12-=116=13-=128=105-=106=124 0 =12-=112-=108-=116-=125-=117-=12-=138=103-=115 0-=134=11-=146=105-=104=118-=11=117-=117-=102-=122-=145=107-=133-=14=11-=133=132-=132=15-=155=11-=11=104-=125=115-=137=11-=112=128-=117=134-=135=11-=135=113-=12=14-=125-=105 0 =11-=142=11-=144=107-=12=12-=147=103-=163-=13-=13-=117-=125-=157-=118-=105 0-=14-=152-=145-=16-=11-=118=12-=15=13-=13=114=125=133-=112=144-=126=11-=114=115-=128=117-=144 0-=117=124-=112=135-=116=163=16=123-=14=145-=152=11-=105=125=108=13-=106=108-=124=115-=126=113-=152 0-=11=12-=102=12-=12=102-=115=133-=124=146-=13=133-=112=115=144=124=166=115=14=127=18=14 1=12=105=12=125=12=11=138-=12=12-=127=145-=12=17=116=147=185=126=192=17=105=132=116=178=12 1 =102=124-=102-=106 0 0z"
          ></path>
          <path
            className={`map-region ${district.find(
              dis => dis === "Ratnapura"
            ) && "active"}`}
            d="M10.66 27.3c.63-.07.4.15.2.53-.13.23.04.46.3.46.12-.02.37-.35.47-.3 0 0-.43 1.05-.46 1.16-.1.28-.34.45-.3.77.06.33-.04.75.26.97.2.14 1 1.22.38 1.06-.12-.04-.23-.04-.3-.17-.1-.2-.4-.08-.57-.1-.2-.03-.4-.05-.56-.14-.2-.1-.4-.1-.6-.17.14-.16.08-.58-.17-.47-.05.02-.38.1-.2-.04.1-.1-.13-.1-.1-.2 0-.1.15-.18-.08-.16-.24.03-.5.03-.67.2-.1.07-.83-.13-1-.24-.3-.2-.33-.47-.54-.8-.13-.2-.46-.48-.3-.6.15-.12-.18-.37-.24-.4-.24-.13-.17-.35-.34-.53-.26-.3-.45-.75-.5-1.14.22.03.15-.2.1-.3-.06-.2.13-.5 0-.6-.1-.13 0-.38.17-.34.16.02.33.46.45.6.13.12.25.46.37.54.12.08.38-.16.47-.22.42-.28.75.34 1.12.13.04.18 0 .47.26.47.25 0 .87.2 1.07.12.24-.1.44 0 .68-.05.22-.06.43-.1.63-.1.1-.02-.04 0 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M10=166 27=13c=163-=107=14=115=12=153-=113=123=104=146=13=146=112-=102=137-=135=147-=13 0 0-=143 1=105-=146 1=116-=11=128-=134=145-=13=177=106=133-=104=175=126=197=12=114 1 1=122=138 1=106-=112-=104-=123-=104-=13-=117-=11-=12-=14-=108-=157-=11-=12-=103-=14-=105-=156-=114-=12-=11-=14-=11-=16-=117=114-=116=108-=158-=117-=147-=105=102-=138=11-=12-=104=11-=11-=113-=11-=11-=12 0-=11=115-=118-=108-=116-=124=103-=15=103-=167=12-=11=107-=183-=113-1-=124-=13-=12-=133-=147-=154-=18-=113-=12-=146-=148-=13-=16=115-=112-=118-=137-=124-=14-=124-=113-=117-=135-=134-=153-=126-=13-=145-=175-=15-1=114=122=103=115-=12=11-=13-=106-=12=113-=15 0-=16-=11-=113 0-=138=117-=134=116=102=133=146=145=16=113=112=125=146=137=154=112=108=138-=116=147-=122=142-=128=175=134 1=112=113=104=118 0 =147=126=147=125 0 =187=12 1=107=112=124-=11=144 0 =168-=105=122-=106=143-=11=163-=11=11-=102-=104 0 0 0z"
          ></path>
          <path
            className={`map-region ${district.find(dis => dis === "Kalutara") &&
              "active"}`}
            d="M6.98 30.3c-.1.17-.4.2-.55.27-.32.1.08.48-.07.6-.04.03-.5-.4-.6-.4-.28-.04-.2.34-.6.2-.14-.03-1.3-.7-1.33-.67-.15.17-.2.1-.28-.14-.07-.17.04-.14.03-.33-.02-.26-.12-.45-.2-.7-.1-.36-.25-.7-.4-1.05-.07-.2-.25-.37-.03-.5.2-.1.38-.1.6-.1.25-.03.54-.14.74-.3.18-.17.55-.2.82-.2.33.02.25.3.34.56.1.27.46.58.5.84.02.28.25.3.4.5.2.22-.13.16.06.4.12.17.7.83.56 1.03-.08.13 0 0 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M6=198 30=13c-=11=117-=14=12-=155=127-=132=11=108=148-=107=16-=104=103-=15-=14-=16-=14-=128-=104-=12=134-=16=12-=114-=103-1=13-=17-1=133-=167-=115=117-=12=11-=128-=114-=107-=117=104-=114=103-=133-=102-=126-=112-=145-=12-=17-=11-=136-=125-=17-=14-1=105-=107-=12-=125-=137-=103-=15=12-=11=138-=11=16-=11=125-=103=154-=114=174-=13=118-=117=155-=12=182-=12=133=102=125=13=134=156=11=127=146=158=15=184=102=128=125=13=14=15=12=122-=113=116=106=14=112=117=17=183=156 1=103-=108=113 0 0 0 0z"
          ></path>
          <path
            className={`map-region ${district.find(dis => dis === "Galle") &&
              "active"}`}
            d="M8.05 30.8c0 .06-.28.05-.3.2 0 .13.18.2.22.3.14.3-.37.07-.47.02-.14-.07-.75-.28-.38.03.16.12.63.48.37.72-.07.06-.3.05-.33.1-.04.1.02.4-.08.42-.24.04.25.45.3.5.22.2-.15.25-.23.4-.08.14.18.23.05.36-.2.18-.24.34-.2.6-.13.03-.27-.07-.38-.14-.2-.1-.36-.08-.54-.16-.06-.02-.52-.37-.46-.4l-.04.13c-.1.05-.65-.56-.7-.6-.4-.38-.58-.9-.8-1.37-.1-.2-.04-.36-.1-.55-.05-.2-.2-.4-.24-.6 0-.06-.08-.57.14-.43.02 0 .06.36.08.3.12-.25.6-.04.78.18.14.17.72.32.8.05.13-.48.78.43.84.28.07-.16-.25-.3-.08-.5.16-.16.42-.16.6-.3.1-.07.5.26.72.27.14 0 .4 0 .4.18 0 .05 0-.02 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M8=105 30=18c0 =106-=128=105-=13=12 0 =113=118=12=122=13=114=13-=137=107-=147=102-=114-=107-=175-=128-=138=103=116=112=163=148=137=172-=107=106-=13=105-=133=11-=104=11=102=14-=108=142-=124=104=125=145=13=15=122=12-=115=125-=123=14-=108=114=118=123=105=136-=12=118-=124=134-=12=16-=113=103-=127-=107-=138-=114-=12-=11-=136-=108-=154-=116-=106-=102-=152-=137-=146-=14l-=104=113c-=11=105-=165-=156-=17-=16-=14-=138-=158-=19-=18-1=137-=11-=12-=104-=136-=11-=155-=105-=12-=12-=14-=124-=16 0-=106-=108-=157=114-=143=102 0 =106=136=108=13=112-=125=16-=104=178=118=114=117=172=132=18=105=113-=148=178=143=184=128=107-=116-=125-=13-=108-=15=116-=116=142-=116=16-=13=11-=107=15=126=172=127=114 0 =14 0 =14=118 0 =105 0-=102 0 0z"
          ></path>
          <path
            className={`map-region ${district.find(dis => dis === "Matara") &&
              "active"}`}
            d="M9.48 31.4c-.12.12.1.48-.2.4-.1-.05-.4.54-.14.64.14.06.44-.03.3.2-.2.35.2.2.3.34.06.1-.35.77-.16.8.08 0 .2 0 .14.12-.1.2.25.23.34.32-.08.06-.12.2-.23.2-.2 0-.44.04-.6.16-.07.06-.42.33-.5.27-.1-.07-.05-.2-.2-.2-.2 0-.4.08-.6.05-.16-.02-.3-.02-.36-.17-.1-.2-.17-.07-.32-.05-.46.08-.18-.45 0-.62.16-.17-.26-.26.06-.48.24-.17.2-.23-.02-.4-.3-.22-.17-.24-.17-.53.02-.35.18-.2.38-.38s-.06-.53-.23-.64c-.23-.14-.28-.46.04-.24.14.1.4.33.6.28.26-.07-.18-.4-.13-.52.05-.13.46-.23.57-.3.1-.05.84-.2.7 0-.02 0 .06.37.05.36.08.04.3-.22.38-.15.06.04 0 .5 0 .52-.1.08 0 0 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M9=148 31=14c-=112=112=11=148-=12=14-=11-=105-=14=154-=114=164=114=106=144-=103=13=12-=12=135=12=12=13=134=106=11-=135=177-=116=18=108 0 =12 0 =114=112-=11=12=125=123=134=132-=108=106-=112=12-=123=12-=12 0-=144=104-=16=116-=107=106-=142=133-=15=127-=11-=107-=105-=12-=12-=12-=12 0-=14=108-=16=105-=116-=102-=13-=102-=136-=117-=11-=12-=117-=107-=132-=105-=146=108-=118-=145 0-=162=116-=117-=126-=126=106-=148=124-=117=12-=123-=102-=14-=13-=122-=117-=124-=117-=153=102-=135=118-=12=138-=138s-=106-=153-=123-=164c-=123-=114-=128-=146=104-=124=114=11=14=133=16=128=126-=107-=118-=14-=113-=152=105-=113=146-=123=157-=13=11-=105=184-=12=17 0-=102 0 =106=137=105=136=108=104=13-=122=138-=115=106=104 0 =15 0 =152-=11=108 0 0 0 0z"
          ></path>
          <path
            className={`map-region ${district.find(
              dis => dis === "Hambantota"
            ) && "active"}`}
            d="M17.67 29.02c.2.02.9.5.73.75-.1.16-.32.3-.46.45-.14.14-.2-.08-.2-.1-.13.12-.07.37-.22.5-.12.1-.33.3-.48.3-.35.03-.38.33-.64.48-.17.1-.5.12-.62.3-.03.06.1.03.12.04 0 .02-.23.26-.27.27 0 .02 0-.1-.06-.1-.1 0-.44.37-.5.44-.2.16-.87.46-1.1.4.1.02.37-.33.1-.25-.03.04.1 0 .04.07.05-.05-.6.12-.2.14.03.02-.25.26-.28.28-.27.14-.55.15-.84.2-.1 0-1 .13-1.02.23 0-.06.28-.04.33-.1.02-.06-.22.05-.2.06.02.02-.17-.35-.3-.14-.02.04.1.15.12.2.04.12-.3.17-.4.15 0-.04.03-.07.07-.1 0-.07-.2.17-.2.17-.08.08-.34.3-.45.14 0 0 .3-.04.3-.08 0-.1-.42.08-.46.1-.1.08-.02.14-.08.22-.08.1-.26.1-.38.15-.14.05-.25-.03-.36-.1-.15-.08 0-.14-.05-.24-.04-.08-.24 0-.22-.13.02-.13.15-.28.18-.4.1-.32.1-.33-.16-.37-.32-.06.1-.45-.08-.47-.35-.04-.56-.04-.34-.46.06-.12.12-.18.27-.2.17 0 0-.3.1-.4.3.1.6.2.9.27.32.08.64-.04.92.17.78.6.23-.35.4-.4.2-.04.37-.12.53-.22.28-.17.22-.24.17-.5 0-.07.53-.3.6-.33.38-.15.4.08.7.22.2.1.3.13.5.33.33.35.42-.03.72-.13s1.1-.66 1.4-.46c.34.22.28-.58.34-.73.07-.18.45-.36.6-.47.12-.08.5-.14.4-.14.16 0 0 0 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M17=167 29=102c=12=102=19=15=173=175-=11=116-=132=13-=146=145-=114=114-=12-=108-=12-=11-=113=112-=107=137-=122=15-=112=11-=133=13-=148=13-=135=103-=138=133-=164=148-=117=11-=15=112-=162=13-=103=106=11=103=112=104 0 =102-=123=126-=127=127 0 =102 0-=11-=106-=11-=11 0-=144=137-=15=144-=12=116-=187=146-1=11=14=11=102=137-=133=11-=125-=103=104=11 0 =104=107=105-=105-=16=112-=12=114=103=102-=125=126-=128=128-=127=114-=155=115-=184=12-=11 0-1 =113-1=102=123 0-=106=128-=104=133-=11=102-=106-=122=105-=12=106=102=102-=117-=135-=13-=114-=102=104=11=115=112=12=104=112-=13=117-=14=115 0-=104=103-=107=107-=11 0-=107-=12=117-=12=117-=108=108-=134=13-=145=114 0 0 =13-=104=13-=108 0-=11-=142=108-=146=11-=11=108-=102=114-=108=122-=108=11-=126=11-=138=115-=114=105-=125-=103-=136-=11-=115-=108 0-=114-=105-=124-=104-=108-=124 0-=122-=113=102-=113=115-=128=118-=14=11-=132=11-=133-=116-=137-=132-=106=11-=145-=108-=147-=135-=104-=156-=104-=134-=146=106-=112=112-=118=127-=12=117 0 0-=13=11-=14=13=11=16=12=19=127=132=108=164-=104=192=117=178=16=123-=135=14-=14=12-=104=137-=112=153-=122=128-=117=122-=124=117-=15 0-=107=153-=13=16-=133=138-=115=14=108=17=122=12=11=13=113=15=133=133=135=142-=103=172-=113s1=11-=166 1=14-=146c=134=122=128-=158=134-=173=107-=118=145-=136=16-=147=112-=108=15-=114=14-=114=116 0 0 0 0 0z"
          ></path>
          <path
            className={`map-region ${district.find(dis => dis === "Jaffna") &&
            "active"}`}
            d="M1.43 3.47c.1.5-1.07-.2-.66-.47.1-.07.3.13.37.17.22.12.23 0 .3.3.04.26 0-.02 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M1=143 3=147c=11=15-1=107-=12-=166-=147=11-=107=13=113=137=117=122=112=123 0 =13=13=104=126 0-=102 0 0z"
          ></path>
          <path
            className={`map-region ${district.find(dis => dis === "Jaffna") &&
            "active"}`}
            d="M2.6 2.45c.1.26-.17.37-.37.33-.15-.03-.23-.5-.1-.5.1-.03.08.27.23.26 0 0 .2-.16.23-.1 0 .05-.02 0 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M2=16 2=145c=11=126-=117=137-=137=133-=115-=103-=123-=15-=11-=15=11-=103=108=127=123=126 0 0 =12-=116=123-=11 0 =105-=102 0 0 0z"
          ></path>
          <path
            className={`map-region ${district.find(dis => dis === "Jaffna") &&
            "active"}`}
            d="M1.83 2.35c0 .05.02.3-.07.3-.22-.04.05-.4.07-.3.02.08 0 0 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M1=183 2=135c0 =105=102=13-=107=13-=122-=104=105-=14=107-=13=102=108 0 0 0 0z"
          ></path>
          <path
            className={`map-region ${district.find(dis => dis === "Jaffna") &&
            "active"}`}
            d="M1.9 1.86c0 .06-.1.2-.16.2-.1 0 .02-.3.1-.27.02 0 .03.04.05.06 0 .1 0 0 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M1=19 1=186c0 =106-=11=12-=116=12-=11 0 =102-=13=11-=127=102 0 =103=104=105=106 0 =11 0 0 0 0z"
          ></path>
          <path
            className={`map-region ${district.find(dis => dis === "Jaffna") &&
            "active"}`}
            d="M2.16 1.5c.02.02 0 .24-.04.25-.16.03 0-.33.04-.26.02 0 0 0 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M2=116 1=15c=102=102 0 =124-=104=125-=116=103 0-=133=104-=126=102 0 0 0 0 0z"
          ></path>
          <path
            className={`map-region ${district.find(dis => dis === "Jaffna") &&
            "active"}`}
            d="M2.83 1.1c.2.43-.4.5-.36.32.03-.1-.04-.3.1-.36.07-.04.22-.05.26.04.03.07 0 0 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M2=183 1=11c=12=143-=14=15-=136=132=103-=11-=104-=13=11-=136=107-=104=122-=105=126=104=103=107 0 0 0 0z"
          ></path>
          <path
            className={`map-region ${district.find(dis => dis === "Jaffna") &&
            "active"}`}
            d="M3.6 2.2c.04.24-.5 0-.66.13-.2.15-.4-.5-.3-.45-.12-.06-.06.18-.2-.04-.05-.08-.1-.3.06-.28.1.02.28.03.33.13 0 .03.07.27.14.24-.04.02.2-.3.22-.14 0 .12-.2.1.05.25.03.02.34.13.34.15.02.15 0 0 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M3=16 2=12c=104=124-=15 0-=166=113-=12=115-=14-=15-=13-=145-=112-=106-=106=118-=12-=104-=105-=108-=11-=13=106-=128=11=102=128=103=133=113 0 =103=107=127=114=124-=104=102=12-=13=122-=114 0 =112-=12=11=105=125=103=102=134=113=134=115=102=115 0 0 0 0z"
          ></path>
          <path
            className={`map-region ${district.find(dis => dis === "Jaffna") &&
            "active"}`}
            d="M3.8 2.24c.3.3-.5.33-.37.16.06-.08.14.05.2-.1.04-.1.06-.17.17-.06.03.04 0 0 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M3=18 2=124c=13=13-=15=133-=137=116=106-=108=114=105=12-=11=104-=11=106-=117=117-=106=103=104 0 0 0 0z"
          ></path>
          <path
            className={`map-region ${district.find(dis => dis === "Jaffna") &&
              "active"}`}
            d="M6.25 1.87l-.2.54c-.05 0-.88-.62-.94-.38-.02.2.18.4.27.45.04.03-.28.1-.2-.03h.08c.03-.07-.8-.36-.84-.5 0-.1.13-.38.25-.4.16-.02.08.24.1.24.05-.02.08-.06.1-.12.02.02.05.04.08.04.07-.04-.18-.18 0-.25.14-.05.07-.22.05-.2 0 0-.14.4-.3.23 0 0 .12-.05.03-.1-.05-.05-.1.18-.16.1l.04-.08c-.04-.04-.3.4-.3.5.04.07.6.56.6.52-.05.12-.47-.25-.53-.28-.18-.1-.35.04-.54-.08-.08-.06-.08-.39-.22-.35 0 0-.42-.1-.43-.13-.04-.1-.25-.45-.25-.5 0-.16.43-.38.55-.42.2-.06.6-.2.8-.1.12.03.6-.07.6-.07.2.14-.3.54.13.52-.02 0-.02.16.06.1.06-.06 0-.2.17-.13.03 0 .3.2.26.2.06-.03-.08-.2.08-.16.08.03.2.08.25.15.15.24.26.48.45.72zm-.8-.5c.03-.07-.13-.05-.1 0 0 .1.1.06.1 0 .04-.07 0 0 0 0zm-.08-.08c0-.1-.2-.13-.24-.07-.07.1.23.23.24.06 0-.06 0 0 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M6=125 1=187l-=12=154c-=105 0-=188-=162-=194-=138-=102=12=118=14=127=145=104=103-=128=11-=12-=103h=108c=103-=107-=18-=136-=184-=15 0-=11=113-=138=125-=14=116-=102=108=124=11=124=105-=102=108-=106=11-=112=102=102=105=104=108=104=107-=104-=118-=118 0-=125=114-=105=107-=122=105-=12 0 0-=114=14-=13=123 0 0 =112-=105=103-=11-=105-=105-=11=118-=116=11l=104-=108c-=104-=104-=13=14-=13=15=104=107=16=156=16=152-=105=112-=147-=125-=153-=128-=118-=11-=135=104-=154-=108-=108-=106-=108-=139-=122-=135 0 0-=142-=11-=143-=113-=104-=11-=125-=145-=125-=15 0-=116=143-=138=155-=142=12-=106=16-=12=18-=11=112=103=16-=107=16-=107=12=114-=13=154=113=152-=102 0-=102=116=106=11=106-=106 0-=12=117-=113=103 0 =13=12=126=12=106-=103-=108-=12=108-=116=108=103=12=108=125=115=115=124=126=148=145=172zm-=18-=15c=103-=107-=113-=105-=11 0 0 =11=11=106=11 0 =104-=107 0 0 0 0zm-=108-=108c0-=11-=12-=113-=124-=107-=107=11=123=123=124=106 0-=106 0 0 0 0z"
          ></path>
          <path
            className={`map-region ${district.find(dis => dis === "Jaffna") &&
            "active"}`}
            d="M9 3.66c-.22.1-.44-.36-.7-.34-.38.03-.72-.28-.8-.6.06 0 .1.02.17.04-.05.06-1.17-.94-1.3-1.04-.22-.2-.5-.8-.83-.74-.08.02-.6-.17-.6-.15.02.03.04.06.04.1-.08.07-.1-.3-.1-.34 0-.2.24-.16.38-.18.17-.02.3-.08.5-.03.22.05.2.38.35.56.37.47.68.93 1.15 1.3.6.47 1.16.94 1.76 1.4z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M9 3=166c-=122=11-=144-=136-=17-=134-=138=103-=172-=128-=18-=16=106 0 =11=102=117=104-=105=106-1=117-=194-1=13-1=104-=122-=12-=15-=18-=183-=174-=108=102-=16-=117-=16-=115=102=103=104=106=104=11-=108=107-=11-=13-=11-=134 0-=12=124-=116=138-=118=117-=102=13-=108=15-=103=122=105=12=138=135=156=137=147=168=193 1=115 1=13=16=147 1=116=194 1=176 1=14z"
          ></path>
          <path
            className={`map-region ${district.find(
              dis => dis === "Trincomalee"
            ) && "active"}`}
            d="M10.43 8.33c.32-.25.76-.1 1.1-.3.02.15.16.35.34.26.05-.04-.07-.1-.03-.14.06-.04.22.05.2-.08 0-.02-.1-.38.04-.26.13.1.1.47.16.52l.16-.07c.06.02 0 .24-.02.25.04-.18.05.05.1.05.06 0-.05-.1 0-.12.16-.07.24.57.5.38-.05.03.73 1.26.8 1.26-.04 0-.32-.27-.3-.04.03.15.1.06.15.02.07-.06.3.65.32.64.2-.15-.2-.4-.16-.58-.03.07.35.5.4.57.22.34-.27.33-.1.48.4-.2.36.6.24.56-.1-.02-.2-.64-.33-.1-.02.1.3.54-.1.28 0-.03.03-.03.08 0 0-.04-.04-.1-.07-.13-.15.18 0-.12-.2-.05-.12.04-.4.26-.25.37.14.1.23-.16.38-.07.05.03.34.25.24.33-.05.04-.08-.14-.13-.12.05-.02 0 .26 0 .27.06.05.12-.06.14-.1.06-.1.22.05.3.07.07 0 .58.2.58.2.02-.03.02-.2.02-.24 0 .04-.36.1-.16-.03 0 .04-.23.1-.24.02 0-.02.14-.05.17-.06.16-.07 0-.13 0-.23s.47-.4.45-.2c0 .07-.12-.04-.12.02.12-.02.17 0 .14.1-.04.02-.06.06-.04.1.1.05.22-.18.2.12-.04-.02-.08-.02-.12 0 .02-.03.56.95.43 1.03-.2.1-.16-.6-.37-.47-.12.08 0 .24.05.32l.12.17c.1.14-.03.1.06.03-.02 0 .06.47.07.5.04.2.2.43-.05.48-.25.05-.5 0-.75.07-.33.1-.34.4-.5.65-.1.2-.4.28-.32-.04.1-.37.05-.5-.3-.68-.36-.17-.74-.22-1.1-.32-.13-.03-.23.1-.35 0-.14-.13-.23-.3-.37-.44-.27-.26-.24-.5-.06-.82.1-.14.45-.37.47-.5 0-.1-.27-.14-.3-.18-.14-.1-.17-.25-.2-.4-.06-.37-.4-.63-.37-1.04.04-.32-.12-.63-.05-.93.06-.27.27-.57.1-.86-.2-.4-.87-.4-1.25-.4.1-.1.22-.18.33-.27l-.13.1c.3-.25 0 0 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M10=143 8=133c=132-=125=176-=11 1=11-=13=102=115=116=135=134=126=105-=104-=107-=11-=103-=114=106-=104=122=105=12-=108 0-=102-=11-=138=104-=126=113=11=11=147=116=152l=116-=107c=106=102 0 =124-=102=125=104-=118=105=105=11=105=106 0-=105-=11 0-=112=116-=107=124=157=15=138-=105=103=173 1=126=18 1=126-=104 0-=132-=127-=13-=104=103=115=11=106=115=102=107-=106=13=165=132=164=12-=115-=12-=14-=116-=158-=103=107=135=15=14=157=122=134-=127=133-=11=148=14-=12=136=16=124=156-=11-=102-=12-=164-=133-=11-=102=11=13=154-=11=128 0-=103=103-=103=108 0 0-=104-=104-=11-=107-=113-=115=118 0-=112-=12-=105-=112=104-=14=126-=125=137=114=11=123-=116=138-=107=105=103=134=125=124=133-=105=104-=108-=114-=113-=112=105-=102 0 =126 0 =127=106=105=112-=106=114-=11=106-=11=122=105=13=107=107 0 =158=12=158=12=102-=103=102-=12=102-=124 0 =104-=136=11-=116-=103 0 =104-=123=11-=124=102 0-=102=114-=105=117-=106=116-=107 0-=113 0-=123s=147-=14=145-=12c0 =107-=112-=104-=112=102=112-=102=117 0 =114=11-=104=102-=106=106-=104=11=11=105=122-=118=12=112-=104-=102-=108-=102-=112 0 =102-=103=156=195=143 1=103-=12=11-=116-=16-=137-=147-=112=108 0 =124=105=132l=112=117c=11=114-=103=11=106=103-=102 0 =106=147=107=15=104=12=12=143-=105=148-=125=105-=15 0-=175=107-=133=11-=134=14-=15=165-=11=12-=14=128-=132-=104=11-=137=105-=15-=13-=168-=136-=117-=174-=122-1=11-=132-=113-=103-=123=11-=135 0-=114-=113-=123-=13-=137-=144-=127-=126-=124-=15-=106-=182=11-=114=145-=137=147-=15 0-=11-=127-=114-=13-=118-=114-=11-=117-=125-=12-=14-=106-=137-=14-=163-=137-1=104=104-=132-=112-=163-=105-=193=106-=127=127-=157=11-=186-=12-=14-=187-=14-1=125-=14=11-=11=122-=118=133-=127l-=113=11c=13-=125 0 0 0 0z"
          ></path>
          <path
            className="map-region"
            d="M15.8 14.14l-.05.12c-.05 0-.23-.73-.1-.84-.1.07.13.6.14.72-.02.04 0-.02 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M15=18 14=114l-=105=112c-=105 0-=123-=173-=11-=184-=11=107=113=16=114=172-=102=104 0-=102 0 0z"
          ></path>
          <path
            className="map-region"
            d="M3.13 8c.07.06-.4.08-.36 0v.1c.2-.16-1.6-1.13-1.6-1 .02-.5 1-.08 1.28.03.07.03.74.56.67.62-.13.13-.52-.47-.56-.45-.14.08.53.6.57.7.02 0 0-.02 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M3=113 8c=107=106-=14=108-=136 0v=11c=12-=116-1=16-1=113-1=16-1 =102-=15 1-=108 1=128=103=107=103=174=156=167=162-=113=113-=152-=147-=156-=145-=114=108=153=16=157=17=102 0 0-=102 0 0z"
          ></path>
          <path
            className="map-region"
            d="M2.98 8.17c.1.12-.14.1-.14.04 0-.07.12-.05.14-.03.1.13 0 0 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M2=198 8=117c=11=112-=114=11-=114=104 0-=107=112-=105=114-=103=11=113 0 0 0 0z"
          ></path>
          <path
            className="map-region"
            d="M3.1 8.6c.06.1-.3-.27-.22-.3.04-.03.2.25.23.3.02.02 0-.02 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M3=11 8=16c=106=11-=13-=127-=122-=13=104-=103=12=125=123=13=102=102 0-=102 0 0z"
          ></path>
          <path
            className={`map-region ${district.find(dis => dis === "Mannar") &&
              "active"}`}
            d="M7.13 8.73c.05.32-.1.37-.38.4-.18 0-.33.1-.5.14-.18.03-.4-.17-.58-.03-.2.16-.2.5-.27.72-.08.26.32.43.4.64-.44 0-1 .47-1.4.14-.33-.28-.3 1.13-.3 1.28-.28-.02-.47-.2-.74-.23-.5-.05-.18-.47-.1-.75.07-.17.18-.78.1-.94-.03-.04-.3-.58-.27-.58.03 0 .34.1.36 0-.02.1-.2-.12-.17-.1-.03 0-.1.1-.1.1-.02 0-.04-.18-.08-.16h.08c-.05.08 0-.35 0-.33.02-.26-.06-.18.05-.3.05-.06-.2-.32-.2-.4.02-.08.62-.1.64-.33.03-.33.44-.2.52-.5.05-.18.1-.38.17-.55.02-.06.27-.02.27-.02 0-.06-.13-.13-.17-.17-.15-.15.03-.23.1-.37.07-.1.1-.27.15-.38.08-.15.4-.14.57-.23 0 .44.4 1 .26 1.43-.05.15-.23.16-.16.35.07.2.04.3.05.5 0 .2 1 .1 1.17.08.33-.05.45.36.5.57.06.46-.02-.06 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M7=113 8=173c=105=132-=11=137-=138=14-=118 0-=133=11-=15=114-=118=103-=14-=117-=158-=103-=12=116-=12=15-=127=172-=108=126=132=143=14=164-=144 0-1 =147-1=14=114-=133-=128-=13 1=113-=13 1=128-=128-=102-=147-=12-=174-=123-=15-=105-=118-=147-=11-=175=107-=117=118-=178=11-=194-=103-=104-=13-=158-=127-=158=103 0 =134=11=136 0-=102=11-=12-=112-=117-=11-=103 0-=11=11-=11=11-=102 0-=104-=118-=108-=116h=108c-=105=108 0-=135 0-=133=102-=126-=106-=118=105-=13=105-=106-=12-=132-=12-=14=102-=108=162-=11=164-=133=103-=133=144-=12=152-=15=105-=118=11-=138=117-=155=102-=106=127-=102=127-=102 0-=106-=113-=113-=117-=117-=115-=115=103-=123=11-=137=107-=11=11-=127=115-=138=108-=115=14-=114=157-=123 0 =144=14 1 =126 1=143-=105=115-=123=116-=116=135=107=12=104=13=105=15 0 =12 1 =11 1=117=108=133-=105=145=136=15=157=106=146-=102-=106 0 0z"
          ></path>
          <path
            className={`map-region ${district.find(
              dis => dis === "Anuradhapura"
            ) && "active"}`}
            d="M4.1 12.02c0-.1 0-1.4.2-1.34.25.06.34.2.63.14.28-.06.57-.23.86-.22.04.16-.13.28-.02.43.07.1.2.1.28.17.14.14.06.7.43.5.2-.1.4-.36.54-.57.08-.13.22-.2.3-.32.06-.08.04-.3.2-.3.2.02.36.2.55.23.25.06.52-.38.67-.54.1-.1.64-.58.57-.74-.06-.12-.57-.4-.5-.52.1-.15.6-.12.78-.1.28 0 .34-.35.6-.34.4 0 .5-.08.88.1.35.15.54.5.36.86-.17.36-.1.57.03.94.1.27-.14.43.02.72.14.25.27.44.33.72.05.2.55.44.38.55-.25.17-.47.45-.54.75-.05.2.12.27 0 .4-.08.07-.13.2-.14.3-.05.3-.52.1-.7.16-.32.1-.2.55-.36.77-.23.32-.2.57-.2.95 0 .32.12.62-.24.8-.25.13-.48.34-.65.57-.22.3-.2.67-.66.72-.24.03-.92.75-.85 0 0-.22.05-.62-.16-.8-.08-.06-.2-.04-.27-.1-.13-.14-.02-.46-.06-.62-.06-.32-.48-.22-.67-.44-.25-.3-.56-.38-.9-.52-.33-.13-.7-.2-1-.36-.15-.07-.22-.2-.34-.3-.1-.06-.27-.03-.3-.1-.1-.16-.4.06-.53-.26-.12-.33-.2-.65-.33-.97-.04-.1.43-.58.5-.66.2-.2.25-.44.25-.68 0-.3 0 .03 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M4=11 12=102c0-=11 0-1=14=12-1=134=125=106=134=12=163=114=128-=106=157-=123=186-=122=104=116-=113=128-=102=143=107=11=12=11=128=117=114=114=106=17=143=15=12-=11=14-=136=154-=157=108-=113=122-=12=13-=132=106-=108=104-=13=12-=13=12=102=136=12=155=123=125=106=152-=138=167-=154=11-=11=164-=158=157-=174-=106-=112-=157-=14-=15-=152=11-=115=16-=112=178-=11=128 0 =134-=135=16-=134=14 0 =15-=108=188=11=135=115=154=15=136=186-=117=136-=11=157=103=194=11=127-=114=143=102=172=114=125=127=144=133=172=105=12=155=144=138=155-=125=117-=147=145-=154=175-=105=12=112=127 0 =14-=108=107-=113=12-=114=13-=105=13-=152=11-=17=116-=132=11-=12=155-=136=177-=123=132-=12=157-=12=195 0 =132=112=162-=124=18-=125=113-=148=134-=165=157-=122=13-=12=167-=166=172-=124=103-=192=175-=185 0 0-=122=105-=162-=116-=18-=108-=106-=12-=104-=127-=11-=113-=114-=102-=146-=106-=162-=106-=132-=148-=122-=167-=144-=125-=13-=156-=138-=19-=152-=133-=113-=17-=12-1-=136-=115-=107-=122-=12-=134-=13-=11-=106-=127-=103-=13-=11-=11-=116-=14=106-=153-=126-=112-=133-=12-=165-=133-=197-=104-=11=143-=158=15-=166=12-=12=125-=144=125-=168 0-=13 0 =103 0 0z"
          ></path>
          <path
            className={`map-region ${district.find(dis => dis === "Matale") &&
              "active"}`}
            d="M12.04 19.95c-.05.38-.05.77.04 1.15-.18.03-.32-.1-.44.1-.1.16-.33.04-.5.04-.35 0-.7.6-.95.13-.08-.13-.18-.32-.33-.16-.18.2-.02.38-.06.6-.1.6-1-.24-1.25-.28 0-.26.3-.4.1-.72-.2-.3-.03-.83-.07-1.18-.05-.32-.2-.57-.32-.87-.12-.3-.38-.44-.04-.62.13-.06.4-.36.54-.34.32.04.47-.22.57-.5.12-.36.85-1.2 1.05-.56.1.3.37-.32.5.05.03.13-.16.33-.2.45-.1.22-.1.44-.07.67.08.47-.2.18-.38.4-.13.2-.07.55 0 .74.08.28.23.37.52.32.1-.03.15-.15.24-.2.16-.07.28.04.45-.07.13-.06.27-.1.4-.1.24 0 .24-.04.27.16.06.33-.02.5-.06.8-.05.38 0-.04 0-.02z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M12=104 19=195c-=105=138-=105=177=104 1=115-=118=103-=132-=11-=144=11-=11=116-=133=104-=15=104-=135 0-=17=16-=195=113-=108-=113-=118-=132-=133-=116-=118=12-=102=138-=106=16-=11=16-1-=124-1=125-=128 0-=126=13-=14=11-=172-=12-=13-=103-=183-=107-1=118-=105-=132-=12-=157-=132-=187-=112-=13-=138-=144-=104-=162=113-=106=14-=136=154-=134=132=104=147-=122=157-=15=112-=136=185-1=12 1=105-=156=11=13=137-=132=15=105=103=113-=116=133-=12=145-=11=122-=11=144-=107=167=108=147-=12=118-=138=14-=113=12-=107=155 0 =174=108=128=123=137=152=132=11-=103=115-=115=124-=12=116-=107=128=104=145-=107=113-=106=127-=11=14-=11=124 0 =124-=104=127=116=106=133-=102=15-=106=18-=105=138 0-=104 0-=102z"
          ></path>
          <path
            className={`map-region ${district.find(
              dis => dis === "Polonnaruwa"
            ) && "active"}`}
            d="M14.25 18.72c-.14.2.1.96-.4.72-.18-.1-.4-.08-.55-.2-.23-.22-.17-.34-.52-.3l-.9.06c-.16 0-.38.02-.5.14-.15.16-.32-.04-.5.15-.06.07-.43.15-.53.07-.1-.08-.15-.43-.18-.55-.03-.15-.07-.38.05-.5.1-.1.3-.03.37-.14.1-.14-.02-.46 0-.63.03-.2.33-.58.28-.76-.08-.32-.33.1-.42.03-.13-.1-.1-.35-.28-.37.28-.2.1-.84.1-1.16.02-.24.13-.36.25-.55.12-.2.04-.44.17-.63.24-.35.72.13.88-.37.22-.66.53.53.9.33.1-.05.84.13.97.18.32.13.72.37.64.76-.1.4.2.3.32 0 .13-.3.15-.66.57-.6-.03.3 0 .6 0 .92 0 .32-.1.68-.03 1 .07.28.67.87-.1.7-.62-.1-.44.52-.57.85-.07.17-.13.25-.07.46.03.1.07.3.02.36-.14.2 0-.02 0 0z"
            data-reactid=".13p0ej9h9ok.0.2.0.2.0.2.0.0.0.0.$M14=125 18=172c-=114=12=11=196-=14=172-=118-=11-=14-=108-=155-=12-=123-=122-=117-=134-=152-=13l-=19=106c-=116 0-=138=102-=15=114-=115=116-=132-=104-=15=115-=106=107-=143=115-=153=107-=11-=108-=115-=143-=118-=155-=103-=115-=107-=138=105-=15=11-=11=13-=103=137-=114=11-=114-=102-=146 0-=163=103-=12=133-=158=128-=176-=108-=132-=133=11-=142=103-=113-=11-=11-=135-=128-=137=128-=12=11-=184=11-1=116=102-=124=113-=136=125-=155=112-=12=104-=144=117-=163=124-=135=172=113=188-=137=122-=166=153=153=19=133=11-=105=184=113=197=118=132=113=172=137=164=176-=11=14=12=13=132 0 =113-=13=115-=166=157-=16-=103=13 0 =16 0 =192 0 =132-=11=168-=103 1 =107=128=167=187-=11=17-=162-=11-=144=152-=157=185-=107=117-=113=125-=107=146=103=11=107=13=102=136-=114=12 0-=102 0 0z"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default SriLankaMap;
