import {
  SECTION_CONTENT_SET_LIST,
  SECTION_CONTENT_SET_SEARCH_QUERY,
  SECTION_CONTENT_SET_PAGINATION,
  SECTION_CONTENT_SET_ITEM,
  SECTION_CONTENT_SET_REQUEST_IN_PROGRESS
} from "../actions/actionsTypes";

const initialState = {
  loading: false,
  list: [],
  searchQuery: '',
  pagination: {
    page: 1,
    perPage: 10,
    items: 0
  },
  item: null
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SECTION_CONTENT_SET_LIST:
      return {
        ...state,
        list: action.payload,
      };
    case SECTION_CONTENT_SET_PAGINATION:
      return {
        ...state,
        pagination: {
          page: action.payload.page,
          perPage: action.payload.perPage,
          items: action.payload.numberOfItems,
        }
      };
    case SECTION_CONTENT_SET_ITEM:
      return {
        ...state,
        item: action.payload
      }
    case SECTION_CONTENT_SET_REQUEST_IN_PROGRESS:
      return {
        ...state,
        loading: action.payload
      }
    case SECTION_CONTENT_SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.payload
      }
    default:
      return state;
  }
};
export default reducer;
