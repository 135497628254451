import {
  FAIL_STUDENT_NEWS,
  INIT_STUDENT_NEWS,
  SUCCESS_STUDENT_NEWS,
  RESET_NEWS,
} from "../actions/actionsTypes";

const initialState = {
  loading: false,
  err: null,
  data: null,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_STUDENT_NEWS:
      return {
        ...state,
        loading: true,
      };
    case SUCCESS_STUDENT_NEWS:
      return {
        ...state,
        loading: false,
        data: action.data,
        err: null,
      };
    case FAIL_STUDENT_NEWS:
      return {
        ...state,
        loading: false,
        err: action.err,
        data: null,
      };
    case RESET_NEWS:
      return {
        ...state,
        loading: false,
        err: null,
        data: null,
      };
    default:
      return state;
  }
};
export default reducer;
