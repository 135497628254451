import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import LogoIco from "../../../../assets/elf-logo-clr.png";
import { Button, ButtonBlue } from "../../Button/Button";

import "./CancelRecurringModal.scss";

function CancelRecurringModal(props) {
  return (
    <Modal show={props.show} onHide={props.handleClose} className="CancelRecurringModal" size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="TitleContent">
            <h5>Educate Lanka</h5>
            <h2>{props.title} </h2>
            <img src={LogoIco} width="50" alt="Logo" />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <Row>
          <Col md={12} className="mt-3  text-center">
            <div className="smallNormal">
            <small>{props.description}</small>
            </div>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col sm={6}>
            <ButtonBlue title="Cancel" onClick={props.handleClose} full />
          </Col>
          <Col sm={6}>
            <Button title="Confirm" onClick={props.handleConfirm} full />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default CancelRecurringModal;
