/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Alert, Button, InputGroup, FormControl } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import Filters from "../../../components/admin/Dashboard/Filters";
import Sponsorships from "../../../components/admin/Dashboard/Sponsorships";
// import {RightSideBarTogglerContext} from "../../../utils/ContextUtil";
import Loading from "../../../components/Reusable/Loading/Loading";
import {
  getAllSponsorships,
  getAllStudents,
  getAllUsers,
} from "../../../store/actions";
import { RightSideBarTogglerContext } from "../../../utils/ContextUtil";
const pageSize = 50;
const attributes = ["id", "firstName", "lastName"];
function Dashboard() {
  const [page, setPage] = useState(0);
  const [toggle, setToggle] = useState(false);
  const [orderBy, setOrderBy] = useState("");
  const [filterBy, setFilterBy] = useState({
    beneficiaryId: "",
    studentId: "",
    status: "",
  });
  const { spList: list, spLoading: loading } = useSelector(
    (state) => state.sponsorships
  );
  const { token, users } = useSelector((state) => state.auth);
  const { data: studentData } = useSelector((state) => state.students);
  const dispatch = useDispatch();

  useEffect(() => {
    const filters = { orderBy, page, filterBy, pageSize };
    dispatch(getAllSponsorships(token, filters));
  }, [dispatch, page, orderBy, filterBy]);

  useEffect(() => {
    if (!users) {
      dispatch(getAllUsers({ pageSize: 2000 }));
    }
  }, [dispatch, users]);

  useEffect(() => {
    if (!studentData) {
      dispatch(getAllStudents(1, { pageSize: 2000 }));
    }
  }, [dispatch, studentData]);

  const handleOrder = (e) => {
    setOrderBy(e.target.value);
  };

  const handleFilter = (e) => {
    setFilterBy(e);
  };

  const handleCurrentPagination = (e) => {
    setPage(e.selected);
  };
  const handleToggle = (e) => {
    setToggle(!toggle);
  };

  let content = null;
  if (loading) {
    content = <Loading />;
  }

  if (!loading) {
    content = <Sponsorships list={list.rows} />;
  }

  return (
    <>
      <div className="HeaderText">
        List of Sponsorships
        <div className="Filters">
          <InputGroup className="ml-3">
            <InputGroup.Prepend>
              <InputGroup.Text> Order by</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl as="select" name="orderBy" onChange={handleOrder}>
              <option value="">Select</option>
              <option value="createdAt-ASC">Created At (ASC)</option>
              <option value="createdAt-DESC">Created At (DESC)</option>
              <option value="dueDate-ASC">Next Due (ASC)</option>
              <option value="dueDate-DESC">Next Due (DESC)</option>
            </FormControl>
          </InputGroup>

          <Button size="md" onClick={handleToggle}>
            <i className="fa fa-filter" aria-hidden="true"></i>
          </Button>
        </div>
      </div>

      {content}
      <ReactPaginate
        pageCount={list.pages}
        onPageChange={handleCurrentPagination}
        containerClassName="paginations"
      />
      <RightSideBarTogglerContext.Provider
        value={{
          toggle,
          onClick: handleToggle,
        }}
      >
        <Filters handleFilter={handleFilter} />
      </RightSideBarTogglerContext.Provider>
      {list.length === 0 && <Alert variant="warning">No results</Alert>}
    </>
  );
}

export default Dashboard;
