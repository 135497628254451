import moment from "moment";
import { useLocation } from "react-router-dom";

export const filters = (filter) => {
  let filters = {
    AcademicLevel: null,
    Age: null,
    Gender: null,
    Location: null,
  };

  if (filter) {
    if (filter.Age && filter.Age.value !== "All") {
      const ages = filter.Age.value.split("-");

      if (ages[1] === "above") {
        ages[1] = 100;
      }

      var startDate = moment()
        .add(-ages[0], "years")
        .format("YYYY MM DD")
        .split(" ")
        .join("-");
      var endDate = moment()
        .add(-ages[1], "years")
        .format("YYYY MM DD")
        .split(" ")
        .join("-");

      filters = { ...filters, Age: [startDate, endDate] };
    }
    if (filter.Gender && filter.Gender.value !== "All") {
      filters = { ...filters, Gender: filter.Gender.value };
    }
    if (filter.Location && filter.Location.value !== "All") {
      filters = { ...filters, Location: filter.Location.value };
    }
  }

  return filters;
};
export const age = (dob) => {
  if (dob) {
    const birthday = moment(dob);
    const newDate = moment(new Date());
    return `${newDate.diff(birthday, "year")} years  old`;
  }
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

export const groupBy=(arr,key)=>{
  return arr.reduce((pv,cv)=>{
    pv[cv[key]]=(pv[cv[key]]||[])
    pv[cv[key]].push(cv)
    return pv
  },{})
}

export const  syntaxHighlight=(json)=> {
  json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
  return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
      var cls = 'number';
      if (/^"/.test(match)) {
          if (/:$/.test(match)) {
              cls = 'key';
          } else {
              cls = 'string';
          }
      } else if (/true|false/.test(match)) {
          cls = 'boolean';
      } else if (/null/.test(match)) {
          cls = 'null';
      }
      return '<span class="' + cls + '">' + match + '</span>';
  });
}

export function daysUntilBirthday(birthdayString) {
  // Current date
  var currentDate = new Date();

  // Birthday date
  var birthday = new Date(birthdayString);

  // Set the year of the birthday to the current year
  birthday.setFullYear(currentDate.getFullYear());

  // If the birthday has already occurred this year, set it to next year
  if (birthday < currentDate) {
    birthday.setFullYear(currentDate.getFullYear() + 1);
  }

  // Calculate the difference in days
  var differenceInTime = birthday.getTime() - currentDate.getTime();
  var differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

  return differenceInDays;
}