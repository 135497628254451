import BasicTemplate from "./BasicTemplate";
import TemplateOne from "./TemplateOne";
import TemplateTwo from "./TemplateTwo";
import TemplateThree from "./TemplateThree";
import TemplateFore from "./TemplateFore";
import TemplateFive from "./TemplateFive";
import TemplateSix from "./TemplateSix";
import TemplateSeven from "./TemplateSeven";
import TemplateEight from "./TemplateEight";
import TemplateNine from "./TemplateNine";
import TemplateTen from "./TemplateTen";
import TemplateEleven from "./TemplateEleven";
import TemplateTwelve from "./TemplateTwelve";
import TemplateThirteen from "./TemplateThirteen";
import TemplateFourteen from "./TemplateFourteen";
import TemplateFifteen from "./TemplateFifteen";
import TemplateSixteen from "./TemplateSixteen";
import TemplateSeventeen from "./TemplateSeventeen";
import TemplateHeader from "./TemplateHeader";
import TemplateEighteen from "./TemplateEighteen";

import React from "react";
import "./PageSections.scss";

function PageSections({ pageSections }) {
  const content = pageSections?.map((sec, i) => {
    switch (sec.section.key) {
      case "basic":
        return <BasicTemplate key={i} data={sec.data} />;
      case "template-1":
        return <TemplateOne key={i} data={sec.data} />;
      case "template-2":
        return <TemplateTwo key={i} data={sec.data} />;
      case "template-3":
        return <TemplateThree key={i} data={sec.data} />;
      case "template-4":
        return <TemplateFore key={i} data={sec.data} />;
      case "template-5":
        return <TemplateFive key={i} data={sec.data} />;
      case "template-6":
        return <TemplateSix key={i} data={sec.data} />;
      case "template-7":
        return <TemplateSeven key={i} data={sec.data} />;
      case "template-8":
        return <TemplateEight key={i} data={sec.data} />;
      case "template-9":
        return <TemplateNine key={i} data={sec.data} />;
      case "template-10":
        return <TemplateTen key={i} data={sec.data} />;
      case "template-11":
        return <TemplateEleven key={i} data={sec.data} />;
      case "template-12":
        return <TemplateTwelve key={i} data={sec.data} />;
      case "template-13":
        return <TemplateThirteen key={i} data={sec.data} />;
      case "template-14":
        return <TemplateFourteen key={i} data={sec.data} />;
      case "template-15":
        return <TemplateFifteen key={i} data={sec.data} />;
      case "template-16":
        return <TemplateSixteen key={i} data={sec.data} />;
      case "template-17":
        return <TemplateSeventeen key={i} data={sec.data} />;
      case "template-18":
          return <TemplateEighteen key={i} data={sec.data} />;
      case "header":
        return <TemplateHeader key={i} data={sec.data} />;
      default:
        return null;
    }
  });

  return <div className="PageSections">{content}</div>;
}

export default PageSections;
