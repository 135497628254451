import React from "react";
import { Modal, Row, Col } from "react-bootstrap";

import LogoIco from "../../../../assets/elf-logo-clr.png";
import { Button } from "../../Button/Button";

import "./DocList.scss";

function DocList(props) {

  const typeApplication = props?.list.some(item =>
    item.Type__c === "Doc_Application" || item.Type__c === "Doc_Other"
  );

  const typeFeedBack = props?.list.some(item => 
    item.Type__c === "Doc_Feedback" || item.Type__c === "Doc_Receipts"
  );

  const modalTittle = () => {
    if(typeApplication) {
      return(
        <>
          <h2>Student Application Documentation</h2>
        </>
      );
    } else if(typeFeedBack) {
      return(
        <>
          <h2>Student Feedback Documentation</h2>
        </>
      );
    }
  }

  return (
    <Modal show={props.list} onHide={props.handleClose} className="DocList">
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="TitleContent">
            <h5>Educate Lanka</h5>
            {modalTittle()}
            <img src={LogoIco} width="50" alt="Logo" />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12} className="mt-3  ">
            <ul>
              {props.list.length > 0 &&
                props.list.map((e) => (
                  <li>
                    <a
                      href={e.File_Link__c}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {e.Name}
                    </a>
                  </li>
                ))}
            </ul>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col sm={12}>
            <Button title="Cancel" onClick={props.handleClose} full />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default DocList;
