/*eslint-disable*/
import React from "react";
import { useSelector } from "react-redux";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import "./StudentNewsList.scss";

import Loading from "../../Reusable/Loading/Loading";

function StudentNewsList() {
  const news = useSelector((state) => state.news);
  const ps = new PerfectScrollbar("#container", {
    wheelSpeed: 2,
    wheelPropagation: true,
    minScrollbarLength: 20,
  });
  return (
    <div className="StudentNewsList">
      <PerfectScrollbar>
        {news.loading ? (
          <div className="ml-5">
            <Loading button />
          </div>
        ) : (
          news?.data?.length === 0 && (
            <div className="ml-5">No news at this time!</div>
          )
        )}
        {news?.data?.map((n) => {
          return (
            <div className="newsItem">
              <div className="dateTag">
                <span>{moment(n.Date__c).format("MMM")}</span>
                <h4>{moment(n.Date__c).format("DD")}</h4>
                <span>{moment(n.Date__c).format("YYYY")}</span>
              </div>
              <h2>{n.News_Header__c}</h2>
              <div className="newsItemContent">
                {n.Image__c && (
                  <div className="img">
                    <img src={n.Image__c} alt={n.News_Header__c} />
                  </div>
                )}{" "}
                {n.video_iframe__c ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: n.video_iframe__c }}
                  ></div>
                ) : (
                  n.video_code__c && (
                    <iframe
                      width="560"
                      height="315"
                      src={`https://www.youtube.com/embed/${n.video_code__c}`}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                      style={{ maxWidth: "100%" }}
                    ></iframe>
                  )
                )}
                <div
                  className="newsBody"
                  dangerouslySetInnerHTML={{ __html: n.News_Body__c }}
                ></div>
              </div>
            </div>
          );
        })}
      </PerfectScrollbar>
    </div>
  );
}

export default StudentNewsList;
