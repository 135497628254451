import React from "react";
import PaymentTag from "./PaymentTag";

function PaymentHistory(props) {
  const { sponsorship } = props;
  const { payments } = sponsorship;
  return (
    <div className="History">
      <div className="Table">
        <table>
          <thead>
            <tr>
              <th>Payment Method</th>
              <th>Amount</th>
              <th>Tip</th>
              <th>Current Plan (Months)</th>
              <th>Recurring</th>
              <th>Due Date</th>
              <th>Pay Date</th>
              <th>Note</th>
            </tr>
          </thead>

          <tbody>
            {payments.map((payment) => (
              <PaymentTag key={payment.id} payment={payment} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PaymentHistory;
