import React, { useState, useEffect } from "react";
import _ from "lodash";
import Logo from "../../../../assets/user.png";
import { Link } from "react-router-dom";

import "./Student.scss";
import { age } from "../../../../utils/func";

function Student(props) {
  const { sponsorship } = props;
  const { beneficiary } = sponsorship;

  return (
    <div className="Student">
      <img src={beneficiary.image ? beneficiary.image : Logo} alt="Logo" />
      <h1>$ {parseInt(sponsorship?.amount)?.toLocaleString()}</h1>
      <h2>{beneficiary.firstName} {beneficiary.lastName}</h2>
      <p>
        <strong>{age(beneficiary.birthday)}</strong>
        <strong> {beneficiary.district}</strong>
      </p>
      <div className="SponsoredBy">
        <Link to={`/student/${beneficiary.id}`}>SHOW</Link>
        {/* <div>
          <h1>Sponsored by</h1>
          <Link to="">
            <img src={Photo} alt="Person" /> J.M. Thanagaraja
          </Link>
        </div>
        <div>
          <i className="fa fa-check-circle tick"></i>
        </div> */}
      </div>
    </div>
  );
}

export default Student;
