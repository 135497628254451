import React, { useEffect } from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import { Controller } from "react-hook-form";
import DatePicker from "react-multi-date-picker";
import { useSelector } from "react-redux";

const CreateEvent = ({
  handleOpenFilter,
  editMode,
  control,
  errors,
  getValues,
}) => {
  const { onBoardedStudents } = useSelector((state) => state.distributionEvent);

  if (onBoardedStudents.length) {
    console.log();
  }
  return (
    <>
      <div className="WhiteContent">
        <Row>
          <Col md={12}>
            <FormGroup>
              <FormLabel>Name</FormLabel>
              <Controller
                control={control}
                render={({ field }) => (
                  <FormControl {...field} isInvalid={errors?.name?.message} />
                )}
                name="name"
              />

              <Form.Control.Feedback type="invalid">
                {errors?.name?.message}
              </Form.Control.Feedback>
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <FormLabel>Date</FormLabel>
              <Controller
                control={control}
                name="dateOfDistribution"
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    value={value}
                    onChange={(date) => {
                      onChange(date?.isValid ? date : "");
                    }}
                    format={"YYYY-MM-DD"}
                    containerClassName={`form-control position-relative ${
                      errors?.dateOfDistribution?.message ? "is-invalid" : ""
                    }`}
                  />
                )}
              />

              <div className="invalid-feedback">
                {errors?.dateOfDistribution?.message}
              </div>
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <FormLabel>Exchange Rate ($ to LKR)</FormLabel>
              <Controller
                control={control}
                render={({ field }) => (
                  <FormControl
                    {...field}
                    type="number"
                    isInvalid={errors?.exchangeRate?.message}
                  />
                )}
                name="exchangeRate"
              />

              <Form.Control.Feedback type="invalid">
                {errors?.exchangeRate?.message}
              </Form.Control.Feedback>
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <FormLabel>Number of Months</FormLabel>
              <Controller
                control={control}
                render={({ field }) => (
                  <FormControl
                    {...field}
                    type="number"
                    isInvalid={errors?.numberOfMonths?.message}
                  />
                )}
                name="numberOfMonths"
              />

              <Form.Control.Feedback type="invalid">
                {errors?.numberOfMonths?.message}
              </Form.Control.Feedback>
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <FormLabel>Location</FormLabel>
              <Controller
                control={control}
                render={({ field }) => (
                  <FormControl
                    {...field}
                    isInvalid={errors?.location?.message}
                  />
                )}
                name="location"
              />
              <Form.Control.Feedback type="invalid">
                {errors?.location?.message}
              </Form.Control.Feedback>
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <FormLabel>LO Expenses LKR</FormLabel>
              <Controller
                control={control}
                render={({ field }) => (
                  <FormControl
                    {...field}
                    type="number"
                    isInvalid={errors?.loExpenses?.message}
                  />
                )}
                name="loExpenses"
              />
              <Form.Control.Feedback type="invalid">
                {errors?.loExpenses?.message}
              </Form.Control.Feedback>
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <FormLabel>Status</FormLabel>
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => {
                  console.log(value);
                  return (
                    <select
                      className="form-control"
                      value={value}
                      onChange={(e) => onChange(e)}
                    >
                      <option value="CREATED">Initiated</option>
                      <option value="PENDING">In-Process</option>
                      <option value="COMPLETED">Completed</option>
                    </select>
                  );
                }}
                name="status"
              />
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup>
              <FormLabel>Comment</FormLabel>
              <Controller
                control={control}
                render={({ field }) => <FormControl as="textarea" {...field} />}
                name="comments"
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      {editMode ? (
        <div className="WhiteContent mt-3">
          <Row className="text-nowrap align-items-center">
            <Col className="flex-grow-0">
              Total Number of Students :{" "}
              <strong>{onBoardedStudents.length}</strong>
            </Col>
            <Col className="flex-grow-0">
              Total Amount LKR:{" "}
              <strong>
                {onBoardedStudents.length
                  ? onBoardedStudents
                      ?.map((val) => val.totalAmountLKR)
                      .reduce((a, b) => {
                        return a + +b;
                      }, 0)
                  : 0}
              </strong>
            </Col>
            <Col className="flex-grow-0">
              Total Amount USD:{" "}
              <strong>
                {onBoardedStudents.length
                  ? onBoardedStudents
                      ?.map((val) => val.totalAmountUSD)
                      .reduce((a, b) => {
                        return a + +b;
                      }, 0)
                  : 0}
              </strong>
            </Col>
            <Col className="flex-grow-0 ml-auto">
              <Button onClick={handleOpenFilter} variant="dark">
                Manage Students
              </Button>
            </Col>
          </Row>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default CreateEvent;
