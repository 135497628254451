/*eslint-disable*/
import React, { useState, useEffect } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import LogoIco from "../../../../assets/elf-logo-clr.png";
import { Button as ButtonSubmit } from "../../Button/Button";
import SocialButton from "../../SocialButton/SocialButton";

import {
  loginUser,
  loginFbUser,
  loginGoogleUser,
} from "../../../../store/actions";
import "./SignInModal.scss";
import SharedModal from "../SharedModal/SharedModal";

const formObj = { email: "", password: "" };

function SignInModal(props) {
  const [validator, setValidator] = useState(
    new SimpleReactValidator({
      autoForceUpdate: this,
      messages: { email: "That is not an email." },
    })
  );
  const [validationLoad, setValidationLoad] = useState(false);
  const [formData, setFormData] = useState(formObj);

  useEffect(() => {
    setFormData(formObj); //Reset form in mount
    return () => validator.hideMessages(); //Hide validation in unmount
  }, []);

  const handleOnchange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    if (!validator.allValid()) {
      validator.showMessageFor(e.target.name);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      props.onUserLogin(formData); //Send data to API
      setValidationLoad(true); //This function use only for the trigget first time validation. Otherwise it not work in initial submit
    } else {
      setValidationLoad(true); //This function use only for the trigget first time validation. Otherwise it not work in initial submit
      validator.showMessages();
    }
  };

  return (
    <SharedModal title={"Sign in"} show={props.show} onHide={props.handleClose} >
      <form onSubmit={handleSubmit}>
        <div className="inputContainer icon">
          {validator.message(
            "email",
            formData && formData.email,
            "required|email"
          )}
          <input
            type="text"
            placeholder="Email"
            name="email"
            onChange={handleOnchange}
          />
          <i className="fa fa-envelope"></i>
        </div>
        <div className="inputContainer icon">
          {validator.message(
            "password",
            formData && formData.password,
            "required"
          )}
          <input
            type="password"
            placeholder="Password"
            name="password"
            onChange={handleOnchange}
          />
          <i className="fa fa-lock"></i>
        </div>
        <ButtonSubmit
          title="Sign in"
          full
          disabled={validator.allValid() ? false : true}
        />
      </form>
      <br />
      <SocialButton
        loginGoogleUser={props.loginGoogleUser}
        loginFbUser={props.loginFbUser}
      />
      <Modal.Footer className="px-0 pt-2 pb-0">
        <div className="forgotSign">
          <div>
            Forgot{" "}
            <button
              className="btn btn-link link pl-1"
              onClick={props.handlePasswordModal}
            >
              password?
            </button>
          </div>
          <div>
            Not registered?
            <button
              className="btn btn-link link pl-1"
              onClick={props.handleSighnUpModal}
            >
              Sign up
            </button>
          </div>
        </div>
      </Modal.Footer>
    </SharedModal>

  );
}

const mapStateToProps = (state) => ({
  Loading: state.auth.loading,
  logErr: state.auth.logErr,
});
const mapDispatchToProp = (dispatch) => ({
  onUserLogin: (userData) => dispatch(loginUser(userData)),
  loginGoogleUser: (data) => dispatch(loginGoogleUser(data)),
  loginFbUser: (data) => dispatch(loginFbUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProp)(SignInModal);
