/*eslint-disable*/
import React, { useEffect } from "react";
import { connect } from "react-redux";
import Profile from "../../components/SponsorPublic/Profile/Profile";

import SponsoredStudents from "../../components/SponsorPublic/SponsoredStudents/SponsoredStudents";
import * as actionCreators from "../../store/actions";
import "./SponsorPublic.scss";
import Loading from "../../components/Reusable/Loading/Loading";

function Sponsor(props) {
  useEffect(() => {
    props.getOneSponsor(props?.match?.params?.id);
  }, []);

  let content = null;
  if (props.sponsor.loading) {
    content = <Loading page />;
  }
  if (props.sponsor.error) {
    content = <h1>Sponsor not found</h1>;
  }
  if (props.sponsor.data) {
    content = (
      <>
        <Profile sponsor={props.sponsor.data} />
        <SponsoredStudents sponsor={props.sponsor.data} />
      </>
    );
  }

  return <div className="SponsorPublic">{content}</div>;
}
const mapStateToProps = (state) => ({
  sponsor: state.sponsor,
});

const mapDispatchToProps = (dispatch) => ({
  getOneSponsor: (id) => dispatch(actionCreators.getOneSponsor(id)),
  // getSponsorships: acId => dispatch(actionCreators.getSponsorships(acId))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sponsor);
