import React from "react";

function TemplateFive({ data }) {
  return (
    <div id={data?.title?.trim().split(" ").join("").toLowerCase()} className="BlueImpactArea">
      <div className="row">
        <div className="col-lg-3">
          <div className="handrend">{data.largeText}</div>
        </div>
        <div className="col-lg-9">
          <div className="row">
            <div className="col-lg-3">
              <h2>{data.grid1.title}</h2>
              <div dangerouslySetInnerHTML={{ __html: data.grid1.text1 }}></div>
            </div>
            <div className="col-lg-3">
              <h2>{data.grid2.title}</h2>
              <div dangerouslySetInnerHTML={{ __html: data.grid2.text1 }}></div>
            </div>
            <div className="col-lg-3">
              <h2>{data.grid3.title}</h2>
              <div dangerouslySetInnerHTML={{ __html: data.grid3.text1 }}></div>
            </div>
            <div className="col-lg-3">
              <h2>{data.grid4.title}</h2>
              <div dangerouslySetInnerHTML={{ __html: data.grid4.text1 }}></div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div dangerouslySetInnerHTML={{ __html: data.text1 }}></div>
        </div>
      </div>
    </div>
  );
}

export default TemplateFive;
