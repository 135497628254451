/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import { connect, useSelector } from "react-redux";

import LogoIco from "../../../../assets/elf-logo-clr.png";
import { Button } from "../../Button/Button";
import "./GiftcardPay.scss";
import * as actionCreators from "../../../../store/actions";
import Loading from "../../Loading/Loading";
const dataSet = {
  payment: null,
  beneficiaryId: null,
  type: null,
  month: null,
  amount: null,
  cardId: null,
  extra: 0,
};

const GiftcardPay = ({ ...props }) => {
  const [validator, setValidator] = useState(
    new SimpleReactValidator({
      autoForceUpdate: this,
    })
  );
  const [giftcard, setGiftcard] = useState("");
  const [valid, setValid] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [form, setForm] = useState(dataSet);

  const { data: gcData } = useSelector((state) => state.giftcard);
  const { token } = useSelector((state) => state.auth);

  const payment = gcData?.data;
  const amount = props.amount + props.tip;
  const month = parseInt(payment?.amount / amount);
  const tipCal = props.tip + (payment?.amount % amount) / month; //Here will add tip + extra
  const tip = +tipCal.toFixed(2);

  const paymentAmount = payment?.amount;
  const propsAmount = props.amount;
  const result = paymentAmount / propsAmount;

  // Function to format the result
  const formatResult = (num) => {
    return Number.isInteger(num) ? num : num.toFixed(1);
  };

  useEffect(() => {
    return () => props.resetGiftcard();
  }, []);

  useEffect(() => {
    if (gcData) {
      setForm({
        ...form,
        payment: payment,
        amount: props.month * props.amount,
        beneficiaryId: props.studentId,
        type: "student",
        month: month,
        cardId: payment.id,
        tip: props.tip,
      });
    }
  }, [gcData]);

  const handleOnchangeValidate = (e) => {
    setGiftcard(e.target.value);
    if (e.target.value.length === 36) {
      setInvalid(false);
      setValid(true);
      props.getGiftcard(token, e.target.value);
    } else {
      setInvalid(true);
      setValid(false);
    }
  };
  const handlePayByGiftcard = (month) => {
    if (token) {
      props.payGiftcard({ ...form, month: month }, token);
    } else {
      alert("Please login to the system");
    }
  };

  let donateContent = null;

  if (gcData?.data?.status === "donated") {
    donateContent = <div className="donated">This card already donated.</div>;
  }
  if (
    gcData?.data?.status &&
    gcData?.data?.status !== "donated" &&
    props.month
  ) {
    donateContent = (
      <>
        <div className="toStudent">
          You’ve chosen to pay <span>${props.month * props.amount}</span> for{" "}
          <span>
            {props.month === 1
              ? props.month + " month"
              : props.month + " months"}
          </span>{" "}
          of this student's education.
        </div>
        <div className="toStudent">
          Tip for Educate Lanka Fund: <span>${props.tip.toFixed(2)} </span>
        </div>
        <div className="toStudent">
          TOTAL AMOUNT: <span>${props.month * props.amount + props.tip} </span>
        </div>
        {props.month * props.amount + props.tip <= gcData?.data?.amount ? (
          <>
            <div className="giftCardBalanceCard">
              GIFT CARD LEFTOVER BALANCE:{" "}
              <span>
                $
                {(
                  gcData?.data?.amount -
                  (props.month * props.amount + props.tip)
                ).toFixed(2)}
              </span>
            </div>
            <div className="center">
              <Button
                title="Pay via Gift Card"
                onClick={() => handlePayByGiftcard(props.month)}
              />
            </div>
          </>
        ) : gcData?.data?.amount < 10 ? (
          <div className="giftCardBalanceNotSufficient">
            The available Gift Card Amount is lower than the minimum payment
            required for a student’s monthly sponsorship.
            <br />
            <br />
            Therefore, we would consider this leftover balance a donation to the{" "}
            <span
              style={{ cursor: "pointer" }}
              onClick={() => window.open("/educate-lanka-fund", "_blank")}
            >
              Educate Lanka Fund
            </span>
            . If you would prefer it to be processed differently, please reach
            out to us at{" "}
            <span
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                textTransform: "lowercase",
              }}
            >
              <a
                href="mailto:info@educatelanka.org"
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                info@educatelanka.org
              </a>
            </span>
            . Thank you.
          </div>
        ) : props.amount > gcData?.data?.amount ? (
          <div className="giftCardBalanceNotSufficient">
            The available Gift Card Amount is not sufficient to cover the
            minimum payment for this student.
            <br />
            <br />
            You could either choose another student with a lower monthly amount
            or consider the leftover balance a donation to the{" "}
            <span
              style={{ cursor: "pointer" }}
              onClick={() => window.open("/educate-lanka-fund", "_blank")}
            >
              Educate Lanka Fund
            </span>
            . Please reach out to{" "}
            <span
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                textTransform: "lowercase",
              }}
            >
              <a
                href="mailto:info@educatelanka.org"
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                info@educatelanka.org
              </a>
            </span>{" "}
            if any questions. Thank you.
          </div>
        ) : (
          <div className="giftCardBalanceNotSufficient">
            The available Gift Card Amount is not sufficient to cover the
            selected payment.
            <br />
            <br />
            Please change the payment in the previous screen to an amount lower
            than the Gift Card balance. Thank you.
          </div>
        )}
      </>
    );
  }

  return (
    <Modal show={props.show} onHide={props.onHide} className="GiftcardPay">
      {props.payment.loading && <Loading />}
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="TitleContent">
            <h5>Educate Lanka</h5>
            {/* <h2>Student Sponsorship</h2>
            <small>100% funds scholarship</small> */}
            <h3>Student Sponsorship Payment</h3>
            <small>
              Investing in their future, one deserving student at a time.
            </small>
            <img src={LogoIco} width="50" alt="logo" />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <div className="inputContainer icon">
              {invalid && (
                <div className="srv-validation-message">
                  The giftcard number must be 36 characters.
                </div>
              )}
              {valid ? (
                props.giftcard.loading ? (
                  <div className="loading">
                    <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
                  </div>
                ) : gcData ? (
                  gcData?.data?.status === "donated" ? (
                    <div className="info">
                      <i className="fa fa-info-circle" aria-hidden="true"></i>
                    </div>
                  ) : (
                    <div className="success">
                      <i className="fa fa-check-circle" aria-hidden="true"></i>
                    </div>
                  )
                ) : (
                  <div className="fail">
                    <i className="fa fa-times-circle" aria-hidden="true"></i>
                  </div>
                )
              ) : null}
              <input
                type="text"
                name="giftcard"
                value={giftcard}
                disabled={
                  props.giftcard.loading ||
                  (gcData && gcData?.data?.status === "created")
                }
                placeholder="Enter Gift Card number"
                onChange={handleOnchangeValidate}
              />
            </div>
            <div>
              <div className="cardAmount">
                {gcData && (
                  <div className="giftcardDetail">
                    GIFT CARD AVAILABLE AMOUNT:{" "}
                    <span>${gcData?.data?.amount}</span>
                  </div>
                )}
                {/* <div className="need">
                  STUDENT'S MONTHLY SPONSORSHIP AMOUNT:{" "}
                  <span>${props.amount} </span>
                </div>{" "} */}
                {/* <h3>
                  <span>${props.month * props.amount}</span> for{" "}
                  {props.month === 12
                    ? recurring
                      ? " yearly"
                      : " 1 year"
                    : props.month === 1
                    ? props.month + " month"
                    : props.month + " months"}
                </h3> */}
                {/* <div className="need">
                  Monthly Tip = <span>${tip ? tip : 0} </span>
                </div> */}
                <div>{donateContent}</div>
              </div>
            </div>
          </Col>
        </Row>

        <br />
        <br />
      </Modal.Body>
    </Modal>
  );
};

//REDUX STORE MAP

const mapStateToProps = (state) => ({
  auth: state.auth,
  payment: state.payment,
  giftcard: state.giftcard,
});

const mapDispatchToProps = (dispatch) => ({
  payGiftcard: (data, token) =>
    dispatch(actionCreators.payGiftcard(data, token)),
  getGiftcard: (token, id) => dispatch(actionCreators.getGiftcard(token, id)),
  resetGiftcard: () => dispatch(actionCreators.resetGiftcard()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GiftcardPay);
