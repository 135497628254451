import React from "react";
import { Row, Col } from "react-bootstrap";
function TemplateEight({ data }) {
  return (
    <section id={data?.title?.trim().split(" ").join("").toLowerCase()}>
      <div className="inContent">
        <div className="text-heading-section">
          <div className="section-header text-center mt-5 mb-5">{data?.title}</div>
          <Row>
            {data?.tags?.map((tag, i) => (
              <Col sm={6} key={i} className="mb-5">
                <div className="imageRoundWithTitle verticle">
                  <div
                    className="img"
                    style={{
                      backgroundImage: `url(${tag?.image})`,
                    }}
                  ></div>
                  <div>
                    <h3>{tag?.title}.</h3>
                    <p>{tag?.text}</p>
                    <a href={tag?.url}>{tag?.linkText}</a>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </section>
  );
}

export default TemplateEight;
