import * as actions from "../actions/actionsTypes";

const initialState = {
  loading: false,
  data: [],
  lastMessage: null,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.INIT_CHAT_SENT:
      return {
        ...state,
        loading: true,
      };
    case actions.SUCCESS_CHAT_SENT:
      let messages = null;
      if (action.data?.admin) {
        const ls = state.data;
        const index = state.data.findIndex(
          (c) => c.sponsorshipId === +action.data.sponsorshipId
        );
        ls[index].list.push(action.data);
        messages = ls;
      } else {
        messages = state.data.concat(action.data);
      }
      return {
        ...state,
        loading: false,
        data: messages,
        lastMessage: action.data,
        error: null,
      };
    case actions.FAIL_CHAT_SENT:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case actions.INIT_CHAT_UPDATE:
      return {
        ...state,
        loading: true,
      };
    case actions.SUCCESS_CHAT_UPDATE:
      return {
        ...state,
        loading: false,
        data: state.data.map((m) => {
          if (action.data?.sponsorshipId === m.sponsorshipId) {
            m.readAt = action.data.readAt;
          }
          return m;
        }),
        lastMessage: action.data,
        error: null,
      };
    case actions.FAIL_CHAT_UPDATE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case actions.INIT_CHAT_GET:
      return {
        ...state,
        loading: true,
        data: [],
        error: null,
      };
    case actions.SUCCESS_CHAT_GET:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: null,
      };
    case actions.FAIL_CHAT_GET:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};
export default reducer;
