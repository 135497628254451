import * as actions from "../actions/actionsTypes";

const initialState = {
  loading: false,
  err: null,
  concat: false,
  dataLimit: null,
  student: null,
  stLoading: false,
  data: null
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.INIT_STUDENTS:
      return {
        ...state,
        loading: true,
        err: null
      };
    case actions.SUCCESS_STUDENTS:
      return {
        ...state,
        loading: false,
        data: action.data,
        err: null
      };
    case actions.SUCCESS_STUDENTS_LIMIT:
      return {
        ...state,
        loading: false,
        dataLimit: action.data,
        err: null
      };

    case actions.FAIL_STUDENTS:
      return {
        ...state,
        loading: false,
        data: [],
        err: action.err
      };

    case actions.INIT_ONE_STUDENT:
      return {
        ...state,
        stLoading: true,
        student: null,
        err: null
      };
    case actions.SUCCESS_ONE_STUDENT:
      return {
        ...state,
        stLoading: false,
        student: action.data,
        err: null
      };

    case actions.FAIL_ONE_STUDENT:
      return {
        ...state,
        stLoading: false,
        student: null,
        err: action.err
      };
    default:
      return state;
  }
};
export default reducer;
