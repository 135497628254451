import {
  FAIL_SYNC_STUDENTS,
  INIT_SYNC_STUDENTS,
  SUCCESS_SYNC_STUDENTS,
} from "../actions/actionsTypes";

const initialState = {
  loading: false,
  students: null,
  error: null,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_SYNC_STUDENTS:
      return {
        ...state,
        loading: true,
        error: false,
        students: null,
      };

    case SUCCESS_SYNC_STUDENTS:
      return {
        ...state,
        loading: false,
        students: action.data,
      };

    case FAIL_SYNC_STUDENTS:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};
export default reducer;
