import React from "react";
import { Button } from "react-bootstrap";

function DonateToFund(props) {
  const hasFundSponsorship = props.list?.find((sp) => sp.beneficiaryId === 1);
  const formSubmit = (e) => {
    props.handleCreateSponsorship(1, "elf");
  };

  let content = null;
  if (hasFundSponsorship?.status === "canceled" || !hasFundSponsorship) {
    content = (
      <div className="OfflinePay">
        <div className="formIn">
          <h4>Sponse to Educate Lanka Fund </h4>
          <Button
            variant="success"
            className="ml-3 mr-3"
            type="submit"
            onClick={formSubmit}
          >
            {hasFundSponsorship?.status === "canceled"
              ? "Update sponsorship"
              : "Create Sponsorship"}
          </Button>
        </div>
      </div>
    );
  }
  return content;
}

export default DonateToFund;
