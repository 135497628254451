import {
  FORGET_PASSWORD_MODAL_VISIBILITY,
  LOGIN_MODAL_VISIBILITY,
  SIGNUP_MODAL_VISIBILITY,
} from "../actions/actionsTypes";

const initialState = {
  isLoginModalVisable: false,
  isSignupModalVisable: false,
  isForgetPasswordModal: false,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_MODAL_VISIBILITY:
      return {
        ...state,
        isSignupModalVisable: false,
        isLoginModalVisable: action.value,
      };
    case SIGNUP_MODAL_VISIBILITY:
      return {
        ...state,
        isLoginModalVisable: false,
        isSignupModalVisable: action.value,
      };
    case FORGET_PASSWORD_MODAL_VISIBILITY:
      return {
        ...state,
        isLoginModalVisable: false,
        isSignupModalVisable: false,
        isForgetPasswordModal: action.value,
      };
    default:
      return state;
  }
};
export default reducer;
