import React from "react";
import { Link } from "react-router-dom";

import Item from "./Item/Item";
import "./TopSlider.scss";
import { LinkButtonLargeText } from "../../Reusable/Button/Button";

function TopSlider({
  textBottom,
  linkUrl,
  linkText,
  slides,
  btnTitle,
  btnLink,
}) {
  return (
    <div className="TopSlider">
      <div className="Slider mb-5">
        <Item slides={slides} />
      </div>
      {btnTitle || btnLink ? (
        <LinkButtonLargeText title={btnTitle} to={btnLink}/>
      ) : undefined}
      <div className="Detail">
        <p>
          <small>{textBottom}</small>
        </p>
        <p>or</p>
        <h5>
          <strong>
            <Link to={linkUrl}>{linkText}</Link>
          </strong>
        </h5>
      </div>
    </div>
  );
}

export default TopSlider;
