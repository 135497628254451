/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./GitCardHeader.scss";

import Background from "../../../assets/bg-poolfund-top-banner.png";
import { Button } from "../../Reusable/Button/Button";
import QuickPay from "../../Reusable/Modal/QuickPay/QuickPay";
import SimpleReactValidator from "simple-react-validator";
import { useSelector, useDispatch } from "react-redux";
import { loginModalHandle } from "../../../store/actions";
import ThankYouModal from "../../Reusable/Modal/ThnakYouModal/ThankYouModal";

function FundHeader({ auth, section }) {
  const [validator, setValidator] = useState(
    new SimpleReactValidator({
      autoForceUpdate: this,
      messages: { email: "That is not an email." },
    })
  );

  const [amount, setAmount] = useState('');
  const [show, setShow] = useState(false);
  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const [tempConfirmAlert, setTempConfirmAlert] = useState(false);

  // const [selected, setSelected] = useState(null);
  const dispatch = useDispatch();
  const payment = useSelector((state) => state.payment);
  useEffect(() => {
    if (payment.data) {
      if (payment.data.message == "Giftcard created successfully") {
        setShowThankYouModal(true);
      }
      setShow(false);
    }
  }, [payment]);

  useEffect(() => {
    if (auth.token && amount && amount > 0 && tempConfirmAlert) {
      setTempConfirmAlert(false);
      setShow(true);
    }
  }, [auth.token, amount, , tempConfirmAlert]);

  const handleChangeText = (e) => {
    const inputValue = e.target.value;

    // Remove commas from the input value
    const cleanedValue = inputValue.replace(/,/g, "");

    // Check if the cleaned input is a valid number or empty string
    if (!isNaN(cleanedValue) || cleanedValue === "" || cleanedValue === ".") {
      setAmount(cleanedValue);

      // Show validation message if needed
      if (!validator.allValid()) {
        validator.showMessageFor(e.target.name);
      }
    }
  };

  const handleModal = () => {
    if (amount >= 0 && validator.allValid()) setShow(!show);
    // if (auth.token) {

    // } else {
    //   setTempConfirmAlert(true);
    //   dispatch(loginModalHandle(true));
    // }
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "#000",
    }),
  };

  return (
    <div
      className="GitCardHeader"
      style={{ backgroundImage: `url(${section?.data?.image})` }}
    >
      <div className="GiftBox">
        <div dangerouslySetInnerHTML={{ __html: section?.data?.text1 }}></div>
        <div className="inputContainer withGroup">
          <div className="TwoTag">
            {validator.message("amount", amount, "currency")}
            <input
              type="text"
              value={amount}
              name="amount"
              onChange={handleChangeText}
            />
            <span>$</span>
            <span>USD</span>
          </div>

          <Button
            title={section?.data?.buttonText}
            onClick={handleModal}
            disabled={amount <= 0 || !validator.allValid()}
          />
        </div>

        <div dangerouslySetInnerHTML={{ __html: section?.data?.text2 }}></div>
        {show && (
          <QuickPay
            giftcard={true}
            amount={amount}
            show={show}
            onHide={handleModal}
          />
        )}
        <ThankYouModal
          show={showThankYouModal}
          onHide={() => setShowThankYouModal(false)}
        />
      </div>
    </div>
  );
}

export default FundHeader;
