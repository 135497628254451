import * as actions from "../actions/actionsTypes";

const initialState = {
  loading: false,
  data: null,
  list: null,
  error: null,
  progress:null
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.INIT_MEDIA:
      return {
        ...state,
        loading: true,
      };
    case actions.FAIL_MEDIA:
      return {
        ...state,
        loading: false,
        list: null,
        data: null,
        error: action.error,
      };
    case actions.UPLOAD_PROGRESS_MEDIA:
      return {
        ...state,
        loading: false,
        error: null,
        progress: action.progress,
      };
    case actions.SUCCESS_CREATE_MEDIA:
      console.log(action);
      return {
        ...state,
        loading: false,
        error: null,
        progress: null,
        list: [...state.list, action.data?.attachment],
      };
    case actions.SUCCESS_LIST_MEDIA:
      return {
        ...state,
        loading: false,
        error: null,
        list: action.list?.data,
      };
    case actions.SUCCESS_DELETE_MEDIA:
      return {
        ...state,
        loading: false,
        error: null,
        list: state.list.filter((p) => p.id !== action.mediaId),
      };
    default:
      return state;
  }
};
export default reducer;
