import React from "react";
import { Modal, Alert } from "react-bootstrap";
import LogoIco from "../../../../assets/elf-logo-clr.png";

import "./PaymentMessage.scss";

const PaymentMessage = ({ ...props }) => {
  let message = null;
  if (props.data) {
    message = (
      <Alert variant="success" className="successmsg">
        <i className="fa fa-check-circle-o" aria-hidden="true"></i>
        Your payment successfully completed
      </Alert>
    );
  }
  if (props.error) {
    message = (
      <div className="error">Your payment incomplete. Please try again</div>
    );
  }
  return (
    <Modal {...props} className="PaymentMessage">
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="TitleContent">
            <h5>Educate Lanka</h5>
            <h2>Student Sponsorship</h2>
            <small>100% funds scholarship</small>
            <img src={LogoIco} width="50" alt="logo" />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message}
        <br />
        <br />
      </Modal.Body>
    </Modal>
  );
};

export default PaymentMessage;
