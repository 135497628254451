import * as actions from "./actionsTypes";
import axios from "../../axios";
import { toast } from "react-toastify";

const initMedia = () => ({
  type: actions.INIT_MEDIA,
});
const errorMedia = (error) => ({
  type: actions.FAIL_MEDIA,
  error,
});
const addMedia = (data) => ({
  type: actions.SUCCESS_CREATE_MEDIA,
  data,
});
const progressOfMedia = (progress) => ({
  type: actions.UPLOAD_PROGRESS_MEDIA,
  progress,
});
const listingMedia = (list) => ({
  type: actions.SUCCESS_LIST_MEDIA,
  list,
});
const deletedMedia = (mediaId) => ({
  type: actions.SUCCESS_DELETE_MEDIA,
  mediaId,
});
export const listMedia = (payload) => async (dispatch) => {
  try {
    dispatch(initMedia());
    const media = await axios.get("/attachments?page=1&perPage=500");
    dispatch(listingMedia(media?.data));
    return media;
  } catch (error) {
    dispatch(errorMedia(error));
  }
};

export const createMedia = (file) => async (dispatch) => {
  try {
    dispatch(initMedia());
    const formData = new FormData();
    formData.append("file", file);
    const media = await axios.post("attachments", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      timeout: "500000",
      onUploadProgress: (uploadingData) => {
        dispatch(
          progressOfMedia((uploadingData.loaded / uploadingData.total) * 100)
        );
      },
    });
    dispatch(addMedia(media?.data?.data));
    toast.success("Media added successfully.", { autoClose: 3000 });
    return media;
  } catch (error) {
    dispatch(errorMedia(error));
    toast.error("Media added fail.", { autoClose: 3000 });
  }
};

export const deleteMedia = (id) => async (dispatch) => {
  try {
    dispatch(initMedia());
    const media = await axios.delete(`attachments/${id}`);
    dispatch(deletedMedia(id));
    toast.success("Media delete successfully.", { autoClose: 3000 });
    return media;
  } catch (error) {
    dispatch(errorMedia(error));
    toast.error("Media delete fail.", { autoClose: 3000 });
  }
};
