/*eslint-disable*/
import React from "react";
import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { contactUsReset, sendContactUs } from "../../../store/actions";
import Loading from "../../Reusable/Loading/Loading";

function ContactForm() {
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm();

  const { data, loading } = useSelector((state) => state.contact);
  const dispatch = useDispatch();
  useEffect(() => {
    if (data) {
      dispatch(contactUsReset());
      reset(data);
    }
  }, [data]);
  const onSubmit = () => {
    dispatch(sendContactUs(getValues()));
  };
  return (
    <Container className="mt-5 mb-5">
      {loading && <Loading page />}
      <div className="section-header text-center mt-5 mb-5">SEND US A MESSAGE</div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col sm="6">
            <div className="inputContainer sm icon">
              <label>Name</label>
              {errors.name && (
                <p className="errorMessage">{errors.name.message}</p>
              )}
              <input
                type="text"
                {...register("name", {
                  required: {
                    value: true,
                    message: "Name required.",
                  },
                  minLength: {
                    value: 3,
                    message: "Min length should be at least 3 letter",
                  },
                })}
              />
            </div>
          </Col>
          <Col sm="6">
            <div className="inputContainer sm icon">
              <label>Email</label>
              {errors.email && (
                <p className="errorMessage">{errors.email.message}</p>
              )}
              <input
                type="text"
                {...register("email", {
                  required: {
                    value: true,
                    message: "Email required.",
                  },
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Entered value does not match email format",
                  },
                })}
              />
            </div>
          </Col>
          <Col sm="12">
            <div className="inputContainer sm icon">
              <label>Message</label>
              {errors.message && (
                <p className="errorMessage">{errors.message.message}</p>
              )}
              <textarea
                {...register("message", {
                  required: {
                    value: true,
                    message: "Message required.",
                  },
                  minLength: {
                    value: 10,
                    message: "Min text length should be at least 10 letters",
                  },
                })}
              ></textarea>
            </div>
          </Col>
        </Row>

        <div className="text-center">
          <button className="blueButton">
            Send <i className="fa fa-send-o"></i>
          </button>
        </div>
      </form>
      <hr />
    </Container>
  );
}

export default ContactForm;
