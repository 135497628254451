import React from "react";
import "./SponsorshipAgreement.scss";

function SponsorshipAgreement() {
  return (
    <div className="container mt-5 mb-5">
      <h1>Educate Lanka Sponsorship Commitment Agreement</h1>
      <h2>Educate Lanka Foundation, Inc. (“Educate Lanka”)</h2>
      <hr />
      <h2>Commitment and Acknowledgment</h2>
      <p>
        By agreeing to this Sponsorship Commitment Agreement, I affirm my
        understanding of the financial responsibilities associated with
        sponsoring a student through the Educate Lanka Foundation. I am
        committed to fulfilling these obligations to the best of my ability,
        ensuring the student receives uninterrupted financial support for their
        education.
      </p>
      <hr />
      <h2>Terms of Financial Sponsorship</h2>
      <ol>
        <li>
          <strong>Monthly Sponsorship Amount</strong>
          <ul>
            <li>
              I agree to contribute the specified monthly sponsorship amount for
              the duration of the sponsorship.
            </li>
            <li>
              If I choose to increase the monthly sponsorship amount beyond the
              default amount set by Educate Lanka at the time of sponsorship, I
              acknowledge that this decision is made solely at my discretion. I
              commit to honoring the updated amount for the duration of the
              sponsorship.
            </li>
          </ul>
        </li>
        <li>
          <strong>Payment Frequency and Mode</strong>
          <ul>
            <li>
              I understand that I may select the payment frequency (e.g.,
              monthly, quarterly, annually) and mode of payment (e.g., credit
              card, debit card, PayPal) from the options provided by Educate
              Lanka on its website.
            </li>
            <li>
              I may also arrange alternative payment methods, such as check
              payments or bank transfers, by discussing these options with
              Educate Lanka in advance.
            </li>
            <li>
              I commit to adhering to the selected payment schedule and
              understand it is my responsibility to ensure payments are made on
              time.
            </li>
          </ul>
        </li>
        <li>
          <strong>Responsibility for Payment Timeliness</strong>
          <ul>
            <li>
              I acknowledge my commitment to keeping my sponsorship payments
              current.
            </li>
            <li>
              In the event of delayed or missed payments, I agree to take the
              necessary steps to promptly address outstanding payments, ensuring
              my sponsorship remains in good standing.
            </li>
          </ul>
        </li>
        <li>
          <strong>Notification of Payment Challenges</strong>
          <ul>
            <li>
              If I am unable to meet the agreed-upon payment schedule due to
              unforeseen circumstances, I commit to notifying Educate Lanka at
              the earliest possible time.
            </li>
            <li>
              This notification will allow Educate Lanka to make necessary
              arrangements and minimize any disruption to the student’s
              scholarship.
            </li>
          </ul>
        </li>
        <li>
          <strong>Payment Adjustments and Termination</strong>
          <ul>
            <li>
              I understand that I may modify the sponsorship terms, including
              payment frequency or amount, through my Educate Lanka dashboard or
              by contacting the organization directly.
            </li>
            <li>
              Should I choose to discontinue the sponsorship, I commit to:
              <ul>
                <li>
                  Providing written notification to Educate Lanka in advance.
                </li>
                <li>
                  Settling any outstanding payment obligations for the current
                  sponsorship period, if applicable.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <strong>Use of Funds</strong>
          <ul>
            <li>
              I acknowledge that all payments made as part of this sponsorship
              are directed toward the student’s education-related expenses, as
              determined by the Educate Lanka Foundation.
            </li>
            <li>
              I understand that Educate Lanka reserves the right to allocate
              sponsorship funds in alignment with the student’s educational
              needs and program guidelines.
            </li>
          </ul>
        </li>
        <li>
          <strong>Tax Deductibility</strong>
          <ul>
            <li>
              I understand that all contributions made to the Educate Lanka
              Foundation are tax-deductible to the extent allowed by U.S. law.
            </li>
            <li>
              I may request a receipt for tax purposes upon completion of each
              payment.
            </li>
          </ul>
        </li>
      </ol>
    </div>
  );
}

export default SponsorshipAgreement;
