import { toast } from "react-toastify";
import axios from "../../axios";
import {
  INIT_GET_METADATA,
  SUCCESS_GET_METADATA,
  FAIL_GET_METADATA,
  INIT_GET_STUDENT,
  SUCCESS_GET_STUDENT,
  FAIL_GET_STUDENT,
  ADD_STUDENT_TO_LIST,
  DELETE_STUDENT_FROM_LIST,
  INIT_CREATE_EVENT,
  SUCCESS_CREATE_EVENT,
  FAIL_CREATE_EVENT,
  INIT_UPDATE_EVENT,
  SUCCESS_UPDATE_EVENT,
  FAIL_UPDATE_EVENT,
  INIT_GET_EVENT_LIST,
  SUCCESS_GET_EVENT_LIST,
  FAIL_GET_EVENT_LIST,
  INIT_GET_EVENT,
  SUCCESS_GET_EVENT,
  FAIL_GET_EVENT,
  CLEAR_STUDENT_LIST,
  HANDLE_EVENT_DATA_CHANGE,
  SUCCESS_DELETE_EVENT,
  INIT_DELETE_EVENT,
  FAIL_DELETE_EVENT,
  INIT_DOWNLOAD_EXCEL,
  SUCCESS_DOWNLOAD_EXCEL,
  FAIL_DOWNLOAD_EXCEL,
  INIT_EVENT_SYNC,
  SUCCESS_EVENT_SYNC,
  FAIL_EVENT_SYNC,
} from "../actions/actionsTypes";

const initGetMetadata = () => ({
  type: INIT_GET_METADATA,
});
const successGetMetadata = (data) => ({
  type: SUCCESS_GET_METADATA,
  data,
});
const errorGetMetadata = (error) => ({
  type: FAIL_GET_METADATA,
  error,
});

const initGetEventList = () => ({
  type: INIT_GET_EVENT_LIST,
});
const successGetEventList = (data) => ({
  type: SUCCESS_GET_EVENT_LIST,
  data,
});
const errorGetEventList = (error) => ({
  type: FAIL_GET_EVENT_LIST,
  error,
});

const initCreateEvent = () => ({
  type: INIT_CREATE_EVENT,
});
const successCreateEvent = (data) => ({
  type: SUCCESS_CREATE_EVENT,
  data,
});
const errorCreateEvent = (error) => ({
  type: FAIL_CREATE_EVENT,
  error,
});

const initUpdateEvent = () => ({
  type: INIT_UPDATE_EVENT,
});
const successUpdateEvent = (data) => ({
  type: SUCCESS_UPDATE_EVENT,
  data,
});
const errorUpdateEvent = (error) => ({
  type: FAIL_UPDATE_EVENT,
  error,
});

const initGetStudents = () => ({
  type: INIT_GET_STUDENT,
});

const successGetStudents = (data) => ({
  type: SUCCESS_GET_STUDENT,
  data,
});
const errorGetStudents = (error) => ({
  type: FAIL_GET_STUDENT,
  error,
});
const successDeleteEvent = (id) => ({
  type: SUCCESS_DELETE_EVENT,
  id,
});

const initGetEvent = () => ({
  type: INIT_GET_EVENT,
});
const successGetEvent = (data) => ({
  type: SUCCESS_GET_EVENT,
  data,
});
const errorGetEvent = (error) => ({
  type: FAIL_GET_EVENT,
  error,
});
const initDeleteEvent = () => ({
  type: INIT_DELETE_EVENT,
});
export const deleteStudentFromList = (studentId) => ({
  type: DELETE_STUDENT_FROM_LIST,
  studentId,
});
const errorDeleteEvent = (error) => ({
  type: FAIL_DELETE_EVENT,
  error,
});

export const getMetadata = (payload) => async (dispatch) => {
  try {
    dispatch(initGetMetadata());
    const metadata = await axios.get(`distribution-events/add-edit-metadata`);
    dispatch(successGetMetadata(metadata?.data));
  } catch (error) {
    dispatch(errorGetMetadata(error));
  }
};
export const filterStudents = (payload) => async (dispatch) => {
  try {
    dispatch(initGetStudents());
    const metadata = await axios.get(`distribution-events/students`, {
      params: payload,
    });
    dispatch(successGetStudents(metadata?.data?.students));
  } catch (error) {
    dispatch(errorGetStudents(error));
  }
};
export const getEventList = (payload) => async (dispatch) => {
  try {
    dispatch(initGetEventList());
    const metadata = await axios.get(`distribution-events?page=1&perPage=100`, {
      params: payload,
    });
    dispatch(successGetEventList(metadata?.data));
  } catch (error) {
    dispatch(errorGetEventList(error));
  }
};
export const addStudentToList = (student) => ({
  type: ADD_STUDENT_TO_LIST,
  student,
});
export const clearStudentFilter = () => ({
  type: CLEAR_STUDENT_LIST,
});

const initDownloadExcel = () => ({
  type: INIT_DOWNLOAD_EXCEL,
});
const successDownloadExcel = (data) => ({
  type: SUCCESS_DOWNLOAD_EXCEL,
  data,
});
const errorDownloadExcel = (error) => ({
  type: FAIL_DOWNLOAD_EXCEL,
  error,
});

const initEventSync = () => ({
  type: INIT_EVENT_SYNC,
});
const successEventSync = (data) => ({
  type: SUCCESS_EVENT_SYNC,
  data,
});
const errorEventSync = (error) => ({
  type: FAIL_EVENT_SYNC,
  error,
});

export const handleEventDataChange = (input, studentId) => ({
  type: HANDLE_EVENT_DATA_CHANGE,
  input,
  studentId,
});

export const downloadDistributionEventExcel = (id) => async (dispatch) => {
  try {
    dispatch(initDownloadExcel());
    const response = await axios.post(
      `distribution-events/${id}/generate-excel`,
      {}
    );
    dispatch(successDownloadExcel(response?.data?.students));
    toast.success("Excel generated successful", {
      autoClose: 10000,
    });
    return response?.data;
  } catch (error) {
    dispatch(errorDownloadExcel(error));
    toast.error("Excel generated failed", {
      autoClose: 10000,
    });
  }
};

export const createDistributionEvent = (payload) => async (dispatch) => {
  try {
    dispatch(initCreateEvent());
    const response = await axios.post(`distribution-events`, payload);
    dispatch(successCreateEvent(response?.data?.students));
    toast.success("Event create successful", {
      autoClose: 10000,
    });
    return response?.data;
  } catch (error) {
    dispatch(errorCreateEvent(error));
    toast.error("Event create failed", {
      autoClose: 10000,
    });
  }
};

export const getDistributionEvent = (id) => async (dispatch) => {
  try {
    dispatch(initGetEvent());
    const response = await axios.get(`distribution-events/${id}`);
    dispatch(successGetEvent(response?.data?.data));
  } catch (error) {
    dispatch(errorGetEvent(error));
  }
};
export const deleteDistributionEvent = (id) => async (dispatch) => {
  try {
    dispatch(initDeleteEvent());
    const res = await axios.delete(`distribution-events/${id}`);
    dispatch(successDeleteEvent(id));
    toast.success("Event delete successful", {
      autoClose: 10000,
    });
  } catch (error) {
    dispatch(errorDeleteEvent(error));
    toast.error("Event delete failed", {
      autoClose: 10000,
    });
  }
};

export const updateDistributionEvent = (payload) => async (dispatch) => {
  try {
    dispatch(initUpdateEvent());
    const response = await axios.patch(
      `distribution-events/${payload.id}`,
      payload
    );
    toast.success("Event update successful", {
      autoClose: 10000,
    });
    dispatch(successUpdateEvent(response?.data?.students));
  } catch (error) {
    dispatch(errorUpdateEvent(error));
    toast.error("Event update failed", {
      autoClose: 10000,
    });
  }
};

export const syncDistributionEvent = (id) => async (dispatch) => {
  try {
    dispatch(initEventSync());
    const res = await axios.post(`distribution-events/${id}/sync-with-cloud`);
    dispatch(successEventSync(id));
    toast.success("Event sync successful", {
      autoClose: 10000,
    });
  } catch (error) {
    dispatch(errorEventSync(error));
    toast.error("Event sync failed", {
      autoClose: 10000,
    });
  }
};
