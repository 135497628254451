import React from "react";
import { Link } from "react-router-dom";

function TemplateThirteen({ data }) {
  return (
    <section className="EighthSection" id={data?.title?.trim().split(" ").join("").toLowerCase()}>
      <h1>{data?.title}</h1>
      <div className="row">
        {data?.tags?.map((tag, i) => (
          <div className="col-md-4">
            <div className="Icon">
              <i className={tag.image}></i>
            </div>
            <div className="iconContent">
              <a href={tag?.url} target="_blank" rel="noopener noreferrer">
                {tag?.titleTop} <strong>{tag?.titleBold}</strong>
              </a>
              <p>{tag?.text}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default TemplateThirteen;
