import * as actions from "../actions/actionsTypes";

const initialState = {
  loading: false,
  data: null,
  list: null,
  error: null,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.INIT_SECTION:
      return {
        ...state,
        loading: true,
      };
    case actions.FAIL_SECTION:
      return {
        ...state,
        loading: false,
        list: null,
        data: null,
        error: action.error,
      };
    case actions.SUCCESS_CREATE_SECTION:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.data,
      };
    case actions.SUCCESS_LIST_SECTION:
      return {
        ...state,
        loading: false,
        error: null,
        list: action.list?.data,
      };
    case actions.SUCCESS_SECTION:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.data,
      };
    case actions.SUCCESS_DELETE_SECTION:
      return {
        ...state,
        loading: false,
        error: null,
        list: state.list.filter((p) => p.id !== action.sectionId),
      };
    default:
      return state;
  }
};
export default reducer;
