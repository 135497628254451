import * as actions from "../actions/actionsTypes";

const initialState = {
  loading: false,
  data: null,
  error: null
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.INIT_PAYMENT:
      return {
        ...state,
        loading: true
      };
    case actions.RESET_PAYMENT:
      return {
        ...state,
        data: null,
        error: null
      };
    case actions.SUCCESS_PAYMENT:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.data
      };

    case actions.FAIL_PAYMENT:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.error
      };

    default:
      return state;
  }
};
export default reducer;
