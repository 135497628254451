import * as actions from "../actions/actionsTypes";

const initialState = {
  loading: false,
  data: null,
  error: null,
  spLoading: false,
  sponsorships: [],
  spError: null,
  cancelLoading: false,
  cancelError: null,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.INIT_ONE_SPONSOR:
      return {
        ...state,
        loading: true,
        data: null,
        error: null,
      };
    case actions.SUCCESS_ONE_SPONSOR:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: null,
      };

    case actions.FAIL_ONE_SPONSOR:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.err,
      };

    case actions.INIT_SPONSORSHIPS:
      return {
        ...state,
        spLoading: true,
        sponsorships: null,
        spError: null,
      };
    case actions.SUCCESS_SPONSORSHIPS:
      return {
        ...state,
        spLoading: false,
        sponsorships: action.data,
        spError: null,
      };

    case actions.FAIL_SPONSORSHIPS:
      return {
        ...state,
        spLoading: false,
        sponsorships: null,
        spError: action.err,
      };

    case actions.INIT_CANCEL_SPONSORSHIP:
      return {
        ...state,
        cancelLoading: true,
      };
    case actions.SUCCESS_CANCEL_SPONSORSHIPS:
      const { data } = action.data;
      const spsId = data.id;
      const updatedSponsorships = state?.data?.sponsorships?.map((sp) => {
        if (sp.id === spsId) {
          sp.status = data.status;
        }
        return sp;
      });

      return {
        ...state,
        cancelLoading: false,
        data: { ...state.data, sponsorships: updatedSponsorships },
      };

    case actions.SUCCESS_CANCEL_SUBSCRIPTION:
      const details = action.data.data;
      const sponserID = details.id;
      const updatedSponsorshipsDetails = state?.data?.sponsorships?.map(
        (sp) => {
          if (sp.id === sponserID) {
            sp.recurring = false;
          }
          return sp;
        }
      );

      return {
        ...state,
        cancelLoading: false,
        data: { ...state.data, sponsorships: updatedSponsorshipsDetails },
      };

    case actions.FAIL_CANCEL_SPONSORSHIPS:
      return {
        ...state,
        cancelLoading: false,
        cancelError: action.err,
      };

    case actions.FAIL_CANCEL_SUBSCRIPTION:
      return {
        ...state,
        cancelLoading: false,
        cancelError: action.err,
      };

    default:
      return state;
  }
};
export default reducer;
