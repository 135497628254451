import { toast } from "react-toastify";
import axios from "../../axios";
import {
  INIT_HOME,
  SUCCESS_HOME,
  FAIL_HOME,
  INIT_INVITE,
  SUCCESS_INVITE,
  FAIL_INVITE,
} from "./actionsTypes";

const initHome = () => ({
  type: INIT_HOME,
});
const successHome = (data) => ({
  type: SUCCESS_HOME,
  data,
});
const failHome = (err) => ({
  type: FAIL_HOME,
  err,
});
const initInvite = () => ({
  type: INIT_INVITE,
});
const successInvite = (data) => ({
  type: SUCCESS_INVITE,
  data,
});
const failInvite = (err) => ({
  type: FAIL_INVITE,
  err,
});
export const getHome = () => {
  return (dispatch) => {
    dispatch(initHome());
    axios
      .get("/home-contents")
      .then((res) => {
        dispatch(successHome(res.data.data));
      })
      .catch((err) => {
        dispatch(failHome(err.response));
      });
  };
};
export const inviteUser = (data) => {
  return (dispatch) => {
    dispatch(initInvite());
    axios
      .post("/user/invite", data)
      .then((res) => {
        toast.success("Your invitation sent successfully", {
          autoClose: 15000,
        });
        dispatch(successInvite(res.data.data));
        return res
      })
      .catch((err) => {
        dispatch(failInvite(err.response));
      });
  };
};
