import {
  INIT_FORGET_PASSWORD,
  SUCCESS_FORGET_PASSWORD,
  FAIL_FORGET_PASSWORD,
  RESET_FORGET_PASSWORD,
  SUCCESS_RESET_PASSWORD,
} from "../actions/actionsTypes";

const initialState = {
  loading: false,
  err: null,
  data: null,
  redirect: false,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_FORGET_PASSWORD:
      return {
        ...state,
        loading: true,
        redirect: false,
      };
    case SUCCESS_FORGET_PASSWORD:
      return {
        ...state,
        loading: false,
        data: "success",
        err: null,
      };
    case SUCCESS_RESET_PASSWORD:
      return {
        ...state,
        loading: false,
        data: "success",
        redirect: true,
        err: null,
      };
    case FAIL_FORGET_PASSWORD:
      return {
        ...state,
        loading: false,
      };
    case RESET_FORGET_PASSWORD:
      return {
        ...state,
        loading: false,
        data: null,
        redirect: false,
      };

    default:
      return state;
  }
};
export default reducer;
