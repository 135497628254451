import React, { useState, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

import {
  FormControl,
  FormGroup,
  FormLabel,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { useEffect } from "react";

function TemplateSixteen({ section, editMode, handleSectionDataChange }) {
  const editorRef = useRef(null);
  const [data, setData] = useState({
    title: "",
    student_1: null,
    student_2: null,
    student_3: null,
    student_4: null,
    text1: "",
    text2: "",
    buttonText: "",
    buttonLink: "",
  });
  useEffect(() => {
    handleSectionDataChange({
      ...section,
      index: section.index,
      data: data,
    });
  }, [data]);

  //===EDIT MODE SET DATA
  useEffect(() => {
    if (editMode && section.data) {
      setData(section.data);
    }
  }, [editMode]);
  //===END EDIT MODE SET DATA

  const handleChange = (e) => {
    setData((dt) => ({ ...dt, [e.target.name]: e.target.value }));
  };

  const handleChangeTextOne = (e) => {
    setData((dt) => ({ ...dt, text1: e }));
  };
  const handleChangeTextTwo = (e) => {
    setData((dt) => ({ ...dt, text2: e }));
  };

  return (
    <div>
      <FormGroup>
        <FormLabel>Title</FormLabel>
        <FormControl name="title" value={data?.title} onChange={handleChange} />
      </FormGroup>
<FormGroup>
        <FormLabel>Content 1</FormLabel>
        <Editor
          name="text1"
          value={data?.text1}
          apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
          init={{
            plugins: [
              "lists link image paste help wordcount code",
              "searchreplace",
              "insertdatetime media",
            ],
            image_title: true,
            image_dimensions: true,
            image_advtab: true
          }}
          onEditorChange={handleChangeTextOne}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Content 2</FormLabel>
        <Editor
          name="text2"
          value={data?.text2}
          apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
          init={{
            plugins: [
              "lists link image paste help wordcount code",
              "searchreplace",
              "insertdatetime media",
            ],
            image_title: true,
            image_dimensions: true,
            image_advtab: true
          }}
          onEditorChange={handleChangeTextTwo}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Button Text</FormLabel>
        <FormControl
          name="buttonText"
          value={data?.buttonText}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Button Link</FormLabel>
        <FormControl
          name="buttonLink"
          value={data?.buttonLink}
          onChange={handleChange}
        />
      </FormGroup>
      
      <div className="RowColors">
        <Row>
        <Col sm={3}>
          <FormGroup>
            <FormLabel>Student ID </FormLabel>
            <FormControl
              name="student_1"
              value={data?.student_1}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col sm={3}>
          <FormGroup>
            <FormLabel>Student ID </FormLabel>
            <FormControl
              name="student_2"
              value={data?.student_2}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col sm={3}>
          <FormGroup>
            <FormLabel>Student ID </FormLabel>
            <FormControl
              name="student_3"
              value={data?.student_3}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col sm={3}>
          <FormGroup>
            <FormLabel>Student ID </FormLabel>
            <FormControl
              name="student_4"
              value={data?.student_4}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
        </Row>
      </div>
    </div>
  );
}

export default TemplateSixteen;
