import * as actions from "../actions/actionsTypes";

const initialState = {
  loading: false,
  sponsorships: null,
  sponsorship: null,
  data: null,
  error: null,
  spLoading: false,
  spList: [],
  spError: null,
  legacyPayment: null,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.INIT_UPDATE_STATUS_SPONSORSHIP:
      return {
        ...state,
        loading: true,
      };
    case actions.SUCCESS_CUPDATE_STATUSSPONSORSHIPS:
      const status = action.payload?.data?.status;
      return {
        ...state,
        loading: false,
        sponsorship: { ...state.sponsorship, status: status },
      };
    case actions.FAIL_CUPDATE_STATUSSPONSORSHIPS:
      return {
        ...state,
        loading: false,
        spError: action.payload,
      };
    case actions.INIT_GET_ALL_SPONSORSHIPS:
      return {
        ...state,
        spLoading: true,
      };
    case actions.SUCCESS_GET_ALL_SPONSORSHIPS:
      return {
        ...state,
        spLoading: false,
        spList: action.data,
      };
    case actions.FAIL_GET_ALL_SPONSORSHIPS:
      return {
        ...state,
        spLoading: false,
        spError: action.error,
      };

    case actions.INIT_CREATE_SPONSORSHIP:
      return {
        ...state,
        loading: true,
        data: null,
        error: null,
      };
    case actions.SUCCESS_CREATE_SPONSORSHIP:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };

    case actions.FAIL_CREATE_SPONSORSHIP:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };

    case actions.INIT_GET_SPONSORSHIP:
      return {
        ...state,
        loading: true,
        data: null,
        error: null,
      };
    case actions.SUCCESS_GET_SPONSORSHIP:
      return {
        ...state,
        loading: false,
        sponsorship: action.payload,
        error: null,
      };

    case actions.FAIL_GET_SPONSORSHIP:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };

    case actions.INIT_LEGACY_PAYMENT:
      return {
        ...state,
        loading: true,
        legacyPayment: null,
        error: null,
      };
    case actions.SUCCESS_LEGACY_PAYMENT:
      return {
        ...state,
        loading: false,
        legacyPayment: action.payload?.data,
        error: null,
      };

    case actions.FAIL_LEGACY_PAYMENT:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload?.data,
      };

    case actions.RESET_SPONSORSHIP:
      return {
        ...state,
        loading: false,
        data: null,
        error: null,
      };

    default:
      return state;
  }
};
export default reducer;
