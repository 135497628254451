import React from "react";

import SponsorshipCard from "./SponsorshipCard";

function Sponsorships(props) {
  const { list } = props;
  return (
    <div>
      {list?.map((sponsorship) => {
        return (
          <SponsorshipCard key={sponsorship.id} sponsorship={sponsorship} />
        );
      })}
    </div>
  );
}

export default Sponsorships;
