/*eslint-disable*/
import React, { useEffect } from "react";
import GitCardHeader from "../../components/BuyAGitCard/GitCardHeader/GitCardHeader";
import GitCardSecondSection from "../../components/BuyAGitCard/GitCardSecondSection/GitCardSecondSection";
import BlueImpactArea from "../../components/Reusable/BlueImpactArea/BlueImpactArea";
import "./BuyAGitCard.scss";
import { connect } from "react-redux";

import NotFound from "../../components/Reusable/NotFound";
import { useSelector } from "react-redux";
import PageSections from "../../components/Reusable/PageSections/PageSections";

function Fund(props) {
  const { list } = useSelector((state) => state.pages);

  const page = list?.find((p) => p.group === "/buy-a-gift-card");

  if (page) {
    return (
      <div>
        <GitCardHeader
          section={page?.pageSections.find(
            (s) => s.section.key === "gift-card"
          )}
          auth={props.auth}
        />
        <PageSections pageSections={page?.pageSections} />
      </div>
    );
  } else {
    return <NotFound />;
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Fund);
