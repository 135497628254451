/*eslint-disable*/
import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import FundHeader from "../../components/PoolFund/FundHeader/FundHeader";
import "./PoolFund.scss";
import {
  createPaypalBilling,
  payWithStripe,
  getPaypalExecute,
  resetPayment,
} from "../../store/actions";
import Loading from "../../components/Reusable/Loading/Loading";
import PageSections from "../../components/Reusable/PageSections/PageSections";

function Fund(props) {
  const { list, loading } = useSelector((state) => state.pages);

  useEffect(() => {
    return () => props.resetPayment();
  }, []);

  let loadingContent;
  if (props.payment.loading || props.auth.meLoading) {
    loadingContent = <Loading />;
  }

  const page = list?.find((p) => p.group === "/educate-lanka-fund");
  return (
    <div>
      {loadingContent}
      {page && (
        <>
          <FundHeader page={page} {...props} />
          <PageSections pageSections={page.pageSections} />
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  payment: state.payment,
});
const mapDispatchToProps = (dispatch) => ({
  createPaypalBilling: (data, token) =>
    dispatch(createPaypalBilling(data, token)),
  payWithStripe: (data, token) => dispatch(payWithStripe(data, token)),
  getPaypalExecute: (data, token) => dispatch(getPaypalExecute(data, token)),
  resetPayment: () => dispatch(resetPayment()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Fund);
