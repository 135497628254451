import {
  Alert,
  FormGroup,
  InputGroup,
  FormLabel,
  FormControl,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router";
import {
  createPages,
  listSection,
  getPage,
  updatePages,
} from "../../../../store/actions";
import Sections from "../../../../components/admin/CMS/CmsSections/sections";
import UpDownButtons from "../../../../components/admin/CMS/CmsSections/UpDownButtons";

const pageGroups = [
  { name: "Select page", path: "", nested: false },
  { name: "home", path: "/", nested: false },
  { name: "educate-lanka-fund", path: "/educate-lanka-fund", nested: false },
  {
    name: "pledge-your-birthday",
    path: "/pledge-your-birthday",
    nested: false,
  },
  { name: "buy-a-gift-card", path: "/buy-a-gift-card", nested: false },
  // {
  //   name: "campaign-overview",
  //   path: "/campaign-overview",
  //   nested: false,
  // },
  { name: "contact-us", path: "/contact-us", nested: false },
  {
    name: "terms-and-conditions",
    path: "/terms-and-conditions",
    nested: false,
  },
  { name: "privacy-policy", path: "/privacy-policy", nested: false },
  // { name: "fundraiser", path: "/fundraiser", nested: false },
  // { name: "volunteer", path: "/volunteer", nested: false },
  // { name: "press", path: "/press", nested: false },
  // { name: "downloads", path: "/downloads", nested: false },
  // { name: "financial", path: "/financial", nested: false },
  // { name: "live-events", path: "/live-events", nested: false },

  { name: "skills", path: "/skills", nested: true },
  { name: "about", path: "/about", nested: true },
  { name: "blog-post-group", path: "/posts/", nested: true },
  { name: "page", path: "/page/", nested: true },
];
function Add() {
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.section);
  const { data: page } = useSelector((state) => state.pages);

  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState("");
  const [group, setGroup] = useState("");
  const [isLocked, setIsLocked] = useState(false);
  const [slug, setSlug] = useState("");
  const [sectionId, setSectionId] = useState();
  const [sections, setSections] = useState([]);

  let isMultiSupport = pageGroups.find((p) => {
    return p.path === group && p.nested;
  });
  //======EDIT MODE===========
  const params = useParams();
  let navigate = useNavigate();
  useEffect(() => {
    if (params.id) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  }, [params]);

  useEffect(() => {
    editMode && dispatch(getPage(`/${params.id}?include=sections`));
  }, [dispatch, editMode]);

  useEffect(() => {
    if (editMode && page) {
      isMultiSupport=pageGroups.find((p) => {
        return p.path === page.group && p.nested;
      });
      setName(page.name);
      setGroup(page.group);

      setIsLocked(page.isLocked);
      setSections(
        page.pageSections?.map((p) => {
          p.key = p.section.key;
          return p;
        })
      );
      if(isMultiSupport) {
        const splittedArray=page.link.split("/")
        let nestedLink=splittedArray.slice(2,splittedArray.length).join("/")
        if(splittedArray.length>2){
          nestedLink="/"+nestedLink
        }
        setSlug(nestedLink)
      };
    }
  }, [page, editMode]);
  // //======END EDIT MODE===========

  useEffect(() => {
    dispatch(listSection());
  }, [dispatch, listSection]);
  useEffect(() => {
    if (list?.length) {
      setSectionId(list[0]?.id);
    }
  }, [list]);

  const addSections = () => {
    setSections((old) => [
      ...old,
      list.find((e) => {
        if (e.id == sectionId) {
          e.sectionId = sectionId;
          return e;
        }
      }),
    ]);
  };
  const handleSectionDataChange = (obj) => {
    const copySections = [...sections];
    const selectedSections = copySections[obj.index];
    copySections[obj.index] = {
      ...selectedSections,
      data: obj.data,
      sectionId: editMode ? obj.sectionId : obj.id,
      friendlyName: obj?.data?.title ? obj?.data?.title : obj.name + obj.index,
    };
    setSections(copySections);
  };
  const handleOrder = (payload) => {
    let copySection = sections[payload.index];
    const copySectionsList = [...sections];
    copySectionsList.splice(payload.index, 1);
    if (payload.type === "UP") {
      copySectionsList.splice(payload.index - 1, 0, copySection);
    } else {
      copySectionsList.splice(payload.index + 1, 0, copySection);
    }
    setSections(copySectionsList);
  };
  const handleSectionDelete = (index) => {
    if (window.confirm("Are you sure to delete this section?")) {
      const copySectionsList = [...sections];
      copySectionsList.splice(index, 1);
      setSections(copySectionsList);
    }
  };
  const handleSubmit = async () => {
    const data = {
      name: name,
      group: group,
      isLocked: isLocked,
      isAPartial: 0,
      link: "",
      sections: sections.map((sec) => {
        if (typeof sec.data !== "string") {
          sec.data = JSON.stringify(sec.data);
        }
        return sec;
      }),
    };
    if (isMultiSupport) {
      if (slug) {
        data.link = `${pageGroups.find((p) => p.path === group)?.path}${slug}`;
      } else {
        data.link = `${pageGroups.find((p) => p.path === group)?.path}`;
      }
    } else {
      data.link = pageGroups.find((p) => p.path === group)?.path;
    }
    if (editMode) {
      data.id = params.id;
      dispatch(updatePages(data));
    } else {
      await dispatch(createPages(data));
      navigate("/admin/cms-pages");
    }
  };

  let content = (
    <div className="WhiteContent">
      <Alert variant="danger">Please add CMS Section first</Alert>
    </div>
  );

  if (list?.length) {
    content = (
      <>
        <div className="WhiteContent">
          <FormGroup>
            <FormLabel>Page Name</FormLabel>
            <FormControl
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Group Name</FormLabel>
            <select
              className="form-control"
              name="group"
              value={group}
              onChange={(e) => setGroup(e.target.value)}
            >
              {pageGroups.map((val, i) => (
                <option key={i + 1} value={val.path}>
                  {val.name}
                </option>
              ))}
            </select>
          </FormGroup>
          <InputGroup>
            <InputGroup.Text>
              Link {pageGroups.find((p) => p.path === group)?.path}
            </InputGroup.Text>
            {isMultiSupport && (
              <FormControl
                name="slug"
                value={slug}
                onChange={(e) => setSlug(e.target.value)}
              />
            )}
          </InputGroup>
          <FormGroup className="mt-3">
            <FormLabel>
              <input
                type="checkbox"
                name="isLocked"
                checked={isLocked}
                onChange={() => setIsLocked(!isLocked)}
              />{" "}
              Locked
            </FormLabel>
          </FormGroup>
        </div>

        {sections?.map((se, index) => (
          <div key={index} className="WhiteContent mt-3 sectionContent">
            {se.key === "meta-data" && (
              <>
                <label>{se.key}</label>
                <UpDownButtons
                  handleSectionDelete={handleSectionDelete}
                  length={sections.length}
                  index={index}
                  handleOrder={handleOrder}
                />
                <Sections.MetaData
                  editMode={editMode}
                  section={{ ...se, index }}
                  handleSectionDataChange={handleSectionDataChange}
                />
              </>
            )}
            {se.key === "basic" && (
              <>
                <label>{se.key}</label>
                <UpDownButtons
                  handleSectionDelete={handleSectionDelete}
                  length={sections.length}
                  index={index}
                  handleOrder={handleOrder}
                />
                <Sections.BasicTemplate
                  editMode={editMode}
                  section={{ ...se, index }}
                  handleSectionDataChange={handleSectionDataChange}
                />
              </>
            )}
            {se.key === "educate-lanka-fund-header" && (
              <>
                <label>{se.key}</label>
                <UpDownButtons
                  handleSectionDelete={handleSectionDelete}
                  length={sections.length}
                  index={index}
                  handleOrder={handleOrder}
                />
                <Sections.PoolFundHeader
                  editMode={editMode}
                  section={{ ...se, index }}
                  handleSectionDataChange={handleSectionDataChange}
                />
              </>
            )}
            {se.key === "home-page-header" && (
              <>
                <label>{se.key}</label>
                <UpDownButtons
                  handleSectionDelete={handleSectionDelete}
                  length={sections.length}
                  index={index}
                  handleOrder={handleOrder}
                />
                <Sections.HomePageHeader
                  sections={sections}
                  editMode={editMode}
                  section={{ ...se, index }}
                  handleSectionDataChange={handleSectionDataChange}
                />
              </>
            )}
            {se.key === "template-1" && (
              <>
                <label>{se.key}</label>
                <UpDownButtons
                  handleSectionDelete={handleSectionDelete}
                  length={sections.length}
                  index={index}
                  handleOrder={handleOrder}
                />
                <Sections.TemplateOne
                  editMode={editMode}
                  section={{ ...se, index }}
                  handleSectionDataChange={handleSectionDataChange}
                />
              </>
            )}
            {se.key === "template-2" && (
              <>
                <label>{se.key}</label>
                <UpDownButtons
                  handleSectionDelete={handleSectionDelete}
                  length={sections.length}
                  index={index}
                  handleOrder={handleOrder}
                />
                <Sections.TemplateTwo
                  editMode={editMode}
                  section={{ ...se, index }}
                  handleSectionDataChange={handleSectionDataChange}
                />
              </>
            )}
            {se.key === "template-3" && (
              <>
                <label>{se.key}</label>
                <UpDownButtons
                  handleSectionDelete={handleSectionDelete}
                  length={sections.length}
                  index={index}
                  handleOrder={handleOrder}
                />
                <Sections.TemplateThree
                  editMode={editMode}
                  section={{ ...se, index }}
                  handleSectionDataChange={handleSectionDataChange}
                />
              </>
            )}
            {se.key === "template-4" && (
              <>
                <label>{se.key}</label>
                <UpDownButtons
                  handleSectionDelete={handleSectionDelete}
                  length={sections.length}
                  index={index}
                  handleOrder={handleOrder}
                />
                <Sections.TemplateFore
                  editMode={editMode}
                  section={{ ...se, index }}
                  handleSectionDataChange={handleSectionDataChange}
                />
              </>
            )}
            {se.key === "template-5" && (
              <>
                <label>{se.key}</label>
                <UpDownButtons
                  handleSectionDelete={handleSectionDelete}
                  length={sections.length}
                  index={index}
                  handleOrder={handleOrder}
                />
                <Sections.TemplateFive
                  editMode={editMode}
                  section={{ ...se, index }}
                  handleSectionDataChange={handleSectionDataChange}
                />
              </>
            )}
            {se.key === "template-6" && (
              <>
                <label>{se.key}</label>
                <UpDownButtons
                  handleSectionDelete={handleSectionDelete}
                  length={sections.length}
                  index={index}
                  handleOrder={handleOrder}
                />
                <Sections.TemplateSix
                  editMode={editMode}
                  section={{ ...se, index }}
                  handleSectionDataChange={handleSectionDataChange}
                />
              </>
            )}
            {se.key === "template-7" && (
              <>
                <label>{se.key}</label>
                <UpDownButtons
                  handleSectionDelete={handleSectionDelete}
                  length={sections.length}
                  index={index}
                  handleOrder={handleOrder}
                />
                <Sections.TemplateSeven
                  editMode={editMode}
                  section={{ ...se, index }}
                  handleSectionDataChange={handleSectionDataChange}
                />
              </>
            )}
            {se.key === "template-8" && (
              <>
                <label>{se.key}</label>
                <UpDownButtons
                  handleSectionDelete={handleSectionDelete}
                  length={sections.length}
                  index={index}
                  handleOrder={handleOrder}
                />
                <Sections.TemplateEight
                  editMode={editMode}
                  section={{ ...se, index }}
                  handleSectionDataChange={handleSectionDataChange}
                />
              </>
            )}
            {se.key === "template-9" && (
              <>
                <label>{se.key}</label>
                <UpDownButtons
                  handleSectionDelete={handleSectionDelete}
                  length={sections.length}
                  index={index}
                  handleOrder={handleOrder}
                />
                <Sections.TemplateNine
                  editMode={editMode}
                  section={{ ...se, index }}
                  handleSectionDataChange={handleSectionDataChange}
                />
              </>
            )}
            {se.key === "template-10" && (
              <>
                <label>{se.key}</label>
                <UpDownButtons
                  handleSectionDelete={handleSectionDelete}
                  length={sections.length}
                  index={index}
                  handleOrder={handleOrder}
                />
                <Sections.TemplateTen
                  editMode={editMode}
                  section={{ ...se, index }}
                  handleSectionDataChange={handleSectionDataChange}
                />
              </>
            )}
            {se.key === "template-11" && (
              <>
                <label>{se.key}</label>
                <UpDownButtons
                  handleSectionDelete={handleSectionDelete}
                  length={sections.length}
                  index={index}
                  handleOrder={handleOrder}
                />
                <Sections.TemplateEleven
                  editMode={editMode}
                  section={{ ...se, index }}
                  handleSectionDataChange={handleSectionDataChange}
                />
              </>
            )}
            {se.key === "template-12" && (
              <>
                <label>{se.key}</label>
                <UpDownButtons
                  handleSectionDelete={handleSectionDelete}
                  length={sections.length}
                  index={index}
                  handleOrder={handleOrder}
                />
                <Sections.TemplateTwelve
                  editMode={editMode}
                  section={{ ...se, index }}
                  handleSectionDataChange={handleSectionDataChange}
                />
              </>
            )}
            {se.key === "template-13" && (
              <>
                <label>{se.key}</label>
                <UpDownButtons
                  handleSectionDelete={handleSectionDelete}
                  length={sections.length}
                  index={index}
                  handleOrder={handleOrder}
                />
                <Sections.TemplateThirteen
                  editMode={editMode}
                  section={{ ...se, index }}
                  handleSectionDataChange={handleSectionDataChange}
                />
              </>
            )}
            {se.key === "template-14" && (
              <>
                <label>{se.key}</label>
                <UpDownButtons
                  handleSectionDelete={handleSectionDelete}
                  length={sections.length}
                  index={index}
                  handleOrder={handleOrder}
                />
                <Sections.TemplateFourteen
                  editMode={editMode}
                  section={{ ...se, index }}
                  handleSectionDataChange={handleSectionDataChange}
                />
              </>
            )}
            {se.key === "template-15" && (
              <>
                <label>{se.key}</label>
                <UpDownButtons
                  handleSectionDelete={handleSectionDelete}
                  length={sections.length}
                  index={index}
                  handleOrder={handleOrder}
                />
                <Sections.TemplateFifteen
                  editMode={editMode}
                  section={{ ...se, index }}
                  handleSectionDataChange={handleSectionDataChange}
                />
              </>
            )}
            {se.key === "template-16" && (
              <>
                <label>{se.key}</label>
                <UpDownButtons
                  handleSectionDelete={handleSectionDelete}
                  length={sections.length}
                  index={index}
                  handleOrder={handleOrder}
                />
                <Sections.TemplateSixteen
                  editMode={editMode}
                  section={{ ...se, index }}
                  handleSectionDataChange={handleSectionDataChange}
                />
              </>
            )}
            {se.key === "template-17" && (
              <>
                <label>{se.key}</label>
                <UpDownButtons
                  handleSectionDelete={handleSectionDelete}
                  length={sections.length}
                  index={index}
                  handleOrder={handleOrder}
                />
                <Sections.TemplateSeventeen
                  editMode={editMode}
                  section={{ ...se, index }}
                  handleSectionDataChange={handleSectionDataChange}
                />
              </>
            )}
             {se.key === "template-18" && (
              <>
                <label>{se.key}</label>
                <UpDownButtons
                  handleSectionDelete={handleSectionDelete}
                  length={sections.length}
                  index={index}
                  handleOrder={handleOrder}
                />
                <Sections.TemplateEighteen
                  editMode={editMode}
                  section={{ ...se, index }}
                  handleSectionDataChange={handleSectionDataChange}
                />
              </>
            )}
            {se.key === "contact-header" && (
              <>
                <label>{se.key}</label>
                <UpDownButtons
                  handleSectionDelete={handleSectionDelete}
                  length={sections.length}
                  index={index}
                  handleOrder={handleOrder}
                />
                <Sections.ContactHeader
                  editMode={editMode}
                  section={{ ...se, index }}
                  handleSectionDataChange={handleSectionDataChange}
                />
              </>
            )}
            {se.key === "pledge-your-birthday" && (
              <>
                <label>{se.key}</label>
                <UpDownButtons
                  handleSectionDelete={handleSectionDelete}
                  length={sections.length}
                  index={index}
                  handleOrder={handleOrder}
                />
                <Sections.PledgeYourBirthdayHeader
                  editMode={editMode}
                  section={{ ...se, index }}
                  handleSectionDataChange={handleSectionDataChange}
                />
              </>
            )}
            {se.key === "gift-card" && (
              <>
                <label>{se.key}</label>
                <UpDownButtons
                  handleSectionDelete={handleSectionDelete}
                  length={sections.length}
                  index={index}
                  handleOrder={handleOrder}
                />
                <Sections.GiftCardPageHeader
                  editMode={editMode}
                  section={{ ...se, index }}
                  handleSectionDataChange={handleSectionDataChange}
                />
              </>
            )}
            {se.key === "header" && (
              <>
                <label>{se.key}</label>
                <UpDownButtons
                  handleSectionDelete={handleSectionDelete}
                  length={sections.length}
                  index={index}
                  handleOrder={handleOrder}
                />
                <Sections.TemplateHeader
                  editMode={editMode}
                  section={{ ...se, index }}
                  handleSectionDataChange={handleSectionDataChange}
                />
              </>
            )}
          </div>
        ))}

        <div className="WhiteContent mt-3">
          <Row>
            <Col md={12}>
              {sections?.map((s, i) => (
                <span key={i}>{s.name}</span>
              ))}
            </Col>
            <Col md={10}>
              <FormGroup>
                <FormLabel>Section Type</FormLabel>
                <select
                  className="form-control"
                  name="section"
                  onChange={(e) => setSectionId(e.target.value)}
                >
                  {list?.map((section, i) => (
                    <option key={i} value={section.id}>
                      {section.name}
                    </option>
                  ))}
                </select>
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <FormLabel>&nbsp;</FormLabel>
                <Button block onClick={addSections}>
                  + ADD
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </div>
      </>
    );
  }
  return (
    <div>
      <div className="HeaderTextCMS justify-content-between">
        {editMode ? page?.name : "Add new page"}
        <Button onClick={handleSubmit}>PUBLISH</Button>
      </div>
      {content}
    </div>
  );
}

export default Add;
