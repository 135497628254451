import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { getLog, getLogList } from "../../../store/actions";
import { useState } from "react";
import { syntaxHighlight } from "../../../utils/func";
import ReactPaginate from "react-paginate";
const Logs = () => {
  const pageData = {
    page: 1,
    perPage: 30,
  };
  const dispatch = useDispatch();
  const { data, item, loading } = useSelector((state) => state.logs);
  const [show, setShow] = useState(false);
  console.log(item);
  useEffect(() => {
    dispatch(getLogList(pageData));
  }, [dispatch]);

  const fetchSelectedLog = async (id) => {
    const result = await dispatch(getLog({ id }));
    if (result === "success") {
      setShow(true);
    }
  };
  const handleCurrentPagination = (e) => {
    const pageNumber = e.selected + 1;
    pageData.page = pageNumber;
    dispatch(getLogList(pageData));
  };
  const before = item?.before;
  const after = item?.after;
  const added = item?.diff?.added;
  const updated = item?.diff?.updated;
  const deleted = item?.diff?.deleted;
  return (
    <div>
      <div className="HeaderTextCMS justify-content-between">
        Log activities
      </div>
      <div className="SponsorshipCard">
        <div className="Table">
          <table width="100%">
            <thead>
              <tr>
                <th>Module</th>
                <th>Action</th>
                <th>Admin</th>
                <th>User</th>
                <th width="150"></th>
              </tr>
            </thead>
            <tbody>
              {data?.data?.map((item) => (
                <tr key={item.id}>
                  <td>{item.modelName}</td>
                  <td>{item.operation}</td>
                  <td>
                    <Link to={`/admin/users/${item["admin"]?.id}`}>
                      {item["admin"].firstName}
                    </Link>
                  </td>
                  <td>
                    <Link to={`/admin/users/${item["user"]?.id}`}>
                      {item["user"].firstName}
                    </Link>
                  </td>
                  <td>
                    <Button onClick={() => fetchSelectedLog(item?.id)}>
                      Details view
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <ReactPaginate
        pageCount={
          data?.meta?.pagination?.numberOfItems.length / pageData.perPage
        }
        onPageChange={handleCurrentPagination}
        containerClassName="paginations"
      />
      <Modal show={show} onHide={() => setShow(false)} size="xl">
        <Modal.Header closeButton>Details</Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={4}>
              <strong>Before</strong>
              <div className="codeBlock">
                <pre
                  dangerouslySetInnerHTML={{
                    __html: before
                      ? syntaxHighlight(JSON.stringify(before, null, 2))
                      : "",
                  }}
                ></pre>
              </div>
            </Col>
            <Col sm={4}>
              <strong>After</strong>
              <div className="codeBlock">
                <pre
                  dangerouslySetInnerHTML={{
                    __html: after
                      ? syntaxHighlight(JSON.stringify(after, null, 2))
                      : "",
                  }}
                ></pre>
              </div>
            </Col>
            <Col sm={4}>
              <strong>Difference</strong>
              <div className="codeBlock">
                <strong>added</strong>
                <pre
                  dangerouslySetInnerHTML={{
                    __html: added
                      ? syntaxHighlight(JSON.stringify(added, null, 2))
                      : "",
                  }}
                ></pre>
              </div>
              <div className="codeBlock">
                <strong>updated</strong>
                <pre
                  dangerouslySetInnerHTML={{
                    __html: updated
                      ? syntaxHighlight(JSON.stringify(updated, null, 2))
                      : "",
                  }}
                ></pre>
              </div>
              <div className="codeBlock">
                <strong>deleted</strong>
                <pre
                  dangerouslySetInnerHTML={{
                    __html: deleted
                      ? syntaxHighlight(JSON.stringify(deleted, null, 2))
                      : "",
                  }}
                ></pre>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Logs;
