/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Form } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";

import LogoIco from "../../../../assets/elf-logo-clr.png";
import { Button as ButtonSubmit } from "../../Button/Button";
import SocialButton from "../../SocialButton/SocialButton";

import countries from "../../../../store/data/countries";

import {
  registerUser,
  loginFbUser,
  loginGoogleUser,
} from "../../../../store/actions";

import "./SignUpModal.scss";
import SharedModal from "../SharedModal/SharedModal";
const formObj = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  anonymous: false,
  salutation: "Mr.",
  country: "",
};

function SignUpModal(props) {
  const [validator, setValidator] = useState(
    new SimpleReactValidator({
      autoForceUpdate: this,
      messages: { email: "This is not an email." },
    })
  );
  const [formData, setFormData] = useState(formObj);
  const [validationLoad, setValidationLoad] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");

  const onChangeHandler = (e) => {
    if (e.target.name === "country") {
      setSelectedCountry(e.target.value);
    }
    if (e.target.name === "anonymous") {
      setFormData({ ...formData, anonymous: !formData.anonymous });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    if (!validator.allValid()) {
      validator.showMessageFor(e.target.name);
    }
  };
  useEffect(() => {
    setFormData(formObj); //Reset form in mount
    return validator.hideMessages(); //Hide validation in unmount
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      props.onUserRegister(formData); //Send data to API
      setValidationLoad(true); //This function use only for the trigget first time validation. Otherwise it not work in initial submit
    } else {
      validator.showMessages();
      setValidationLoad(true); //This function use only for the trigget first time validation. Otherwise it not work in initial submit
    }
  };

  return (
    <SharedModal
      title="Account Registration"
      subTitle="We are honored to welcome you to our journey"
      show={props.show}
      onHide={props.handleCloseSignUp}
    >
      {/* {props.regErr && <Alert variant="danger">{props.regErr[0].message}</Alert>} */}

      <form onSubmit={handleSubmit}>
        <div className="inputContainer icon">
          {validator.message("salutation", formData.salutation, "required")}
          <select
            name="salutation"
            defaultValue={formData.salutation}
            onChange={onChangeHandler}
          >
            <option value="Dr.">Dr.</option>
            <option value="Mr." selected>
              Mr.
            </option>
            <option value="Mrs.">Mrs.</option>
            <option value="Ms.">Ms.</option>
            <option value="Mstr.">Mstr.</option>
            <option value="Other">Other</option>
          </select>
          <i className="fa fa-lock"></i>
        </div>
        <Row>
          <Col sm={6}>
            <div className="inputContainer icon">
              {validator.message("firstName", formData.firstName, "required")}
              <input
                onChange={onChangeHandler}
                type="text"
                name="firstName"
                value={formData.firstName}
                placeholder="First name"
              />
              <i className="fa fa-user"></i>
            </div>
          </Col>
          <Col sm={6}>
            <div className="inputContainer icon">
              {validator.message("lastname", formData.lastName, "required")}
              <input
                onChange={onChangeHandler}
                type="text"
                name="lastName"
                value={formData.lastName}
                placeholder="Last name"
              />
              <i className="fa fa-user"></i>
            </div>
          </Col>
        </Row>
        <div className="inputContainer icon">
          {validator.message("email", formData.email, "required|email")}
          <input
            onChange={onChangeHandler}
            type="text"
            name="email"
            value={formData.email}
            placeholder="Email"
          />
          <i className="fa fa-envelope"></i>
        </div>
        <div className="inputContainer icon">
          {validator.message("password", formData.password, "required")}
          <input
            onChange={onChangeHandler}
            type="password"
            name="password"
            value={formData.password}
            placeholder="Password"
          />
          <i className="fa fa-lock"></i>
        </div>

        <Row>
          <Col sm={12}>
            <div className="inputContainer icon">
              {validator.message("country", formData.country, "required")}
              <select
                id="country"
                name="country"
                value={selectedCountry}
                onChange={onChangeHandler}
              >
                <option value="" disabled>
                  Select Country
                </option>
                {countries.map((country, index) => (
                  <option key={index} value={country}>
                    {country}
                  </option>
                ))}
              </select>
              <i className="fa fa-lock"></i>
            </div>
          </Col>
        </Row>
        <Form className="text-center mb-4">
          <Form.Check // prettier-ignore
            name="anonymous"
            type="switch"
            id="anonymous"
            label="Anonymize my name for public view"
            onChange={onChangeHandler}
            checked={formData.anonymous}
            className="custom-switch"
            style={{
              cursor: "pointer",
            }}
          />
        </Form>

        <ButtonSubmit
          title="Sign up"
          full
          disabled={validator.allValid() ? false : true}
        />
      </form>
      <br />
      <SocialButton
        loginGoogleUser={props.loginGoogleUser}
        loginFbUser={props.loginFbUser}
      />

      <Modal.Footer className="px-0 pt-2 pb-0">
        <div className="forgotSign justify-content-center">
          <div>
            Already have an account?{" "}
            <button
              className="btn btn-link link"
              onClick={props.handleSignInModal}
            >
              Sign in
            </button>
          </div>
        </div>
      </Modal.Footer>
    </SharedModal>
  );
}
const mapStateToProps = (state) => ({
  Loading: state.auth.loading,
  regData: state.auth.regData,
  regErr: state.auth.regErr,
});
const mapDispatchToProp = (dispatch) => ({
  onUserRegister: (userData) => dispatch(registerUser(userData)),
  loginGoogleUser: (data) => dispatch(loginGoogleUser(data)),
  loginFbUser: (data) => dispatch(loginFbUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProp)(SignUpModal);
