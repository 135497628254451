import {
  INIT_GET_METADATA,
  SUCCESS_GET_METADATA,
  FAIL_GET_METADATA,
  INIT_GET_STUDENT,
  SUCCESS_GET_STUDENT,
  FAIL_GET_STUDENT,
  ADD_STUDENT_TO_LIST,
  DELETE_STUDENT_FROM_LIST,
  INIT_GET_EVENT_LIST,
  SUCCESS_GET_EVENT_LIST,
  FAIL_GET_EVENT_LIST,
  INIT_GET_EVENT,
  SUCCESS_GET_EVENT,
  FAIL_GET_EVENT,
  CLEAR_STUDENT_LIST,
  HANDLE_EVENT_DATA_CHANGE,
  SUCCESS_DELETE_EVENT,
  INIT_DELETE_EVENT,
  FAIL_DELETE_EVENT,
  INIT_DOWNLOAD_EXCEL,
  SUCCESS_DOWNLOAD_EXCEL,
  FAIL_DOWNLOAD_EXCEL,
  INIT_EVENT_SYNC,
  SUCCESS_EVENT_SYNC,
  FAIL_EVENT_SYNC,
} from "../actions/actionsTypes";

const initialState = {
  loading: false,
  syncLoading: false,
  err: null,
  data: null,
  students: [],
  onBoardedStudents: [],
  eventList: null,
  eventData: null,
  file: null,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_EVENT_SYNC:
      return {
        ...state,
        syncLoading: true,
      };
    case SUCCESS_EVENT_SYNC:
      return {
        ...state,
        syncLoading: false,
      };
    case FAIL_EVENT_SYNC:
      return {
        ...state,
        syncLoading: false,
      };
    case INIT_DOWNLOAD_EXCEL:
      return {
        ...state,
        loading: true,
      };
    case SUCCESS_DOWNLOAD_EXCEL:
      return {
        ...state,
        loading: false,
        file: action.data,
      };
    case FAIL_DOWNLOAD_EXCEL:
      return {
        ...state,
        loading: false,
        err: action.err,
      };
    case CLEAR_STUDENT_LIST:
      return {
        ...state,
        students: [],
      };
    case INIT_GET_METADATA:
      return {
        ...state,
        loading: true,
      };
    case SUCCESS_GET_METADATA:
      return {
        ...state,
        loading: false,
        data: action.data,
        err: null,
      };
    case FAIL_GET_METADATA:
      return {
        ...state,
        loading: false,
        err: null,
      };

    case INIT_GET_EVENT:
      return {
        ...state,
        loading: true,
      };
    case SUCCESS_GET_EVENT:
      return {
        ...state,
        loading: false,
        onBoardedStudents: action.data?.event_students,
        eventData: action.data,
        err: null,
      };
    case INIT_DELETE_EVENT:
      return {
        ...state,
        loading: true,
      };
    case SUCCESS_DELETE_EVENT:
      return {
        ...state,
        loading: false,
        eventList: {
          ...state.eventList,
          data: state.eventList.data.filter((e) => e.id !== action.id),
        },
        err: null,
      };
    case FAIL_DELETE_EVENT:
      return {
        ...state,
        loading: false,
        err: action.error,
      };
    case FAIL_GET_EVENT:
      return {
        ...state,
        loading: false,
        err: null,
      };

    case INIT_GET_EVENT_LIST:
      return {
        ...state,
        loading: true,
      };
    case SUCCESS_GET_EVENT_LIST:
      return {
        ...state,
        loading: false,
        eventList: action.data,
        eventData: null,
        onBoardedStudents: [],
        students: [],
        err: null,
      };
    case FAIL_GET_EVENT_LIST:
      return {
        ...state,
        loading: false,
        err: null,
      };

    case INIT_GET_STUDENT:
      return {
        ...state,
        loading: true,
      };
    case SUCCESS_GET_STUDENT:
      const studentsChecker = action.data.map((st) => {
        const hasMatch = state.onBoardedStudents.find((e) => e.id === st.id);
        st.listed = false;
        if (hasMatch) {
          st.listed = true;
        }
        return st;
      });
      return {
        ...state,
        loading: false,
        students: studentsChecker,
        err: null,
      };
    case FAIL_GET_STUDENT:
      return {
        ...state,
        loading: false,
        err: null,
      };
    case ADD_STUDENT_TO_LIST:
      return {
        ...state,
        onBoardedStudents: [action.student, ...state.onBoardedStudents],
        students: state.students.map((e) => {
          if (e.id === action.student.id) {
            e.listed = true;
          }
          return e;
        }),
      };
    case DELETE_STUDENT_FROM_LIST:
      return {
        ...state,
        onBoardedStudents: state.onBoardedStudents.filter(
          (e) => e.id !== action.studentId
        ),
        students: state.students.map((e) => {
          if (e.id === action.studentId) {
            e.listed = false;
          }
          return e;
        }),
      };

    case HANDLE_EVENT_DATA_CHANGE:
      console.log(action.input, action.studentId);

      const inputName = action.input.target.name;
      const inputValue = action.input.target.value;

      return {
        ...state,
        onBoardedStudents: state.onBoardedStudents.map((e) => {
          if (e.id === action.studentId) {
            e[inputName] = inputValue;

            switch (inputName) {
              case "monthlyAmountUSD":
                e.monthlyAmountLKR = inputValue * e.exchangeRate;
                e.totalAmountUSD = inputValue * e.numberOfMonths;
                e.totalAmountLKR =
                  inputValue * e.numberOfMonths * e.exchangeRate;
                break;
              case "numberOfMonths":
                e.totalAmountUSD = inputValue * e.monthlyAmountUSD;
                e.totalAmountLKR =
                  inputValue * e.monthlyAmountUSD * e.exchangeRate;
                break;
              case "exchangeRate":
                e.monthlyAmountLKR = inputValue * e.monthlyAmountUSD;
                e.totalAmountLKR =
                  inputValue * e.monthlyAmountUSD * e.numberOfMonths;
                break;
              default:
                return e;
            }
          }
          return e;
        }),
      };
    default:
      return state;
  }
};
export default reducer;
