import React from "react";
import { useEffect } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { handleEventDataChange } from "../../../store/actions";
import DatePicker from "react-multi-date-picker";
import moment from "moment";

const EventDetailView = () => {
  const { onBoardedStudents, eventData } = useSelector(
    (state) => state.distributionEvent
  );
  const dispatch = useDispatch();
  const handleChangeDate = (e, studentId) => {
    const input = {
      target: {},
    };
    input.target.value = `${e.year}-${e.monthIndex}-${e.day}`;
    input.target.name = "dispersementDate";
    dispatch(handleEventDataChange(input, studentId));
  };
  const handleChange = (e, studentId) => {
    dispatch(handleEventDataChange(e, studentId));
  };
  return (
    <div>
      <div className="WhiteContent mt-3">
        <div className="tableWrapper sm-form">
          <table width="100%">
            <thead>
              <tr>
                <th>Student</th>
                <th>Sponsor</th>
                <th>Spo. Status</th>
                <th width="140">Disp. Date</th>
                <th>Ex. Rate</th>
                <th>Mo. USD</th>
                <th>Mo. LKR</th>
                <th>Months</th>
                <th>Total USD</th>
                <th>Total LKR</th>
                <th width="140">Note</th>
              </tr>
            </thead>
            <tbody>
              {!onBoardedStudents?.length ? (
                <tr>
                  <td colSpan={4}>
                    <div className="text-center">No students filtered.</div>
                  </td>
                </tr>
              ) : (
                onBoardedStudents?.map((student) => (
                  <tr key={student.id}>
                    <td>
                      {student.firstName} {student.lastName}
                    </td>

                    <td>
                      {student?.sponsor?.firstName} {student?.sponsor?.lastName}
                    </td>
                    <td>{student?.sponsor?.newSponsor ? "NEW" : "OLD"}</td>
                    <td>
                      <DatePicker
                        name="dispersementDate"
                        value={
                          student?.dispersementDate
                            ? student?.dispersementDate
                            : eventData.distributionDate
                        }
                        onChange={(e) => handleChangeDate(e, student.id)}
                        format={"YYYY-MM-DD"}
                        containerClassName={`form-control position-relative`}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        onChange={(e) => handleChange(e, student.id)}
                        name="exchangeRate"
                        value={student.exchangeRate}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        onChange={(e) => handleChange(e, student.id)}
                        name="monthlyAmountUSD"
                        value={student.monthlyAmountUSD}
                      />
                    </td>

                    <td>
                      <input
                        type="text"
                        // onChange={(e) => handleChange(e, student.id)}
                        disabled
                        name="monthlyAmountLKR"
                        value={student.monthlyAmountLKR}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        onChange={(e) => handleChange(e, student.id)}
                        name="numberOfMonths"
                        value={student.numberOfMonths}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        // onChange={(e) => handleChange(e, student.id)}
                        disabled
                        name="totalAmountUSD"
                        value={student.totalAmountUSD}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        // onChange={(e) => handleChange(e, student.id)}
                        disabled
                        name="totalAmountLKR"
                        value={student.totalAmountLKR}
                      />
                    </td>

                    <td>
                      <input
                        className="w-auto"
                        type="text"
                        onChange={(e) => handleChange(e, student.id)}
                        name="note"
                        value={student.note}
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default EventDetailView;
