/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import * as actionCreators from "../../store/actions";
import {
  loginModalHandle,
  updateCampaignStatusMessage,
} from "../../store/actions";

import Banner from "../../components/Campaign/Banner";
import Loading from "../../components/Reusable/Loading/Loading";
import PaymentBox from "../../components/Campaign/PaymentBox";
import MessageContainer from "../../components/Campaign/MessageContainer";
import CampaignList from "../../components/Campaign/CampaignList";
import CampaignPayment from "../../components/Reusable/Modal/CampaignPayment/CampaignPayment";
import { useParams } from "react-router-dom";
import MiddlePopup from "../../components/Reusable/Modal/MiddlePopup/MiddlePopup";

const Campaign = (props) => {
  const params=useParams()
  const [id, setId] = useState();
  const [show, setShow] = useState(false);
  const [tempConfirmAlert, setTempConfirmAlert] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [modalData, setModalData] = useState(null);

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const campaignPayment = useSelector((state) => state.campaignPayment);
  const campaign = useSelector((state) => state.campaign);

  useEffect(() => {
    const campId = params.id;
    setId(campId);
    props.getCampaign(campId); // GET CAMPAIGN BY ID
  }, [params.id || campaignPayment.data]);

  useEffect(() => {
    if (campaignPayment.data) {
      setShow(false);
      setShowPopup(true);

      const campaignData = { 
        donationAmount: campaignPayment.data[1].amount,
        campaignName: campaign.data.name,
      };

      setModalData(campaignData);
       
    }
  }, [campaignPayment.data]);
  useEffect(() => {
    if (campaignPayment.data) {
      props.getCampaign(id);
    }
  }, [campaignPayment.data]);
  useEffect(() => {
    if (campaign.data && campaign.data.userId) {
      dispatch(actionCreators.getCampaignList(campaign.data.userId));
    }
  }, [campaign.data]);

  useEffect(() => {
    if (tempConfirmAlert && auth.token) {
      setTempConfirmAlert(false);
      setShow(true);
    }
  }, [auth.token, tempConfirmAlert]);

  const sendMessage = (message) => {
    dispatch(updateCampaignStatusMessage(message, id, auth.token));
  };

  const handleModal = () => {
    setShow(!show);
  };

  const handlePayment = (payment) => {
    const data = {
      campaignId: id,
      detail: "Just pay",
      amount: payment.amount,
      //cardNumber: payment.cardNumber,
      //cvs: payment.cvs,
      //expireDate: payment.expireDate,
      method: payment.method,
      type: "campaign",
      anonymous: payment.anonymous,
      stripeTokenId: payment.stripeTokenId,
    };

    if (!auth.token) {
      data.user = {};
      data.user.firstName = payment.firstName;
      data.user.lastName = payment.lastName;
      data.user.email = payment.email;
    }

    if (payment.method === "pp") {
      props.createCampaignPaymentWithPaypal(data, auth.token); //PAYPAL PAYMENT
    } else if (payment.method === "cc") {
      props.createCampaignPaymentWithStripe(data, auth.token); // STRIPE PAYMENT
    }
  };
  let content = null;
  if (campaign.loading) {
    content = <Loading />;
  } else if (campaign.data) {
    content = (
      <div>
        {campaignPayment.loading && <Loading />}

        <Banner campaign={campaign} auth={auth} handleModal={handleModal} />
        <Container className="mt-5 mb-5">
          <Row>
            <Col md={5}>
              <PaymentBox campaign={campaign} handleModal={handleModal} />
            </Col>
            <Col md={7}>
              <MessageContainer
                campaign={campaign}
                auth={auth}
                sendMessage={sendMessage}
              />
            </Col>
          </Row>
        </Container>

        <CampaignList campaign={campaign} />

        <CampaignPayment
          show={show}
          handleModal={handleModal}
          handlePayment={handlePayment}
        />

        <MiddlePopup
          show={showPopup}
          onHide={() => setShowPopup(false)}
          modalData={modalData}
          donationType={"campaign"}
        />
      </div>
    );
  }
  return <>{content}</>;
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  campaignPayment: state.campaignPayment,
  campaign: state.campaign,
});
const mapDispatchToProps = (dispatch) => ({
  createCampaignPaymentWithStripe: (data, token) =>
    dispatch(actionCreators.createCampaignPaymentWithStripe(data, token)),
  createCampaignPaymentWithPaypal: (data, token) =>
    dispatch(actionCreators.createCampaignPaymentWithPaypal(data, token)),
  exicuteCampaignPaymentWithPaypal: (data, token) =>
    dispatch(actionCreators.exicuteCampaignPaymentWithPaypal(data, token)),
  getCampaign: (id) => dispatch(actionCreators.getCampaign(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Campaign);
