/*eslint-disable*/
import * as actions from "../actions/actionsTypes";

const initialState = {
  loading: false,
  data: [],
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.INIT_ACCOUNT:
      return {
        ...state,
        loading: true,
        data: state.data.concat({
          loading: true,
          stId: action.stId,
          data: null,
          error: null,
        }),
      };
    case actions.SUCCESS_ACCOUNT:
      return {
        ...state,
        loading: false,
        data: state.data.map((res) => {
          if (res.stId === action.stId) {
            res.loading = false;
            res.data = action.data;
            res.error = null;
          }
          return res;
        }),
      };

    case actions.FAIL_ACCOUNT:
      return {
        ...state,
        loading: false,
        data: state.data.map((res) => {
          if (res.stId === action.stId) {
            res.loading = false;
            action.err = action.err;
          }
          return res;
        }),
      };
    case actions.RESET_ACCOUNT:
      return {
        ...state,
        data: [],
      };
    default:
      return state;
  }
};
export default reducer;
