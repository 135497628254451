import * as actions from "./actionsTypes";
import axios from "../../axios";

const initStudentNews = () => ({
  type: actions.INIT_STUDENT_NEWS,
});
const successStudentNews = (data) => ({
  type: actions.SUCCESS_STUDENT_NEWS,
  data,
});
const failStudentNews = (err) => ({
  type: actions.FAIL_STUDENT_NEWS,
  err,
});
export const resetStudentNews = () => ({
  type: actions.RESET_NEWS,
});

export const getStudentNews = (sId) => async (dispatch) => {
  try {
    dispatch(initStudentNews());
    const news = await axios.get(`salesforce/students/news/${sId}`);
    return dispatch(successStudentNews(news.data));
  } catch (err) {
    return dispatch(failStudentNews(err));
  }
};
