import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { cancelSponsorship } from "../../../../../store/actions";
import SponsorshipCancel from "../../../../Reusable/Modal/SponsorshipCancel/SponsorshipCancel";
import "./Sponsorship.scss";

const Sponsorship = (props) => {
  const [sponsorCancelationNote, setSponsorCancelationNote] = useState("");
  const [show, setShow] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const { cancelLoading } = useSelector((state) => state.sponsor);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!cancelLoading) {
      setShow(false);
    }
  }, [cancelLoading]);
  const handleConfirm = () => {
    const data = {
      sponsorshipId: props.sponsorship.id,
      sponsorCancelationNote,
    };
    dispatch(cancelSponsorship(data, token))
      .then(() => {
        toast.success("Sponsorship successfully canceled.");
      })
      .catch(() => {
        toast.error("Failed to cancel sponsorship.");
      });
  };

  return (
    <div className="Sponsorship">
      <ul>
        <li>
          <strong>
            {props.index === 0
              ? "Current Monthly Sponsorship Amount"
              : "Sponsorship Amount"}
          </strong>
          ${props.sponsorship.amount}
        </li>
        <li>
          <strong>
            {props.index === 0
              ? "Current Sponsorship Start Date"
              : "Sponsorship Start Date"}
          </strong>
          {moment(props.sponsorship.startDate).local().format("MMMM D, YYYY")}
        </li>
        <li>
          <strong>
            {props.index === 0
              ? "Length of Current Sponsorship"
              : "Length of Sponsorship Since Inception"}
          </strong>
          {moment(props.sponsorship.startDate).local().fromNow(true)}
        </li>
        {props.index === 0 && (
          <li>
            <strong>Current Sponsorship Status</strong>
            {props.sponsorship.status === "active" && (
              <span className="Green">
                <i className="fa fa-check-circle"></i> Active
              </span>
            )}

            {props.sponsorship.status === "canceled" && (
              <span className="Red">
                <i className="fa fa-ban"></i> Canceled
              </span>
            )}
            {props.sponsorship.status === "pending" && (
              <span className="Orange">
                <i className="fa fa-hourglass-start"></i> Pending payment
              </span>
            )}
            {props.sponsorship.status === "renewed" && (
              <span className="yellow">
                <i className="fa fa-ban"></i> Renewed
              </span>
            )}
          </li>
        )}
      </ul>
      {props.sponsorship.status !== "renewed" && (
        <div className="cancelLink">
          {props.index === 0 && props.sponsorship.status !== "canceled" ? (
            <div>
              Would you like to cancel the sponsorship?{" "}
              <button
                onClick={() => setShow(true)}
                className="btn btn-link p-0"
              >
                Cancel Sponsorship
              </button>
            </div>
          ) : (
            <div>
              This sponsorship was canceled on{" "}
              {moment(props.sponsorship.cancelledAt).local().format("MMMM D, YYYY")}
            </div>
          )}
        </div>
      )}
      <SponsorshipCancel
        show={show}
        title="Sponsorship Cancelation"
        description={`Please confirm your decision to cancel your sponsorship for ${props.sponsorship?.beneficiary?.firstName} ${props.sponsorship?.beneficiary?.lastName}`}
        note={sponsorCancelationNote}
        handleChangeNote={(e) => setSponsorCancelationNote(e.target.value)}
        handleClose={() => setShow(false)}
        handleConfirm={handleConfirm}
      />
    </div>
  );
};

export default Sponsorship;
