import React from "react";
import ContactForm from "../../components/ContactUs/ContactForm/ContactForm";
import FundHeader from "../../components/ContactUs/ContactHeader/ContactHeader";
import NotFound from "../../components/Reusable/NotFound";
import "./ContactUs.scss";
import { useSelector } from "react-redux";
import PageSections from "../../components/Reusable/PageSections/PageSections";

function ContactUs() {
  const { list } = useSelector((state) => state.pages);

  const page = list?.find((p) => p.group === "/contact-us");

  if (page) {
    return (
      <div>
        <FundHeader section={page?.pageSections.find(s=>s.section.key==="contact-header")} />
        <ContactForm />
        <PageSections pageSections={page.pageSections} />
      </div>
    );
  } else {
    return <NotFound />;
  }
}

export default ContactUs;
