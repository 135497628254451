import React, { useState, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

import {
  FormControl,
  FormGroup,
  FormLabel,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { useEffect } from "react";

function TemplateFifteen({ section, editMode, handleSectionDataChange }) {
  const editorRef = useRef(null);
  const [data, setData] = useState({
    title: "",
    tags: [],
  });
  useEffect(() => {
    handleSectionDataChange({
      ...section,
      index: section.index,
      data: data,
    });
  }, [data]);

  //===EDIT MODE SET DATA
  useEffect(() => {
    if (editMode && section.data) {
      setData(section.data);
    }
  }, [editMode]);
  //===END EDIT MODE SET DATA

  const handleChange = (e) => {
    setData((dt) => ({ ...dt, [e.target.name]: e.target.value }));
  };

  const handleChangeTags = (e) => {
    const copyTags = [...data.tags];
    const tagIndex = e.target.name.split("_")[1];
    copyTags[tagIndex][e.target.name.split("_")[0]] = e.target.value;
    setData((dt) => ({ ...dt, tags: copyTags }));
  };
  const handleChangeSubTabs = (e) => {
    const parentIndex = e.target.name.split("_")[1];
    const subIndex = e.target.name.split("_")[2];
    const copyTags = [...data.tags];
    copyTags[parentIndex].subTab[subIndex].image = e.target.value;
    setData((dt) => ({ ...dt, tags: copyTags }));
  };
  const handleChangeMainTab = (e, parentIndex, subIndex) => {
    const copyTags = [...data.tags];
    copyTags[parentIndex].subTab[subIndex].text = e;
    setData((dt) => ({ ...dt, tags: copyTags }));
  };
  const addSection = () => {
    setData((dt) => ({
      ...dt,
      tags: [...dt.tags, { name: "", subTab: [] }],
    }));
  };
  const addSubSection = (parentIndex) => {
    const copyData = { ...data };
    copyData.tags[parentIndex].subTab.push({ image: "", text: "" });
    setData(copyData);
  };

  const handleSectionDelete = (index) => {
    if (window.confirm("Are you sure to delete this?")) {
      const copyData = { ...data };
      copyData.tags.splice(index, 1);
      setData(copyData);
    }
  };
  return (
    <div>
      <FormGroup>
        <FormLabel>Title</FormLabel>
        <FormControl name="title" value={data?.title} onChange={handleChange} />
      </FormGroup>


      <div className="RowColors">
        {data?.tags?.map((v, i) => (
          <Row  key={i}>
            <Col sm={3}>
              <FormGroup>
                <FormLabel>Name {i + 1}</FormLabel>
                <FormControl
                  name={`name_${i}`}
                  value={data?.tags[i].name}
                  onChange={handleChangeTags}
                />
              </FormGroup>
            </Col>
            <Col sm={8}>
              <div className="RowColors">
                {v?.subTab.map((sub, ind) => {
                  return (
                    <div key={ind}>
                      <FormGroup>
                        <FormLabel>
                          Image {i + 1}.{ind + 1}
                        </FormLabel>
                        <FormControl
                          name={`image_${i}_${ind}`}
                          value={data?.tags[i]?.subTab[ind]?.image}
                          onChange={handleChangeSubTabs}
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormLabel>
                          Content {i + 1}.{ind + 1}
                        </FormLabel>
                        <Editor
                          textareaName={`text_${i}_${ind}`}
                          value={data?.tags[i]?.subTab[ind]?.text}
                          apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                          onInit={(evt, editor) => (editorRef.current = editor)}
                          init={{
                            plugins: [
                              "lists link image paste help wordcount code",
                              "searchreplace",
                              "insertdatetime media",
                            ],
                          }}
                          onEditorChange={(e) => handleChangeMainTab(e, i, ind)}
                        />
                      </FormGroup>
                    </div>
                  );
                })}
              </div>
              <Button onClick={() => addSubSection(i)}>ADD SUB TAB</Button>
            </Col>
            <Col sm={1} className="pt-2">
              <Button
                onClick={() => handleSectionDelete(i)}
                className="mt-4"
                variant="danger"
              >
                <i className="fa fa-trash" />
              </Button>
            </Col>
          </Row>
        ))}
      </div>
      <Button onClick={addSection}>ADD TAB</Button>
    </div>
  );
}

export default TemplateFifteen;
