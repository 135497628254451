import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ProgressStudents from "../../Reusable/ProgressStudent/ProgressStudents";
import "./ProgressArea.scss";

function ProgressArea(props) {
  const student = props.student?.data;
  const { me } = useSelector((state) => state.auth);
  let activeSponsorship = null;
  if (student.sponsorships.length > 0) {
    activeSponsorship = student?.sponsorships.filter(
      (sponsor) => sponsor.status === "active" || sponsor.status === "pending"
    );
  }

  let sponsorBtn = (
    <div className="sponser need">
      <button onClick={props.handleModalShow}>
        <span>${student.amount}</span>
        <div>Start sponsoring</div>
      </button>
      <span>from ${student.amount} per month</span>
    </div>
  );
  if (student.sponsorships.length > 0 && activeSponsorship.length > 0) {
    let sponsorship = student.sponsorships?.find(
      (s) => s.status === "active" || s.status === "pending"
    );

    if (sponsorship?.user?.id === me?.data?.id) {
      sponsorBtn = (
        <div key={sponsorship?.id} className="sponser">
          <Link to={`/sponsorship/${sponsorship?.id}`}>
            <span>${sponsorship ? sponsorship.amount : student?.amount}</span>
            <div>
              <strong>View plan</strong>

              {sponsorship?.user?.firstName + " " + sponsorship?.user?.lastName}
            </div>
          </Link>
        </div>
      );
    } else if (sponsorship?.user?.anonymous) {
      sponsorBtn = (
        <div key={sponsorship?.id} className="sponser">
          <button disabled>
            <span>${sponsorship ? sponsorship.amount : student?.amount}</span>
            <div>
              <strong>User type</strong>
              Anonymous
            </div>
          </button>
        </div>
      );
    } else {
      sponsorBtn = (
        <div key={sponsorship?.id} className="sponser">
          <Link to={"#"}>
            <span>${sponsorship ? sponsorship.amount : student?.amount}</span>
            <div>
              <strong>Sponsored by</strong>

              {sponsorship?.user?.firstName + " " + sponsorship?.user?.lastName}
            </div>
          </Link>
          <span>at ${student.amount} per month</span>
        </div>
      );
    }
  }
  return (
    <div className="ProgressArea">
      <div className="progressTabLine">
        <ProgressStudents student={student} />
      </div>
      {sponsorBtn}
    </div>
  );
}

export default ProgressArea;
