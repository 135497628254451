import * as actions from "./actionsTypes";

import axios from "../../axios";

const initAccount = (stId) => ({
  type: actions.INIT_ACCOUNT,
  stId,
});

const successAccount = (data, stId) => ({
  type: actions.SUCCESS_ACCOUNT,
  data: data,
  stId: stId,
});

const failAccount = (err) => ({
  type: actions.FAIL_ACCOUNT,
  err,
});

export const getAccount = (acIds, stId) => {
  const accounts = {
    accounts: acIds,
  };
  return (dispatch) => {
    dispatch(initAccount(stId));
    axios
      .post(`account`, accounts)
      .then((res) => {
        dispatch(successAccount(res.data.data, stId));
      })
      .catch((err) => {
        return dispatch(failAccount(err));
      });
  };
};
export const resetAccount = () => ({
  type: actions.RESET_ACCOUNT,
});
