/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Alert, Button, InputGroup, FormControl } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import UsersList from "../../../components/admin/Users/UsersList";
import Loading from "../../../components/Reusable/Loading/Loading";
import { getAllUsers } from "../../../store/actions";

const pageSize = 30
const attributes = ['id', 'firstName', 'lastName', "type"]
function Users() {
  const [page, setPage] = useState(0);
  const [name, setName] = useState("");

  const dispatch = useDispatch();

  const { users, loading } = useSelector((state) => state.auth);
  useEffect(() => {
    const filters = { page, name, pageSize, attributes };
    dispatch(getAllUsers(filters));
  }, [dispatch, page]);

  const handleSearch = (e) => {
    e.preventDefault()
    const filters = { page, name, pageSize, attributes };
    dispatch(getAllUsers(filters));
  }
  const handleCurrentPagination = (e) => {
    const pageNumber = e.selected + 1
    setPage(pageNumber);
    const filters = { pageNumber, name, pageSize, attributes };
    dispatch(getAllUsers(filters));
  };

  let content = null;
  if (!loading) {
    if (users?.rows.length) {
      content = <UsersList users={users?.rows} />;
    } else {
      content = <Alert variant="warning" className="text-center">Users not match... </Alert>;
    }
  }

  return (
    <>
      {loading ? <Loading /> : null}
      <div className="HeaderText">
        List of Sponsorships
        <div className="Filters">
          <form onSubmit={handleSearch}>
            <InputGroup className="ml-3">

              <FormControl value={name} onChange={e => setName(e.target.value)} />
              <InputGroup.Prepend>
                <Button type="submit"> Search by name</Button>
              </InputGroup.Prepend>
            </InputGroup>
          </form>
        </div>
      </div>
      {content}
      <ReactPaginate
        pageCount={users?.pages}
        onPageChange={handleCurrentPagination}
        containerClassName="paginations"
      />
    </>
  );
}

export default Users;
