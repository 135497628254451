import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { useEffect } from "react";

function BasicTemplate({ section, editMode, handleSectionDataChange }) {
  const [data, setData] = useState({
    title: "",
    description: "",
    image: "",
  });
  useEffect(() => {
    handleSectionDataChange({
      ...section,
      index: section.index,
      data: data,
    });
  }, [data]);

  //===EDIT MODE SET DATA
  useEffect(() => {
    if (editMode&&section.data) {
      setData(section.data);
    }
}, [editMode]);
  //===END EDIT MODE SET DATA

  const handleChange = (e) => {
    setData((dt) => ({ ...dt, [e.target.name]: e.target.value }));
  };


  return (
    <div>
      <FormGroup>
        <FormLabel>Title</FormLabel>
        <FormControl name="title" value={data?.title} onChange={handleChange} />
      </FormGroup>
      <FormGroup>
        <FormLabel>Content</FormLabel>
        <FormControl name="description" as="textarea" value={data?.description} onChange={handleChange} />
      </FormGroup>
      <FormGroup>
        <FormLabel>Image</FormLabel>
        <FormControl name="image" value={data?.image} onChange={handleChange} />
      </FormGroup>
    </div>
  );
}

export default BasicTemplate;
