/**
 * Created by asankah on 11/9/15.
 */
import React, { Component } from 'react';
import './SocialMediaLinks.css';

class SocialMediaLinks extends Component {
  render() {
    return (
      <div className="socialMediaLinks">
       <div>
       <h4 className='mr-3'>Follow us on Social Media</h4>
        <div id="social">
          <a className="fa fa-facebook" target="_blank" rel="opener noopener noreferrer" href="https://www.facebook.com/educatelanka" >&nbsp;</a>
          <a className="fa fa-twitter" target="_blank" rel="opener noopener noreferrer" href="https://twitter.com/educatelanka" >&nbsp;</a>
          <a className="fa fa-instagram" target="_blank" rel="opener noopener noreferrer" href="https://www.instagram.com/educatelanka" >&nbsp;</a>
          <a className="fa fa-linkedin" target="_blank" rel="opener noopener noreferrer" href="https://www.linkedin.com/company/educate-lanka" >&nbsp;</a>
          <a className="fa fa-youtube" target="_blank" rel="opener noopener noreferrer" href="https://www.youtube.com/EducateLanka" >&nbsp;</a>
        </div>
        <h4 className='pt-4'><div><a className='inherit'>get our newsletter</a></div></h4>
       </div>
      </div>
    );
  }

}

export default SocialMediaLinks;
