import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { FormControl, FormGroup, FormLabel, Row, Col } from "react-bootstrap";
import { useEffect } from "react";

function TemplateFore({ section, editMode, handleSectionDataChange }) {
  const [data, setData] = useState({
    title: "",
    largeText: "",
    text1: "",
    grid1: { title: "", text1: "" },
    grid2: { title: "", text1: "" },
    grid3: { title: "", text1: "" },
    grid4: { title: "", text1: "" },
  });
  useEffect(() => {
    handleSectionDataChange({
      ...section,
      index: section.index,
      data: data,
    });
  }, [data]);

  //===EDIT MODE SET DATA
  useEffect(() => {
    if (editMode&&section.data) {
      setData(section.data);
    }
}, [editMode]);
  //===END EDIT MODE SET DATA

  const handleChange = (e, type) => {
    if (type) {
      setData((dt) => ({
        ...dt,
        [type]: {
          ...dt[type],
          [e.target.name]: e.target.value,
        },
      }));
    } else {
      setData((dt) => ({ ...dt, [e.target.name]: e.target.value }));
    }
  };

  const handleChangeText = (e) => {
    setData((dt) => ({ ...dt, text1: e }));
  };
  const handleChangeTextOne = (e) => {
    setData((dt) => ({ ...dt, grid1: { ...dt.grid1, text1: e } }));
  };
  const handleChangeTextTwo = (e) => {
    setData((dt) => ({ ...dt, grid2: { ...dt.grid2, text1: e } }));
  };
  const handleChangeTextThree = (e) => {
    setData((dt) => ({ ...dt, grid3: { ...dt.grid3, text1: e } }));
  };
  const handleChangeTextFore = (e) => {
    setData((dt) => ({ ...dt, grid4: { ...dt.grid4, text1: e } }));
  };
  return (
    <div>
      <FormGroup>
        <FormLabel>Title</FormLabel>
        <FormControl name="title" value={data?.title} onChange={handleChange} />
      </FormGroup>
      <FormGroup>
        <FormLabel>Large Text</FormLabel>
        <FormControl
          name="largeText"
          value={data?.largeText}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Content</FormLabel>
        <Editor
          name="text1"
          value={data?.text1}
          apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
          init={{
            plugins: [
              "lists link image paste help wordcount code",
              "searchreplace",
              "insertdatetime media",
            ],
            image_title: true,
            image_dimensions: true,
            image_advtab: true
          }}
          onEditorChange={handleChangeText}
        />
      </FormGroup>
      <Row>
        <Col sm={3}>
          <FormGroup>
            <FormLabel>Section Title</FormLabel>
            <input
              className="form-control"
              name="title"
              value={data?.grid1?.title}
              onChange={(e) => handleChange(e, "grid1")}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Content</FormLabel>
            <Editor
              name="text1"
              value={data?.grid1?.text1}
              apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
              init={{
                plugins: [
                  "lists link image paste help wordcount code",
                  "searchreplace",
                  "insertdatetime media",
                ],
              }}
              onEditorChange={handleChangeTextOne}
            />
          </FormGroup>
        </Col>
        <Col sm={3}>
          <FormGroup>
            <FormLabel>Section Title</FormLabel>
            <input
              className="form-control"
              name="title"
              value={data?.grid2?.title}
              onChange={(e) => handleChange(e, "grid2")}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Content</FormLabel>
            <Editor
              name="text1"
              value={data?.grid2?.text1}
              apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
              init={{
                plugins: [
                  "lists link image paste help wordcount code",
                  "searchreplace",
                  "insertdatetime media",
                ],
              }}
              onEditorChange={handleChangeTextTwo}
            />
          </FormGroup>
        </Col>
        <Col sm={3}>
          <FormGroup>
            <FormLabel>Section Title</FormLabel>
            <input
              className="form-control"
              name="title"
              value={data?.grid3?.title}
              onChange={(e) => handleChange(e, "grid3")}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Content</FormLabel>
            <Editor
              name="text1"
              value={data?.grid3?.text1}
              apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
              init={{
                plugins: [
                  "lists link image paste help wordcount code",
                  "searchreplace",
                  "insertdatetime media",
                ],
              }}
              onEditorChange={handleChangeTextThree}
            />
          </FormGroup>
        </Col>
        <Col sm={3}>
          <FormGroup>
            <FormLabel>Section Title</FormLabel>
            <input
              className="form-control"
              name="title"
              value={data?.grid4?.title}
              onChange={(e) => handleChange(e, "grid4")}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Content</FormLabel>
            <Editor
              name="text1"
              value={data?.grid4?.text1}
              apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
              init={{
                plugins: [
                  "lists link image paste help wordcount code",
                  "searchreplace",
                  "insertdatetime media",
                ],
              }}
              onEditorChange={handleChangeTextFore}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
}

export default TemplateFore;
