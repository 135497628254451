import React from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { NavHashLink } from 'react-router-hash-link';
import './FooterMenu.scss'
import { Row, Col } from 'react-bootstrap'

function FooterMenu() {
    const { hash } = useLocation();
    return (

        <Row>
            <Col md={3}>
                <div className="footerMenu">
                    <h4>Connect</h4>
                    <ul >
                        <li><NavLink to="/contact-us">Contact Us</NavLink></li>
                        <li><NavLink>Read Our Blog</NavLink></li>
                        <li><NavLink>Watch Live Events</NavLink></li>
                    </ul>
                </div>
            </Col>
            <Col md={3}>
                <div className="footerMenu">
                    <h4>About us</h4>
                    <ul >
                        <li><NavHashLink className={hash === `#Mission` ? "on" : ""} to="/about#Mission">Mission</NavHashLink></li>
                        <li><NavHashLink className={hash === `#Impact` ? "on" : ""} to="/about#Impact">Impact</NavHashLink></li>
                        <li><NavHashLink className={hash === `#Partners` ? "on" : ""} to="/about#Partners">Partners</NavHashLink></li>
                        <li><NavHashLink className={hash === `#Faqs` ? "on" : ""} >Faqs</NavHashLink></li>
                    </ul>
                </div>
            </Col>
            <Col md={3}>
                <div className="footerMenu">
                    <h4>Resources</h4>
                    <ul >
                        <li><NavLink>Press</NavLink></li>
                        <li><NavLink>Downloads</NavLink></li>
                        <li><NavLink>financials</NavLink></li>

                    </ul>
                </div>
            </Col>
            <Col md={3}>
                <div className="footerMenu">
                    <h4>Get Involved</h4>
                    <ul >

                        <li><NavLink to="/student-list">Sponsor a Student</NavLink></li>
                        <li><NavLink to="/educate-lanka-fund">Donate</NavLink></li>
                        <li><NavLink to="/create-campaign">Fundraise</NavLink></li>
                        <li><NavLink to="https://docs.google.com/forms/d/e/1FAIpQLSersqmIXGIArLZh6_kpSekvw95DfgeMJcXZ2LAlZVje10-_cg/viewform" target="_blank">Volunteer</NavLink></li>
                        <li><NavLink to="https://docs.google.com/forms/d/e/1FAIpQLSdE7A_VtLr1Xx37yaLw0NxyXkyGEKyC_gqE24nPThsCTpgxgQ/viewform" target="_blank">Partner</NavLink></li>
                        {/* <li><NavLink to="/pages/get-involved">Get Involved</NavLink></li> */}
                    </ul>
                </div>
            </Col>
        </Row>

    )
}

export default FooterMenu
