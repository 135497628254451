import { toast } from "react-toastify";
import axios from "../../axios";
import {
  INIT_CONTACT_US,
  SUCCESS_CONTACT_US,
  FAIL_CONTACT_US,
  RESET_CONTACT_US,
} from "./actionsTypes";

const initContactUs = () => ({
  type: INIT_CONTACT_US,
});
const successContactUs = (data) => ({
  type: SUCCESS_CONTACT_US,
  data,
});
const failContactUs = (err) => ({
  type: FAIL_CONTACT_US,
});

export const sendContactUs = (data) => {
  return (dispatch) => {
    dispatch(initContactUs());
    axios
      .post(`contact`, data)
      .then((res) => {
        toast.success("Thank you for contacting us.", { autoClose: 8000 });
        dispatch(successContactUs(res));
      })
      .catch((err) => {
        dispatch(failContactUs());
      });
  };
};

export const contactUsReset = () => ({
  type: RESET_CONTACT_US,
});
