const addThousandSeparator = (value) => {

    const number = parseFloat(value);

    if (isNaN(number)) {
        return '';
    }

    const hasDecimals = value.toString().includes('.');

    const formattedNumber = number.toLocaleString('en-US', {
        minimumFractionDigits: hasDecimals ? 2 : 0,
        maximumFractionDigits: hasDecimals ? 2 : 0,
    });

    return formattedNumber;

};

export default addThousandSeparator;