import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import {
  FormControl,
  FormGroup,
  FormLabel,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { useEffect } from "react";
import moment from "moment";

function TemplateOne({ section, editMode, handleSectionDataChange }) {
  const [data, setData] = useState({
    title: "",
    backgroundImage: "",
    text1: "",
    goals: [],
    btnText: "",
    btnLink: "",
    text2: "",
  });
  useEffect(() => {
    handleSectionDataChange({
      ...section,
      index: section.index,
      data: data,
    });
  }, [data]);

  //===EDIT MODE SET DATA
  useEffect(() => {
    if (editMode && section.data) {
      setData(section.data);
    }
  }, [editMode]);
  //===END EDIT MODE SET DATA

  const handleChange = (e, type) => {
    if (type) {
      setData((dt) => ({
        ...dt,
        [type]: {
          ...dt[type],
          [e.target.name]: e.target.value,
        },
      }));
    } else {
      setData((dt) => ({ ...dt, [e.target.name]: e.target.value }));
    }
  };

  const handleChangeText = (e) => {
    setData((dt) => ({ ...dt, text1: e }));
  };
  const handleChangeTwo = (e) => {
    setData((dt) => ({ ...dt, text2: e }));
  };

  const handleChangeGoals = (e) => {
    const copyGoals = [...data.goals];
    const goalIndex = e.target.name.split("_")[1];
    copyGoals[goalIndex][e.target.name.split("_")[0]] = e.target.value;
    setData((dt) => ({ ...dt, goals: copyGoals }));
  };
  const addGoals = (e) => {
    setData((dt) => {
      dt.goals = dt.goals.map((d) => {
        d.active = false;
        return d;
      });
      return {
        ...dt,
        goals: [
          ...dt.goals,
          {
            goal: "",
            goalLabel: "",
            startDate: moment().format("L"),
            active: true,
          },
        ],
      };
    });
  };
  const handleSectionDelete = (index) => {
    if (window.confirm("Are you sure to delete this?")) {
      const copyData = {...data};
      copyData.goals.splice(index,1)
      setData(copyData);
    }
  };
  return (
    <div>
      <FormGroup>
        <FormLabel>Title</FormLabel>
        <FormControl name="title" value={data?.title} onChange={handleChange} />
      </FormGroup>
      <FormGroup>
        <FormLabel>Content</FormLabel>
        <Editor
          name="text1"
          value={data?.text1}
          apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
          init={{
            plugins: [
              "lists link image paste help wordcount code",
              "searchreplace",
              "insertdatetime media",
            ],
            image_title: true,
            image_dimensions: true,
            image_advtab: true
          }}
          onEditorChange={handleChangeText}
        />
      </FormGroup>

      <FormGroup>
        <FormLabel>Background Image</FormLabel>
        <FormControl
          name="backgroundImage"
          value={data?.backgroundImage}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Button Text</FormLabel>
        <FormControl
          name="btnText"
          value={data?.btnText}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Button Link</FormLabel>
        <FormControl
          name="btnLink"
          value={data?.btnLink}
          onChange={handleChange}
        />
      </FormGroup>

      <FormGroup>
        <FormLabel>Content</FormLabel>
        <Editor
          name="text2"
          value={data?.text2}
          apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
          init={{
            plugins: [
              "lists link image paste help wordcount code",
              "searchreplace",
              "insertdatetime media",
            ],
            image_title: true,
            image_dimensions: true,
            image_advtab: true
          }}
          onEditorChange={handleChangeTwo}
        />
      </FormGroup>
      <div className="RowColors">
      {data?.goals?.map((v, i) => (
        <Row key={i}>
          <Col sm={3}>
            <FormGroup>
              <FormLabel>Goal {i + 1}</FormLabel>
              <FormControl
                disabled={!data?.goals[i].active}
                name={`goal_${i}`}
                value={data?.goals[i].goal}
                onChange={handleChangeGoals}
              />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <FormLabel>Goal Label {i + 1}</FormLabel>
              <FormControl
                disabled={!data?.goals[i].active}
                name={`goalLabel_${i}`}
                value={data?.goals[i].goalLabel}
                onChange={handleChangeGoals}
              />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <FormLabel>Start Date {i + 1}</FormLabel>
              <FormControl
                type="date"
                disabled={!data?.goals[i].active}
                name={`startDate_${i}`}
                value={data?.goals[i].startDate}
                onChange={handleChangeGoals}
              />
            </FormGroup>
          </Col>
          <Col sm={1} className="pt-1">
            <Button
              onClick={() => handleSectionDelete(i)}
              className="mt-4"
              variant="danger"
            >
              <i className="fa fa-trash" />
            </Button>
          </Col>
        </Row>
      ))}
</div>
      <Button onClick={addGoals}>ADD SECTION</Button>
    </div>
  );
}

export default TemplateOne;
