import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useMemo } from "react";
import moment from "moment";

import CreateEvent from "../../../components/admin/DistributionEvent/CreateEvent";
import EventDetailView from "../../../components/admin/DistributionEvent/EventDetailView";
import StudentFilter from "../../../components/admin/DistributionEvent/StudentFilter";
import useYupValidationResolver from "../../../utils/useYupValidationResolver";
import {
  addStudentToList,
  createDistributionEvent,
  downloadDistributionEventExcel,
  getDistributionEvent,
  syncDistributionEvent,
  updateDistributionEvent,
} from "../../../store/actions";

const Add = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [filterModal, setFilterModal] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const { loading, syncLoading, eventData, onBoardedStudents, students } =
    useSelector((state) => state.distributionEvent);

  const validationSchema = useMemo(() => {
    const validation = {
      name: yup.string().required("Name field is required"),
      location: yup.string().required("Location field is required"),
      loExpenses: yup
        .number()
        .required("Expenses field is required")
        .positive("Expenses must be a positive number"),
      dateOfDistribution: yup.string().required("Date field is required"),
      exchangeRate: yup
        .number()
        .typeError("Rate must be a number")
        .required("Rate field is required")
        .positive("Rate must be a positive number")
        .integer("Rate must be a integer number"),
      numberOfMonths: yup
        .number()
        .typeError("Months must be a number")
        .required("Months field is required")
        .positive("Months must be a positive number")
        .integer("Months must be a integer number"),
    };
    return yup.object(validation);
  }, []);

  const resolver = useYupValidationResolver(validationSchema);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm({
    resolver,
    defaultValues: {
      name: "",
      term: "",
      dateOfDistribution: moment().format("YYYY-MM-DD"),
      location: "",
      loExpenses: "",
      exchangeRate: "",
      amount: 0,
      totalAmount: 0,
      numberOfMonths: 1,
      comments: "",
      status: "CREATED",
    },
  });

  useEffect(() => {
    if (eventData) {
      for (let key in eventData) {
        for (let fieldKey in control._fields) {
          if (fieldKey === key) {
            setValue(key, eventData[key]);
          }
        }
      }
    }
  }, [eventData]);

  useEffect(() => {
    if (id) {
      setEditMode(true);
      dispatch(getDistributionEvent(id));
    }
  }, [id]);

  useEffect(() => {
    if (onBoardedStudents.length) {
      setValue(
        "totalAmount",
        onBoardedStudents.reduce((a, b) => {
          return a + parseInt(b.totalAmountLKR);
        }, 0)
      );
      setValue(
        "amount",
        onBoardedStudents.reduce((a, b) => {
          return a + parseInt(b.totalAmountUSD);
        }, 0)
      );
    }
  }, [onBoardedStudents]);

  const addStudent = (id) => {
    console.log(id);
    const student = students?.find((st) => st.id === id);
    const hasActiveSponsorship = student?.sponsorships?.filter(
      (e) => e.status === "active"
    );
    let sponsor = {
      id: "",
      salutation: "",
      firstName: "Educate Lanka",
      lastName: "Foundation",
      newSponsor: false,
      cancelledSponsor: false,
    };
    if (hasActiveSponsorship.length) {
      const someMonthsBefore = moment()
        .subtract(getValues("numberOfMonths"), "months")
        .format("YYYY-MM-DD");
      sponsor = hasActiveSponsorship[0].user;

      sponsor.newSponsor = moment(hasActiveSponsorship[0].startDate).isAfter(
        someMonthsBefore
      );
      sponsor.cancelledSponsor = false;
    }

    const data = {
      id: id,
      firstName: student.firstName,
      lastName: student.lastName,
      monthlyAmountUSD: student?.amount,
      dispersementDate : getValues("dateOfDistribution"),
      monthlyAmountLKR: parseInt(student?.amount * getValues("exchangeRate")),
      numberOfMonths: parseInt(getValues("numberOfMonths")),
      exchangeRate: parseInt(getValues("exchangeRate")),
      totalAmountUSD: parseInt(student?.amount * getValues("numberOfMonths")),
      totalAmountLKR: parseInt(
        student?.amount *
          getValues("numberOfMonths") *
          getValues("exchangeRate")
      ),
      notes: null,
      sponsor: sponsor,
    };

    dispatch(addStudentToList(data));
  };
  const handleFormSubmit = async (data) => {
    data.event_students = onBoardedStudents;
    data.distributionDate = data.dateOfDistribution;
    data.distributionDate = data.dateOfDistribution;

    if (editMode) {
      data.id = +id;
      dispatch(updateDistributionEvent(data));
    } else {
      data.term = "";
      data.totalAmount = 1000;
      data.distributionDate = moment(data.distributionDate).format(
        "YYYY-MM-DD"
      );
      data.dateOfDistribution = moment(data.dateOfDistribution).format(
        "YYYY-MM-DD"
      );
      const response = await dispatch(createDistributionEvent(data));
      if (response?.data?.id) {
        navigate(`/admin/distribution-events/edit/${response?.data?.id}`);
      }
    }
  };
  const handleFileDownload = async () => {
    const file = await dispatch(downloadDistributionEventExcel(id));
    window.location.href = `${process.env.REACT_APP_SERVER}content${file.filePath}`;
  };
  const HandleSyncDistributionEvent = async () => {
    if (
      window.confirm(
        "You are going to sync the data to SalesForce, please confirm"
      )
    ) {
      await dispatch(syncDistributionEvent(id));
    }
  };
  return (
    <Form noValidate onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="HeaderTextCMS justify-content-between">
        Distribution Event{" "}
        <div>
          {editMode ? (
            <>
              <Button
                disabled={syncLoading}
                variant="info"
                className="mr-2"
                onClick={HandleSyncDistributionEvent}
              >
                <i
                  className={`fa fa-refresh ${syncLoading ? "fa-spin" : ""}`}
                  aria-hidden="true"
                ></i>{" "}
                Sync to Salesforce
              </Button>
              <Button
                disabled={loading}
                variant="success"
                className="mr-2"
                onClick={handleFileDownload}
              >
                <i className="fa fa-file-excel-o" aria-hidden="true"></i> Print
                Excel
              </Button>
            </>
          ) : (
            ""
          )}
          <Button disabled={loading} type="submit">
            {editMode ? "Update" : "Create"}
          </Button>
        </div>
      </div>
      <CreateEvent
        getValues={getValues}
        control={control}
        errors={errors}
        editMode={editMode}
        handleOpenFilter={() => setFilterModal(true)}
        setValue={setValue}
      />
      {editMode ? (
        <>
          <StudentFilter
            show={filterModal}
            handleClose={() => setFilterModal(false)}
            addStudent={addStudent}
          />
          <EventDetailView setValue={setValue} />
        </>
      ) : (
        ""
      )}
    </Form>
  );
};

export default Add;
