import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import {
  FormControl,
  FormGroup,
  FormLabel,
  Row,
  Col,
} from "react-bootstrap";
import { useEffect } from "react";

function TemplateEighteen({ section, editMode, handleSectionDataChange }) {
  const [data, setData] = useState({
    title: "",
    image_1: "",
    image_2: "",
    image_3: "",
    imageUpText_1: "",
    imageUpText_2: "",
    imageUpText_3: "",
    imageDownText_1: "",
    imageDownText_2: "",
    imageDownText_3: "",
    text1: "",
    text2: "",
  });
  useEffect(() => {
    handleSectionDataChange({
      ...section,
      index: section.index,
      data: data,
    });
  }, [data]);

  //===EDIT MODE SET DATA
  useEffect(() => {
    if (editMode && section.data) {
      setData(section.data);
    }
  }, [editMode]);
  //===END EDIT MODE SET DATA

  const handleChange = (e) => {
    setData((dt) => ({ ...dt, [e.target.name]: e.target.value }));
  };

  const handleChangeText = (e, fieldName) => {
    setData((dt) => ({ ...dt, [fieldName]: e }));
  };

  return (
    <div>
      <FormGroup>
        <FormLabel>Title</FormLabel>
        <FormControl name="title" value={data?.title} onChange={handleChange} />
      </FormGroup>
      <FormGroup>
        <FormLabel>Content 1</FormLabel>
        <Editor
          name="text1"
          value={data?.text1}
          apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
          init={{
            plugins: [
              "lists link image paste help wordcount code",
              "searchreplace",
              "insertdatetime media",
            ],
            image_title: true,
            image_dimensions: true,
            image_advtab: true,
          }}
          onEditorChange={(e)=> handleChangeText(e, "text1")}
        />
      </FormGroup>

      <div className="RowColors">
        <Row>
          <Col sm={4}>
            <FormGroup>
              <FormLabel>Image up text 1 </FormLabel>
              <Editor
                name="imageUpText_1"
                value={data?.imageUpText_1}
                apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                init={{
                  plugins: [
                    "lists link image paste help wordcount code",
                    "searchreplace",
                    "insertdatetime media",
                  ],
                  image_title: true,
                  image_dimensions: true,
                  image_advtab: true,
                }}
                onEditorChange={(e)=> handleChangeText(e, "imageUpText_1")}
              />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <FormLabel>Image up text 2</FormLabel>
              <Editor
                name="imageUpText_2"
                value={data?.imageUpText_2}
                apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                init={{
                  plugins: [
                    "lists link image paste help wordcount code",
                    "searchreplace",
                    "insertdatetime media",
                  ],
                  image_title: true,
                  image_dimensions: true,
                  image_advtab: true,
                }}
                onEditorChange={(e)=> handleChangeText(e, "imageUpText_2")}
              />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <FormLabel>Image up text 3</FormLabel>
              <Editor
                name="imageUpText_3"
                value={data?.imageUpText_3}
                apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                init={{
                  plugins: [
                    "lists link image paste help wordcount code",
                    "searchreplace",
                    "insertdatetime media",
                  ],
                  image_title: true,
                  image_dimensions: true,
                  image_advtab: true,
                }}
                onEditorChange={(e)=> handleChangeText(e, "imageUpText_3")}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="RowColors">
        <Row>
          <Col sm={4}>
            <FormGroup>
              <FormLabel>Image 1 </FormLabel>
              <FormControl
                name="image_1"
                value={data?.image_1}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <FormLabel>Image 2 </FormLabel>
              <FormControl
                name="image_2"
                value={data?.image_2}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <FormLabel>Image 3 </FormLabel>
              <FormControl
                name="image_3"
                value={data?.image_3}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="RowColors">
        <Row>
          <Col sm={4}>
            <FormGroup>
              <FormLabel>Image down text 1 </FormLabel>
              <Editor
                name="imageDownText_1"
                value={data?.imageDownText_1}
                apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                init={{
                  plugins: [
                    "lists link image paste help wordcount code",
                    "searchreplace",
                    "insertdatetime media",
                  ],
                  image_title: true,
                  image_dimensions: true,
                  image_advtab: true,
                }}
                onEditorChange={(e)=> handleChangeText(e, "imageDownText_1")}
              />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <FormLabel>Image down text 2</FormLabel>
              <Editor
                name="imageDownText_2"
                value={data?.imageDownText_2}
                apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                init={{
                  plugins: [
                    "lists link image paste help wordcount code",
                    "searchreplace",
                    "insertdatetime media",
                  ],
                  image_title: true,
                  image_dimensions: true,
                  image_advtab: true,
                }}
                onEditorChange={(e)=> handleChangeText(e, "imageDownText_2")}
              />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <FormLabel>Image down text 3</FormLabel>
              <Editor
                name="imageDownText_3"
                value={data?.imageDownText_3}
                apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                init={{
                  plugins: [
                    "lists link image paste help wordcount code",
                    "searchreplace",
                    "insertdatetime media",
                  ],
                  image_title: true,
                  image_dimensions: true,
                  image_advtab: true,
                }}
                onEditorChange={(e)=> handleChangeText(e, "imageDownText_3")}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>

      <FormGroup>
        <FormLabel>Content 2</FormLabel>
        <Editor
          name="text2"
          value={data?.text2}
          apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
          init={{
            plugins: [
              "lists link image paste help wordcount code",
              "searchreplace",
              "insertdatetime media",
            ],
            image_title: true,
            image_dimensions: true,
            image_advtab: true,
          }}
          onEditorChange={(e)=> handleChangeText(e, "text2")}
        />
      </FormGroup>
    </div>
  );
}

export default TemplateEighteen;
