import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { io } from "socket.io-client";
// import Loading from "../../../components/Reusable/Loading/Loading";
import { syncStudents } from "../../../store/actions";

function DataSync() {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.dataSync);
  const [studentSync, setStudentSync] = useState();
  useEffect(() => {
    const socket = io(process.env.REACT_APP_SERVER, {
      transports: ["websocket"],
    });
    socket.on("SYNC STUDENTS FROM SF", (data) => {
      setStudentSync(data);
    });
  }, [setStudentSync]);
  const handleSync = () => {
    dispatch(syncStudents(token));
  };
  return (
    <div>
      <div className="ButtonFlex">
        <Button
          variant="info"
          onClick={handleSync}
          disabled={loading ? true : false}
        >
          <div className="loadingBtn">
            Sync Students {loading && <i className="fa fa-cog fa-spin"></i>}
          </div>
        </Button>
        {studentSync?.action === "WAITING_FOR_GET_STUDENT_FROM_SF" && (
          <div className="Success">
            Waiting <i className="fa fa-cog fa-spin"></i> - Get salsforce
            student {studentSync?.data}
          </div>
        )}
        {studentSync?.action === "GET_STUDENT_FROM_SF" && (
          <div className="Success">
            Waiting <i className="fa fa-cog fa-spin"></i> - Fetched the number
            of students {studentSync?.data?.length}
          </div>
        )}
        {studentSync?.action === "GET_STUDENT_REALATED_FILES_FROM_SF" && (
          <div className="Success">
            Waiting <i className="fa fa-cog fa-spin"></i> - Get the number of
            students {studentSync?.data?.length} and related files data
            {studentSync?.data?.filesLength}
          </div>
        )}

        {studentSync?.action === "COMPLETE_STUDENT_SYNC" && (
          <div className="Success">
            <i className="fa fa-check"></i> Add or Update{" "}
            {studentSync?.data?.length} students
          </div>
        )}
        {studentSync?.action === "ERROR_STUDENT_SYNC" && (
          <div className="Err">
            <i className="fa fa-close"></i> Fail
          </div>
        )}
      </div>
    </div>
  );
}

export default DataSync;
