import React from "react";
import { Row, Col } from "react-bootstrap";
function TemplateSeven({ data }) {
  return (
    <section id={data?.title?.trim().split(" ").join("").toLowerCase()}>
      <div className="inContent">
        <div className="text-heading-section">
          <div className="section-header text-center mt-5 mb-5">{data?.title}</div>
          <Row className="justify-content-center">
            <Col sm={10} >
              <Row className="mb-5 justify-content-center">
                {data?.tags?.map((tag,i) => (
                  <Col key={i} xl={3} lg={4} md={6} className="mb-5">
                    <div className="imageRoundWithTitle">
                      <div
                        className="img"
                        style={{
                          backgroundImage: `url(${tag?.image})`,
                        }}
                      ></div>

                      {tag?.title&&<h5 className="font-weight-bold">{tag?.title}</h5>}
                      {tag?.text&&<span className="text-black-50">{tag?.text}</span>}
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
}

export default TemplateSeven;
