import React from "react";

import {
  Alert,
  Button,
  Col,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { deleteStudentFromList } from "../../../store/actions";
import moment from "moment";

const CompareUsers = ({ handleClose, addStudent }) => {
  const { students, onBoardedStudents } = useSelector(
    (state) => state.distributionEvent
  );
  const dispatch = useDispatch();

  const handleAddStudentToList = (id) => {
    addStudent(id);
  };
  const handleDeleteStudentFromList = (id) => {
    dispatch(deleteStudentFromList(id));
  };

  const handleSaveAndClose = () => {
    handleClose();
  };

  let content;
  if (!students.length && !onBoardedStudents.length) {
    content = (
      <Alert variant="warning">No students search or added to event.</Alert>
    );
  } else {
    content = (
      <Row className="justify-content-end">
        <Col md={8}>
          <div className="WhiteContent mt-3">
            <div className="tableWrapper">
              <table width="100%">
                <thead>
                  <tr>
                    <th>Student</th>
                    <th>Amount</th>
                    <th>Dist. Date</th>
                    <th>Sponsor</th>
                    <th>Lia. Officer</th>
                    <th width="50"></th>
                  </tr>
                </thead>
                <tbody>
                  {!students?.length ? (
                    <tr>
                      <td colSpan={4}>
                        <div className="text-center">No students filtered.</div>
                      </td>
                    </tr>
                  ) : (
                    students?.map((student) => (
                      <tr
                        key={student.id}
                        className={student.listed ? "opacity-3" : undefined}
                      >
                        <td>
                          {student.firstName} {student.lastName}
                        </td>
                        <td>${student?.amount}</td>
                        <td>{moment(student?.lastDistributionDate).format("MM-DD-YYYY")}</td>
                        <td>{student?.sponsor}</td>
                        <td>{student?.liaisonOfficer?.name}</td>
                        <td>
                          {student.listed ? (
                            ""
                          ) : (
                            <Button
                              variant="outline-success"
                              size="sm"
                              onClick={() => handleAddStudentToList(student.id)}
                            >
                              <i
                                className="fa fa-long-arrow-right"
                                aria-hidden="true"
                              ></i>
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Col>

        <Col md={4}>
          <div className="WhiteContent mt-3">
            <div className="tableWrapper">
              <table width="100%">
                <thead>
                  <tr>
                    <th>Student</th>
                    <th width="50"></th>
                  </tr>
                </thead>
                <tbody>
                  {!onBoardedStudents?.length ? (
                    <tr>
                      <td colSpan={2}>
                        <div className="text-center">No students added.</div>
                      </td>
                    </tr>
                  ) : (
                    onBoardedStudents.map((student) => (
                      <tr key={student.id}>
                        <td>
                          {student.firstName} {student.lastName}
                        </td>
                        <td>
                          <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() =>
                              handleDeleteStudentFromList(student.id)
                            }
                          >
                            <i className="fa fa-close" aria-hidden="true"></i>
                          </Button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Col>

        <Col className="flex-grow-0 mt-5">
          <Button
            variant="success"
            onClick={handleSaveAndClose}
            className="text-nowrap"
          >
            Update
          </Button>
        </Col>
      </Row>
    );
  }
  return content;
};

export default CompareUsers;
