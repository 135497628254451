import {
  INIT_CONTACT_US,
  SUCCESS_CONTACT_US,
  FAIL_CONTACT_US,
  RESET_CONTACT_US,
} from "../actions/actionsTypes";

const initialState = {
  loading: false,
  data: null,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_CONTACT_US:
      return {
        ...state,
        loading: true,
      };

    case SUCCESS_CONTACT_US:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case FAIL_CONTACT_US:
      return {
        ...state,
        loading: false,
      };

    case RESET_CONTACT_US:
      return {
        ...state,
        loading: false,
        data: null,
      };

    default:
      return state;
  }
};
export default reducer;
