import React from "react";
import { Row, Col } from "react-bootstrap";
import HowItImage from "../../../assets/bg-how-it-works.png";

function TemplateThree({ data }) {
  return (
    <>
      <div
        id={data?.title?.trim().split(" ").join("").toLowerCase()}
        className="section-header text-center mt-5 mb-5"
      >
        {data.title}
      </div>
      <div
        className="WHoItWOrk"
        style={{ backgroundImage: `url(${HowItImage})` }}
      >
        <Row>
          <Col md={4}>
            <div className="HowItBox">
              <img src={data?.grid1?.iconLink} alt={data?.grid1?.title} />
              <h3>{data?.grid1?.title}</h3>
              <div
                dangerouslySetInnerHTML={{ __html: data?.grid1?.text1 }}
              ></div>
            </div>
          </Col>
          <Col md={4}>
            <div className="HowItBox">
              <img src={data?.grid2?.iconLink} alt={data?.grid2?.title} />
              <h3>{data?.grid2?.title}</h3>
              <div
                dangerouslySetInnerHTML={{ __html: data?.grid2?.text1 }}
              ></div>
            </div>
          </Col>
          <Col md={4}>
            <div className="HowItBox">
              <img src={data?.grid3?.iconLink} alt={data?.grid3?.title} />
              <h3>{data?.grid3?.title}</h3>
              <div
                dangerouslySetInnerHTML={{ __html: data?.grid3?.text1 }}
              ></div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default TemplateThree;
