import React from "react";
import { Button } from "react-bootstrap";
import "./Header.scss";

function AdminHeader(props) {
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("impersonateToken");
    window.location.href="/";
  };
  return (
    <div className="AdminHeader">
      <Button onClick={handleLogout} variant="link">
        Logout 
      </Button>
    </div>
  );
}

export default AdminHeader;
