import React from "react";
import { Tabs, Tab } from "react-bootstrap";
function TemplateFifteen({ data }) {
  return (
    <section
      id={data?.title
        .trim()
        .replace(/&/g, "")
        .split(" ")
        .join("")
        .toLowerCase()}
      className="SeventhSection"
    >
      <Tabs>
        {data?.tags.map((tab, i) => (
          <Tab key={i} eventKey={i} title={tab?.name}>
            <div className="tab-content reverse">
              <Tabs>
                {tab?.subTab?.map((sub, j) => (
                  <Tab
                    key={j}
                    eventKey={j}
                    title={<img src={sub?.image} alt={sub.name} />}
                  >
                    <div
                      className="wrapper"
                      dangerouslySetInnerHTML={{ __html: sub?.text }}
                    ></div>
                  </Tab>
                ))}
              </Tabs>
            </div>
          </Tab>
        ))}
      </Tabs>
    </section>
  );
}

export default TemplateFifteen;
