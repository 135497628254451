import React, { useState } from "react";
import "./Campaign.scss";
import moment from "moment";

const MessageContainer = (props) => {
  const [message, setMessage] = useState("");

  const data = props.campaign.data;
  const me = props.auth.me ? props.auth.me.data : null;

  const sendMessage = (e) => {
    e.preventDefault();
    if (message) {
      props.sendMessage(message);
    }
  };
  return (
    <div className="MessageContainer">
      <p>{data.description}</p>
      <div className="Updates">
        <div>Updates</div>
        {me && me.id === data.userId && (
          <div className="updateMesages">
            <form onSubmit={sendMessage}>
              <textarea onChange={(e) => setMessage(e.target.value)}></textarea>
              <div className="line">
                <button type="submit">POST UPDATE</button>
              </div>
            </form>
          </div>
        )}

        <ul>
          {data.statusMessages &&
            data.statusMessages.map((msg) => (
              <li>
                <p>{msg.message}</p>
                <span>{moment(msg.date).format("DD-MMM-YYYY")}</span>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default MessageContainer;
