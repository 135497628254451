import * as actions from "../actions/actionsTypes";

const initialState = {
  loading: false,
  data: null,
  error: null
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.INIT_GIFTCARD:
      return {
        ...state,
        loading: true
      };
    case actions.SUCCESS_GIFTCARD:
      return {
        ...state,
        regErr: null,
        loading: false,
        data: action.data
      };
    case actions.FAIL_GIFTCARD:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.err
      };
    case actions.RESET_GIFTCARD:
      return {
        ...state,
        loading: false,
        data: null,
        error: null
      };

    default:
      return state;
  }
};
export default reducer;
