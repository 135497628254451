import * as actions from "./actionsTypes";
import axios from "../../axios";
import { toast } from "react-toastify";

const initPages = () => ({
  type: actions.INIT_PAGES,
});
const errorPages = (error) => ({
  type: actions.FAIL_PAGES,
  error,
});
const addPages = (data) => ({
  type: actions.SUCCESS_CREATE_PAGES,
  data,
});
const listingPages = (list) => ({
  type: actions.SUCCESS_LIST_PAGES,
  list,
});
const getSinglePage = (data) => ({
  type: actions.SUCCESS_PAGES,
  data,
});
const deletedPage = (pageId) => ({
  type: actions.SUCCESS_DELETE_PAGES,
  pageId,
});

const initGetDonations = () => ({
  type: actions.INIT_GET_ELF_DONATIONS,
});
const errorGetDonations = (error) => ({
  type: actions.FAIL_GET_ELF_DONATIONS,
  error,
});
const successGetDonations = (data) => ({
  type: actions.SUCCESS_GET_ELF_DONATIONS,
  data,
});
export const createPages = (payload) => async (dispatch) => {
  try {
    dispatch(initPages());
    const section = await axios.post("pages", payload);
    dispatch(addPages(section?.data?.data));
    toast.success("Page added successfully.", { autoClose: 3000 });
  } catch (error) {
    dispatch(errorPages(error));
    toast.error("Page added fail.", { autoClose: 3000 });
  }
};
export const updatePages = (payload) => async (dispatch) => {
  try {
    dispatch(initPages());
    const section = await axios.patch(`pages/${payload.id}`, payload);
    dispatch(addPages(section?.data?.data));
    toast.success("Page update successfully.", { autoClose: 3000 });
  } catch (error) {
    dispatch(errorPages(error));
    toast.error("Page update fail.", { autoClose: 3000 });
  }
};
export const listPages = (payload) => async (dispatch) => {
  try {
    dispatch(initPages());
    // "?page=1&perPage=100&include=sections"
    let pages;
    if (payload) {
      pages = await axios.get(`pages${payload}`);
    } else {
      pages = await axios.get(`pages?perPage=100`);
    }
    dispatch(listingPages(pages?.data));
    return pages;
  } catch (error) {
    dispatch(errorPages(error));
  }
};
export const getPage = (payload) => async (dispatch) => {
  try {
    dispatch(initPages());
    // "?page=1&perPage=100&include=sections"
    let pages = await axios.get(`pages${payload}`);
    dispatch(getSinglePage(pages?.data?.data));
    return pages;
  } catch (error) {
    dispatch(errorPages(error));
  }
};
export const deletePage = (id) => async (dispatch) => {
  try {
    dispatch(initPages());
    await axios.delete(`pages/${id}`);
    dispatch(deletedPage(id));
    toast.success("Page delete successfully.", { autoClose: 3000 });
  } catch (error) {
    dispatch(errorPages(error));
    toast.error("Page delete fail.", { autoClose: 3000 });
  }
};

export const getElfDonations = ({to,from})=>async(dispatch)=>{
  try {
    dispatch(initGetDonations());
    let donations = await axios.get(`payment/elf-donations?from=${from}&to=${to}`);
    dispatch(successGetDonations(donations.data));
  } catch (error) {
    dispatch(errorGetDonations(error));
  }
}