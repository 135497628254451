/*eslint-disable*/
import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import List from "../../../components/admin/Messages/List";
import Loading from "../../../components/Reusable/Loading/Loading";

import * as actionCreators from "../../../store/actions";

function Messages(props) {
  const auth = useSelector((state) => state.auth);
  const chat = useSelector((state) => state.chat);
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth.token) {
      dispatch(actionCreators.getMessages("*", auth.token));
    }
  }, [auth.token]);

  return (
    <>
      {chat.loading && <Loading />}
      <List chats={chat.data} />
    </>
  );
}

export default Messages;
