import React  from "react";


function TemplateHeader({ data }) {
  return (
    <div
      className="featuredImage p-5"
      style={{
        backgroundImage: `url(${data?.imageLink})`,
      }}
    >
      <div dangerouslySetInnerHTML={{__html:data?.text1}}></div>
    </div>
  );
}

export default TemplateHeader;
