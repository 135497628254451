/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import "./CreateCampaign.scss";
import CreateCampaignForm from "../../components/CreateCampaign/CreateCampaignForm/CreateCampaignForm";
import * as actionCreators from "../../store/actions";
import { loginModalHandle } from "../../store/actions";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function CreateCampaign(props) {
  const [edit, setEdit] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [beforeSignInModalOpen, setBeforeSignInModalOpen] = useState(false);
  const [submitData, setSubmitData] = useState(null);
  const { me } = useSelector((state) => state.auth);

  useEffect(() => {
    if (params.id) {
      if (props.auth.token) {
        setEdit(true);
        props.getCampaign(params.id);
      }
    }
    return () => props.resetCapaign();
  }, []);

  useEffect(() => {
    if (props.campaign.success) {
      navigate(`/campaign/${props.campaign.data.id}`);
      window.location.reload();
    }
  }, [props.campaign.success]);

  useEffect(() => {
    if (me && me?.data && beforeSignInModalOpen) {
      handleSubmit(submitData);
    }
  }, [me]);

  const handleSubmit = (data) => {
    setSubmitData(data);
    if (props.auth.token) {
      const datachnage = { ...data };
      if (edit) {
        props.updateCampaign(datachnage, params.id, props.auth.token);
      } else {
        props.createCampaign(datachnage, props.auth.token);
      }
      setBeforeSignInModalOpen(false);
    } else {
      dispatch(loginModalHandle(true));
      setBeforeSignInModalOpen(true);
    }
  };

  const handleImageUploadAlert = () => {
    toast.success("Image uploaded successfully.");
  };

  return (
    <div className="CreateCampaign">
      <h2>Create my campaign</h2>
      <h4>
        Let's create your fundraising campaign so you could share with your
        network.
      </h4>
      <CreateCampaignForm
        edit={edit}
        campaign={props.campaign}
        handleSubmit={handleSubmit}
        handleImageUploadAlert={handleImageUploadAlert}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  campaign: state.campaign,
});
const mapDiapatchToProps = (dispatch) => ({
  createCampaign: (data, token) =>
    dispatch(actionCreators.createCampaign(data, token)),
  updateCampaign: (data, id, token) =>
    dispatch(actionCreators.updateCampaign(data, id, token)),
  getCampaign: (id, token) => dispatch(actionCreators.getCampaign(id)),
  resetCapaign: () => dispatch(actionCreators.resetCapaign()),
});
export default connect(mapStateToProps, mapDiapatchToProps)(CreateCampaign);
