/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Route, Routes,useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";

import ScrollToTop from "./utils/ScrollToTop";
import Layout from "./components/Layout/Layout";
import AdminLayout from "./components/Layout/AdminLayout";
import "./App.scss";

import {getMe} from "./store/actions";
import { routes as routesList } from "./routes/index";


function App(props) {
  const [admin, setAdmin] = useState(false);
  const [adminRouteUrl, setAdminRouteUrl] = useState(false);
  const {pathname}=useLocation()

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      props.getMe(token);
    }
  }, []);

  useEffect(() => {
    if (props.me && props.me.data) {
      if (props.me.data.type === 1 || props.me.data.type === 2) {
        setAdmin(true);
      }
    }
  }, [props.me]);

  useEffect(() => {
    const isAdminRoute = pathname.split("/")[1];
    if (isAdminRoute === "admin") {
      setAdminRouteUrl(true);
    } else {
      setAdminRouteUrl(false);
    }
  }, [pathname]);

  const nonAdminRotes = routesList.filter((r) => !r.adminRoutes);

  let bodyContent = (
    <>
      {admin && adminRouteUrl ? (
        <AdminLayout>
          <ScrollToTop>
            <Routes>
              {routesList.map((r, i) => {
                if (r.adminRoutes) {
                  if (r.authReqired) {
                    if (props.token) {
                      return (
                        <Route
                          exact={`${r.exact}`}
                          key={i}
                          path={r.path}
                          element={<r.component />}
                          // state={{ admin: false }}
                        />
                      );
                    }
                  }
                  return (
                    <Route
                      exact={`${r.exact}`}
                      key={i}
                      path={r.path}
                      element={<r.component />}
                      // state={false}
                    />
                  );
                }
              })}
            </Routes>
          </ScrollToTop>
        </AdminLayout>
      ) : (
        <Layout>
          <ScrollToTop>
          <Routes>
            {nonAdminRotes.map((r, i) => {
              if (r.authReqired) {
                if (props.token) {
                  return (
                    <Route key={i} path={r.path} element={<r.component />} />
                  );
                }
              }
              return (
                <Route
                  exact={`${r.exact}`}
                  key={i}
                  path={r.path}
                  element={<r.component />}
                />
              );
            })}
          </Routes>
          </ScrollToTop>
        </Layout>
      )}
    </>
  );
  return (
    <>
      <ToastContainer />
      {bodyContent}
    </>
  );
}
const mapStateToProps = (state) => ({
  ...state.auth,
});
const mapDispatchToProps = (dispatch) => ({
  getMe: (token) => dispatch(getMe(token)),
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
