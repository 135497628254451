import * as actions from "./actionsTypes";
import axios from "../../axios";
import { getAllStudents, getOneStudent } from "./students";
import { getAdminAuthSponsor } from "./sponsor";
import { toast } from "react-toastify";

const initCreateSponsorship = () => ({
  type: actions.INIT_CREATE_SPONSORSHIP,
});

const successCreateSponsorship = (payload) => ({
  type: actions.SUCCESS_CREATE_SPONSORSHIP,
  payload,
});

const failCreateSponsorship = (payload) => ({
  type: actions.FAIL_CREATE_SPONSORSHIP,
  payload,
});

const initGetSponsorship = () => ({
  type: actions.INIT_GET_SPONSORSHIP,
});

const successGetSponsorship = (payload) => ({
  type: actions.SUCCESS_GET_SPONSORSHIP,
  payload,
});

const failGetSponsorship = (payload) => ({
  type: actions.FAIL_GET_SPONSORSHIP,
  payload,
});
export const resetSponsorship = () => ({
  type: actions.RESET_SPONSORSHIP,
});

export const createSponsorship = (data, token) => {
  return (dispatch) => {
    dispatch(initCreateSponsorship());
    axios
      .post("sponsorships/create", data, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        dispatch(successCreateSponsorship(res.data));
        if (data?.sponsorId) {
          toast.success(res.data.message, {
            autoClose: 15000,
          });
          dispatch(getAdminAuthSponsor(data?.sponsorId, token));
          dispatch(getAllStudents(1, { pageSize: 1000 }));
        } else {
          dispatch(getOneStudent(data.beneficiaryId));
        }
      })
      .catch((err) => {
        dispatch(failCreateSponsorship(err));
      });
  };
};

export const getSponsorship = (id, token) => {
  return (dispatch) => {
    dispatch(initGetSponsorship());
    axios
      .get(`sponsorships/${id}`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        dispatch(successGetSponsorship(res.data));
      })
      .catch((err) => {
        dispatch(failGetSponsorship(err));
      });
  };
};

const initAllSponsorships = () => ({
  type: actions.INIT_GET_ALL_SPONSORSHIPS,
});

const successAllSponsorships = (data) => ({
  type: actions.SUCCESS_GET_ALL_SPONSORSHIPS,
  data,
});

const failAllSponsorships = (err) => ({
  type: actions.FAIL_GET_ALL_SPONSORSHIPS,
  err,
});
export const getAllSponsorships = (token, filters) => {
  return (dispatch) => {
    dispatch(initAllSponsorships());

    axios
      .get("sponsorships/allsponsorships", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: filters,
      })
      .then((res) => {
        dispatch(successAllSponsorships(res.data));
      })
      .catch((err) => {
        dispatch(failAllSponsorships(err));
      });
  };
};

const initLegacyPayment = () => ({
  type: actions.INIT_LEGACY_PAYMENT,
});

const successLegacyPayment = (payload) => ({
  type: actions.SUCCESS_LEGACY_PAYMENT,
  payload,
});

const failLegacyPayment = (payload) => ({
  type: actions.FAIL_LEGACY_PAYMENT,
  payload,
});

export const getLegacyPayment = (id, token) => {
  return async (dispatch) => {
    try {
      dispatch(initLegacyPayment());
      const payments = await axios.get(`sponsorships/getlegacypayments/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(successLegacyPayment(payments.data));
    } catch (error) {
      dispatch(failLegacyPayment(error));
    }
  };
};

const initUpdateStatus = () => ({
  type: actions.INIT_UPDATE_STATUS_SPONSORSHIP,
});
const successUpdateStatus = (payload) => ({
  type: actions.SUCCESS_CUPDATE_STATUSSPONSORSHIPS,
  payload,
});
const failUpdateStatus = (payload) => ({
  type: actions.FAIL_CUPDATE_STATUSSPONSORSHIPS,
  payload,
});

export const updateStatusSponsorship = (data, token) => {
  return (dispatch) => {
    dispatch(initUpdateStatus());

    axios
      .put(
        `sponsorships/updatestatus/${data.sponsorshipId}`,
        { status: data.status },
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        dispatch(successUpdateStatus(res.data));
      })
      .catch((err) => {
        dispatch(failUpdateStatus(err));
      });
  };
};
