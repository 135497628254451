import React from "react";
import { LinkLineButton } from "../Button/Button";

function TemplateFore({ data }) {
  return (
    <div
      id={data?.title?.trim().split(" ").join("").toLowerCase()}
      className="pb-5 pt-5"
      style={{ backgroundColor: data?.color, backgroundImage: `url("${data?.image}")`,backgroundPosition:"center",backgroundSize:"cover" }}
    >
      <div dangerouslySetInnerHTML={{ __html: data.text1 }}></div>

      <div className="meetAll">
        {data.buttonText && (
          <LinkLineButton title={data.buttonText} to={data.buttonLink} />
        )}
        <div dangerouslySetInnerHTML={{ __html: data.text2 }}></div>
      </div>
    </div>
  );
}

export default TemplateFore;
