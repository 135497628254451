import React from "react";
import { Carousel } from "react-responsive-carousel";

function Item({ slides }) {
  return (
    <Carousel
      className="MainSlider"
      showThumbs={false}
      showArrows={false}
      showStatus={false}
      showIndicators={false}
      autoPlay={true}
      infiniteLoop={true}
      interval={4000}
      stopOnHover={false}
      transitionTime={1000}
      // animationHandler={"fade"}
    >
      {slides?.map((s,i) => (
        <div className="Item" key={i}>
          <h1>{s.title}</h1>
          <h4 className="mt-3">{s.text}</h4>
        </div>
      ))}
    </Carousel>
  );
}

export default Item;

// import React from 'react'
// import {Carousel} from 'react-responsive-carousel';
// import _ from 'lodash'
// import Loading from '../../../Reusable/Loading/Loading';

// function Item(props) {

//     return (
//        !props.Loading?<Carousel className="MainSlider" showThumbs={false} showArrows={false} showStatus={false} showIndicators={false} autoPlay={true}>
//         {SliderItem}

//     </Carousel>:null
//     )
// }

// export default Item
