import React from "react";
import { Row, Col } from "react-bootstrap";
function TemplateTen({ data }) {
  return (
    <section id={data?.title?.trim().split(" ").join("").toLowerCase()}>
      <div className="inContent">
        <div className="text-heading-section">
          <h3>{data?.title}</h3>
          <Row>
            {data?.tags?.map((tag, i) => (
              <React.Fragment key={i}>
                <Col sm={6} className="mb-5">
                  <div
                    className="cardMainImage"
                    style={{ backgroundImage: `url(${tag?.image})` }}
                  ></div>
                </Col>
                <Col
                  sm={6}
                  className="flex flex-column justify-content-between"
                >
                  <div>
                    {tag?.title && <h1>{tag?.title}</h1>}
                    {tag?.text && <p>{tag?.text}</p>}
                  </div>
                  <a href={tag?.url}>{tag?.linkText}</a>
                </Col>
              </React.Fragment>
            ))}
          </Row>
        </div>
      </div>
    </section>
  );
}

export default TemplateTen;
