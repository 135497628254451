import React from "react";
import { Row, Col } from "react-bootstrap";
import Slider from "./Slider/Slider";
import SriLanka from "../../Reusable/SriLankaMap/SriLankaMap";
import "./StudentProfile.scss";
import { age } from "../../../utils/func";
import DocList from "../../Reusable/Modal/DocList/DocList";

function StudentProfile(props) {
  const student = props.student?.data;
  const stringDetail = student?.detail;
  const detail = typeof stringDetail === "string" ? JSON.parse(stringDetail) : stringDetail;


  const studentApplication = detail?.fileCustom?.filter(
    (d) => d.Type__c === "Doc_Application" || d.Type__c === "Doc_Other"
  );
  const feedbackLetters = detail?.fileCustom?.filter(
    (d) => d.Type__c === "Doc_Feedback" || d.Type__c === "Doc_Receipts"
  );
  let address = [];

  detail?.Permanent_District__c && address.push(detail?.Permanent_District__c);
  detail?.Permanent_Province__c && address.push(detail?.Permanent_Province__c);

  return (
    <div className="StudentProfile">
      <Row>
        <Col md={4}>
          <Slider student={student} />
        </Col>
        <Col md={8}>
          <Row>
            <Col md={12}>
              <h1>
                <span className="font-weight-bold">{student?.firstName}</span> {student?.lastName}
              </h1>

              {detail.Student_Tagline__c && <p className="font-italic">{detail.Student_Tagline__c}</p>}
            </Col>
            <Col md={8} className="mt-4">
              <p>
                <strong>Age: </strong>
                {age(student.birthday)}
              </p>
              <p>
                <strong>Location: </strong>
                {address.join(", ")}
              </p>
              <p>
                <strong>Current Academic Level: </strong>
                {detail.Current_Academic_Level_Grade_Stream__c}
              </p>
              <br />
              <br />
              <div className="download">
                <p>
                  {student.firstName}'s Documents: <i className="fa fa-info-circle"></i>
                </p>
                {studentApplication?.length > 0 && (
                  <div className="mt-2">
                    <button onClick={() => props.handleListModal(studentApplication)}>
                      <i className="fa fa-file-text-o fa-document"></i> Student Application
                    </button>
                  </div>
                )}
                {feedbackLetters?.length > 0 && (
                  <div className="mt-2">
                    <button onClick={() => props.handleListModal(feedbackLetters)}>
                      <i className="fa fa-file-text-o fa-document"></i> Feedback Letters
                    </button>
                  </div>
                )}
              </div>
            </Col>
            <Col md={4}>
              <SriLanka district={detail?.Permanent_District__c} />
            </Col>
          </Row>
        </Col>
      </Row>
      {props.docList && <DocList list={props.docList} handleClose={props.handleListModalClose} />}
    </div>
  );
}

export default StudentProfile;
