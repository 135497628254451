import * as actions from "../actions/actionsTypes";

const initialState = {
  loading: false,
  data: null,
  item: null,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.INIT_LOG_ITEM || actions.INIT_LOG_LIST:
      return {
        ...state,
        loading: true,
      };
    case actions.SUCCESS_LOG_LIST:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case actions.SUCCESS_LOG_ITEM:
      return {
        ...state,
        loading: false,
        item: action.data,
      };
    case actions.FAIL_LOG_LIST || actions.FAIL_LOG_ITEM:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};
export default reducer;
