import React from "react";
import { Row, Col, Card } from "react-bootstrap";
function TemplateEleven({ data }) {
  return (
    <section id={data?.title?.trim().split(" ").join("").toLowerCase()}>
      <div className="inContent">
        <div className="text-heading-section">
          <h3 className="section-header text-center mt-5 mb-5">{data?.title}</h3>
          <Row className="justify-content-center">
            {data?.tags?.map((tag, i) => (
              <Col sm={data?.tags?.length>2?4:6} key={i} className="mb-4">
                <Card>
                  <div
                    className="cardImage"
                    style={{ backgroundImage: `url(${tag?.image})` }}
                  ></div>
                  <Card.Body>
                    {tag?.title && <Card.Title>{tag?.title}</Card.Title>}
                    {tag?.text && <Card.Text>{tag?.text}</Card.Text>}
                    <a className="card-link" href={tag?.url}>
                      {tag?.linkText}
                    </a>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </section>
  );
}

export default TemplateEleven;
