import React from "react";
import "./Campaign.scss";
import { Link } from "react-router-dom";
import { Button } from "../Reusable/Button/Button";
import { isValidHttpUrl } from "../../utils/func";
import CopyToClipboardButton from "../Reusable/CopyToClipboardButton/CopyToClipboardButton";

function Banner(props) {
  const data = props.campaign.data;
  const me = props.auth.me ? props.auth.me.data : null;

  let image = null;
  if (data?.uploadImage) {
    image = isValidHttpUrl(data.uploadImage)
      ? data.uploadImage
      : `${process.env.REACT_APP_SERVER}${data.uploadImage}`;
  }
  return (
    <div
      className="Banner"
      style={{
        backgroundImage: `url(${
          image
        })`,
      }}
    >
      <div className="Wrapper">
        <div className="profileBox">
          <div className="profileImg" style={{
                backgroundImage: `url(${
                  data.sponsor.image
                })`,
              }}> 
          </div>
          <div className="profDetail">
            <div className="name">{data.name}</div>
            <div className="userName">
              {data.sponsor && data.sponsor.firstName}{" "}
              {data.sponsor && data.sponsor.lastName}
            </div>
            <div className="buttonLine mt-3">
              <Button size="sm" title="DONATE" onClick={props.handleModal} color="pink" />
              <div className="px-4 d-flex">
                <a href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} target={"_blank"}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z"/></svg>
                </a>
                <a href={`https://twitter.com/intent/tweet?url=${window.location.href}`} target={"_blank"}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>
                </a>
                {/* <a href={`mailto:?subject=Support our cause!&body=${encodeURIComponent(window.location.href)}`}>
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                </a> */}
                <CopyToClipboardButton text={window.location.href} />
              </div>
              {me && me.id === data.userId && (
                <Link to={`/edit-campaign/${data.id}`} className="editBtn">
                  EDIT CAMPAIGN
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
