import React from "react";
import { Link } from "react-router-dom";
import "./StudentTag.scss";
import ElfLogo from "../../../assets/user.png";

import Photo from "../../../assets/photo.png";
import { age, isValidHttpUrl } from "../../../utils/func";
import ProgressStudents from "../../Reusable/ProgressStudent/ProgressStudents";

function StudentTag(props) {
  const student = props.studentData;
  const stringDetail = student.detail;
  const detail =
    typeof stringDetail === "string" ? JSON.parse(stringDetail) : stringDetail;
  let content = null;
  let activeSpship = null;
  if (student) {
    let description = "No description available for this student. ";
    if (detail.Student_Tagline__c) {
      description = detail.Student_Tagline__c.substring(0, 90).concat("...");
    }

    let profileImg = <img src={ElfLogo} alt="Logo text" />;
    if (student.image) {
      profileImg = <img src={student.image} alt="Logo text" />;
    }
    if (student.users.length > 0) {
      activeSpship = student.users.filter(
        (sponsor) =>
          sponsor.sponsorship.status === "active" ||
          sponsor.sponsorship.status === "pending"
      );
    }

    let progressBar = <ProgressStudents student={student} sm={true} />;

    let userImage = null;
    if (activeSpship && activeSpship[0]?.image) {
      userImage = isValidHttpUrl(activeSpship[0].image)
        ? activeSpship[0].image
        : `${process.env.REACT_APP_SERVER}${activeSpship[0].image}`;
    }

    content = (
      <Link to={`/student/${student.id}`} className="StudentTag">
        <div className="ProfilePic">{profileImg}</div>
        <div className="stName text-center">{detail.Name}</div>
        <div className="small">{age(student.birthday)}</div>
        {progressBar}

        <div className="discription">{description}</div>

        {student.users.length > 0 && activeSpship.length > 0 ? (
          activeSpship ? (
            <div key={activeSpship[0].id} className="text-center spTag">
              <div className="spBy">
                Sponsored by
                <i className="fa fa-check-circle" aria-hidden="true"></i>
              </div>
              <div className="profDetail">
                <img src={activeSpship[0].image ? userImage : Photo} alt="" />{" "}
                {activeSpship[0].anonymous
                  ? "Anonymous"
                  : `${activeSpship[0]?.firstName} ${activeSpship[0]?.lastName}`}
              </div>
            </div>
          ) : (
            <div className="sponserButton">
              <span>${student.amount}</span>
              <div className="twoLine">
                <i className="on">Sponsor</i>
                <i className="off">Meet</i> {student?.firstName}
              </div>
              <p>from ${student.amount} per month</p>
            </div>
          )
        ) : (
          <div className="sponserButton">
            <span>${student.amount}</span>
            <div className="twoLine">
              <i className="on">Sponsor</i>
              <i className="off">Meet</i> {student?.firstName}
            </div>
            <p>from ${student.amount} per month</p>
          </div>
        )}

        {/* <div className="sponserButton">
          <span>$10</span>
          Sponsor
          <p>from $10 per month</p>
        </div> */}
      </Link>
    );
  }
  return content;
}

export default StudentTag;
