/*eslint-disable*/
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import OfflinePay from "../../../components/admin/ViewSponsorship/OfflinePay";
import PaymentHistory from "../../../components/admin/ViewSponsorship/PaymentHistory";
import SponsorshipHeader from "../../../components/admin/ViewSponsorship/SponsorshipHeader";
import ViewPlan from "../../../components/admin/ViewSponsorship/ViewPlan";
import Loading from "../../../components/Reusable/Loading/Loading";

import {
  getSponsorship,
  getMessageFilterFromSponsorship,
  sentMessage,
} from "../../../store/actions";

function ViewSponsorship() {
  const { token } = useSelector((state) => state.auth);
  const history = useNavigate();
  const { data: payment } = useSelector((state) => state.payment);
  const { sponsorship, loading } = useSelector((state) => state.sponsorships);
  const { data: sponsor, cancelLoading } = useSelector(
    (state) => state.sponsor
  );
  const { data: chat, loading: chatLoading } = useSelector(
    (state) => state.chat
  );

  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getSponsorship(id, token));
    dispatch(getMessageFilterFromSponsorship(id));
  }, [dispatch, getSponsorship, id, token, sponsor, payment]);

  const handleChat = async () => {

    if (chat.length) {
      navigate("/admin/messages");
    } else {
      const data = {
        chatfiles: "",
        message: "Hello",
        sponsorshipId: id,
        admin: true,
      };

      await dispatch(sentMessage(data));
      navigate("/admin/messages");
    }
  };
  let content = null;

  if (!loading && sponsorship) {
    content = (
      <>
        <SponsorshipHeader sponsorship={sponsorship} />
        <ViewPlan sponsorship={sponsorship} handleChat={handleChat} />
        {sponsorship.status !== "canceled" && (
          <OfflinePay sponsorship={sponsorship} />
        )}

        <PaymentHistory sponsorship={sponsorship} />
      </>
    );
  }

  return (
    <>
      {loading && <Loading page />}
      {cancelLoading && <Loading page />}
      {content}
    </>
  );
}

export default ViewSponsorship;
