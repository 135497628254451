/*eslint-disable*/
import React from "react";
import "./ChatBuble.scss";
import ProfImage from "../../../../../../assets/user.png";
import {isValidHttpUrl} from '../../../../../../utils/func'

const ChatBuble = (props) => {
  let image;
  if (props.picture) {
    image = isValidHttpUrl(props.picture)
      ? props.picture
      : `${process.env.REACT_APP_SERVER}${props.picture}`;
  }
  return (
    <div className={`ChatBuble ${!props.student && "StudentBuble"}`}>
      <img src={image ? image : ProfImage} alt="Profile pic" />
      <div className="msg">
        {props.msg}
        {props.data.file && (
          <a
            href={props.data.file}
            target="_blank"
          >
            <img
              src={props.data.file}
              alt="Download"
            />
            Download attchement
          </a>
        )}
      </div>
    </div>
  );
};

export default ChatBuble;
