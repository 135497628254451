import React, { useState, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

import {
  FormControl,
  FormGroup,
  FormLabel,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { useEffect } from "react";

function TemplateSeventeen({ section, editMode, handleSectionDataChange }) {
  const [data, setData] = useState({
    title: "",
    text1: "",
    text2: "",
    fb: "",
    twitter: "",
    linkedin: "",
    email: "",
  });
  useEffect(() => {
    handleSectionDataChange({
      ...section,
      index: section.index,
      data: data,
    });
  }, [data]);

  //===EDIT MODE SET DATA
  useEffect(() => {
    if (editMode && section.data) {
      setData(section.data);
    }
  }, [editMode]);
  //===END EDIT MODE SET DATA

  const handleChange = (e) => {
    setData((dt) => ({ ...dt, [e.target.name]: e.target.value }));
  };

  const handleChangeTextOne = (e) => {
    setData((dt) => ({ ...dt, text1: e }));
  };
  const handleChangeTextTwo = (e) => {
    setData((dt) => ({ ...dt, text2: e }));
  };

  return (
    <div>
      <FormGroup>
        <FormLabel>Title</FormLabel>
        <FormControl name="title" value={data?.title} onChange={handleChange} />
      </FormGroup>
      <FormGroup>
        <FormLabel>Content up</FormLabel>
        <Editor
          name="text1"
          value={data?.text1}
          apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
          init={{
            plugins: [
              "lists link image paste help wordcount code",
              "searchreplace",
              "insertdatetime media",
            ],
            image_title: true,
            image_dimensions: true,
            image_advtab: true
          }}
          onEditorChange={handleChangeTextOne}
        />
      </FormGroup>

      <div className="RowColors">
        <Row>
          <Col sm={3}>
            <FormGroup>
              <FormLabel>Facebook share </FormLabel>
              <FormControl
                name="fb"
                value={data?.fb}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
          <Col sm={3}>
            <FormGroup>
              <FormLabel>Twitter share </FormLabel>
              <FormControl
                name="twitter"
                value={data?.twitter}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>

          <Col sm={3}>
            <FormGroup>
              <FormLabel>LinkedIn share</FormLabel>
              <FormControl
                name="linkedin"
                value={data?.linkedin}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
          <Col sm={3}>
            <FormGroup>
              <FormLabel>Email share </FormLabel>
              <FormControl
                name="email"
                value={data?.email}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>

      <FormGroup>
        <FormLabel>Content down</FormLabel>
        <Editor
          name="text2"
          value={data?.text2}
          apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
          init={{
            plugins: [
              "lists link image paste help wordcount code",
              "searchreplace",
              "insertdatetime media",
            ],
            image_title: true,
            image_dimensions: true,
            image_advtab: true
          }}
          onEditorChange={handleChangeTextTwo}
        />
      </FormGroup>
    </div>
  );
}

export default TemplateSeventeen;
