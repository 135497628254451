import React from 'react'

function HTMLSample() {
   return (
      <div>
         <h1>HTMLSample</h1>
         <hr />
         <a className="Button   orange mr-5" href="">Button</a>
         <code>
            &lt;a className="Button  orange" href=""&gt;Button&lt;/a&gt;
         </code>
         <hr />

         <a className="Button   blue mr-5" href="">Button</a>
         <code>
            &lt;a className="Button   blue" href=""&gt;Button&lt;/a&gt;
         </code>
         <hr />

         <a className="Button sm  orange mr-5" href="">Button</a>
         <code>
            &lt;a className="Button sm orange" href=""&gt;Button&lt;/a&gt;
         </code>
         <hr />


         <a className="Button sm  blue mr-5" href="">Button</a>
         <code>
            &lt;a className="Button sm  blue" href=""&gt;Button&lt;/a&gt;
         </code>
      </div>
   )
}

export default HTMLSample