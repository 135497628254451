import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { listPages, deletePage } from "../../../../store/actions";

function List() {
  const dispatch = useDispatch();
  const { list, loading } = useSelector((state) => state.pages);
  useEffect(() => {
    dispatch(listPages());
  }, [dispatch]);

  const deletePageHandler = (id) => {
    if (window.confirm("Are you sure?")) {
      dispatch(deletePage(id));
    }
  };
  let content = "";
  if (loading) {
    content = (
      <tr>
        <td colSpan={4}>LOADING...</td>
      </tr>
    );
  } else {
    content = list?.map((page) => {
      return (
        <tr key={page.id}>
          <td>{page.name}</td>
          <td>{page.group}</td>
          <td>{page.link}</td>
          <td>
            <Link
              className="btn btn-warning mr-2"
              to={page.link}
              target="_blank"
            >
              
              <i className="fa fa-eye"></i>
            </Link>
            <Link
              className="btn btn-info mr-2"
              to={`/admin/cms-pages/edit/${page.id}`}
            >
              
              <i className="fa fa-pencil"></i>
            </Link>
            <Button
              disabled={page.isLocked}
              variant="danger"
              onClick={() => deletePageHandler(page.id)}
            >
              {page.isLocked ? <i className="fa fa-lock"></i> : <i className="fa fa-trash"></i>}
              
            </Button>
          </td>
        </tr>
      );
    });
  }
  return (
    <div>
      <div className="HeaderTextCMS">
        CMS/Pages{" "}
        <div className="Filters">
          <Link className="btn btn-success" to="/admin/cms-pages/add">
            ADD
          </Link>
        </div>
      </div>
      <div className="WhiteContent">
        <table width="100%">
          <thead>
            <tr>
              <th>Name</th>
              <th>Group</th>
              <th>Link</th>
              <th width="180"></th>
            </tr>
          </thead>
          <tbody>{content}</tbody>
        </table>
      </div>
    </div>
  );
}

export default List;
