import { toast } from "react-toastify";
import axios from "../../axios";
import {
  INIT_FORGET_PASSWORD,
  SUCCESS_FORGET_PASSWORD,
  FAIL_FORGET_PASSWORD,
  RESET_FORGET_PASSWORD,
  SUCCESS_RESET_PASSWORD,
} from "./actionsTypes";

const initForgetPassword = () => ({
  type: INIT_FORGET_PASSWORD,
});
const successForgetPassword = (data) => ({
  type: SUCCESS_FORGET_PASSWORD,
  data,
});
const failForgetPassword = (err) => ({
  type: FAIL_FORGET_PASSWORD,
});
const successResetPassword = () => ({
  type: SUCCESS_RESET_PASSWORD,
});

export const forgotPassword = (data) => {
  return (dispatch) => {
    dispatch(initForgetPassword());
    axios
      .post("auth/forget-password", data)
      .then((res) => {
        dispatch(successForgetPassword());
      })
      .catch((err) => {
        dispatch(failForgetPassword());
      });
  };
};
export const resetPasswordIsValidToken = (data) => {
  return (dispatch) => {
    dispatch(initForgetPassword());
    axios
      .get(`auth/reset-password-is-valid-token/${data.id}/${data.token}`)
      .then((res) => {
        dispatch(successForgetPassword());
      })
      .catch((err) => {
        dispatch(failForgetPassword());
      });
  };
};
export const resetPassword = (data) => {
  return (dispatch) => {
    dispatch(initForgetPassword());
    axios
      .post(`auth/reset-password`, data)
      .then((res) => {
        toast.success("Password successfully reset.", { autoClose: 8000 });
        toast.success("Please login with new password.", { autoClose: 8000 });
        dispatch(successResetPassword());
      })
      .catch((err) => {
        dispatch(failForgetPassword());
      });
  };
};

export const forgetPasswordReset = () => ({
  type: RESET_FORGET_PASSWORD,
});
