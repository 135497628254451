import React, { useContext } from "react";
import { RightSideBarTogglerContext } from "../../../utils/ContextUtil";
import classes from "./RightSideBarToggler.module.scss";

function RightSideBarToggler(props) {
  const mainClass = [classes.RightSideBarToggler];
  const context=useContext(RightSideBarTogglerContext)
  if (context.toggle) {
    mainClass.push(classes.open);
  }

  return (
    <div className={mainClass.join(" ")}>
      <div className={classes.WhiteContent}>{props.children}</div>
      <div className={classes.BackDrop} onClick={context.onClick} ></div>
    </div>
  );
}

export default RightSideBarToggler;
