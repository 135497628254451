import React,{useState} from "react";
import classes from "./MultiSelect.module.scss";

function MultiSelect({ options,name,selected,onSelect }) {
  const [openSelect,setOpenSelect]=useState(false)

  let itemsList = "No items";
  const mainClass=[classes.MultiSelect]
  if(openSelect){
    mainClass.push(classes.open)
  }
  
  if (options) {
    itemsList = options.map((e,i) => {
      if(e.value){
        return <li key={i} onClick={()=>onSelect(e)} value={e.value} className={selected.find(sl=>sl.value===e.value)?classes.Selected:""} >{e.label}</li>;
      }else{
        return <li className={classes.NoValues} key={i} >{e.label}</li>;
      }
    });
  }
  
  
  let countItem = null;
  if(selected.length===1){
    countItem=<li className={classes.LengthItem}>{selected[0].label}</li>
  }
  if(selected.length>1){
    countItem=<li className={classes.LengthItem}>{selected.length} item selected</li>
  }

  return <div  name={name} className={mainClass.join(" ")}  >
      <ul className={classes.Items} onClick={()=>setOpenSelect(true)}>
        {countItem}
        {itemsList}
      </ul>
      <i className="fa fa-caret-down" aria-hidden="true"></i>
      <div className={classes.backDrop} onClick={()=>setOpenSelect(false)}></div>
    </div>;
}

export default MultiSelect;
