/*eslint-disable*/
import moment from "moment";
import React, { useState, useEffect } from "react";
import "./ProgressStudents.scss";

function ProgressStudents(props) {
  const { student } = props;
  const stDetail = student?.detail;

  const [startGrade, setStartGrade] = useState(0);
  const [actualStartGrade, setActualStartGrade] = useState(0);
  const [currentGrade, setCurrentGrade] = useState(0);
  const [degreeDuration, setDegreeDuration] = useState(0);
  const [totalDuration, setTotalDuration] = useState(19);

  useEffect(() => {
    setStartGrade(
      stDetail?.Starting_Grade__c
        ? parseInt(stDetail?.Starting_Grade__c?.substring(0, 4)) <= 8
          ? parseInt(stDetail?.Starting_Grade__c?.substring(0, 4))
          : 8
        : 8
    );
    setActualStartGrade(parseInt(stDetail?.Starting_Grade__c?.substring(0, 4)));
    setDegreeDuration(
      stDetail?.Degree_Duration__c ? parseInt(stDetail?.Degree_Duration__c) : 4
    );
  }, [stDetail]);

  useEffect(() => {
    setCurrentGrade(
      stDetail?.Current_Grade__c
        ? parseInt(stDetail?.Current_Grade__c?.substring(0, 4))
        : startGrade
    );

    setTotalDuration(15 - startGrade + degreeDuration);
  }, [startGrade, stDetail]);

  let finalYear = moment()
    .add(startGrade + totalDuration - currentGrade, "Y")
    .format("YYYY");

  let dYear = 1;

  let innerGrades = [...Array(totalDuration)].map((e, i) => {
    let grade = i + startGrade;
    let cText = null;

    let gText = "G" + grade;
    const classes = [];
    if (grade === startGrade) {
      gText = "G8";
      classes.push("pointed");
    }
    if (grade === 11) {
      gText = "O/L";
      classes.push("pointed");
    }
    if (grade === 14) {
      gText = "A/L";
      classes.push("pointed");
    }
    if (grade === 15) {
      gText = "AF A/L";
    }
    if (grade >= 16) {
      gText = "Y" + dYear;
      dYear++;
    }

    //check is start grade
    if (actualStartGrade === grade) {
      classes.push("start");
      gText = "G" + grade;
    }
    //check is current grade
    if (grade === currentGrade) {
      classes.push("current");
      cText = `${
        stDetail?.Current_Grade__c
          ? stDetail?.Current_Grade__c?.split("-")[1]
          : ""
      } (${moment().format("YYYY")})`;
    }

    if (grade < currentGrade && startGrade <= grade) {
      classes.push("on");
    }

    if (actualStartGrade > grade) {
      classes.push("off");
    }

    if (grade < currentGrade) {
      classes.push("inactive");
    }

    return (
      <span key={i} className={classes.join(" ")} data-gtext={gText}>
        <small>{gText}</small>
        {cText && <small className="curentStartText">{stDetail?.FirstName}</small>}
        {grade === 11 && ( !props.sponsorPeriod && <big className={currentGrade <= 11 ? "current-title" : ""}>Up to Grade 11 (O/L)</big>)}
        {grade === 14 && ( !props.sponsorPeriod && <big className={currentGrade > 11 && currentGrade <= 14 ? "current-title" : ""}>In Grade 12 & 13 (A/L)</big>)}
      </span>
    );
  });

  return (
    <div>
      {/* <ul>
        <li>Starting_Grade__c: {stDetail?.Starting_Grade__c}</li>
        <li>Current_Grade__c: {stDetail?.Current_Grade__c}</li>
        <li>Degree_Duration__c: {stDetail?.Degree_Duration__c}</li>
      </ul> */}

      <div
        className={`ProgressStudents ${props.orange && "Orange"} ${
          props.sm && "sm"
        }`}
      >
        <div className="Progress">
          <div className="line">{innerGrades}</div>

          <i
            className={`fa fa-graduation-cap ${
              currentGrade === 15+degreeDuration  ? "active" : ""
            }`}
          >
            { props.sponsorPeriod ? 
            <big className="period-tittle">Sponsorship Period</big>
            :<big className={currentGrade > 14 ? "uni-title current-title" : "uni-title"}>In Higher Education</big>
            }
            
            <small>{finalYear}</small>
          </i>
        </div>
      </div>
    </div>
  );
}

export default ProgressStudents;
