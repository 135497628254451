import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { listSection, deleteSection } from "../../../../store/actions";

import basic from "../../../../assets/sections/basic.png";
import poolFundHeader from "../../../../assets/sections/pool-fund-header.png";
import homePageHeader from "../../../../assets/sections/home-page-header.png";
import contactHeader from "../../../../assets/sections/contact-header.png";
import giftCard from "../../../../assets/sections/gift-card.png";
import header from "../../../../assets/sections/header.png";
import pledgeYourBirthday from "../../../../assets/sections/pledge-your-birthday.png";
import template1 from "../../../../assets/sections/template-1.png";
import template2 from "../../../../assets/sections/template-2.png";
import template3 from "../../../../assets/sections/template-3.png";
import template4 from "../../../../assets/sections/template-4.png";
import template5 from "../../../../assets/sections/template-5.png";
import template6 from "../../../../assets/sections/template-6.png";
import template7 from "../../../../assets/sections/template-7.png";
import template8 from "../../../../assets/sections/template-8.png";
import template9 from "../../../../assets/sections/template-9.png";
import template10 from "../../../../assets/sections/template-10.png";
import template11 from "../../../../assets/sections/template-11.png";
import template12 from "../../../../assets/sections/template-12.png";
import template13 from "../../../../assets/sections/template-13.png";
import template14 from "../../../../assets/sections/template-14.png";
import template15 from "../../../../assets/sections/template-15.png";

function List() {
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.section);
  useEffect(() => {
    dispatch(listSection());
  }, [dispatch]);

  const deleteSectionHandler = (id) => {
    if (window.confirm("Are you sure?")) {
      dispatch(deleteSection(id));
    }
  };
  

  return (
    <div>
      <div className="HeaderTextCMS">
        CMS/Sections
        <div className="Filters">
          <Link to="/admin/cms-sections/add" className="btn btn-success">
            ADD
          </Link>
        </div>
      </div>
      <div className="WhiteContent">
        <table width="100%">
          <thead>
            <tr>
              <th>Design</th>
              <th>Name</th>
              <th>Key</th>
              <th width="120"></th>
            </tr>
          </thead>
          {list?.map((section) => {
            let templateImage = null;
            switch (section.key) {
              case "basic":
                templateImage = basic;
                break;
              case "home-page-header":
                templateImage = homePageHeader;
                break;
              case "educate-lanka-fund-header":
                templateImage = poolFundHeader;
                break;
              case "contact-header":
                templateImage = contactHeader;
                break;
              case "template-1":
                templateImage = template1;
                break;
              case "template-2":
                templateImage = template2;
                break;
              case "template-3":
                templateImage = template3;
                break;
              case "template-4":
                templateImage = template4;
                break;
              case "template-5":
                templateImage = template5;
                break;
              case "template-6":
                templateImage = template6;
                break;
              case "template-7":
                templateImage = template7;
                break;
              case "template-8":
                templateImage = template8;
                break;
              case "template-9":
                templateImage = template9;
                break;
              case "template-10":
                templateImage = template10;
                break;
              case "template-11":
                templateImage = template11;
                break;
              case "template-12":
                templateImage = template12;
                break;
              case "template-13":
                templateImage = template13;
                break;
              case "template-14":
                templateImage = template14;
                break;
              case "template-15":
                templateImage = template15;
                break;
              case "header":
                templateImage = header;
                break;
              case "pledge-your-birthday":
                templateImage = pledgeYourBirthday;
                break;
              case "gift-card":
                templateImage = giftCard;
                break;
            }
            return (
              <tr key={section.id}>
                <td><img src={templateImage} alt="" width="250" /></td>
                <td>{section.name}</td>
                <td>{section.key}</td>
                <td>
                  <Link
                    className="btn btn-info mr-2"
                    to={`/admin/cms-sections/edit/${section.id}`}
                  >
                    <i className="fa fa-pencil"></i>
                  </Link>
                  <Button
                    // disabled={page.isLocked}
                    variant="danger"
                    onClick={() => deleteSectionHandler(section.id)}
                  >
                    <i className="fa fa-trash"></i>
                  </Button>
                </td>
              </tr>
            );
          })}
        </table>
      </div>
    </div>
  );
}

export default List;
