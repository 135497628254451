import React from "react";
import { Modal, Row, Col, FormControl } from "react-bootstrap";

import LogoIco from "../../../../assets/elf-logo-clr.png";
import { Button, ButtonBlue } from "../../Button/Button";

import "./SponsorshipCancel.scss";

function SponsorshipCancel(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      className="SponsorshipCancel"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="TitleContent">
            <h5>Educate Lanka</h5>
            <h2>{props.title}</h2>
            <small>{props.description}</small>
            <img src={LogoIco} width="50" alt="Logo" />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12} className="mt-3 mb-3  text-center">
          <div className="inputContainer">
            <input
              type="text"
              value={props.note}
              placeholder="Add note"
              onChange={props.handleChangeNote}
            />
            </div>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col sm={6}>
            <Button title="Cancel" onClick={props.handleClose} full />
          </Col>
          <Col sm={6}>
            <ButtonBlue title="Confirm" onClick={props.handleConfirm} full />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default SponsorshipCancel;
