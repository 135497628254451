/*eslint-disable*/
import React, { useState, useEffect } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import SimpleReactValidator from "simple-react-validator";

import "react-datepicker/dist/react-datepicker.css";
import "./CreateCampaignForm.scss";
import { ButtonBlue } from "../../Reusable/Button/Button";
import Loading from "../../Reusable/Loading/Loading";

const type = [
  { value: "Birthday", label: "Birthday", name: "type" },
  { value: "SpecialOccasion", label: "Special Occasion", name: "type" },
  { value: "SportsEvent", label: "Sports Event", name: "type" },
  { value: "Fundraiser", label: "Fundraiser", name: "type" },
  { value: "Holidays", label: "Holidays", name: "type" },
  { value: "Something Else", label: "Something Else", name: "type" },
];

function CreateCampaign(props) {
  const [validator, setValidator] = useState(
    new SimpleReactValidator({ autoForceUpdate: this })
  );

  const [formData, setFormData] = useState({
    endDate: "",
    goal: "",
    type: null,
    name: "",
    selectImage: null,
    description: "",
    campaignImage: null,
    uploadImage: null,
  });

  useEffect(() => {
    if (props.edit && props.campaign.data) {
      const data = props.campaign.data;
      setFormData({
        ...formData,
        endDate: new Date(data.endDate),
        goal: data.goal,
        type: data.type,
        name: data.name,
        selectImage: data.selectImage,
        description: data.description,
        campaignImage: data.campaignImage,
        uploadImage: data.uploadImage,
      });
    }
  }, [props.campaign.data && props.edit]);

  useEffect(() => {
    return () => validator.hideMessages(); //Hide validation in unmount
  }, []);

  //DATE PICKER ON CHANGE
  const handleChangeDate = (e) => {
    setFormData({ ...formData, endDate: e });
  };
  //SELECT BOX ON CHANGE
  const handleOnSelectChange = (e) => {
    setFormData({ ...formData, type: e.value });
  };

  const handleChange = (e) => {
    if (e.target.type === "file") {

      const selectedFile = e.target.files[0];

      if (selectedFile) {
        const reader = new FileReader();
        reader.onload = (event) => {
          setFormData({ ...formData, [e.target.name]: selectedFile, uploadImage: reader.result });
        };
        reader.readAsDataURL(selectedFile);
        props.handleImageUploadAlert();
      }

      setFormData({ ...formData, [e.target.name]: selectedFile });

    } else {
      if (e.target.name === 'goal') {
        const inputValue = e.target.value;
        const cleanedValue = inputValue.replace(/,/g, "");

        // Check if the input is a valid number
        if (!isNaN(cleanedValue) || cleanedValue === "" || cleanedValue === ".") {
          setFormData({ ...formData, [e.target.name]: cleanedValue});
        }
      } else {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      }
    }
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      props.handleSubmit(formData);
    } else {
      validator.showMessages();
    }
  };

  return (
    <div className="CreateCampaignForm">
      <div className="inputContainer">
        <label>CAMPAIGN TYPE</label>
        {validator.message("Campaing type", formData.type, "required")}
        <Select
          isMulti={false}
          placeholder="Select type "
          value={type.filter((data) => data.value === formData.type)}
          onChange={handleOnSelectChange}
          options={type}
          className="customSelect"
        />
      </div>
      <div className="inputContainer sm icon">
        <label>CAMPAIGN NAME</label>
        {validator.message("Name", formData.name, "required")}
        <input
          type="text"
          onChange={handleChange}
          name="name"
          value={formData.name}
        />
        <i className="fa fa-bullhorn"></i>
      </div>
      <div className="inputContainer sm icon mb-0">
        <label>CAMPAIGN GOAL</label>
      </div>
      <div className="inputSideCont">
        {validator.message("Goal", formData.goal, "required|currency|min:10,num")}
        <div className="inputContainer sm icon">
          <input
            name="goal"
            onChange={handleChange}
            value={formData.goal}
            type="text"
          />
        </div>
        <div className="CamTag">
          <i className="fa fa-male fa-icn"></i>
          {formData.goal / 10} months of education will be funded
        </div>
      </div>
      <div className="inputContainer sm">
        <label>CAMPAIGN END DATE</label>
        {validator.message("End date", formData.endDate, "required")}
        <DatePicker
          minDate={new Date()}
          selected={formData.endDate}
          onChange={handleChangeDate}
        />
      </div>
      <div className="campainPhoto">
        <label>CAMPAIGN PHOTO</label>
        <div className="fileSelect">
          <span className="file">
            <input type="file" name="campaignImage" onChange={handleChange} />

            {formData.campaignImage ? (
              <small>{formData.campaignImage.name}</small>
            ) : (
              "UPLOAD your own photo"
            )}
          </span>
        </div>

        {formData.uploadImage && (
          <div
            className="imageUploaded"
            style={{
              backgroundImage: `url(${
                // process.env.REACT_APP_SERVER + "/" + formData.uploadImage
                formData.uploadImage
                })`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "contain",
            }}
          ></div>
        )}
      </div>
      <div className="inputContainer textarea">
        <label>CAMPAIGN STORY</label>
        {validator.message("CAMPAIGN STORY", formData.description, "required")}
        <textarea
          onChange={handleChange}
          name="description"
          rows="5"
          value={formData.description}
        ></textarea>
      </div>
      <div className="text-center">
        <ButtonBlue
          onClick={handleOnSubmit}
          title={props.edit ? "Update Campaign" : "Create Campaign"}
          disabled={props.campaign.loading ? true : false}
        />
        {props.campaign.loading && <Loading button />}
      </div>
    </div>
  );
}

export default CreateCampaign;
