import React from 'react'
import styled, {keyframes} from 'styled-components'

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Ensure it appears above other elements */
`

const LoadingContainer = styled.div`
  border-radius: 0.475rem;
  box-shadow: 0 0 50px 0 rgb(82 63 105 / 15%);
  color: #7e8299;
  font-weight: 500;
  margin: 0;
  width: auto;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Spinner = styled.div`
  border: 4px solid #fff;
  border-top: 4px solid #e3759b;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: ${spin} 1s linear infinite;
  margin-right: 0.5rem;
`

const CommonLoading = () => {
  return (
    <Overlay>
      <LoadingContainer>
        <div className='d-flex flex-column align-items-center'>
          <Spinner />
          <span className='text-white'>Processing...</span>
        </div>
      </LoadingContainer>
    </Overlay>
  )
}

export {CommonLoading}