import React from "react";
import "./SponsorshipRelationshipAgreement.scss";
import { useSearchParams } from "react-router-dom";

function SponsorshipRelationshipAgreement() {
  const [searchParams] = useSearchParams();
  const studentName = searchParams.get("studentName") || "<StudentName>";
  console.log("studentName", studentName);

  return (
    <div className="container mt-5 mb-5">
      <h1 className="title">
        Educate Lanka Student-Sponsor Relationship Agreement
      </h1>
      <h2> Educate Lanka Foundation, Inc. (“Educate Lanka”)</h2>
      <p>
        I hereby commit to sponsoring the education of{" "}
        <strong>{studentName} </strong>
        by financially contributing the agreed-upon monthly sponsorship amount
        through Educate Lanka Foundation, Inc. This sponsorship is intended to
        support the student’s educational expenses and provide them with
        opportunities to achieve their academic and personal development goals.
      </p>
      <hr />
      <h2>Terms and Conditions</h2>
      <ol>
        <li>
          <strong>Financial Commitment</strong>
          <p>
            I agree to make timely payments of the agreed-upon monthly
            sponsorship amount through the methods provided by the Educate Lanka
            Foundation. I acknowledge that my sponsorship funds will be
            allocated toward {studentName}'s educational needs in the form of a
            micro-scholarship, as deemed appropriate by Educate Lanka, to
            maximize the student’s development and success. I understand that
            failure to maintain timely payments may result in the suspension or
            termination of the sponsorship.
          </p>
        </li>
        <li>
          <strong>Reporting and Updates</strong>
          <p>
            Educate Lanka commits to providing periodic documentation and
            feedback related to {studentName}’s academic progress, achievements,
            and use of funds. I acknowledge that the frequency and format of
            updates may vary based on logistical and operational considerations.
          </p>
        </li>
        <li>
          <strong>Right to Modify or Cancel Sponsorship</strong>
          <p>
            I understand that I have the right to cancel or adjust my
            sponsorship commitment at any time by:
          </p>
          <ul>
            <li>
              Accessing my private profile dashboard on the Educate Lanka
              website, or
            </li>
            <li>Informing Educate Lanka in written form via email.</li>
          </ul>
          <p>
            I understand that cancellation of sponsorship does not absolve any
            outstanding payment obligations for the current sponsorship period,
            if applicable.
          </p>
        </li>
        <li>
          <strong>Communication and Correspondence</strong>
          <p>
            I acknowledge that all communication, including written
            correspondence, virtual meetings, or in-person visits with {studentName}, must be facilitated and approved by
            the Educate Lanka Foundation. Educate Lanka will, upon my request,
            facilitate such interactions in a manner that safeguards the
            confidentiality, safety, and integrity of both parties.
          </p>
          <p>
            I understand that Educate Lanka reserves the right to determine the
            appropriateness, timing, and nature of such communications to ensure
            alignment with the organization’s policies and safeguarding
            principles.
          </p>
        </li>
        <li>
          <strong>Confidentiality and Ethical Standards</strong>
          <p>
            I agree to respect and maintain the confidentiality of{" "}{studentName}’s personal information as shared by
            Educate Lanka Foundation. I acknowledge that Educate Lanka will
            protect my personal information and only share necessary details
            with the student under strict confidentiality protocols.
          </p>
          <p>
            I commit to upholding Educate Lanka’s ethical standards by fostering
            a positive, respectful, and professional relationship with {studentName}.
          </p>
        </li>
        <li>
          <strong>Use of Funds and Sponsorship Terms</strong>
          <p>
            I understand that my sponsorship contributions will be used solely
            for {studentName}’s education-related expenses, as
            determined by the Educate Lanka Foundation. I acknowledge that
            Educate Lanka reserves the right to redirect funds or adjust
            sponsorship terms in cases where unforeseen circumstances, such as
            changes in the student’s status or needs, necessitate such action.
          </p>
        </li>
        <li>
          <strong>Termination of Sponsorship by Educate Lanka</strong>
          <p>
            Educate Lanka Foundation reserves the right to terminate the
            sponsorship under the following circumstances:
          </p>
          <ul>
            <li>Breach of this agreement by the sponsor.</li>
            <li>
              Significant changes in the student’s educational circumstances.
            </li>
            <li>
              Ethical concerns or actions that compromise the integrity or
              safety of the student, the sponsor, or Educate Lanka’s operations.
            </li>
          </ul>
        </li>
      </ol>
    </div>
  );
}

export default SponsorshipRelationshipAgreement;
