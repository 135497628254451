import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { FormControl, FormGroup, FormLabel, Row, Col } from "react-bootstrap";
import { useEffect } from "react";

function TemplateThree({ section,editMode, handleSectionDataChange }) {
  const [data, setData] = useState({
    title: "",
    grid1: { iconLink: "", title: "", text1: "" },
    grid2: { iconLink: "", title: "", text1: "" },
    grid3: { iconLink: "", title: "", text1: "" },
  });
  useEffect(() => {
    handleSectionDataChange({
      ...section,
      index: section.index,
      data: data,
    });
  }, [data]);

    //===EDIT MODE SET DATA
    useEffect(() => {
      if (editMode&&section.data) {
        setData(section.data);
      }
  }, [editMode]);
    //===END EDIT MODE SET DATA

  const handleChange = (e,type) => {
    if (type) {
      setData((dt) => ({
        ...dt,
        [type]: {
          ...dt[type],
          [e.target.name]: e.target.value,
        },
      }));
    } else {
      setData((dt) => ({ ...dt, [e.target.name]: e.target.value }));
    }
  };

  const handleChangeTextOne = (e) => {
    setData((dt) => ({ ...dt, grid1: { ...dt.grid1, text1: e } }));
  };
  const handleChangeTextTwo = (e) => {
    setData((dt) => ({ ...dt, grid2: { ...dt.grid2, text1: e } }));
  };
  const handleChangeTextThree = (e) => {
    setData((dt) => ({ ...dt, grid3: { ...dt.grid3, text1: e } }));
  };
  return (
    <div>
      <FormGroup>
        <FormLabel>Title</FormLabel>
        <FormControl name="title" value={data?.title} onChange={handleChange} />
      </FormGroup>

      <Row>
        <Col sm={4}>
          <FormGroup>
            <FormLabel>Icon</FormLabel>
            <input className="form-control"
              name="iconLink"
              value={data?.grid1?.iconLink}
              onChange={e=>handleChange(e,"grid1")}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Title</FormLabel>
            <input className="form-control"
              name="title"
              value={data?.grid1?.title}
              onChange={e=>handleChange(e,"grid1")}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Content</FormLabel>
            <Editor
              name="text1"
              value={data?.grid1?.text1}
              apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
              init={{
                plugins: [
                  "lists link image paste help wordcount code",
                  "searchreplace",
                  "insertdatetime media",
                ],
              }}
              onEditorChange={handleChangeTextOne}
            />
          </FormGroup>
        </Col>
        <Col sm={4}>
          <FormGroup>
            <FormLabel>Icon</FormLabel>
            <input className="form-control"
    
              name="iconLink"
              value={data?.grid2?.iconLink}
              onChange={e=>handleChange(e,"grid2")}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Title</FormLabel>
            <input className="form-control"
    
              name="title"
              value={data?.grid2?.title}
              onChange={e=>handleChange(e,"grid2")}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Content</FormLabel>
            <Editor
              name="text1"
              value={data?.grid2?.text1}
              apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
              init={{
                plugins: [
                  "lists link image paste help wordcount code",
                  "searchreplace",
                  "insertdatetime media",
                ],
              }}
              onEditorChange={handleChangeTextTwo}
            />
          </FormGroup>
        </Col>
        <Col sm={4}>
          <FormGroup>
            <FormLabel>Icon</FormLabel>
            <input className="form-control"
    
              name="iconLink"
              value={data?.grid3?.iconLink}
              onChange={e=>handleChange(e,"grid3")}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Title</FormLabel>
            <input className="form-control"
    
              name="title"
              value={data?.grid3?.title}
              onChange={e=>handleChange(e,"grid3")}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Content</FormLabel>
            <Editor
              name="text1"
              value={data?.grid3?.text1}
              apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
              init={{
                plugins: [
                  "lists link image paste help wordcount code",
                  "searchreplace",
                  "insertdatetime media",
                ],
              }}
              onEditorChange={handleChangeTextThree}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
}

export default TemplateThree;
