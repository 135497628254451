import React from "react";

import UserTag from "./UserTag";

function UsersList(props) {
  const { users } = props;
  return (
    <div className="UsersList">
      {users?.map((user) => {
        return <UserTag key={user.id} user={user} />;
      })}
    </div>
  );
}

export default UsersList;
