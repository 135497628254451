/*eslint-disable*/
import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions";

const PaymentSuccess = (props) => {
  useEffect(() => {
    var url = new URL(window.location.href);
    var PayerID = url.searchParams.get("PayerID");
    const data = {
      PayerID: PayerID,
    };
    props.getPaypalExecute(data, props.auth.token);
  }, []);
  return (
    <div>
      <h1>Successs</h1>
    </div>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  getPaypalExecute: (data, token) =>
    dispatch(actionCreators.getPaypalExecute(data, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess);
